<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.833008 8.33203H19.1663M2.49967 3.33203H17.4997C18.4201 3.33203 19.1663 4.07822 19.1663 4.9987V14.9987C19.1663 15.9192 18.4201 16.6654 17.4997 16.6654H2.49967C1.5792 16.6654 0.833008 15.9192 0.833008 14.9987V4.9987C0.833008 4.07822 1.5792 3.33203 2.49967 3.33203Z"
      stroke-linejoin="round"
      stroke-linecap="round"
      stroke="currentColor"
      stroke-width="1.5"
    />
  </svg>
</template>

<script lang="ts">
  import { Component, Vue } from 'vue-property-decorator';

  @Component
  export default class CreditCardIcon extends Vue {
    /*****         computed       *****/
    /*****         watchers       *****/
    /*****         methods        *****/
    /*****         helpers        *****/
    /*****      vue lifecycle     *****/
  }
</script>
