import ApiRepository from '@/repository/config/Api';
import type IUrlParams from '@/interfaces/IUrlParams';
import { AxiosPromise } from 'axios';

export default class ChartRepository {
  // Retrieve document chart data
  public static documentChart(companyUUID: string, params: IUrlParams): AxiosPromise {
    return ApiRepository.get('/companies/' + companyUUID + '/bookkeeping-records/chart', { params });
  }
}
