import app from '@/main';
import store from '@/store';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import DEFAULT_USER_REFERENCE_FORM from '@/constants/mocks/forms/UserReference';
import type Locale from '@/enums/config/Locale';
import type IUserReference from '@/interfaces/reference/IUserReference';
import type IVatReference from '@/interfaces/reference/IVatReference';
import type IVatCode from '@/interfaces/IVatCode';
import UserModule from '@/store/modules/User';
import TranslationModule from '@/store/modules/Translation';
import ReferencesRepository from '@/repository/References';
import TranslateMixin from '@/mixins/Translate';

@Module({
  store,
  name: 'Reference',
  namespaced: true,
  dynamic: true,
})
class Reference extends VuexModule {
  private vatCodes: IVatCode[] = [];
  private vatReferences: IVatReference[] = [];
  private userReferences: IUserReference[] = [];

  public get getVatCodes(): IVatCode[] {
    return this.vatCodes;
  }

  public get getVatReferences(): IVatReference[] {
    return this.vatReferences;
  }

  public get getUserReferences(): IUserReference[] {
    return this.userReferences;
  }

  public get getDefaultUserReference(): IUserReference {
    return (
      this.userReferences.find((ref) => ref.is_default) || {
        ...DEFAULT_USER_REFERENCE_FORM,
      }
    );
  }

  /*******   Set action   *******/

  @Action({ commit: 'UPDATE_VAT_CODES' })
  public async SET_VAT_CODES(locale: Locale = TranslationModule.getLocale): Promise<IVatCode[]> {
    let codes: IVatCode[] = [];
    try {
      codes = (await ReferencesRepository.listSystemDefinedVatCodes({ locale })).data;
      codes.forEach((codes) => {
        codes.vat_code_group = app.joinByPipe([codes.vat_code, codes.vat_exemption_code]);
      });
    } catch (e) {
      app.$logger('unable to fetch PEPPOL VAT codes...');
    }
    return codes;
  }

  @Action({ commit: 'UPDATE_VAT_REFERENCES' })
  public async SET_VAT_REFERENCES(locale: Locale = TranslationModule.getLocale): Promise<IVatReference[]> {
    let references: IVatReference[] = [];
    try {
      const translate = new TranslateMixin();
      references = (await ReferencesRepository.listSystemDefinedVatReferences({ locale })).data;
      references.forEach((reference) => {
        reference.vat_rate_group = translate.translateVatRateGroup(reference.vat_rate, locale);
      });
    } catch (e) {
      app.$logger('unable to fetch VAT references...');
    }
    return references;
  }

  @Action({ commit: 'UPDATE_USER_REFERENCES' })
  public async SET_USER_REFERENCES(companyUUID: string = UserModule.getCompanyUUID): Promise<IUserReference[]> {
    let userReferences: IUserReference[] = [];
    try {
      userReferences = (await ReferencesRepository.list(companyUUID)).data;
    } catch (e) {
      app.$logger('unable to user references...');
    }
    return userReferences;
  }

  @Action({ commit: 'UPDATE_USER_REFERENCES' })
  public async ADD_USER_REFERENCE(userReference: IUserReference): Promise<IUserReference[]> {
    const userReferences = [...this.userReferences];
    userReferences.push(userReference);
    return userReferences;
  }

  @Action({ commit: 'UPDATE_USER_REFERENCES' })
  public async SET_DEFAULT_USER_REFERENCE(referenceUUID: string): Promise<IUserReference[]> {
    const userReferences = [...this.userReferences];

    // Set all references to not default
    userReferences.forEach((ref) => {
      ref.is_default = false;
    });

    // Find the specified reference and set it as default
    const index = userReferences.findIndex((ref) => ref.uuid === referenceUUID);
    if (index > -1) {
      userReferences[index].is_default = true;
    }

    return userReferences;
  }

  @Action({ commit: 'UPDATE_USER_REFERENCES' })
  public async DELETE_USER_REFERENCE(referenceUUID: string): Promise<IUserReference[]> {
    const userReferences = [...this.userReferences];
    const index = userReferences.findIndex((ref) => ref.uuid === referenceUUID);
    if (index > -1) {
      userReferences.splice(index, 1);
    }
    return userReferences;
  }

  /*******   Unset action   *******/

  @Action({ commit: 'UPDATE_VAT_CODES' })
  public async CLEAR_VAT_CODES(): Promise<IVatCode[]> {
    return [];
  }

  @Action({ commit: 'UPDATE_VAT_REFERENCES' })
  public async CLEAR_VAT_REFERENCES(): Promise<IVatReference[]> {
    return [];
  }

  @Action({ commit: 'UPDATE_USER_REFERENCES' })
  public async CLEAR_USER_REFERENCES(): Promise<IUserReference[]> {
    return [];
  }

  /*******   Update mutation   *******/

  @Mutation
  public async UPDATE_VAT_CODES(vatCodes: IVatCode[]): Promise<IVatCode[]> {
    return (this.vatCodes = vatCodes);
  }

  @Mutation
  public async UPDATE_VAT_REFERENCES(vatReferences: IVatReference[]): Promise<IVatReference[]> {
    return (this.vatReferences = vatReferences);
  }

  @Mutation
  public async UPDATE_USER_REFERENCES(userReferences: IUserReference[]): Promise<IUserReference[]> {
    return (this.userReferences = userReferences);
  }
}

const ReferenceModule = getModule(Reference);

export default ReferenceModule;
