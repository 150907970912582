import { RouteConfig } from 'vue-router';
import PartnerRouteName from '@/enums/config/router/Partner';

const PartnerRouter: Array<RouteConfig> = [
  {
    path: 'partner',
    component: () => import(/* webpackChunkName: "appView" */ '@/views/Partner.vue'),
    children: [
      {
        path: '/',
        name: PartnerRouteName.INDEX,
        component: () => import(/* webpackChunkName: "appView" */ '@/pages/app/partner/Index.vue'),
        meta: {
          title: 'Partner',
        },
        props: true,
      },
    ],
  },
];

export default PartnerRouter;
