<template>
  <div class="flex flex-col h-full">
    <v-scroll-y-transition hide-on-leave leave-absolute>
      <RouterView :key="$route.meta?.parent ?? $route.fullPath" />
    </v-scroll-y-transition>
  </div>
</template>

<script lang="ts">
  import { Component, Vue } from 'vue-property-decorator';

  @Component
  export default class PublicLayout extends Vue {
    /*****         computed       *****/
    /*****         watchers       *****/
    /*****         methods        *****/
    /*****         helpers        *****/
    /*****      vue lifecycle     *****/
  }
</script>
