import { Component, Mixins } from 'vue-property-decorator';
import CatchError from '@/decorators/CatchError';
import Loading from '@/decorators/Loading';
import LocalStorage from '@/enums/config/LocalStorage';
import UserRole from '@/enums/config/UserRole';
import AppState from '@/enums/generics/AppState';
import AuthRouteName from '@/enums/config/router/Auth';
import ProfileRouteName from '@/enums/config/router/Profile';
import type Locale from '@/enums/config/Locale';
import type ErrorCode from '@/enums/generics/ErrorCode';
import type IUser from '@/interfaces/user/IUser';
import type IPendingUser from '@/interfaces/multiuser/IPendingUser';
import type IProfileUpdateForm from '@/interfaces/forms/IProfileUpdateForm';
import type IUserInvitationForm from '@/interfaces/forms/IUserInvitationForm';
import type IVerificationForm from '@/interfaces/forms/auth/IVerificationForm';
import type IProfileMixin from '@/interfaces/mixins/IProfileMixin';
import AuthMixin from '@/mixins/Auth';
import AcceptInvitationMixin from '@/mixins/profile/invitation/Accept';
import FecthInvitationMixin from '@/mixins/profile/invitation/Fetch';
import RejectInvitationMixin from '@/mixins/profile/invitation/Reject';
import CloseMixin from '@/mixins/profile/Close';
import FetchMixin from '@/mixins/profile/Fetch';
import LeaveUserCompanyMixin from '@/mixins/profile/LeaveCompany';
import ResendVerificationMixin from '@/mixins/profile/ResendVerification';
import SwitchUserCompanyMixin from '@/mixins/profile/SwitchCompany';
import UpdateMixin from '@/mixins/profile/Update';
import VerifyEmailMixin from '@/mixins/profile/VerifyEmail';
import AppModule from '@/store/modules/App';
import CompanyModule from '@/store/modules/Company';
import PageModule from '@/store/modules/Page';
import TranslationModule from '@/store/modules/Translation';
import UserModule from '@/store/modules/User';

@Component
export default class ProfileMixin extends Mixins<IProfileMixin>(
  AcceptInvitationMixin,
  FecthInvitationMixin,
  RejectInvitationMixin,
  CloseMixin,
  FetchMixin,
  LeaveUserCompanyMixin,
  ResendVerificationMixin,
  SwitchUserCompanyMixin,
  UpdateMixin,
  VerifyEmailMixin,
) {
  // Fetch user profile - use ProfileFetchMixin
  @Loading()
  public async profileFetch(): Promise<void> {
    const user = await this.FetchMixin();
    if (user) {
      await UserModule.SET_USER_FIELD(user);
    } else {
      await UserModule.CLEAR_USER();
      await this.$notifier({
        message: this.trans('notify.error.unauthorized', 'Lietotājs nav pieslēdzies'),
        state: AppState.ERROR,
      });
      if (this.$route.name != AuthRouteName.LOGIN) {
        await this.$router.replace({ name: AuthRouteName.LOGIN });
      }
    }

    await new AuthMixin().CsrfMixin();
  }

  // Update user profile - use ProfileUpdateMixin
  @Loading()
  @CatchError()
  public async profileUpdate(payload: IProfileUpdateForm): Promise<IUser> {
    const locale: Locale = TranslationModule.getLocale;

    const { user, message } = await this.UpdateMixin(payload);

    if (locale != user.locale) {
      await TranslationModule.SWITCH_LOCALE(user.locale);
    }
    await UserModule.UPDATE_USER(user);

    await this.$notifier({ message, state: AppState.SUCCESS });

    return user;
  }

  // Switch user company - SwitchUserCompanyMixin
  @Loading()
  @CatchError()
  public async switchUserCompany(companyUUID: string, rememberCompany = false, notifyUser = true): Promise<void> {
    await this.SwitchUserCompanyMixin(companyUUID, rememberCompany);

    await PageModule.CLEAR_URL_PARAMS();
    await AppModule.SWITCH_COMPANY();

    if (notifyUser) {
      await this.$notifier({
        message: this.trans('notify.success.company.switched', 'Jūs esat veiksmīgi pārslēdzies uz :company', {
          company: CompanyModule.getCompanyName,
        }),
        state: AppState.SUCCESS,
      });
    }
  }

  // Set remember user company - SwitchUserCompanyMixin
  @Loading()
  @CatchError()
  public async rememberUserCompany(rememberCompany: boolean): Promise<void> {
    await this.SwitchUserCompanyMixin(UserModule.getCompanyUUID, rememberCompany);
    await UserModule.SET_USER_FIELD({ remember_company: rememberCompany });
  }

  // Leave user company - use ProfileLeaveUserCompanyMixin
  @Loading()
  @CatchError()
  public async leaveUserCompany(companyUUID: string): Promise<void> {
    const selectedCompany = UserModule.getCompanies.find((company) => company.selected);
    const leavingCompany = UserModule.getCompanies.find((company) => company.uuid === companyUUID);
    const myCompany = UserModule.getCompanies.find((company) => company.role === UserRole.OWNER);

    await this.LeaveUserCompanyMixin(companyUUID);

    await this.$notifier({
      message: this.trans('notify.success.company.left', 'Jūs vairs neesat saistīts ar uzņēmumu :company', {
        company: leavingCompany?.name ?? '',
      }),
      state: AppState.SUCCESS,
    });

    // If user is leaving selected company, switch to my company when I am owner
    // Otherwise just fetch user profile, to get updated company list
    const selectedWasRemoved = selectedCompany && leavingCompany && selectedCompany?.uuid === leavingCompany?.uuid;
    if (selectedWasRemoved && myCompany) {
      const notifyUser = false;
      await this.switchUserCompany(myCompany.uuid, UserModule.rememberCompany, notifyUser);
    } else {
      await UserModule.SET_USER();
      if (!CompanyModule.hasCompany) {
        await CompanyModule.SET_COMPANY();

        if (!CompanyModule.hasCompany) {
          await this.$router.replace({ name: ProfileRouteName.INDEX });
        }
      }
    }
  }

  // Resend verification email - use ProfileResendEmailVerificationMixin
  @Loading()
  @CatchError()
  public async profileResendVerification(email: string): Promise<boolean> {
    const isSuccess = await this.ResendVerificationMixin();

    if (isSuccess) {
      await this.$notifier({
        message: this.trans(
          'profile.email_verification_resent.title',
          'E-pasta apstiprinājums ir atkārtoti nosūtīts uz Jūsu epastu :email',
          { email },
        ),
        state: AppState.SUCCESS,
      });
    }
    return isSuccess;
  }

  // Verify users new email- use ProfileVerifyEmailMixin
  public async profileVerifyEmail(payload: IVerificationForm): Promise<boolean> {
    const isSuccess = await this.VerifyEmailMixin(payload);
    const isLoggedIn: boolean = UserModule.isLoggedIn;

    if (isSuccess && isLoggedIn) {
      await UserModule.SET_USER();
    }

    return isSuccess;
  }

  // Close users account- use ProfileCloseMixin
  @Loading()
  @CatchError()
  public async profileClose(password: string): Promise<boolean> {
    const isSuccess = await this.CloseMixin(password);

    await UserModule.CLEAR_USER();

    if (this.$route.name != AuthRouteName.LOGIN) {
      await this.$router.replace({ name: AuthRouteName.LOGIN });
    }

    await CompanyModule.CLEAR_COMPANY();

    await this.$notifier({
      message: this.trans('notify.success.account_closed', 'Konts ir veiksmīgi slēgts'),
      state: AppState.SUCCESS,
    });

    await new AuthMixin().CsrfMixin();
    return isSuccess;
  }

  // Fetch user invitation data- use ProfileFetchInvitationMixin
  public async profileFetchInvitation(invitation: IUserInvitationForm): Promise<IPendingUser | ErrorCode> {
    return await this.FecthInvitationMixin(invitation.uuid, invitation.hash);
  }

  // Accept user invitation- use ProfileAcceptInvitationMixin
  @Loading()
  @CatchError()
  public async profileAcceptInvitation(invitation: IUserInvitationForm): Promise<void> {
    await this.AcceptInvitationMixin(invitation.uuid, invitation.hash);
    localStorage.setItem(LocalStorage.SWITCH_COMPANY, 'show');
    await UserModule.SET_USER();
    await CompanyModule.SET_COMPANY();

    await this.$notifier({
      message: this.trans('notify.success.invitation.accepted', 'Ielūgums veiksmīgi pieņemts'),
      state: AppState.SUCCESS,
    });
  }

  // Reject user invitation- use ProfileRejectInvitationMixin
  @Loading()
  @CatchError()
  public async profileRejectInvitation(invitation: IUserInvitationForm): Promise<void> {
    await this.RejectInvitationMixin(invitation.uuid, invitation.hash);

    await this.$notifier({
      message: this.trans('notify.success.invitation.rejected', 'Ielūgums veiksmīgi noraidīts'),
      state: AppState.SUCCESS,
    });
  }
}
