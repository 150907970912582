<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.6667 1.33337L28 6.66671M28 6.66671L22.6667 12M28 6.66671H9.33333C7.91885 6.66671 6.56229 7.22861 5.5621 8.2288C4.5619 9.229 4 10.5856 4 12V14.6667M9.33333 30.6667L4 25.3334M4 25.3334L9.33333 20M4 25.3334H22.6667C24.0812 25.3334 25.4377 24.7715 26.4379 23.7713C27.4381 22.7711 28 21.4145 28 20V17.3334"
      stroke-linejoin="round"
      stroke-linecap="round"
      stroke="currentColor"
      stroke-width="2"
    />
  </svg>
</template>

<script lang="ts">
  import { Component, Vue } from 'vue-property-decorator';

  @Component
  export default class RepeatIcon extends Vue {
    /*****         computed       *****/
    /*****         watchers       *****/
    /*****         methods        *****/
    /*****         helpers        *****/
    /*****      vue lifecycle     *****/
  }
</script>
