import cloneDeep from 'lodash-es/cloneDeep';
import { Component, Vue } from 'vue-property-decorator';
import DEFAULT_COMPANY from '@/constants/mocks/company/Company';
import DEFAULT_DOCUMENT_FORM from '@/constants/mocks/forms/Document';
import DEFAULT_STOCK_FORM from '@/constants/mocks/forms/Stock';
import DateFormat from '@/enums/config/DateFormat';
import DocumentStatus from '@/enums/config/document/Status';
import SignatureType from '@/enums/generics/SignatureType';
import type ICompany from '@/interfaces/company/ICompany';
import type IDocument from '@/interfaces/document/IDocument';
import type IStock from '@/interfaces/stock/IStock';
import CompanyModule from '@/store/modules/Company';
import GenericModule from '@/store/modules/Generic';
import UserModule from '@/store/modules/User';
import StockMixin from '@/mixins/Stock';
import TranslateMixin from '@/mixins/Translate';

@Component
export default class InitCopyDocumentMixin extends Vue {
  public async InitCopyDocumentMixin(fetchedDocument: IDocument): Promise<IDocument> {
    const unitList = GenericModule.getUnits;
    const document: IDocument = cloneDeep({ ...DEFAULT_DOCUMENT_FORM, ...fetchedDocument });

    const company: ICompany = CompanyModule.getCompany || DEFAULT_COMPANY;

    document.logo = company.logo;
    document.color = company.default_document_accent_color;
    document.status = DocumentStatus.DRAFT;
    document.currency_code =
      document.currency_code ||
      document.recipient.preferences.currency_code ||
      company.currency_code ||
      DEFAULT_COMPANY.default_document_currency_code;

    const singleVat = document.has_multi_vat
      ? CompanyModule.getDefaultTaxRate
      : new StockMixin().composeVatPercent(document.items[0].vat_percent);
    document.single_vat = singleVat;

    // Remove empty items and fix measurement
    const payloadItems: IStock[] = document.items
      .filter((e) => !!e.name || !!e.measurement || !!e.price)
      .map((e) => {
        e.measurement = (unitList.find((f) => e.measurement === f.text)?.value ?? e.measurement ?? '').toString();
        e.vat_percent = document.sender.vat_registration_number
          ? document.has_multi_vat
            ? e.vat_percent
            : singleVat
          : 0;
        e.discount = document.has_discount ? e.discount : null;
        return { ...e, ...new StockMixin().composeItem(e) };
      });

    document.items = cloneDeep(payloadItems.length ? payloadItems : [DEFAULT_STOCK_FORM]);

    // Reformat document dates
    document.date = this.parseFormatToFormat(document.date, DateFormat.DATABASE, DateFormat.LARAVEL);
    document.due_date = this.parseFormatToFormat(document.due_date, DateFormat.DATABASE, DateFormat.LARAVEL);

    const dueDays = this.diffDateFormat(document.date, document.due_date, DateFormat.LARAVEL);
    document.date = this.parseNowToFormat(DateFormat.LARAVEL);
    document.due_date = dueDays ? this.addPeriodToFormat(document.date, DateFormat.LARAVEL, dueDays, 'days') : '';

    // Set signature signatories
    const signatories = document.signature.signatories ?? DEFAULT_DOCUMENT_FORM.signature.signatories;
    document.signature.signatories = signatories;

    // Set signature dates
    if (document.signature.type === SignatureType.ESIGNATURE) {
      document.signature.signatories!.sender_sign_date = '';
      document.signature.signatories!.recipient_sign_date = '';
    }

    if (document.signature.type === SignatureType.SENDER_SIGNATURE) {
      document.signature.signatories!.sender_sign_date = document.date;
    }

    if (document.signature.type === SignatureType.BOTH_SIGNATURES) {
      document.signature.signatories!.sender_sign_date = document.date;
      document.signature.signatories!.recipient_sign_date = document.date;
    }

    // This is a workaround to fix the locale of the additional price rows
    // Backend could return the description in document locale
    // But we need to display it in the system locale
    for (const e of document.additional_price_rows) {
      e.description = await new TranslateMixin().translatePaidWithInSystemLocale(e.description, document.lang);
    }

    if (!UserModule.isReadOnly) {
      document.permissions.edit = true;
      document.permissions.delete = true;
    }

    document.logs = [];

    return cloneDeep(document);
  }
}
