import { Component, Vue } from 'vue-property-decorator';
import type IUrlParams from '@/interfaces/IUrlParams';
import DocumentRepository from '@/repository/Document';
import CompanyModule from '@/store/modules/Company';

@Component
export default class ListLabelsDocumentsMixin extends Vue {
  public async ListLabelsDocumentsMixin(
    params: IUrlParams,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<string[]> {
    try {
      return (await DocumentRepository.listLabels(companyUUID, params)).data;
    } catch (_) {
      this.$logger('There was a problem to list document labels...');
      return [];
    }
  }
}
