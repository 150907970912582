<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M30.6667 5.33335V13.3334M30.6667 13.3334H22.6667M30.6667 13.3334L24.48 7.52002C23.047 6.0863 21.2742 5.03896 19.327 4.47572C17.3797 3.91247 15.3215 3.85169 13.3444 4.29903C11.3673 4.74637 9.53577 5.68726 8.02066 7.03391C6.50555 8.38056 5.35626 10.0891 4.68004 12M1.33337 26.6667V18.6667M1.33337 18.6667H9.33337M1.33337 18.6667L7.52004 24.48C8.95304 25.9137 10.7259 26.9611 12.6731 27.5243C14.6204 28.0876 16.6786 28.1483 18.6557 27.701C20.6328 27.2537 22.4643 26.3128 23.9794 24.9661C25.4945 23.6195 26.6438 21.911 27.32 20"
      stroke-linejoin="round"
      stroke-linecap="round"
      stroke="currentColor"
      stroke-width="2"
    />
  </svg>
</template>

<script lang="ts">
  import { Component, Vue } from 'vue-property-decorator';

  @Component
  export default class RefreshIcon extends Vue {
    /*****         computed       *****/
    /*****         watchers       *****/
    /*****         methods        *****/
    /*****         helpers        *****/
    /*****      vue lifecycle     *****/
  }
</script>
