import store from '@/store';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import DEFAULT_GENERICS from '@/constants/mocks/Generics';
import DISCOUNTS from '@/constants/lists/Discounts';
import PERIODS from '@/constants/lists/Periods';
import PER_PAGE from '@/constants/lists/PerPage';
import LATE_FEES from '@/constants/lists/LateFees';
import TIMEZONES from '@/constants/lists/Timezone';
import VAT_PERCENTS from '@/constants/lists/VatPercents';
import DateRange from '@/enums/config/DateRange';
import DocumentStatus from '@/enums/config/document/Status';
import PeriodType from '@/enums/config/PeriodType';
import TemplateKey from '@/enums/config/email/TemplateKey';
import BankType from '@/enums/generics/BankType';
import Unit from '@/enums/generics/Unit';
import type IGenericState from '@/interfaces/states/IGeneric';
import type ISelect from '@/interfaces/config/ISelect';
import type ITranslation from '@/interfaces/ITranslation';
import TranslationModule from '@/store/modules/Translation';
import TranslateMixin from '@/mixins/Translate';

@Module({
  store,
  name: 'Generic',
  namespaced: true,
  dynamic: true,
})
class Generic extends VuexModule {
  private generics: IGenericState = DEFAULT_GENERICS;

  public get getGenerics(): IGenericState {
    return this.generics;
  }

  public get getDateRanges(): ISelect[] {
    return this.getGenerics.dateRanges;
  }

  public get getDiscounts(): ISelect[] {
    return this.getGenerics.discounts;
  }

  public get getEmailTemplateKeys(): ISelect[] {
    return this.getGenerics.emailTemplateKeys;
  }

  public get getLanguages(): ISelect[] {
    return this.getGenerics.languages;
  }

  public get getLateFees(): ISelect[] {
    return this.getGenerics.lateFees;
  }

  public get getPaymentMethods(): ISelect[] {
    return this.getGenerics.paymentMethods;
  }

  public get getPeriods(): ISelect[] {
    return this.getGenerics.periods;
  }

  public get getPeriodTypes(): ISelect[] {
    return this.getGenerics.periodTypes;
  }

  public get getPerPage(): ISelect[] {
    return this.getGenerics.perPage;
  }

  public get getStatusColors(): ISelect[] {
    return this.getGenerics.statusColors;
  }

  public get getTimezones(): ISelect[] {
    return this.getGenerics.timezones;
  }

  public get getUnits(): ISelect[] {
    return this.getGenerics.units;
  }

  public get getVatPercents(): ISelect[] {
    return this.getGenerics.vatPercents;
  }

  /*******   Set action   *******/

  // Create all default select state
  @Action
  public async SET_DEFAULTS(): Promise<void> {
    await this.SET_DATE_RANGES();
    await this.SET_DISCOUNTS();
    await this.SET_EMAIL_TEMPLATE_KEYS();
    await this.SET_LANGUAGES();
    await this.SET_LATE_FEES();
    await this.SET_PAYMENT_METHODS();
    await this.SET_PERIODS();
    await this.SET_PERIOD_TYPES();
    await this.SET_PER_PAGE();
    await this.SET_STATUS_COLORS();
    await this.SET_TIMEZONES();
    await this.SET_UNITS();
    await this.SET_VAT_PERCENTS();
  }

  @Action({ commit: 'UPDATE_GENERICS' })
  public async SET_DATE_RANGES(): Promise<Partial<IGenericState>> {
    const translate = new TranslateMixin();
    const dateRanges = Object.values(DateRange).map((e: DateRange) => {
      return { text: translate.translateDateRange(e), value: e };
    });

    return { dateRanges };
  }

  @Action({ commit: 'UPDATE_GENERICS' })
  public async SET_DISCOUNTS(): Promise<Partial<IGenericState>> {
    const discounts = DISCOUNTS.map((e: ISelect) => {
      return { text: e.text, value: e.value };
    });

    return { discounts };
  }

  @Action({ commit: 'UPDATE_GENERICS' })
  public async SET_EMAIL_TEMPLATE_KEYS(): Promise<Partial<IGenericState>> {
    const translate = new TranslateMixin();
    const emailTemplateKeys = Object.values(TemplateKey).map((e: TemplateKey) => {
      return { text: translate.translateEmailTemplateKey(e), value: e };
    });
    return { emailTemplateKeys };
  }

  @Action({ commit: 'UPDATE_GENERICS' })
  public async SET_LANGUAGES(
    translations: ITranslation[] = TranslationModule.getTranslations,
  ): Promise<Partial<IGenericState>> {
    const translate = new TranslateMixin();
    const languages = translations.map((e: ITranslation) => {
      return {
        text: translate.translateLanguage(e.locale),
        value: e.locale,
        image: require(`@/assets/svg/flag/${e.locale}.svg`),
      };
    });

    return { languages };
  }

  @Action({ commit: 'UPDATE_GENERICS' })
  public async SET_LATE_FEES(): Promise<Partial<IGenericState>> {
    const lateFees = LATE_FEES.map((e: ISelect) => {
      return { text: e.text, value: e.value };
    });

    return { lateFees };
  }

  @Action({ commit: 'UPDATE_GENERICS' })
  public async SET_PAYMENT_METHODS(): Promise<Partial<IGenericState>> {
    const translate = new TranslateMixin();
    const paymentMethods = Object.values(BankType).map((e: BankType) => {
      return { text: translate.translateBankType(e), value: e };
    });
    return { paymentMethods };
  }

  @Action({ commit: 'UPDATE_GENERICS' })
  public async SET_PERIODS(): Promise<Partial<IGenericState>> {
    const periods = PERIODS.map((e: ISelect) => {
      return { text: e.text, value: e.value };
    });

    return { periods };
  }

  @Action({ commit: 'UPDATE_GENERICS' })
  public async SET_PERIOD_TYPES(): Promise<Partial<IGenericState>> {
    const translate = new TranslateMixin();
    const periodTypes = Object.values(PeriodType).map((e: PeriodType) => {
      return { text: translate.translateRecurringPeriodType(e), value: e };
    });
    return { periodTypes };
  }

  @Action({ commit: 'UPDATE_GENERICS' })
  public async SET_PER_PAGE(): Promise<Partial<IGenericState>> {
    const perPage = PER_PAGE.map((e: ISelect) => {
      return { text: e.text, value: e.value };
    });

    return { perPage };
  }

  @Action({ commit: 'UPDATE_GENERICS' })
  public async SET_STATUS_COLORS(): Promise<Partial<IGenericState>> {
    const statusColors = [
      {
        value: DocumentStatus.DRAFT,
        text: 'granite',
      },
      {
        value: DocumentStatus.SENT,
        text: 'picton',
      },
      {
        value: DocumentStatus.PARTIALLY_PAID,
        text: 'tangerine',
      },
      {
        value: DocumentStatus.PAID,
        text: 'primary',
      },
      {
        value: DocumentStatus.OVERDUE,
        text: 'carmine',
      },
      {
        value: DocumentStatus.CANCELED,
        text: 'black',
      },
    ];
    return { statusColors };
  }

  @Action({ commit: 'UPDATE_GENERICS' })
  public async SET_TIMEZONES(): Promise<Partial<IGenericState>> {
    const timezones = TIMEZONES.map((e: ISelect) => {
      const text = `(${e.value}) ${e.text.toString().replace('_', ' ')}`;
      return { text, value: e.text };
    });

    return { timezones };
  }

  @Action({ commit: 'UPDATE_GENERICS' })
  public async SET_UNITS(): Promise<Partial<IGenericState>> {
    const translate = new TranslateMixin();
    const units = Object.values(Unit).map((e: Unit) => {
      return { text: translate.translateUnit(e), value: e };
    });
    return { units };
  }

  @Action({ commit: 'UPDATE_GENERICS' })
  public async SET_VAT_PERCENTS(): Promise<Partial<IGenericState>> {
    const vatPercents = VAT_PERCENTS.map((e: ISelect) => {
      return { text: e.text, value: e.value };
    });

    return { vatPercents };
  }

  /*******   Unset action   *******/

  @Action({ commit: 'UPDATE_GENERICS' })
  public async CLEAR_DATE_RANGES(): Promise<Partial<IGenericState>> {
    return { dateRanges: DEFAULT_GENERICS.dateRanges };
  }

  @Action({ commit: 'UPDATE_GENERICS' })
  public async CLEAR_DISCOUNTS(): Promise<Partial<IGenericState>> {
    return { discounts: DEFAULT_GENERICS.discounts };
  }

  @Action({ commit: 'UPDATE_GENERICS' })
  public async CLEAR_EMAIL_TEMPLATE_KEYS(): Promise<Partial<IGenericState>> {
    return { emailTemplateKeys: DEFAULT_GENERICS.emailTemplateKeys };
  }

  @Action({ commit: 'UPDATE_GENERICS' })
  public async CLEAR_LANGUAGES(): Promise<Partial<IGenericState>> {
    return { languages: DEFAULT_GENERICS.languages };
  }

  @Action({ commit: 'UPDATE_GENERICS' })
  public async CLEAR_LATE_FEES(): Promise<Partial<IGenericState>> {
    return { lateFees: DEFAULT_GENERICS.lateFees };
  }

  @Action({ commit: 'UPDATE_GENERICS' })
  public async CLEAR_PAYMENT_METHODS(): Promise<Partial<IGenericState>> {
    return { paymentMethods: DEFAULT_GENERICS.paymentMethods };
  }

  @Action({ commit: 'UPDATE_GENERICS' })
  public async CLEAR_PERIODS(): Promise<Partial<IGenericState>> {
    return { periods: DEFAULT_GENERICS.periods };
  }

  @Action({ commit: 'UPDATE_GENERICS' })
  public async CLEAR_PERIOD_TYPES(): Promise<Partial<IGenericState>> {
    return { periodTypes: DEFAULT_GENERICS.periodTypes };
  }

  @Action({ commit: 'UPDATE_GENERICS' })
  public async CLEAR_PER_PAGE(): Promise<Partial<IGenericState>> {
    return { perPage: DEFAULT_GENERICS.perPage };
  }

  @Action({ commit: 'UPDATE_GENERICS' })
  public async CLEAR_STATUS_COLORS(): Promise<Partial<IGenericState>> {
    return { statusColors: DEFAULT_GENERICS.statusColors };
  }

  @Action({ commit: 'UPDATE_GENERICS' })
  public async CLEAR_TIMEZONES(): Promise<Partial<IGenericState>> {
    return { timezones: DEFAULT_GENERICS.timezones };
  }

  @Action({ commit: 'UPDATE_GENERICS' })
  public async CLEAR_UNITS(): Promise<Partial<IGenericState>> {
    return { units: DEFAULT_GENERICS.units };
  }

  @Action({ commit: 'UPDATE_GENERICS' })
  public async CLEAR_VAT_PERCENTS(): Promise<Partial<IGenericState>> {
    return { vatPercents: DEFAULT_GENERICS.vatPercents };
  }

  /*******   Update mutation   *******/

  @Mutation
  public UPDATE_GENERICS(generics: Partial<IGenericState>): IGenericState {
    return Object.assign(this.generics, generics);
  }
}

const GenericModule = getModule(Generic);

export default GenericModule;
