<template>
  <LabeledBaseAutocomplete
    v-model="formValue"
    :items="companies"
    :map-item="{ text: 'name', value: searchBy }"
    :label="label"
    :placeholder="placeholder"
    :autocomplete="autocomplete"
    :show-label="showLabel"
    :show-error="showError"
    :show-error-border="showErrorBorder"
    :prepare-input="prepareInput"
    :hide-selected="hideSelected"
    :input-class="inputClass"
    :clearable="clearable"
    :readonly="readonly"
    :hide-list="computedHideList"
    :autofocus="autofocus"
    :overflow="overflow"
    :loading="isLoading"
    :disabled="disabled"
    :debounce-validation="debounceValidation"
    :rules="rules"
    :name="name"
    :dark="dark"
    rounded
    class="w-full"
    @focus="focus"
    @blur="blur"
    @open="open"
    @close="close"
    @input:debounce="inputDebounce"
    @query="query"
    @set="set"
    @clear="clear"
    @keydown="keydown"
    @resize="resizeCompleted"
    @error="error"
  >
    <template #list-item="{ item }">
      <div class="block space-y-1 text-left truncate">
        <div class="text-body-2 truncate leading-none" v-text="item.name" />
        <div class="text-caption truncate leading-none space-x-1">
          <span v-text="trans('workspace.title.registration_number.short', 'Reģ. nr.')" />
          <span class="font-weight-bold" v-text="item.registration_number" />
        </div>
      </div>
    </template>
  </LabeledBaseAutocomplete>
</template>

<script lang="ts">
  import { Component, Emit, Mixins, Prop, VModel } from 'vue-property-decorator';
  import CountryISO from '@/enums/config/iso/CountryISO';
  import RegistrySearchField from '@/enums/config/RegistrySearchField';
  import type IBusinessRegistry from '@/interfaces/IBusinessRegistry';
  import HelperMixin from '@/mixins/Helper';
  import LabeledBaseAutocomplete from '@/components/global/inputs/autocomplete/LabeledBase.vue';

  @Component({
    components: {
      LabeledBaseAutocomplete,
    },
  })
  export default class SelectCompanyFromRegistry extends Mixins(HelperMixin) {
    @VModel({ default: null }) formValue!: string | number | null;

    @Prop({ default: RegistrySearchField.NAME }) searchBy!: RegistrySearchField;
    @Prop({ default: CountryISO.LV }) searchCountry!: CountryISO;

    @Prop({ default: 'off' }) autocomplete?: boolean;
    @Prop({ default: true }) showLabel?: boolean;
    @Prop({ default: true }) showError?: boolean;
    @Prop({ default: true }) showErrorBorder?: boolean;
    @Prop({ default: '' }) placeholder?: string;
    @Prop({ default: '' }) label?: string;
    @Prop({ default: '' }) name?: string;

    @Prop({ default: 0 }) debounceValidation?: number;
    @Prop({ default: '' }) rules?: string;

    @Prop({ type: Boolean }) clearable?: boolean;
    @Prop({ type: Boolean }) autofocus?: boolean;
    @Prop({ type: Boolean }) disabled?: boolean;
    @Prop({ type: Boolean }) readonly?: boolean;

    @Prop({ type: Boolean }) hideSelected?: boolean;
    @Prop({ type: Boolean }) hideList?: boolean;
    @Prop({ type: Boolean }) overflow?: boolean;
    @Prop({ type: Boolean }) dark?: boolean;

    @Prop() prepareInput?: (value: typeof this.formValue) => typeof value;

    @Prop() inputClass?: string;

    public companies: IBusinessRegistry[] = [];
    public isLoading = false;

    /*****         computed       *****/

    public get computedHideList(): boolean {
      return this.hideList || this.searchCountry !== CountryISO.LV;
    }

    /*****         watchers       *****/
    /*****         methods        *****/

    @Emit('focus')
    public focus(): void {}

    @Emit('blur')
    public blur(): void {}

    @Emit('open')
    public open(): void {}

    @Emit('close')
    public close(): void {}

    @Emit('input:debounce')
    public inputDebounce(userInput: string): string {
      return userInput;
    }

    @Emit('query')
    public async query(query: string): Promise<string> {
      if (this.hideList) {
        return query;
      }

      await this.findCompany(query);
      return query;
    }

    @Emit('set')
    public set(item: any): any {
      return item;
    }

    @Emit('clear')
    public clear(): void {}

    @Emit('keydown')
    public keydown(keyCode: string): string {
      return keyCode;
    }

    @Emit('resize')
    public resizeCompleted(): void {}

    @Emit('error')
    public error(error: string): string {
      return error;
    }

    /*****         helpers        *****/

    private async findCompany(query: string): Promise<void> {
      const canLookup: boolean = !this.computedHideList && !!query && query.length > 1;

      this.isLoading = true;
      this.companies = canLookup ? await this.listBusinessRegistry(query, this.searchBy) : [];
      this.isLoading = false;
    }

    /*****      vue lifecycle     *****/
  }
</script>
