import { Component, Vue } from 'vue-property-decorator';
import Locale from '@/enums/config/Locale';
import PeriodType from '@/enums/config/PeriodType';
import TranslationModule from '@/store/modules/Translation';

@Component
export default class TranslateRecurringPeriodTypeMixin extends Vue {
  public TranslateRecurringPeriodTypeMixin(
    type: PeriodType,
    usePlural = false,
    locale: Locale = TranslationModule.getLocale,
  ): string {
    switch (type) {
      case PeriodType.DAY:
        return usePlural
          ? this.trans('workspace.title.days', 'Dienas', locale)
          : this.trans('workspace.title.day', 'Diena', locale);
      case PeriodType.WEEK:
        return usePlural
          ? this.trans('workspace.title.weeks', 'Nedēļas', locale)
          : this.trans('workspace.title.week', 'Nedēļa', locale);
      case PeriodType.MONTH:
        return usePlural
          ? this.trans('workspace.title.months', 'Mēneši', locale)
          : this.trans('workspace.title.month', 'Mēnesis', locale);
      default:
        return this.trans('generic.status.unknown', 'Nezināms', locale);
    }
  }
}
