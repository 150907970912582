enum DateRange {
  ALL_TIME = 'alltime',
  THIS_MONTH = 'thismonth',
  LAST_MONTH = 'lastmonth',
  THIS_YEAR = 'thisyear',
  LAST_YEAR = 'lastyear',
  FIRST_QUARTER = 'firstquarter',
  SECOND_QUARTER = 'secondquarter',
  THIRD_QUARTER = 'thirdquarter',
  FOURTH_QUARTER = 'fourthquarter',
}

export default DateRange;
