enum PublicRouteName {
  DEBUG = 'public.debug',
  INVITATION = 'public.invitation',
  MAINTENANCE = 'public.maintenance',
  ONBOARDING = 'public.onboarding',
  SHARE_DOCUMENT = 'public.share.document',
  EMAIL_VERIFY = 'public.verify.email',
}

export default PublicRouteName;
