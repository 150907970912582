enum TemplateKey {
  NUMBER = 'NUMBER',
  TOTAL_AMOUNT = 'TOTAL_AMOUNT',
  ISSUED_AT = 'ISSUED_AT',
  DUE_AT = 'DUE_AT',
  CLIENT_NAME = 'CLIENT_NAME',
  LATE_FEE_RATE = 'LATE_FEE_RATE',
}

export default TemplateKey;
