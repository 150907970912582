import cloneDeep from 'lodash-es/cloneDeep';
import { Component, Vue } from 'vue-property-decorator';
import DEFAULT_STOCK_FORM from '@/constants/mocks/forms/Stock';
import type IRecurringDocument from '@/interfaces/document/IRecurringDocument';
import type IStock from '@/interfaces/stock/IStock';
import DocumentRepository from '@/repository/Document';
import CompanyModule from '@/store/modules/Company';
import RecurringModule from '@/store/modules/Recurring';
import GenericModule from '@/store/modules/Generic';
import TranslateMixin from '@/mixins/Translate';

@Component
export default class CreateRecurringMixin extends Vue {
  public async CreateRecurringMixin(companyUUID: string = CompanyModule.getCompanyUUID): Promise<IRecurringDocument> {
    const unitList = GenericModule.getUnits;
    const payload = cloneDeep(RecurringModule.getRecurring);

    // Remove empty items and fix measurement
    const payloadItems: IStock[] = payload.items
      .filter((e) => {
        const nameOrUnit = e.name || e.measurement;
        const priceOnly = !e.name && !e.measurement && this.toNumber(e.price) != 0;
        return nameOrUnit || priceOnly;
      })
      .map((e) => {
        if (!payload.has_discount) {
          e.discount = 0;
        }
        e.measurement = (unitList.find((f) => e.measurement === f.text)?.value ?? e.measurement).toString();
        return e;
      });

    // Add VAT reference UUID to payloadItems
    for (const e of payloadItems) {
      e.vat_reference_uuid = e.vat_reference?.uuid ?? '';
    }

    const vatPercent = payload.has_multi_vat ? CompanyModule.getDefaultTaxRate : payload.single_vat ?? null;
    payload.items = cloneDeep(
      payloadItems.length
        ? payloadItems
        : [
            {
              ...DEFAULT_STOCK_FORM,
              vat_percent: vatPercent,
            },
          ],
    );

    // Remove additional price rows if document type should be without them
    if (!RecurringModule.isAdditionalPriceRows) {
      payload.additional_price_rows = [];
    }

    // This is a workaround to fix the locale of the additional price rows
    // User see the description in his locale
    // But we need to save it in the document locale
    for (const e of payload.additional_price_rows) {
      e.description = await new TranslateMixin().translatePaidWithInDocumentLocale(e.description, payload.lang);
    }

    return (await DocumentRepository.create(companyUUID, payload)).data;
  }
}
