const phonePrefixISOs: string[] = [
  // Matches numbers starting with 99, 97, or 96 followed by any digit
  '9[976]\\d|',
  // Matches numbers starting with 89, 88, 87, 85, or 80 followed by any digit
  '8[987530]\\d|',
  // Matches numbers starting with 69, 68, or 67 followed by any digit
  '6[987]\\d|',
  // Matches numbers starting with 59 or 50 followed by any digit
  '5[90]\\d|',
  // Matches numbers starting with 42 followed by any digit
  '42\\d|',
  // Matches numbers starting with 38, 37, or 35 followed by any digit
  '3[875]\\d|',
  // Matches numbers starting with 29, 28, 26, 25, 24, 23, 22, or 21 followed by any digit
  '2[98654321]\\d|',
  // Matches numbers starting with 98, 85, 84, 83, 82, 81, or 80
  '9[8543210]|',
  // Matches numbers starting with 86, 84, 82, or 81
  '8[6421]|',
  // Matches numbers starting with 66, 65, 64, 63, 62, 61, or 60
  '6[6543210]|',
  // Matches numbers starting with 58, 57, 56, 55, 54, 53, 52, or 51
  '5[87654321]|',
  // Matches numbers starting with 49, 48, 47, 46, 45, 44, 43, 41, or 40
  '4[987654310]|',
  // Matches numbers starting with 39, 36, 34, 32, 31, or 30
  '3[9643210]|',
  // Matches numbers starting with 27 or 20
  '2[70]|',
  // Matches numbers starting with 7
  '7|',
  // Matches numbers starting with 1
  '1|',
  // Matches the number 5997
  '5997|',
  // Matches the number 4779
  '4779|',
  //Matches numbers starting with 12, 13, 14, 16, 17, or 18 followed by 02, 04, 05, 06, 07, or 08 and any digit
  '1[234678][0245678]\\d',
];

const PHONE_PREFIX_ISO: RegExp = new RegExp('^\\+(' + phonePrefixISOs.join('') + ')$');

export default PHONE_PREFIX_ISO;
