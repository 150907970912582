import _Vue from 'vue';
import type IToast from '@/interfaces/IToast';
import NotificationModule from '@/store/modules/Notification';

export default function NotifierPlugin(Vue: typeof _Vue): void {
  Vue.prototype.$notifier = async (toast: IToast): Promise<IToast> => {
    return await NotificationModule.SET_TOAST(toast);
  };
}

declare module 'vue/types/vue' {
  interface Vue {
    $notifier(toast: IToast): Promise<IToast>;
  }
}
