import store from '@/store';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import type IStock from '@/interfaces/stock/IStock';

@Module({
  store,
  name: 'Stock',
  namespaced: true,
  dynamic: true,
})
class Stock extends VuexModule {
  private stockList: IStock[] = [];

  public get getStockList(): IStock[] {
    return this.stockList;
  }

  /*******   Set action   *******/

  @Action({ commit: 'UPDATE_STOCK_LIST' })
  public async SET_STOCK_LIST(stockList: IStock[]): Promise<IStock[]> {
    return stockList;
  }

  /*******   Unset action   *******/

  @Action({ commit: 'UPDATE_STOCK_LIST' })
  public async CLEAR_PAGINATION(): Promise<IStock[]> {
    return [];
  }

  @Action
  public async CLEAR_STATE(): Promise<void> {
    this.CLEAR_PAGINATION();
  }

  /*******   Update mutation   *******/

  @Mutation
  public async UPDATE_STOCK_LIST(stockList: IStock[]): Promise<IStock[]> {
    return (this.stockList = stockList);
  }
}

const StockModule = getModule(Stock);

export default StockModule;
