import { Component, Vue } from 'vue-property-decorator';
import DocumentRepository from '@/repository/Document';
import CompanyModule from '@/store/modules/Company';

@Component
export default class DeleteDocumentMixin extends Vue {
  public async DeleteDocumentMixin(
    documentUUID: string,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<void> {
    await DocumentRepository.delete(companyUUID, documentUUID);
  }
}
