import { Component, Vue } from 'vue-property-decorator';
import type INotifcation from '@/interfaces/INotification';
import HelperRepository from '@/repository/Helper';

@Component
export default class ListNotificationsMixin extends Vue {
  public async ListNotificationsMixin(email: string, verified: boolean): Promise<INotifcation[] | null> {
    return (await HelperRepository.fetchNotifications(email, verified)).data;
  }
}
