<template>
  <validation-provider
    :rules="rules"
    :debounce="debounceValidation"
    v-slot="{ errors }"
    :name="label"
    :vid="name"
    tag="div"
    class="relative flex flex-col items-start gap-y-3 text-left w-full"
    :class="{ 'cursor-not-allowed': disabled }"
  >
    <v-checkbox
      v-model="formValue"
      :disabled="disabled || loading"
      :readonly="readonly"
      :false-value="falseValue"
      :true-value="trueValue"
      :color="color"
      :ripple="false"
      hide-details
      :name="name"
      dense
    >
      <template #label>
        <slot name="label" :label="label">
          <div v-text="label" class="granite--text text-subtitle-2 font-weight-thin" @click.stop />
        </slot>
      </template>
      <template #append>
        <slot name="append" />
      </template>
    </v-checkbox>

    <v-scroll-y-transition>
      <div v-show="error(errors) && showError" class="text-caption error--text mt-n1" v-text="error(errors)" />
    </v-scroll-y-transition>
  </validation-provider>
</template>

<script lang="ts">
  import { Component, Emit, Prop, VModel, Vue } from 'vue-property-decorator';

  @Component
  export default class CheckboxField extends Vue {
    @VModel() formValue!: string | number | null;

    @Prop({ default: 'primary' }) color?: string;
    @Prop({ default: '' }) name?: string;
    @Prop({ default: '' }) label?: string;
    @Prop({ default: true }) showLabel?: boolean;
    @Prop({ default: true }) showError?: boolean;

    @Prop({ default: 0 }) debounceValidation?: number;
    @Prop({ default: '' }) rules?: string;

    @Prop({ type: Boolean }) disabled?: boolean;
    @Prop({ type: Boolean }) loading?: boolean;
    @Prop({ type: Boolean }) readonly?: boolean;

    private isBool = false;
    private isNumeric = false;

    /*****         computed       *****/

    public get trueValue(): string | number | boolean {
      if (this.isBool) {
        return true;
      } else if (this.isNumeric) {
        return 1;
      } else {
        return '1';
      }
    }

    public get falseValue(): string | number | boolean {
      if (this.isBool) {
        return false;
      } else if (this.isNumeric) {
        return 0;
      } else {
        return '0';
      }
    }

    /*****         watchers       *****/
    /*****         methods        *****/

    @Emit('error')
    public error(errors: any): string {
      if (errors.length) {
        return errors[0];
      }
      return '';
    }

    /*****         helpers        *****/
    /*****      vue lifecycle     *****/

    beforeMount() {
      this.isNumeric = typeof this.formValue === 'number';
      this.isBool = typeof this.formValue === 'boolean';
    }
  }
</script>
