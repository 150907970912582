import { Component, Vue } from 'vue-property-decorator';
import type ILoginForm from '@/interfaces/forms/auth/ILoginForm';
import type IUser from '@/interfaces/user/IUser';
import AuthRepository from '@/repository/Auth';

@Component
export default class LoginMixin extends Vue {
  public async LoginMixin(payload: ILoginForm): Promise<{ user: IUser; message: string }> {
    return (await AuthRepository.login(payload)).data;
  }
}
