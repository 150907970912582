import TEST_DATA from '@/constants/config/TestData';
import type ILoginForm from '@/interfaces/forms/auth/ILoginForm';
import useEnv from '@/services/Env';

const { isDev } = useEnv();

const DEFAULT_LOGIN_FORM: ILoginForm = {
  email: isDev ? TEST_DATA.email : '',
  password: isDev ? TEST_DATA.password : '',
  remember: 1,
};

export default DEFAULT_LOGIN_FORM;
