import { Component, Vue } from 'vue-property-decorator';
import type Locale from '@/enums/config/Locale';
import RecurringDocumentRoute from '@/enums/config/document/RecurringRoute';
import TranslationModule from '@/store/modules/Translation';

@Component
export default class TranslateNextRecurringMixin extends Vue {
  public TranslateNextRecurringMixin(
    route: RecurringDocumentRoute,
    locale: Locale = TranslationModule.getLocale,
  ): string {
    switch (route) {
      case RecurringDocumentRoute.WAYBILL:
        return this.trans('workspace.title.next_recurring.waybill', 'Nākamā pavadzīme', locale);
      case RecurringDocumentRoute.ADVANCE_INVOICE:
        return this.trans('workspace.title.next_recurring.advance_invoice', 'Nākamais avansa rēķins', locale);
      case RecurringDocumentRoute.OFFER:
        return this.trans('workspace.title.next_recurring.offer', 'Nākamais piedāvājums', locale);
      case RecurringDocumentRoute.INVOICE:
      default:
        return this.trans('workspace.title.next_recurring.invoice', 'Nākamais rēķins', locale);
    }
  }
}
