import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export interface IRootState {}

const store = new Vuex.Store<IRootState>({});

export default store;
