import TEST_DATA from '@/constants/config/TestData';
import CountryISO from '@/enums/config/iso/CountryISO';
import CurrencyISO from '@/enums/config/iso/CurrencyISO';
import type IRegisterCompanyForm from '@/interfaces/forms/auth/IRegisterCompanyForm';

import useEnv from '@/services/Env';

const { isDev } = useEnv();

const DEFAULT_REGISTER_COMPANY_FORM: IRegisterCompanyForm = {
  is_legal: true,

  name: isDev ? TEST_DATA.newUser.company.name : '',

  registration_number: isDev ? TEST_DATA.newUser.company.registration_number : '',
  vat_registration_number: isDev ? TEST_DATA.newUser.company.vat_number : '',

  currency_code: CurrencyISO.EUR,
  country: CountryISO.LV,
  address: '',
};

export default DEFAULT_REGISTER_COMPANY_FORM;
