import { Component, Vue } from 'vue-property-decorator';
import SupportRepository from '@/repository/Support';
import type ISupportForm from '@/interfaces/forms/ISupportForm';

@Component
export default class RequestSupportMixin extends Vue {
  public async RequestSupportMixin(payload: ISupportForm): Promise<void> {
    return (await SupportRepository.contact(payload)).data;
  }
}
