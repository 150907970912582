import { Component, Vue } from 'vue-property-decorator';
import type IAdvanceInvoiceLookupParams from '@/interfaces/forms/document/IAdvanceInvoiceLookupParams';
import type IAdvanceInvoiceList from '@/interfaces/document/IAdvanceInvoiceList';
import DocumentRepository from '@/repository/Document';
import CompanyModule from '@/store/modules/Company';

@Component
export default class ListAdvanceInvoiceDocumentsMixin extends Vue {
  public async ListAdvanceInvoiceDocumentsMixin(
    params: IAdvanceInvoiceLookupParams,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<IAdvanceInvoiceList[]> {
    try {
      return (await DocumentRepository.listAdvanceInvoices(companyUUID, params)).data;
    } catch (_) {
      this.$logger('There was a problem to list advance invoices...');
      return [];
    }
  }
}
