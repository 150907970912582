import { Component, Vue } from 'vue-property-decorator';
import DocumentType from '@/enums/config/document/Type';
import type RecurringDocumentType from '@/enums/config/document/RecurringType';
import type Locale from '@/enums/config/Locale';
import TranslationModule from '@/store/modules/Translation';

@Component
export default class TranslatePaidWithDocumentMixin extends Vue {
  public TranslatePaidWithDocumentMixin(
    type: RecurringDocumentType | DocumentType,
    isFinal: boolean,
    record_number: string,
    locale: Locale = TranslationModule.getLocale,
  ): string {
    switch (type) {
      case DocumentType.INVOICE:
        if (isFinal) {
          return this.paidWithInvoice(record_number, locale);
        }
        return this.paidWithDocument(record_number, locale);
      case DocumentType.ADVANCE_INVOICE:
        return this.paidWithAdvanceInvoice(record_number, locale);
      default:
        return this.paidWithDocument(record_number, locale);
    }
  }
  public async TranslatePaidWithInSystemLocaleMixin(
    desc: string,
    locale: Locale = TranslationModule.getLocale,
  ): Promise<string> {
    // Add translations to the store if not exists
    await TranslationModule.ADD_TRANSLATIONS(locale);
    const record_number = desc.split(' ').pop() ?? '';
    // Translate to document locale description
    const paidWithAdvanceInvoice = this.paidWithAdvanceInvoice(record_number, locale);
    const paidWithInvoice = this.paidWithAdvanceInvoice(record_number, locale);
    const paidWithDocument = this.paidWithDocument(record_number, locale);

    // Change the description to the system locale condition
    const changeToDocumentLocale = TranslationModule.getLocale != locale;
    if (changeToDocumentLocale) {
      if (desc === paidWithAdvanceInvoice) {
        return this.paidWithAdvanceInvoice(record_number);
      }
      if (desc === paidWithInvoice) {
        return this.paidWithInvoice(record_number);
      }
      if (desc === paidWithDocument) {
        return this.paidWithDocument(record_number);
      }
    }

    return desc;
  }

  public async TranslatePaidWithInDocumentLocaleMixin(
    desc: string,
    locale: Locale = TranslationModule.getLocale,
  ): Promise<string> {
    // Add translations to the store if not exists
    await TranslationModule.ADD_TRANSLATIONS(locale);
    const record_number = desc.split(' ').pop() ?? '';
    // Translate to document locale description
    const paidWithAdvanceInvoice = this.paidWithAdvanceInvoice(record_number);
    const paidWithInvoice = this.paidWithAdvanceInvoice(record_number);
    const paidWithDocument = this.paidWithDocument(record_number);

    // Change the description to the system locale condition
    const changeToDocumentLocale = TranslationModule.getLocale != locale;
    if (changeToDocumentLocale) {
      if (desc === paidWithAdvanceInvoice) {
        return this.paidWithAdvanceInvoice(record_number, locale);
      }
      if (desc === paidWithInvoice) {
        return this.paidWithInvoice(record_number, locale);
      }
      if (desc === paidWithDocument) {
        return this.paidWithDocument(record_number, locale);
      }
    }

    return desc;
  }

  private paidWithAdvanceInvoice(record_number: string, locale: Locale = TranslationModule.getLocale): string {
    return this.trans(
      'workspace.description.paid_with_advance_invoice',
      'Apmaksāts ar avansa rēķinu :record_number',
      { record_number },
      locale,
    );
  }

  private paidWithInvoice(record_number: string, locale: Locale = TranslationModule.getLocale): string {
    return this.trans(
      'workspace.description.paid_with_invoice',
      'Apmaksāts ar rēķinu :record_number',
      { record_number },
      locale,
    );
  }

  private paidWithDocument(record_number: string, locale: Locale = TranslationModule.getLocale): string {
    return this.trans(
      'workspace.description.paid_with_document',
      'Apmaksāts ar dokumentu :record_number',
      { record_number },
      locale,
    );
  }
}
