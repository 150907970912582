enum VatExemptionCode {
  VATEX_AE = 'VATEX-EU-AE', // Only use with VAT category code AE
  VATEX_G = 'VATEX-EU-G', // Only use with VAT category code G
  VATEX_D = 'VATEX-EU-D', // Second-hand means of transport - Only use with VAT category code E
  VATEX_F = 'VATEX-EU-F', // Second-hand goods - Only use with VAT category code E
  VATEX_I = 'VATEX-EU-I', // Works of art - Only use with VAT category code E
  VATEX_J = 'VATEX-EU-J', // Collectors' items and antiques - Only use with VAT category code E
  VATEX_309 = 'VATEX-EU-309', // Travel agents performed outside of EU - Only use with VAT category code E
  VATEX_O = 'VATEX-EU-O', // Only use with VAT category code O
  VATEX_IC = 'VATEX-EU-IC', // Only use with VAT category code K
  VATEX_143 = 'VATEX-EU-143', // Only use with VAT category code L and M
  VATEX_148 = 'VATEX-EU-148', // Only use with VAT category code B
  NONE = '',
}

export default VatExemptionCode;
