import { Component, Vue } from 'vue-property-decorator';
import Locale from '@/enums/config/Locale';
import DateFormat from '@/enums/config/DateFormat';
import PeriodType from '@/enums/config/PeriodType';
import type { IRecurringConfig } from '@/interfaces/document/IRecurringDocument';
import TranslationModule from '@/store/modules/Translation';

@Component
export default class TranslateRecurringIterationPeriodMixin extends Vue {
  public TranslateRecurringIterationPeriodMixin(
    config: IRecurringConfig,
    locale: Locale = TranslationModule.getLocale,
  ): string {
    const iterations = this.countIterations(config, DateFormat.LARAVEL, locale);
    const lastDigit = config.period % 10;
    const lastTwoDigits = config.period % 100;

    if (iterations === 0) {
      return '';
    } else {
      let humanReadableIteration = '';

      if (config.period === 1) {
        humanReadableIteration = this.recurringIterationEvery(config.period_type, locale);
      } else {
        switch (locale) {
          case Locale.RU:
            if (lastDigit === 1 && lastTwoDigits !== 11) {
              humanReadableIteration = this.recurringIteratorSingular(config, locale);
            } else if (lastDigit >= 2 && lastDigit <= 4 && (lastTwoDigits < 10 || lastTwoDigits >= 20)) {
              humanReadableIteration = this.recurringIteratorPlural(config, locale);
            } else {
              humanReadableIteration = this.recurringIteratorOther(config, locale);
            }
            break;
          case Locale.LV:
            if ((lastDigit === 1 && lastTwoDigits !== 11) || (lastDigit === 0 && lastTwoDigits !== 10)) {
              humanReadableIteration = this.recurringIteratorSingular(config, locale);
            } else {
              humanReadableIteration = this.recurringIteratorPlural(config, locale);
            }
            break;
          case Locale.EN:
            humanReadableIteration = this.recurringIteratorPlural(config, locale);
            break;
        }
      }

      return humanReadableIteration;
    }
  }

  private recurringIterationEvery(type: PeriodType, locale: Locale): string {
    switch (type) {
      case PeriodType.DAY:
        return this.trans('workspace.title.recurring_generated.day.every', 'Reizi dienā', locale);
      case PeriodType.WEEK:
        return this.trans('workspace.title.recurring_generated.week.every', 'Reizi nedēļā', locale);
      case PeriodType.MONTH:
        return this.trans('workspace.title.recurring_generated.month.every', 'Reizi mēnesī', locale);
      default:
        return '';
    }
  }

  private recurringIteratorSingular(config: IRecurringConfig, locale: Locale): string {
    switch (config.period_type) {
      case PeriodType.DAY:
        return this.trans(
          'workspace.title.recurring_generated.day.singular',
          'Ik pēc :time dienas',
          { time: config.period },
          locale,
        );
      case PeriodType.WEEK:
        return this.trans(
          'workspace.title.recurring_generated.week.singular',
          'Ik pēc :time nedēļas',
          { time: config.period },
          locale,
        );
      case PeriodType.MONTH:
        return this.trans(
          'workspace.title.recurring_generated.month.singular',
          'Ik pēc :time mēneša',
          { time: config.period },
          locale,
        );
      default:
        return '';
    }
  }

  private recurringIteratorPlural(config: IRecurringConfig, locale: Locale): string {
    switch (config.period_type) {
      case PeriodType.DAY:
        return this.trans(
          'workspace.title.recurring_generated.day.plural',
          'Ik pēc :time dienām',
          { time: config.period },
          locale,
        );
      case PeriodType.WEEK:
        return this.trans(
          'workspace.title.recurring_generated.week.plural',
          'Ik pēc :time nedēļām',
          { time: config.period },
          locale,
        );
      case PeriodType.MONTH:
        return this.trans(
          'workspace.title.recurring_generated.month.plural',
          'Ik pēc :time mēnešiem',
          { time: config.period },
          locale,
        );
      default:
        return '';
    }
  }

  private recurringIteratorOther(config: IRecurringConfig, locale: Locale): string {
    switch (config.period_type) {
      case PeriodType.DAY:
        return this.trans(
          'workspace.title.recurring_generated.day.other',
          'Ik pēc :time dienām',
          { time: config.period },
          locale,
        );
      case PeriodType.WEEK:
        return this.trans(
          'workspace.title.recurring_generated.week.other',
          'Ik pēc :time nedēļām',
          { time: config.period },
          locale,
        );
      case PeriodType.MONTH:
        return this.trans(
          'workspace.title.recurring_generated.month.other',
          'Ik pēc :time mēnešiem',
          { time: config.period },
          locale,
        );
      default:
        return '';
    }
  }
}
