import app from '@/main';
import store from '@/store';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import DEFAULT_UTILS from '@/constants/mocks/Utils';
import CountryISO from '@/enums/config/iso/CountryISO';
import type IUtilState from '@/interfaces/states/IUtil';
import type ISelect from '@/interfaces/config/ISelect';
import type ICountry from '@/interfaces/ICountry';
import type ICurrency from '@/interfaces/ICurrency';
import UtilRepository from '@/repository/Util';

@Module({
  store,
  name: 'Util',
  namespaced: true,
  dynamic: true,
})
class Util extends VuexModule {
  private utils: IUtilState = DEFAULT_UTILS;

  public get getUtils(): IUtilState {
    return this.utils;
  }

  public get getCountries(): ICountry[] {
    // id: unknown, iso_code: "ax", phone_prefix: "+358" - same as Finland
    return this.getUtils.countries.filter((e: ICountry) => e.iso_code != CountryISO.AX);
  }

  public get getCurrencies(): ICurrency[] {
    return this.getUtils.currencies;
  }

  public get selectCountries(): ISelect[] {
    return this.getCountries.map((e: ICountry) =>
      Object.assign({
        text: e.title,
        value: e.iso_code.toLowerCase(),
        image: e.flag,
      }),
    );
  }

  public get selectPhonePrefixes(): ISelect[] {
    return this.getCountries
      .filter((e: ICountry) => e.phone_prefix.length > 1)
      .reduce((unique: ISelect[], e: ICountry) => {
        const phonePrefixLower = e.phone_prefix.toLowerCase();
        if (unique.findIndex((uni: ISelect) => uni.value === phonePrefixLower) === -1) {
          unique.push({
            text: e.phone_prefix,
            value: phonePrefixLower,
            description: e.title,
            image: e.flag,
            code: e.iso_code.toLowerCase(),
          });
        }
        return unique;
      }, []);
  }

  public get selectCurrencies(): ISelect[] {
    return this.getCurrencies.map((e: ICurrency) =>
      Object.assign({
        text: e.code,
        value: e.code,
        symbol: e.symbol,
      }),
    );
  }

  /*******   Set action   *******/

  // Create all default select state
  @Action
  public async SET_DEFAULTS(): Promise<void> {
    await Promise.all([this.SET_COUNTRIES(), this.SET_CURRENCIES()]);
  }

  @Action({ commit: 'UPDATE_UTILS' })
  public async SET_COUNTRIES(): Promise<Partial<IUtilState>> {
    let countries: ICountry[] = [];
    try {
      countries = (await UtilRepository.countries()).data;
      // FIXME: Preload images in head could slow down the app
      // countries.forEach((country: ICountry) => {
      //   const link = document.createElement('link');
      //   link.rel = 'preload';
      //   link.href = country.flag;
      //   link.as = 'image';
      //   document.head.appendChild(link);
      // });
    } catch (e) {
      app.$logger('unable to fetch countries...');
    }
    return { countries };
  }

  @Action({ commit: 'UPDATE_UTILS' })
  public async SET_CURRENCIES(): Promise<Partial<IUtilState>> {
    let currencies: ICurrency[] = [];
    try {
      currencies = (await UtilRepository.currencies()).data;
    } catch (e) {
      app.$logger('unable to fetch currencies...');
    }
    return { currencies };
  }

  /*******   Unset action   *******/

  @Action({ commit: 'UPDATE_UTILS' })
  public CLEAR_COUNTRIES(): Partial<IUtilState> {
    return { countries: DEFAULT_UTILS.countries };
  }

  @Action({ commit: 'UPDATE_UTILS' })
  public CLEAR_CURRENCIES(): Partial<IUtilState> {
    return { currencies: DEFAULT_UTILS.currencies };
  }

  /*******   Update mutation   *******/

  @Mutation
  public UPDATE_UTILS(utils: Partial<IUtilState>): IUtilState {
    return Object.assign(this.utils, utils);
  }
}

const UtilModule = getModule(Util);

export default UtilModule;
