import { RouteConfig } from 'vue-router';
import SettingsRouteName from '@/enums/config/router/Settings';

const SettingsRouter: Array<RouteConfig> = [
  {
    path: 'settings',
    component: () => import(/* webpackChunkName: "appView" */ '@/views/Settings.vue'),
    meta: {
      guard: ['redirect', 'admin'],
    },
    children: [
      {
        path: '/',
        name: SettingsRouteName.INDEX,
        component: () => import(/* webpackChunkName: "appView" */ '@/pages/app/settings/Index.vue'),
        meta: {
          title: 'Settings',
        },
        props: true,
        children: [
          {
            path: 'company',
            name: SettingsRouteName.COMPNAY,
            component: () => import(/* webpackChunkName: "appView" */ '@/pages/app/settings/Company.vue'),
            meta: {
              parent: SettingsRouteName.INDEX,
              title: 'Company',
            },
            props: true,
          },
          {
            path: 'brand',
            name: SettingsRouteName.BRAND,
            component: () => import(/* webpackChunkName: "appView" */ '@/pages/app/settings/Brand.vue'),
            meta: {
              parent: SettingsRouteName.INDEX,
              title: 'Brand',
            },
            props: true,
          },
          {
            path: 'document',
            name: SettingsRouteName.DOCUMENT,
            component: () => import(/* webpackChunkName: "appView" */ '@/pages/app/settings/Document.vue'),
            meta: {
              parent: SettingsRouteName.INDEX,
              title: 'Document',
            },
            props: true,
          },
          {
            path: 'email',
            name: SettingsRouteName.EMAIL,
            component: () => import(/* webpackChunkName: "appView" */ '@/pages/app/settings/EmailPreferences.vue'),
            meta: {
              parent: SettingsRouteName.INDEX,
              title: 'Email',
            },
            props: true,
          },
          {
            path: 'payments',
            name: SettingsRouteName.PAYMENTS,
            component: () => import(/* webpackChunkName: "appView" */ '@/pages/app/settings/PaymentMethods.vue'),
            meta: {
              parent: SettingsRouteName.INDEX,
              title: 'Payments',
            },
            props: true,
          },
          {
            path: 'users',
            name: SettingsRouteName.USERS,
            component: () => import(/* webpackChunkName: "appView" */ '@/pages/app/settings/Users.vue'),
            meta: {
              parent: SettingsRouteName.INDEX,
              title: 'Users',
            },
            props: true,
          },
          {
            path: 'subscription',
            name: SettingsRouteName.SUBSCRIPTION,
            component: () => import(/* webpackChunkName: "appView" */ '@/pages/app/settings/Subscription.vue'),
            meta: {
              parent: SettingsRouteName.INDEX,
              title: 'Subscription',
            },
            props: true,
          },
        ],
      },
    ],
  },
];

export default SettingsRouter;
