import { AxiosPromise } from 'axios';
import type Locale from '@/enums/config/Locale';
import ApiRepository from '@/repository/config/Api';
import MicroserviceRepository from '@/repository/config/Microservice';
import type IReferenceForm from '@/interfaces/forms/reference/IUserReferenceForm';

export default class ReferencesRepository {
  // Fetch user references
  public static list(companyUUID: string): AxiosPromise {
    return ApiRepository.get(`/companies/${companyUUID}/vat-references`);
  }

  // Create user reference
  public static create(companyUUID: string, payload: IReferenceForm): AxiosPromise {
    return ApiRepository.post(`/companies/${companyUUID}/vat-references`, payload);
  }

  // Delete user reference
  public static delete(companyUUID: string, referenceUUID: string): AxiosPromise {
    return ApiRepository.delete(`/companies/${companyUUID}/vat-references/${referenceUUID}`);
  }

  // Set default user reference
  public static setDefault(companyUUID: string, referenceUUID: string): AxiosPromise {
    return ApiRepository.post(`/companies/${companyUUID}/vat-references/${referenceUUID}/set-default`);
  }

  // Fetch system defined VAT references
  public static listSystemDefinedVatReferences(params: { locale?: Locale; search?: string }): AxiosPromise {
    return MicroserviceRepository.get('/vat-references', { params });
  }

  // Fetch PEPPOL VAT codes
  public static listSystemDefinedVatCodes(params: { locale?: Locale; search?: string }): AxiosPromise {
    return MicroserviceRepository.get('/vat-codes', { params });
  }

  // Fetch PEPPOL VAT codes
  public static microserviceStatus(params: { locale?: Locale; search?: string }): AxiosPromise {
    return MicroserviceRepository.get('/vat-codes', { params });
  }
}
