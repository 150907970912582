enum SettingsRouteName {
  COMPNAY = 'app.settings.company',
  BRAND = 'app.settings.brand',
  DOCUMENT = 'app.settings.document',
  EMAIL = 'app.settings.email',
  PAYMENTS = 'app.settings.payments',
  USERS = 'app.settings.users',
  SUBSCRIPTION = 'app.settings.subscription',

  INDEX = 'app.settings.index',
}

export default SettingsRouteName;
