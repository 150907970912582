<template>
  <svg width="126" height="29" viewBox="0 0 126 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.89382 0.338867L10.678 19.3729V0.338867H15.0351V28.6613H10.3538L4.36488 10.5251V28.6613H0V0.338867H4.89382Z"
      fill="currentColor"
    />
    <path
      d="M25.8045 28.9775C24.8993 28.9833 24.002 28.8134 23.1643 28.4775C22.3266 28.1417 21.5652 27.6465 20.9241 27.0207C20.283 26.3949 19.7749 25.6509 19.4291 24.8316C19.0834 24.0123 18.9068 23.134 18.9097 22.2475V0.360352H23.1753V22.2019C23.1689 22.5238 23.2309 22.8436 23.3572 23.1409C23.4834 23.4383 23.6714 23.7067 23.909 23.9292C24.1404 24.159 24.4169 24.3405 24.7219 24.4627C25.0269 24.5849 25.354 24.6453 25.6835 24.6402C26.0108 24.6414 26.3352 24.5792 26.6379 24.4571C26.9406 24.335 27.2156 24.1554 27.4472 23.9288C27.6787 23.7022 27.8622 23.4329 27.987 23.1365C28.1119 22.8401 28.1756 22.5225 28.1746 22.2019V0.360352H32.7179V22.2475C32.7179 24.1434 32.0411 25.7391 30.6874 27.0345C30.0487 27.6632 29.2871 28.1594 28.4481 28.4932C27.6091 28.8271 26.71 28.9918 25.8045 28.9775Z"
      fill="currentColor"
    />
    <path
      d="M40.9546 28.6613H36.5959V0.338867H42.8501L46.9125 20.684L50.8105 0.338867H56.8196V28.6613H52.4609V10.8639L48.6793 28.6613H45.1753L40.9546 10.9079V28.6613Z"
      fill="currentColor"
    />
    <path
      d="M60.7173 28.6609V0.338446H67.2429C68.8948 0.319548 70.5149 0.783416 71.8963 1.67078C73.0803 2.42431 73.9257 3.58903 74.4324 5.16494C74.7286 6.13903 74.8689 7.15222 74.8481 8.1684C74.8481 10.0664 74.3311 11.5856 73.297 12.726C72.8781 13.1989 72.3588 13.5763 71.7753 13.832C72.7432 14.1935 73.5658 14.9086 74.2432 15.9771C74.6878 16.7002 75.0182 17.634 75.2343 18.7785C75.3447 19.4275 75.3966 20.0849 75.3894 20.7428C75.3894 22.5355 75.0435 24.0547 74.3517 25.3004C73.8213 26.2714 73.0323 27.0836 72.0685 27.6506C70.963 28.313 69.8638 28.6442 68.7708 28.6442L60.7173 28.6609ZM65.4219 11.9026H67.2429C68.5955 11.9026 69.5024 11.2549 69.9636 9.95954C70.1472 9.38209 70.2332 8.7791 70.218 8.17448C70.218 6.99963 69.9258 6.1114 69.3416 5.5098C69.0719 5.23316 68.7455 5.01566 68.3837 4.87163C68.022 4.7276 67.6331 4.66033 67.2429 4.67424H65.4219V11.9026ZM65.4219 24.4375H67.2429C68.7196 24.4375 69.7418 23.6921 70.3095 22.2012C70.5192 21.6368 70.6212 21.0395 70.6104 20.439C70.6104 18.859 70.3028 17.7075 69.6875 16.9843C69.1332 16.3199 68.3183 15.9882 67.2429 15.9892H65.4219V24.4375Z"
      fill="currentColor"
    />
    <path
      d="M79.165 28.6613V0.338867H91.5741V4.62909H83.5237V12.3542H89.4041V16.646H83.5237V24.3696H91.571V28.6613H79.165Z"
      fill="currentColor"
    />
    <path
      d="M101.441 0.360352C103.87 0.360352 105.653 1.01563 106.791 2.3262C107.791 3.47066 108.291 5.06683 108.291 7.11471V11.2241C108.291 13.2275 107.515 14.9219 105.964 16.3074L109.192 28.6615H104.476L101.839 18.0013H98.928V28.6615H94.5693V0.360352H101.441ZM104.047 7.18156C104.047 5.54082 103.216 4.72045 101.556 4.72045H98.928V13.7764H101.556C101.886 13.7829 102.214 13.7222 102.519 13.5979C102.824 13.4737 103.099 13.2887 103.327 13.0548C103.562 12.8279 103.747 12.5568 103.872 12.2578C103.997 11.9588 104.058 11.6382 104.053 11.3153L104.047 7.18156Z"
      fill="currentColor"
    />
    <path
      d="M119.082 0.00197572C119.994 -0.0136739 120.9 0.154661 121.743 0.496513C122.586 0.838365 123.348 1.34642 123.982 1.98909C124.633 2.61076 125.148 3.35614 125.494 4.17954C125.841 5.00295 126.013 5.88703 125.998 6.77761V22.2248C126.032 23.1335 125.879 24.0396 125.547 24.8889C125.216 25.7383 124.713 26.5134 124.069 27.1681C123.424 27.8227 122.652 28.3435 121.797 28.6991C120.943 29.0548 120.024 29.2381 119.096 29.2381C118.167 29.2381 117.248 29.0548 116.394 28.6991C115.54 28.3435 114.767 27.8227 114.123 27.1681C113.478 26.5134 112.975 25.7383 112.644 24.8889C112.312 24.0396 112.159 23.1335 112.193 22.2248V6.77609C112.191 4.9836 112.915 3.26354 114.207 1.9935C115.498 0.723455 117.252 0.00720637 119.082 0.00197572ZM121.456 6.68645C121.456 6.04179 121.195 5.42352 120.73 4.96768C120.264 4.51183 119.633 4.25573 118.975 4.25573C118.645 4.25063 118.318 4.311 118.012 4.43319C117.707 4.55539 117.43 4.73688 117.199 4.96672C116.96 5.18756 116.771 5.45431 116.642 5.75025C116.514 6.04619 116.449 6.36492 116.453 6.68645V22.1823C116.447 22.5045 116.51 22.8244 116.637 23.1217C116.764 23.419 116.953 23.6872 117.191 23.9096C117.423 24.1381 117.7 24.3182 118.006 24.4391C118.311 24.5599 118.638 24.6191 118.967 24.613C119.293 24.613 119.617 24.5499 119.918 24.4273C120.22 24.3046 120.494 24.1249 120.725 23.8984C120.955 23.6719 121.138 23.403 121.262 23.1072C121.386 22.8114 121.45 22.4945 121.449 22.1747L121.456 6.68645Z"
      fill="currentColor"
    />
  </svg>
</template>

<script lang="ts">
  import { Component, Vue } from 'vue-property-decorator';

  @Component
  export default class NumberoIcon extends Vue {
    /*****         computed       *****/
    /*****         watchers       *****/
    /*****         methods        *****/
    /*****         helpers        *****/
    /*****      vue lifecycle     *****/
  }
</script>
