enum Trigger {
  CONVERT = 'convert',
  COPY = 'copy',
  CREATE = 'create',
  EDIT = 'edit',
  EINVOICE = 'einvoice',
  DELETE = 'delete',
  DOWNLOAD = 'download',
  FILTER = 'filter',
  PAYMENTS = 'payments',
  PRINT = 'print',
  RECURRING = 'recurring',
  SAVE = 'save',
  SEND = 'send',
  SET = 'set',
  SHARE = 'share',
  SORT = 'sort',
  VIEW = 'view',
  REPORT = 'report',

  NONE = 'none',
}

export default Trigger;
