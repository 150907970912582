import { Component, Vue } from 'vue-property-decorator';
import PartnerRepository from '@/repository/Partner';
import CompanyModule from '@/store/modules/Company';

@Component
export default class DeletePartnerMixin extends Vue {
  public async DeletePartnerMixin(
    partnerUUID: string,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<void> {
    await PartnerRepository.delete(companyUUID, partnerUUID);
  }
}
