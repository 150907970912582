import { RouteConfig } from 'vue-router';
import ProfileRouteName from '@/enums/config/router/Profile';

const ProfileRouter: Array<RouteConfig> = [
  {
    path: 'profile',
    component: () => import(/* webpackChunkName: "appView" */ '@/views/Profile.vue'),
    meta: {
      guard: ['redirect'],
    },
    children: [
      {
        path: '/',
        name: ProfileRouteName.INDEX,
        component: () => import(/* webpackChunkName: "appView" */ '@/pages/app/profile/Index.vue'),
        meta: {
          title: 'Profile',
        },
        props: true,
        children: [
          {
            path: 'data',
            name: ProfileRouteName.DATA,
            component: () => import(/* webpackChunkName: "appView" */ '@/pages/app/profile/Data.vue'),
            meta: {
              parent: ProfileRouteName.INDEX,
              title: 'Data',
            },
            props: true,
          },
          {
            path: 'locale',
            name: ProfileRouteName.LOCALE,
            component: () => import(/* webpackChunkName: "appView" */ '@/pages/app/profile/Locale.vue'),
            meta: {
              parent: ProfileRouteName.INDEX,
              title: 'Locale',
            },
            props: true,
          },
          {
            path: 'security',
            name: ProfileRouteName.SECURITY,
            component: () => import(/* webpackChunkName: "appView" */ '@/pages/app/profile/Security.vue'),
            meta: {
              parent: ProfileRouteName.INDEX,
              title: 'Security',
            },
            props: true,
          },
          {
            path: 'deactivate',
            name: ProfileRouteName.DEACTIVATE,
            component: () => import(/* webpackChunkName: "appView" */ '@/pages/app/profile/Deactivate.vue'),
            meta: {
              parent: ProfileRouteName.INDEX,
              title: 'Deactivate',
            },
            props: true,
          },
        ],
      },
    ],
  },
];

export default ProfileRouter;
