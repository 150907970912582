import { Component, Vue } from 'vue-property-decorator';
import type IPartner from '@/interfaces/partner/IPartner';
import type IPartnerLookupParams from '@/interfaces/forms/partner/IPartnerLookupParams';
import PartnerRepository from '@/repository/Partner';
import CompanyModule from '@/store/modules/Company';

@Component
export default class ListMultiSourcePartnerMixin extends Vue {
  public async ListMultiSourcePartnerMixin(
    params: IPartnerLookupParams,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<Partial<IPartner>[]> {
    return (await PartnerRepository.listMultiSource(companyUUID, params)).data;
  }
}
