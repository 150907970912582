import { Component, Vue } from 'vue-property-decorator';
import type ICompany from '@/interfaces/company/ICompany';
import type ICompanyBrandForm from '@/interfaces/forms/ICompanyBrandForm';
import CompanyRepository from '@/repository/Company';
import CompanyModule from '@/store/modules/Company';

@Component
export default class UpdateCompanyBrandMixin extends Vue {
  public async UpdateCompanyBrandMixin(
    payload: ICompanyBrandForm,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<ICompany> {
    const localPayload = { ...payload };
    if (localPayload.logo === '') {
      localPayload.remove_logo = true;
    }
    if (typeof localPayload.logo === 'string') {
      localPayload.logo = '';
    }

    return (await CompanyRepository.updateBrandSettings(companyUUID, localPayload)).data;
  }
}
