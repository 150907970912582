<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.55 4.91C18.6332 3.98392 17.5412 3.24967 16.3377 2.75005C15.1341 2.25043 13.8432 1.99546 12.54 2C7.08005 2 2.63005 6.45 2.63005 11.91C2.63005 13.66 3.09005 15.36 3.95005 16.86L2.55005 22L7.80005 20.62C9.25005 21.41 10.88 21.83 12.54 21.83C18 21.83 22.4501 17.38 22.4501 11.92C22.4501 9.27 21.42 6.78 19.55 4.91ZM12.54 20.15C11.06 20.15 9.61005 19.75 8.34005 19L8.04005 18.82L4.92005 19.64L5.75005 16.6L5.55005 16.29C4.7278 14.977 4.29119 13.4592 4.29005 11.91C4.29005 7.37 7.99005 3.67 12.53 3.67C14.73 3.67 16.8 4.53 18.35 6.09C19.1175 6.85396 19.7257 7.76266 20.1394 8.76342C20.5531 9.76419 20.7641 10.8371 20.76 11.92C20.7801 16.46 17.08 20.15 12.54 20.15ZM17.06 13.99C16.81 13.87 15.59 13.27 15.37 13.18C15.14 13.1 14.98 13.06 14.81 13.3C14.64 13.55 14.17 14.11 14.03 14.27C13.89 14.44 13.74 14.46 13.49 14.33C13.24 14.21 12.44 13.94 11.5 13.1C10.76 12.44 10.27 11.63 10.12 11.38C9.98005 11.13 10.1 11 10.23 10.87C10.34 10.76 10.48 10.58 10.6 10.44C10.72 10.3 10.77 10.19 10.85 10.03C10.93 9.86 10.89 9.72 10.83 9.6C10.77 9.48 10.27 8.26 10.07 7.76C9.87005 7.28 9.66005 7.34 9.51005 7.33H9.03005C8.86005 7.33 8.60005 7.39 8.37005 7.64C8.15005 7.89 7.51005 8.49 7.51005 9.71C7.51005 10.93 8.40005 12.11 8.52005 12.27C8.64005 12.44 10.27 14.94 12.75 16.01C13.34 16.27 13.8 16.42 14.16 16.53C14.75 16.72 15.29 16.69 15.72 16.63C16.2 16.56 17.19 16.03 17.39 15.45C17.6 14.87 17.6 14.38 17.53 14.27C17.46 14.16 17.31 14.11 17.06 13.99Z"
      fill="currentColor"
    />
  </svg>
</template>

<script lang="ts">
  import { Component, Vue } from 'vue-property-decorator';

  @Component
  export default class WhatsAppIcon extends Vue {
    /*****         computed       *****/
    /*****         watchers       *****/
    /*****         methods        *****/
    /*****         helpers        *****/
    /*****      vue lifecycle     *****/
  }
</script>
