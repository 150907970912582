import { Component, Mixins } from 'vue-property-decorator';
import CatchError from '@/decorators/CatchError';
import Loading from '@/decorators/Loading';
import LocalStorage from '@/enums/config/LocalStorage';
import AppState from '@/enums/generics/AppState';
import type ICompanyMixin from '@/interfaces/mixins/ICompanyMixin';
import type ICompany from '@/interfaces/company/ICompany';
import type IRegisterCompanyForm from '@/interfaces/forms/auth/IRegisterCompanyForm';
import type ICompanyBrandForm from '@/interfaces/forms/ICompanyBrandForm';
import type ICompanySettings from '@/interfaces/company/ICompanyDetails';
import type IDocumentSettings from '@/interfaces/company/IDocumentSettings';
import type IEmailSettings from '@/interfaces/company/IEmailSettings';
import type ISubscriptionSettings from '@/interfaces/company/ISubscriptionSettings';
import AppModule from '@/store/modules/App';
import CompanyModule from '@/store/modules/Company';
import UserModule from '@/store/modules/User';
import CreateCompanyMixin from '@/mixins/company/Create';
import DeleteCompanyMixin from '@/mixins/company/Delete';
import FetchCompanyMixin from '@/mixins/company/Fetch';
import UpdateCompanyBrandMixin from '@/mixins/company/update/Brand';
import UpdateCompanyDetailsMixin from '@/mixins/company/update/Details';
import UpdateDocumentSettingsMixin from '@/mixins/company/update/Document';
import UpdateEmailSettingsMixin from '@/mixins/company/update/Email';
import UpdateSubscriptionSettingsMixin from '@/mixins/company/update/Subscription';

@Component
export default class CompanyMixin extends Mixins<ICompanyMixin>(
  CreateCompanyMixin,
  DeleteCompanyMixin,
  FetchCompanyMixin,

  UpdateCompanyBrandMixin,
  UpdateCompanyDetailsMixin,
  UpdateDocumentSettingsMixin,
  UpdateEmailSettingsMixin,
  UpdateSubscriptionSettingsMixin,
) {
  // Create settings - use CreateCompanyMixin
  @Loading()
  @CatchError()
  public async createCompany(payload: IRegisterCompanyForm): Promise<void> {
    await this.CreateCompanyMixin(payload);

    await this.$notifier({
      message: this.trans('notify.success.company_created', 'Kompānija veiksmīgi izveidota'),
      state: AppState.SUCCESS,
    });

    localStorage.setItem(LocalStorage.SWITCH_COMPANY, 'show');
    await AppModule.SWITCH_COMPANY();
  }

  // Fetch settings - use FetchCompanyMixin
  @Loading()
  @CatchError()
  public async fetchCompany(companyUUID: string = CompanyModule.getCompanyUUID): Promise<ICompany> {
    return await this.FetchCompanyMixin(companyUUID);
  }

  // Delete existing company - use DeleteCompanyMixin
  @Loading()
  @CatchError()
  public async deleteCompany(companyUUID: string = CompanyModule.getCompanyUUID): Promise<void> {
    await this.DeleteCompanyMixin(companyUUID);

    await this.$notifier({
      message: this.trans('notify.success.company_deleted', 'Kompānija veiksmīgi izdzēsta'),
      state: AppState.SUCCESS,
    });

    localStorage.setItem(LocalStorage.SWITCH_COMPANY, 'show');
    await AppModule.SWITCH_COMPANY();
  }

  // Update brand settings - use UpdateCompanyBrandMixin
  @Loading()
  @CatchError()
  public async updateCompanyBrand(
    payload: ICompanyBrandForm,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<void> {
    await this.UpdateCompanyBrandMixin(payload, companyUUID);
    await this.$notifier({
      message: this.trans('notify.success.company_logo_updated', 'Uzņēmuma logotips veiksmīgi nomainīts'),
      state: AppState.SUCCESS,
    });
    await CompanyModule.SET_COMPANY();
    await UserModule.SET_USER();
  }

  // Update company settings - use UpdateCompanyDetailsMixin
  @Loading()
  @CatchError()
  public async updateCompanyDetails(
    payload: ICompanySettings,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<void> {
    await this.UpdateCompanyDetailsMixin(payload, companyUUID);
    await this.$notifier({
      message: this.trans('notify.success.company_settings_updated', 'Uzņēmuma iestatījumi veiksmīgi atjaunoti'),
      state: AppState.SUCCESS,
    });
    await CompanyModule.SET_COMPANY();
  }

  // Update document settings - use UpdateDocumentSettingsMixin
  @Loading()
  @CatchError()
  public async updateDocumentSettings(
    payload: IDocumentSettings,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<void> {
    await this.UpdateDocumentSettingsMixin(payload, companyUUID);
    await this.$notifier({
      message: this.trans('notify.success.company_settings_updated', 'Uzņēmuma iestatījumi veiksmīgi atjaunoti'),
      state: AppState.SUCCESS,
    });
    await CompanyModule.SET_COMPANY();
  }

  // Update email settings - UpdateEmailSettingsMixin
  @Loading()
  @CatchError()
  public async updateEmailSettings(
    payload: IEmailSettings,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<void> {
    await this.UpdateEmailSettingsMixin(payload, companyUUID);
    await this.$notifier({
      message: this.trans('notify.success.company_settings_updated', 'Uzņēmuma iestatījumi veiksmīgi atjaunoti'),
      state: AppState.SUCCESS,
    });
    await CompanyModule.SET_COMPANY();
  }

  // Update subscription settings - use UpdateSubscriptionSettingsMixin
  @Loading()
  @CatchError()
  public async updateSubscriptionSettings(
    payload: ISubscriptionSettings,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<void> {
    await this.UpdateSubscriptionSettingsMixin(companyUUID);
    this.$logger('Update company subscription...', payload, companyUUID);
  }
}
