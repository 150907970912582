import { Component, Vue } from 'vue-property-decorator';
import type Locale from '@/enums/config/Locale';
import DocumentRoute from '@/enums/config/document/Route';
import DocumentStatus from '@/enums/config/document/Status';
import DocumentType from '@/enums/config/document/Type';
import TranslationModule from '@/store/modules/Translation';

@Component
export default class TranslateDocumentStatusMixin extends Vue {
  public TranslateDocumentStatusByRouteMixin(
    status: DocumentStatus,
    route: DocumentRoute,
    locale: Locale = TranslationModule.getLocale,
  ): string {
    switch (status) {
      case DocumentStatus.DRAFT:
        return this.trans('generic.status.draft', 'Melnraksts', locale);
      case DocumentStatus.SENT:
        return this.trans('generic.status.sent', 'Nosūtīts', locale);
      case DocumentStatus.PARTIALLY_PAID:
        return this.trans('generic.status.partially_paid', 'Daļēji apmaksāts', locale);
      case DocumentStatus.PAID:
        switch (route) {
          case DocumentRoute.OFFER:
            return this.trans('generic.status.accepted', 'Apstriprināts', locale);
          default:
            return (route as unknown as DocumentType) === DocumentType.CREDIT_NOTE
              ? this.trans('generic.status.returned', 'Atgriezts', locale)
              : this.trans('generic.status.paid', 'Apmaksāts', locale);
        }
      case DocumentStatus.OVERDUE:
        switch (route) {
          case DocumentRoute.OFFER:
            return this.trans('generic.status.rejected', 'Noraidīts', locale);
          default:
            return this.trans('generic.status.overdue', 'Kavēts', locale);
        }
      case DocumentStatus.CANCELED:
        return this.trans('generic.status.canceled', 'Anulēts', locale);
      default:
        return this.trans('generic.status.unknown', 'Nezināms', locale);
    }
  }

  public TranslateDocumentStatusByTypeMixin(
    status: DocumentStatus,
    type: DocumentType,
    locale: Locale = TranslationModule.getLocale,
  ): string {
    switch (status) {
      case DocumentStatus.DRAFT:
        return this.trans('generic.status.draft', 'Melnraksts', locale);
      case DocumentStatus.SENT:
        return this.trans('generic.status.sent', 'Nosūtīts', locale);
      case DocumentStatus.PARTIALLY_PAID:
        return this.trans('generic.status.partially_paid', 'Daļēji apmaksāts', locale);
      case DocumentStatus.PAID:
        switch (type) {
          case DocumentType.CREDIT_NOTE:
            return this.trans('generic.status.returned', 'Atgriezts', locale);
          case DocumentType.OFFER:
            return this.trans('generic.status.accepted', 'Apstriprināts', locale);
          default:
            return this.trans('generic.status.paid', 'Apmaksāts', locale);
        }
      case DocumentStatus.OVERDUE:
        switch (type) {
          case DocumentType.OFFER:
            return this.trans('generic.status.rejected', 'Noraidīts', locale);
          default:
            return this.trans('generic.status.overdue', 'Kavēts', locale);
        }
      case DocumentStatus.CANCELED:
        return this.trans('generic.status.canceled', 'Anulēts', locale);
      default:
        return this.trans('generic.status.unknown', 'Nezināms', locale);
    }
  }
}
