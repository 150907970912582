import { Component, Vue } from 'vue-property-decorator';
import type Locale from '@/enums/config/Locale';
import DocumentType from '@/enums/config/document/Type';
import RecurringDocumentType from '@/enums/config/document/RecurringType';
import TranslationModule from '@/store/modules/Translation';

@Component
export default class TranslateDocumentTypeMixin extends Vue {
  public TranslateDocumentTypeMixin(
    type: DocumentType | RecurringDocumentType,
    isFinal: boolean = false,
    locale: Locale = TranslationModule.getLocale,
  ): string {
    switch (type) {
      case DocumentType.WAYBILL:
        return this.trans('generic.bookkeeping_type.waybill', 'Pavadzīme', locale);
      case DocumentType.ADVANCE_INVOICE:
        return this.trans('generic.bookkeeping_type.advance_invoice', 'Avansa rēķins', locale);
      case DocumentType.OFFER:
        return this.trans('generic.bookkeeping_type.offer', 'Piedāvājums', locale);
      case DocumentType.CREDIT_NOTE:
        return this.trans('generic.bookkeeping_type.credit_note', 'Kredītrēķins', locale);
      case DocumentType.INVOICE:
      default:
        return isFinal
          ? this.trans('generic.bookkeeping_type.invoice.final', 'Gala rēķins', locale)
          : this.trans('generic.bookkeeping_type.invoice', 'Rēķins', locale);
    }
  }
}
