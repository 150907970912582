import { RouteConfig } from 'vue-router';
import PublicRouteName from '@/enums/config/router/Public';
import MaintenancePage from '@/pages/public/Maintenance.vue';

const MaintenanceRouter: Array<RouteConfig> = [
  {
    path: 'maintenance',
    name: PublicRouteName.MAINTENANCE,
    component: MaintenancePage,
    meta: {
      title: 'MaintenancePage',
    },
  },
];

export default MaintenanceRouter;
