import DEFAULT_PAGINATION from '@/constants/mocks/Pagination';
import SortDir from '@/enums/generics/SortDir';
import SortField from '@/enums/generics/SortField';
import type IUrlParams from '@/interfaces/IUrlParams';

const DEFAULT_URL_PARAMS: IUrlParams = {
  search: '',

  page: 1,
  per_page: DEFAULT_PAGINATION.per_page,

  types: [],
  statuses: [],

  sort_direction: SortDir.DESC,
  sort_field: SortField.DATE,

  date_from: '',
  date_to: '',

  templates: 0,
  plain: 1,
};

export default DEFAULT_URL_PARAMS;
