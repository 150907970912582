import { RouteConfig } from 'vue-router';
import AuthRouteName from '@/enums/config/router/Auth';
import LoginPage from '@/pages/auth/Login.vue';

const LoginRouter: Array<RouteConfig> = [
  {
    path: 'login',
    name: AuthRouteName.LOGIN,
    component: LoginPage,
    meta: {
      title: 'Login',
    },
  },
];

export default LoginRouter;
