import { Component, Vue } from 'vue-property-decorator';
import type IDocumentPayment from '@/interfaces/document/IDocumentPayment';
import type IDocumentPaymentForm from '@/interfaces/forms/document/IDocumentPaymentForm';
import DocumentRepository from '@/repository/Document';
import CompanyModule from '@/store/modules/Company';

@Component
export default class CreateDocumentPaymentMixin extends Vue {
  public async CreateDocumentPaymentMixin(
    documentUUID: string,
    payload: IDocumentPaymentForm,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<IDocumentPayment> {
    return (await DocumentRepository.createPayment(companyUUID, documentUUID, payload)).data;
  }
}
