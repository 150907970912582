<template>
  <v-btn
    :href="link"
    :color="color"
    :loading="loading"
    :disabled="disabled"
    :height="height"
    :class="`${textColor}--text px-8 py-8`"
  >
    <slot>
      <div class="flex items-center justify-center gap-2 font-weight-light">
        <v-icon large>{{ icon }}</v-icon>
        <h3 class="text-left" style="width: 84px">
          <small v-text="trans('button.available.on', 'Pieejams')" />
          <br />{{ store }}
        </h3>
      </div>
    </slot>
  </v-btn>
</template>
<script lang="ts">
  import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
  import AppleIcon from '@/components/icons/brands/Apple.vue';
  import GooglePlayIcon from '@/components/icons/brands/GooglePlay.vue';

  @Component({
    components: {
      AppleIcon,
      GooglePlayIcon,
    },
  })
  export default class DownloadAppButton extends Vue {
    @Prop() link!: string;
    @Prop() icon!: string;
    @Prop() store!: string;

    @Prop({ default: 'black' }) color!: string;
    @Prop({ default: 'white' }) textColor!: string;
    @Prop({ default: '45' }) height!: string;

    @Prop({ type: Boolean }) loading!: boolean;
    @Prop({ type: Boolean }) disabled!: boolean;

    /*****         computed       *****/
    /*****         watchers       *****/
    /*****         methods        *****/

    @Emit('submit')
    public async submit(): Promise<void> {}

    /*****         helpers        *****/
    /*****      vue lifecycle     *****/
  }
</script>
