<template>
  <v-container class="flex items-center justify-center text-center">
    <v-row class="items-center justify-center grow-0 w-full">
      <v-col cols="12" class="flex flex-col items-center gap-4 pa-4">
        <h2 class="text-center" v-text="trans('signup.form.heading', 'Reģistrācija')" />
      </v-col>
      <!-- Login form -->
      <v-col cols="12" sm="8" md="6" lg="4">
        <v-form :disabled="isLoading" @submit.prevent>
          <validation-observer ref="form-observer" class="flex flex-col items-center w-full gap-y-4">
            <template v-if="form.company">
              <!-- Company type toggle -->
              <ToggleButton
                v-if="isDefaultRegistration"
                v-model="form.company.is_legal"
                :disabled="isLoading"
                :option-false="trans('generic.type.individual', 'Fiziska persona')"
                :option-true="trans('generic.type.legal', 'Juridiska persona')"
              />
            </template>
            <!-- Email field -->
            <TextField
              v-model="form.email"
              v-trim.all.lowercase
              :type="KeypressInputType.EMAIL"
              :placeholder="trans('workspace.title.email', 'Epasts')"
              :label="trans('workspace.title.email', 'Epasts')"
              :debounce-validation="TimeConfig.DEBOUNCE"
              rules="required|email|max:191"
              autocomplete="username"
              :disabled="isLoading"
              name="email"
              autofocus
              rounded
              class="text-body-2 w-full"
              @keypress.prevent.space
              @blur="fixInputs"
            />
            <!-- Password field -->
            <TextField
              v-model="form.password"
              :placeholder="trans('workspace.title.password', 'Parole')"
              :label="trans('workspace.title.password', 'Parole')"
              autocomplete="current-password"
              rules="required|password_strength|min:8"
              name="password"
              field-type="password"
              :disabled="isLoading"
              caps-lock-warning
              show-progress
              rounded
              class="text-body-2 w-full"
            />
            <!-- First name field -->
            <TextField
              key="first_name"
              v-model="form.first_name"
              v-trim
              :disabled="isLoading"
              :placeholder="trans('profile.first_name', 'Vārds')"
              :label="trans('profile.first_name', 'Vārds')"
              rules="required|max:191"
              autocomplete="given-name"
              name="first_name"
              rounded
              class="text-body-2 w-full"
            />
            <!-- Last name field -->
            <TextField
              key="last_name"
              v-model="form.last_name"
              v-trim
              :disabled="isLoading"
              :placeholder="trans('profile.last_name', 'Uzvārds ')"
              :label="trans('profile.last_name', 'Uzvārds ')"
              rules="required|max:191"
              autocomplete="family-name"
              name="last_name"
              rounded
              class="text-body-2 w-full"
            />
            <v-slide-y-transition hide-on-leave leave-absolute>
              <div v-if="form.company && companyType === CompanyType.LEGAL" class="bloc w-full space-y-4">
                <!-- Company name field -->
                <SelectCompanyFromRegistry
                  v-if="companyType === CompanyType.LEGAL"
                  v-model="form.company.name"
                  v-trim
                  :disabled="isLoading"
                  :search-country="form.company.country"
                  :search-by="RegistrySearchField.NAME"
                  :placeholder="translateCompanyNameField(companyType)"
                  :label="translateCompanyNameField(companyType)"
                  rules="required|max:191"
                  name="company.name"
                  class="text-body-2 w-full"
                  @set="setCompany"
                />
              </div>
            </v-slide-y-transition>
            <v-slide-y-transition hide-on-leave leave-absolute>
              <div v-if="form.first_name && form.last_name && form.company" class="bloc w-full space-y-4">
                <!-- Country field -->
                <SelectCountry
                  v-model="form.company.country"
                  autocomplete="country"
                  :disabled="isLoading"
                  rules="required|iso:country|min:2|max:2"
                  name="company.country"
                  hide-selected
                  class="text-body-2 w-full"
                  @blur="composeVatNumber"
                />
                <!-- Currency field -->
                <SelectCurrency
                  v-model="form.company.currency_code"
                  autocomplete="transaction-currency"
                  :disabled="isLoading"
                  rules="required|iso:currency|min:3|max:3"
                  name="company.currency_code"
                  hide-selected
                  class="text-body-2 w-full"
                />
                <!-- Registration number field -->
                <TextField
                  v-model="form.company.registration_number"
                  v-trim.all.uppercase
                  :disabled="isLoading"
                  :placeholder="translateCompanyTaxNumberField(companyType)"
                  :label="translateCompanyTaxNumberField(companyType)"
                  :rules="registrationNumberRules"
                  name="company.registration_number"
                  rounded
                  class="text-body-2 w-full"
                  @keypress.prevent.space
                  @blur="composeVatNumber"
                />
                <v-slide-y-transition hide-on-leave leave-absolute>
                  <!-- VAT registration number field -->
                  <VatNumberField
                    v-if="form.company.registration_number"
                    v-model="form.company.vat_registration_number"
                    v-trim.all.uppercase
                    :disabled="isLoading"
                    :check-vat-number="vatNumber"
                    :placeholder="trans('workspace.title.vat_registration_number', 'PVN reģistrācijas numurs')"
                    :label="trans('workspace.title.vat_registration_number', 'PVN reģistrācijas numurs')"
                    :rules="registrationNumberRules"
                    name="company.vat_registration_number"
                    rounded
                    class="text-body-2 w-full"
                    @keypress.prevent.space
                    @checked="clearVatNumber"
                    @blur="fixInputs"
                  />
                </v-slide-y-transition>
                <!-- Address field -->
                <TextField
                  v-model="form.company.address"
                  v-trim.capitalize
                  :disabled="isLoading"
                  :placeholder="trans('signup.form.address', 'Adrese')"
                  :label="trans('signup.form.address', 'Adrese')"
                  rules="max:191"
                  name="address"
                  rounded
                  class="text-body-2 w-full"
                />
              </div>
            </v-slide-y-transition>
            <!-- Terms & conditions field -->
            <CheckboxField
              v-model="form.terms"
              :disabled="isLoading"
              :label="trans('login.form.terms_required', 'Lietošanas noteikumi')"
              name="terms"
              rules="required"
            >
              <template #label>
                <div
                  v-html="sanitizeHTML(termsAndConditions)"
                  class="black--text text-subtitle-2 font-weight-light"
                  @click.stop
                />
              </template>
            </CheckboxField>
            <!-- Register button -->
            <v-col cols="10" class="py-4">
              <SubmitButton
                block
                rounded
                :loading="isLoading"
                :text="trans('button.signup', 'Reģistrēties')"
                @mousedown="register"
              />
            </v-col>
            <!-- Login link -->
            <RouterLink class="link text-body-2 my-4" :to="{ name: AuthRouteName.LOGIN }" @click.prevent>
              <span v-text="`${trans('signup.form.already_user', 'Jums jau ir profils?')} `" />
              <span class="text-decoration-underline" v-text="trans('button.login', 'Pieslēgties')" />
            </RouterLink>
          </validation-observer>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
  import Wait from '@/decorators/Wait';
  import cloneDeep from 'lodash-es/cloneDeep';
  import { Component, Mixins, Ref, Watch } from 'vue-property-decorator';
  import useLocalStorage from '@/services/LocalStorage';
  import CountryISO from '@/enums/config/iso/CountryISO';
  import Locale from '@/enums/config/Locale';
  import TimeConfig from '@/enums/config/TimeConfig';
  import RegistrySearchField from '@/enums/config/RegistrySearchField';
  import AuthRouteName from '@/enums/config/router/Auth';
  import CompanyType from '@/enums/generics/CompanyType';
  import KeypressInputType from '@/enums/types/KeypressInputType';
  import type { ProviderInstance } from 'vee-validate/dist/types/types';
  import type IRegisterProfileForm from '@/interfaces/forms/auth/IRegisterProfileForm';
  import type IBusinessRegistry from '@/interfaces/IBusinessRegistry';
  import DEFAULT_REGISTER_PROFILE_FORM from '@/constants/mocks/forms/RegisterProfile';
  import DEFAULT_REGISTER_WITH_INVITATION_FORM from '@/constants/mocks/forms/RegisterWithInvitation';
  import ErrorModule from '@/store/modules/Error';
  import LoadingModule from '@/store/modules/Loading';
  import TranslationModule from '@/store/modules/Translation';
  import AuthMixin from '@/mixins/Auth';
  import TranslateMixin from '@/mixins/Translate';
  import TextField from '@/components/global/inputs/TextField.vue';
  import ToggleButton from '@/components/global/buttons/Toggle.vue';
  import SelectCountry from '@/components/global/inputs/SelectCountry.vue';
  import CheckboxField from '@/components/global/inputs/CheckboxField.vue';
  import SelectCurrency from '@/components/global/inputs/SelectCurrency.vue';
  import VatNumberField from '@/components/global/inputs/VatNumberField.vue';
  import SelectCompanyFromRegistry from '@/components/global/inputs/SelectCompanyFromRegistry.vue';
  import SubmitButton from '@/components/global/buttons/Submit.vue';

  @Component({
    components: {
      TextField,
      ToggleButton,
      SelectCountry,
      CheckboxField,
      SelectCurrency,
      VatNumberField,
      SelectCompanyFromRegistry,
      SubmitButton,
      YoutubeModal: () => import('@/components/global/modals/Youtube.vue'),
    },
  })
  export default class RegisterPage extends Mixins(AuthMixin, TranslateMixin) {
    @Ref('form-observer') formObserver!: ProviderInstance;

    public form: IRegisterProfileForm = cloneDeep(DEFAULT_REGISTER_PROFILE_FORM);
    public invitationHash: string = '';
    public vatNumber: string = '';

    /*****         computed       *****/

    public get isDefaultRegistration(): boolean {
      return !this.invitationHash;
    }

    public get locale(): Locale {
      return TranslationModule.getLocale;
    }

    public get error(): unknown {
      return ErrorModule.getError;
    }

    public get isLoading(): boolean {
      return LoadingModule.isLoading;
    }

    public get localeLink(): string {
      return this.locale === Locale.LV ? '' : `${this.locale}`;
    }

    public get homePage(): string {
      const link = process.env.VUE_APP_HOMEPAGE ?? 'https://numbero.app';
      return `${link}/${this.localeLink}`;
    }

    // Get terms and conditions link placeholder text
    public get termsAndConditions(): string {
      const links = {
        terms: `${this.homePage}/terms-of-service`,
        privacy: `${this.homePage}/privacy`,
      };
      return this.trans(
        'signup.form.terms_and_privacy',
        "Es piekrītu mājas lapas <a target='_blank' href=':terms'>Lietošanas Noteikumiem</a> un <a target='_blank' href=':privacy'>Privātuma Politikai</a>",
        links,
      );
    }

    public get registrationNumberRules(): string {
      const isLatvia = this.form.company!.country === CountryISO.LV;
      return isLatvia ? 'registration_number' : 'min:6|max:50';
    }

    public get companyType(): CompanyType {
      return this.form.company!.is_legal ? CompanyType.LEGAL : CompanyType.PRIVATE;
    }

    public get RegistrySearchField(): typeof RegistrySearchField {
      return RegistrySearchField;
    }

    public get AuthRouteName(): typeof AuthRouteName {
      return AuthRouteName;
    }

    public get TimeConfig(): typeof TimeConfig {
      return TimeConfig;
    }

    public get CompanyType(): typeof CompanyType {
      return CompanyType;
    }

    public get KeypressInputType(): typeof KeypressInputType {
      return KeypressInputType;
    }

    /*****         watchers       *****/

    @Watch('error')
    private watchForError(): void {
      if (this.error) {
        this.formObserver.setErrors(this.error as string[]);
      }
    }

    /*****         methods        *****/

    public async register(): Promise<void> {
      this.fixInputs();

      this.formObserver.reset();
      if (await this.formObserver.validate()) {
        return await this.authRegister(this.form);
      }

      return this.scrollToFirstError(this.formObserver);
    }

    /*****         helpers        *****/

    @Wait()
    public composeVatNumber(): void {
      this.vatNumber = `${this.form.company!.country}${this.form.company!.registration_number}`;
    }

    public clearVatNumber(): void {
      this.vatNumber = '';
    }

    public setCompany(company: IBusinessRegistry): void {
      this.form.company!.name = company.name.trim();
      this.form.company!.registration_number = this.removeWhiteSpaces(company.registration_number).toUpperCase();
      this.form.company!.vat_registration_number = '';
      this.form.company!.address = company.address.trim();

      // Set VAT number to check with VAT info exchange service
      this.composeVatNumber();
    }

    public fixInputs(): void {
      this.form.email = this.removeWhiteSpaces(this.form.email ?? '').toLowerCase();

      if (this.form.company) {
        if (this.companyType === CompanyType.PRIVATE) {
          this.form.company.name = `${this.form.first_name} ${this.form.last_name}`;
        }

        this.form.company.registration_number = this.removeWhiteSpaces(
          this.form.company.registration_number ?? '',
        ).toUpperCase();

        this.form.company.vat_registration_number = this.removeWhiteSpaces(
          this.form.company.vat_registration_number ?? '',
        ).toUpperCase();
      }
    }

    /*****      vue lifecycle     *****/

    beforeMount() {
      this.invitationHash = useLocalStorage().getInvitationHash()?.hash ?? '';
      const email = useLocalStorage().getInvitationHash()?.email ?? '';

      this.form = cloneDeep(
        this.isDefaultRegistration
          ? DEFAULT_REGISTER_PROFILE_FORM
          : { ...DEFAULT_REGISTER_WITH_INVITATION_FORM, invitation_hash: this.invitationHash, email },
      );
    }
  }
</script>
