enum LocalStorage {
  LOCALE = 'locale',
  CHART_TYPE = 'chartType',
  SWITCH_COMPANY = 'switchCompany',
  RESET_PASSWORD_EMAIL = 'resetPasswordEmail',
  INVITATION_HASH = 'ui#',
  COMPACT_MODE = 'compactMode',
}

export default LocalStorage;
