import cloneDeep from 'lodash-es/cloneDeep';
import { Component, Vue } from 'vue-property-decorator';
import DEFAULT_DOCUMENT_FORM from '@/constants/mocks/forms/Document';
import DateFormat from '@/enums/config/DateFormat';
import type { IDocumentPreference } from '@/interfaces/company/IDocumentSettings';
import type IDocument from '@/interfaces/document/IDocument';
import type IPartner from '@/interfaces/partner/IPartner';
import CompanyModule from '@/store/modules/Company';

@Component
export default class InitDocumentWithPartnerMixin extends Vue {
  public async InitDocumentWithPartnerMixin(blankDocument: IDocument, partner: IPartner): Promise<IDocument> {
    const document: IDocument = cloneDeep({ ...DEFAULT_DOCUMENT_FORM, ...blankDocument });

    const documentPreferences: IDocumentPreference = await CompanyModule.GET_DOCUMENT_PREFERENCES(document.type);

    document.recipient = cloneDeep({ ...DEFAULT_DOCUMENT_FORM.recipient, ...partner });

    document.lang = partner.preferences.document_lang || document.lang;
    document.currency_code = partner.preferences.currency_code || document.currency_code;

    const hasLateFee = partner.preferences.enable_late_fees && partner.preferences.late_fee_percent !== null;
    document.late_fee_percent = hasLateFee ? partner.preferences.late_fee_percent : document.late_fee_percent;

    const dueDays = partner.preferences.default_due_days ?? documentPreferences.default_due_days;
    document.due_date = dueDays ? this.addPeriodToFormat(document.date, DateFormat.LARAVEL, dueDays, 'days') : '';

    return cloneDeep(document);
  }
}
