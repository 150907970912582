<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.99996 7.50001V1.66667H15V7.50001M4.99996 15H3.33329C2.89127 15 2.46734 14.8244 2.15478 14.5119C1.84222 14.1993 1.66663 13.7754 1.66663 13.3333V9.16667C1.66663 8.72464 1.84222 8.30072 2.15478 7.98816C2.46734 7.6756 2.89127 7.50001 3.33329 7.50001H16.6666C17.1087 7.50001 17.5326 7.6756 17.8451 7.98816C18.1577 8.30072 18.3333 8.72464 18.3333 9.16667V13.3333C18.3333 13.7754 18.1577 14.1993 17.8451 14.5119C17.5326 14.8244 17.1087 15 16.6666 15H15M4.99996 11.6667H15V18.3333H4.99996V11.6667Z"
      stroke-linejoin="round"
      stroke-linecap="round"
      stroke="currentColor"
      stroke-width="1.5"
    />
  </svg>
</template>

<script lang="ts">
  import { Component, Vue } from 'vue-property-decorator';

  @Component
  export default class PrinterIcon extends Vue {
    /*****         computed       *****/
    /*****         watchers       *****/
    /*****         methods        *****/
    /*****         helpers        *****/
    /*****      vue lifecycle     *****/
  }
</script>
