import { Component, Vue } from 'vue-property-decorator';
import type Locale from '@/enums/config/Locale';
import Unit from '@/enums/generics/Unit';
import TranslationModule from '@/store/modules/Translation';

@Component
export default class TranslateUnitMixin extends Vue {
  public TranslateUnitMixin(unit: Unit | string, locale: Locale = TranslationModule.getLocale): string {
    switch (unit) {
      case Unit.EACH:
        return this.trans('generic.unit.each', 'gab', locale);
      case Unit.MINUTE:
        return this.trans('generic.unit.minute', 'minūte', locale);
      case Unit.HOUR:
        return this.trans('generic.unit.hour', 'gab', locale);
      case Unit.DAY:
        return this.trans('generic.unit.day', 'diena', locale);
      case Unit.NIGHT:
        return this.trans('generic.unit.night', 'nakts', locale);
      case Unit.WEEK:
        return this.trans('generic.unit.week', 'nedēļa', locale);
      case Unit.MONTH:
        return this.trans('generic.unit.month', 'mēnesis', locale);
      case Unit.YEAR:
        return this.trans('generic.unit.year', 'gads', locale);
      case Unit.G:
        return this.trans('generic.unit.g', 'g', locale);
      case Unit.HG:
        return this.trans('generic.unit.hg', 'hg', locale);
      case Unit.KG:
        return this.trans('generic.unit.kg', 'kg', locale);
      case Unit.LB:
        return this.trans('generic.unit.lb', 'mārc.', locale);
      case Unit.TON:
        return this.trans('generic.unit.ton', 'tonna', locale);
      case Unit.MILE:
        return this.trans('generic.unit.mile', 'jūdze', locale);
      case Unit.M:
        return this.trans('generic.unit.m', 'm', locale);
      case Unit.KM:
        return this.trans('generic.unit.km', 'km', locale);
      case Unit.M2:
        return this.trans('generic.unit.m2', 'm²', locale);
      case Unit.HA:
        return this.trans('generic.unit.ha', 'ha', locale);
      case Unit.AC:
        return this.trans('generic.unit.ac', 'akri', locale);
      case Unit.M3:
        return this.trans('generic.unit.m3', 'm³', locale);
      case Unit.L:
        return this.trans('generic.unit.l', 'l', locale);
      case Unit.ML:
        return this.trans('generic.unit.ml', 'ml', locale);
      case Unit.WORD:
        return this.trans('generic.unit.word', 'vārds', locale);
      case Unit.PAGE:
        return this.trans('generic.unit.page', 'lapa', locale);
      case Unit.PACKAGE:
        return this.trans('generic.unit.package', 'iepakojums', locale);
      case Unit.KIT:
        return this.trans('generic.unit.kit', 'komplekts', locale);
      case Unit.KWH:
        return this.trans('generic.unit.kwh', 'kWh', locale);
      case Unit.MWH:
        return this.trans('generic.unit.mwh', 'MWh', locale);
      case Unit.GWH:
        return this.trans('generic.unit.gwh', 'GWh', locale);
      default:
        return unit;
    }
  }
}
