import { RouteConfig } from 'vue-router';
import PublicLayout from '@/layouts/Public.vue';

import DebugRouter from '@/router/public/Debug';
import InvitationsRouter from '@/router/public/Invitations';
import MaintenanceRouter from '@/router/public/Maintenance';
import ShareDocumentRouteName from '@/router/public/ShareDocument';
import OnboardingRouter from '@/router/public/Onboarding';
import VerifyEmailRouter from '@/router/public/VerifyEmail';

const PublicIndexRouter: Array<RouteConfig> = [
  {
    path: '/',
    component: PublicLayout,
    meta: {
      guard: ['maintenance'],
    },
    children: [
      ...InvitationsRouter,
      ...ShareDocumentRouteName,
      ...OnboardingRouter,
      ...VerifyEmailRouter,
      ...MaintenanceRouter,
      ...DebugRouter,
    ],
  },
];

export default PublicIndexRouter;
