import { Component, Vue } from 'vue-property-decorator';
import type ICompany from '@/interfaces/company/ICompany';
import type ICompanySettings from '@/interfaces/company/ICompanyDetails';
import CompanyRepository from '@/repository/Company';
import CompanyModule from '@/store/modules/Company';

@Component
export default class UpdateCompanyDetailsMixin extends Vue {
  public async UpdateCompanyDetailsMixin(
    payload: ICompanySettings,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<ICompany> {
    return (await CompanyRepository.updateCompanySettings(companyUUID, payload)).data;
  }
}
