import DEFAULT_COMPANY_DETAILS from '@/constants/mocks/company/CompanyDetails';
import DEFAULT_COMPANY_BRAND from '@/constants/mocks/company/CompanyBrand';
import DEFAULT_DOCUMENT_SETTINGS from '@/constants/mocks/company/DocumentSettings';
import DEFAULT_EMAIL_SETTINGS from '@/constants/mocks/company/EmailSettings';
import DEFAULT_SUBSCRIPTION_SETTINGS from '@/constants/mocks/company/SubscriptionSettings';
import type ICompany from '@/interfaces/company/ICompany';

const DEFAULT_COMPANY: ICompany = {
  uuid: null,
  ...DEFAULT_COMPANY_DETAILS,
  ...DEFAULT_COMPANY_BRAND,
  ...DEFAULT_DOCUMENT_SETTINGS,
  email_preferences: {
    ...DEFAULT_EMAIL_SETTINGS,
  },
  ...DEFAULT_SUBSCRIPTION_SETTINGS,
};

export default DEFAULT_COMPANY;
