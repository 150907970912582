import { Component, Vue } from 'vue-property-decorator';
import Locale from '@/enums/config/Locale';
import DateFormat from '@/enums/config/DateFormat';
import type { IRecurringConfig } from '@/interfaces/document/IRecurringDocument';
import TranslationModule from '@/store/modules/Translation';

@Component
export default class TranslateRecurringIterationCountMixin extends Vue {
  public TranslateRecurringIterationCountMixin(
    config: IRecurringConfig,
    locale: Locale = TranslationModule.getLocale,
  ): string {
    const iterations = this.countIterations(config, DateFormat.LARAVEL, locale);
    const lastDigit = iterations % 10;
    const lastTwoDigits = iterations % 100;

    if (iterations === 0) {
      return '';
    } else {
      let humanReadableIteration = '';

      switch (locale) {
        case Locale.RU:
          if (lastDigit === 1 && lastTwoDigits !== 11) {
            humanReadableIteration = this.remainingIterationSingular(iterations, locale);
          } else if (lastDigit >= 2 && lastDigit <= 4 && (lastTwoDigits < 10 || lastTwoDigits >= 20)) {
            humanReadableIteration = this.remainingIterationPlural(iterations, locale);
          } else {
            humanReadableIteration = this.remainingIterationOther(iterations, locale);
          }
          break;
        case Locale.LV:
          if ((lastDigit === 1 && lastTwoDigits !== 11) || (lastDigit === 0 && lastTwoDigits !== 10)) {
            humanReadableIteration = this.remainingIterationSingular(iterations, locale);
          } else {
            humanReadableIteration = this.remainingIterationPlural(iterations, locale);
          }
          break;
        case Locale.EN:
          humanReadableIteration = this.remainingIterationPlural(iterations, locale);
          break;
      }

      return humanReadableIteration;
    }
  }

  private remainingIterationSingular(time: number, locale: Locale): string {
    return this.trans('workspace.title.more_times.singular', 'Vēl :time reizi', { time }, locale);
  }

  private remainingIterationPlural(time: number, locale: Locale): string {
    return this.trans('workspace.title.more_times.plural', 'Vēl :time reizes', { time }, locale);
  }

  private remainingIterationOther(time: number, locale: Locale): string {
    return this.trans('workspace.title.more_times.other', 'Vēl :time reizes', { time }, locale);
  }
}
