import ApiRepository from '@/repository/config/Api';
import { AxiosPromise } from 'axios';

export default class UtilRepository {
  // Retrieve country codes, flags and more
  public static countries(): AxiosPromise {
    return ApiRepository.get('/app-data/countries');
  }

  // Get currencies
  public static currencies(): AxiosPromise {
    return ApiRepository.get('/app-data/currencies');
  }
}
