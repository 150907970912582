<template>
  <svg width="28" height="32" viewBox="0 0 28 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M28 9V28C28 29.0609 27.5786 30.0783 26.8284 30.8284C26.0783 31.5786 25.0609 32 24 32H22V30H24C24.5304 30 25.0391 29.7893 25.4142 29.4142C25.7893 29.0391 26 28.5304 26 28V9H22C21.2044 9 20.4413 8.68393 19.8787 8.12132C19.3161 7.55871 19 6.79565 19 6V2H8C7.46957 2 6.96086 2.21071 6.58579 2.58579C6.21071 2.96086 6 3.46957 6 4V22H4V4C4 2.93913 4.42143 1.92172 5.17157 1.17157C5.92172 0.421427 6.93913 0 8 0L19 0L28 9ZM3.2 23.7H0V31.698H1.582V29.014H3.188C3.762 29.014 4.25 28.9 4.652 28.668C5.058 28.434 5.368 28.118 5.578 27.72C5.79597 27.3024 5.90663 26.8371 5.9 26.366C5.9 25.866 5.794 25.414 5.584 25.012C5.37512 24.6124 5.05577 24.2812 4.664 24.058C4.264 23.818 3.778 23.7 3.2 23.7ZM4.29 26.366C4.29721 26.6295 4.23879 26.8907 4.12 27.126C4.01343 27.3309 3.84754 27.4989 3.644 27.608C3.41118 27.7232 3.15365 27.7795 2.894 27.772H1.576V24.96H2.896C3.332 24.96 3.674 25.08 3.92 25.322C4.166 25.566 4.29 25.914 4.29 26.366ZM6.724 23.7V31.698H9.644C10.446 31.698 11.112 31.538 11.64 31.224C12.1743 30.9043 12.5915 30.4212 12.83 29.846C13.09 29.246 13.222 28.522 13.222 27.678C13.222 26.838 13.092 26.122 12.83 25.528C12.5944 24.9594 12.1811 24.4824 11.652 24.168C11.124 23.856 10.454 23.7 9.642 23.7H6.724ZM8.306 24.99H9.432C9.928 24.99 10.332 25.09 10.65 25.294C10.9801 25.5099 11.2291 25.8293 11.358 26.202C11.516 26.604 11.594 27.106 11.594 27.708C11.6002 28.1069 11.5545 28.5049 11.458 28.892C11.3867 29.1973 11.2533 29.4846 11.066 29.736C10.892 29.9603 10.6614 30.1342 10.398 30.24C10.0893 30.3555 9.76149 30.4111 9.432 30.404H8.306V24.99ZM15.792 28.516V31.698H14.212V23.7H19.308V25.006H15.792V27.24H19.004V28.516H15.792Z"
      fill-rule="evenodd"
      clip-rule="evenodd"
      fill="currentColor"
    />
  </svg>
</template>

<script lang="ts">
  import { Component, Vue } from 'vue-property-decorator';

  @Component
  export default class PdfIcon extends Vue {
    /*****         computed       *****/
    /*****         watchers       *****/
    /*****         methods        *****/
    /*****         helpers        *****/
    /*****      vue lifecycle     *****/
  }
</script>
