import { RouteConfig } from 'vue-router';
import DocumentRouteName from '@/enums/config/router/Document';

const DocumentRouter: Array<RouteConfig> = [
  {
    path: 'document',
    component: () => import(/* webpackChunkName: "appView" */ '@/views/Document.vue'),
    meta: {
      guard: ['redirect'],
    },
    children: [
      {
        path: ':document?',
        name: DocumentRouteName.INDEX,
        meta: {
          title: 'Document',
        },
      },
      {
        path: ':document/create',
        name: DocumentRouteName.CREATE,
        component: () => import(/* webpackChunkName: "appView" */ '@/pages/app/document/Index.vue'),
        meta: {
          parent: DocumentRouteName.INDEX,
          title: 'Create document',
        },
      },
      {
        path: ':document/copy/:uuid',
        name: DocumentRouteName.COPY,
        component: () => import(/* webpackChunkName: "appView" */ '@/pages/app/document/Index.vue'),
        meta: {
          parent: DocumentRouteName.INDEX,
          title: 'Copy document',
        },
        props: true,
      },
      {
        path: ':document/edit/:uuid',
        name: DocumentRouteName.EDIT,
        component: () => import(/* webpackChunkName: "appView" */ '@/pages/app/document/Index.vue'),
        meta: {
          parent: DocumentRouteName.INDEX,
          title: 'Edit document',
        },
        props: true,
      },
      {
        path: ':document/convert/:uuid',
        name: DocumentRouteName.CONVERT,
        component: () => import(/* webpackChunkName: "appView" */ '@/pages/app/document/Index.vue'),
        meta: {
          parent: DocumentRouteName.INDEX,
          title: 'Document conversion',
        },
        props: true,
      },
      {
        path: ':document/view/:uuid',
        name: DocumentRouteName.VIEW,
        component: () => import(/* webpackChunkName: "appView" */ '@/pages/app/document/Index.vue'),
        meta: {
          parent: DocumentRouteName.INDEX,
          title: 'View document',
        },
        props: true,
      },
      {
        path: ':document/with-partner/:uuid',
        name: DocumentRouteName.PARTNER,
        component: () => import(/* webpackChunkName: "appView" */ '@/pages/app/document/Index.vue'),
        meta: {
          parent: DocumentRouteName.INDEX,
          title: 'Create document with partner',
        },
        props: true,
      },
    ],
  },
];

export default DocumentRouter;
