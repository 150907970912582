import { Component, Vue } from 'vue-property-decorator';
import type IDocumentPayment from '@/interfaces/document/IDocumentPayment';
import DocumentRepository from '@/repository/Document';
import CompanyModule from '@/store/modules/Company';

@Component
export default class ListDocumentPaymentsMixin extends Vue {
  public async ListDocumentPaymentsMixin(
    documentUUID: string,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<IDocumentPayment[]> {
    try {
      return (await DocumentRepository.listPayments(companyUUID, documentUUID)).data;
    } catch (_) {
      this.$logger('There was a problem to list document payments...');
      return [];
    }
  }
}
