import { Component, Vue } from 'vue-property-decorator';
import ErrorCode from '@/enums/generics/ErrorCode';
import HttpStatus from '@/enums/generics/HttpStatus';
import type { AxiosError, AxiosResponse } from 'axios';
import type IPendingUser from '@/interfaces/multiuser/IPendingUser';
import UserRepository from '@/repository/User';

@Component
export default class FecthInvitationMixin extends Vue {
  public async FecthInvitationMixin(invitationUUID: string, hash: string): Promise<IPendingUser | ErrorCode> {
    try {
      return (await UserRepository.fetchInvitation(invitationUUID, hash)).data;
    } catch (e) {
      this.$logger('There was a problem to list pending invitations...');
      const networkError: AxiosResponse | null = (e as AxiosError).response ?? null;

      if (networkError && networkError.status === HttpStatus.Forbidden) {
        const errorCode = networkError.data.message as ErrorCode;
        switch (errorCode) {
          case ErrorCode.E_EMAIL_MISMATCH:
          case ErrorCode.E_EMAIL_NOT_VERIFIED:
            return errorCode;
          default:
            return ErrorCode.E_UNKNOW_ERROR;
        }
      }

      return ErrorCode.E_UNKNOW_ERROR;
    }
  }
}
