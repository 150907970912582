<template>
  <v-container class="flex items-center justify-center text-center">
    <v-row class="items-center justify-center grow-0 w-full">
      <v-col cols="12" class="flex flex-col items-center gap-4 pa-4">
        <h2 class="text-center" v-text="trans('login.form.heading', 'Autorizācija')" />
        <YoutubeModal :tutorial="YoutubeTutorial.PROFILE" class="block lg-hidden" />
      </v-col>
      <!-- Login form -->
      <v-col cols="12" sm="8" md="6" lg="4">
        <v-form :disabled="isLoading" @submit.prevent="login">
          <validation-observer ref="form-observer" class="flex flex-col items-center w-full gap-y-4">
            <!-- Email field -->
            <TextField
              v-model="form.email"
              v-trim.all.lowercase
              :type="KeypressInputType.EMAIL"
              :placeholder="trans('workspace.title.email', 'Epasts')"
              :label="trans('workspace.title.email', 'Epasts')"
              :debounce-validation="TimeConfig.DEBOUNCE"
              rules="required|email|max:191"
              autocomplete="username"
              :disabled="isLoading"
              autofocus
              name="email"
              rounded
              class="text-body-2 w-full"
              @keypress.prevent.space
              @blur="fixEmailInput"
            />
            <!-- Password field -->
            <TextField
              v-model="form.password"
              :placeholder="trans('workspace.title.password', 'Parole')"
              :label="trans('workspace.title.password', 'Parole')"
              autocomplete="current-password"
              rules="required|min:8"
              :disabled="isLoading"
              name="password"
              field-type="password"
              caps-lock-warning
              rounded
              class="text-body-2 w-full"
            />
            <!-- Forgot password link-->
            <div class="block text-right w-full">
              <RouterLink
                :to="{ name: AuthRouteName.PASSWORD_FORGOT }"
                class="link text-body-2"
                v-text="trans('login.form.forgot_password', 'Aizmirsi paroli?')"
                @click.prevent
              />
            </div>
            <!-- Login button -->
            <v-col cols="10" class="py-4">
              <SubmitButton block rounded :loading="isLoading" :text="trans('button.login', 'Pieslēgties')" />
            </v-col>
            <!-- Register link -->
            <RouterLink class="link text-body-2 my-4" :to="{ name: AuthRouteName.REGISTER }" @click.prevent>
              <span v-text="`${trans('login.form.signup.new_user', 'Esi jauns lietotājs?')} `" />
              <span class="text-decoration-underline" v-text="trans('button.signup', 'Reģistrēties')" />
            </RouterLink>
          </validation-observer>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
  import Wait from '@/decorators/Wait';
  import cloneDeep from 'lodash-es/cloneDeep';
  import { Component, Mixins, Ref, Watch } from 'vue-property-decorator';
  import type { ProviderInstance } from 'vee-validate/dist/types/types';
  import useLocalStorage from '@/services/LocalStorage';
  import TimeConfig from '@/enums/config/TimeConfig';
  import YoutubeTutorial from '@/enums/config/YoutubeTutorial';
  import AuthRouteName from '@/enums/config/router/Auth';
  import KeypressInputType from '@/enums/types/KeypressInputType';
  import type ILoginForm from '@/interfaces/forms/auth/ILoginForm';
  import DEFAULT_LOGIN_FORM from '@/constants/mocks/forms/Login';
  import ErrorModule from '@/store/modules/Error';
  import NotificationModule from '@/store/modules/Notification';
  import LoadingModule from '@/store/modules/Loading';
  import AuthMixin from '@/mixins/Auth';
  import ProfileMixin from '@/mixins/Profile';
  import TextField from '@/components/global/inputs/TextField.vue';
  import SubmitButton from '@/components/global/buttons/Submit.vue';

  @Component({
    components: {
      TextField,
      SubmitButton,
      YoutubeModal: () => import('@/components/global/modals/Youtube.vue'),
    },
  })
  export default class LoginPage extends Mixins(AuthMixin, ProfileMixin) {
    @Ref('form-observer') formObserver!: ProviderInstance;

    public form: ILoginForm = cloneDeep(DEFAULT_LOGIN_FORM);

    /*****         computed       *****/

    public get error(): unknown {
      return ErrorModule.getError;
    }

    public get isLoading(): boolean {
      return LoadingModule.isLoading;
    }

    public get AuthRouteName(): typeof AuthRouteName {
      return AuthRouteName;
    }

    public get YoutubeTutorial(): typeof YoutubeTutorial {
      return YoutubeTutorial;
    }

    public get TimeConfig(): typeof TimeConfig {
      return TimeConfig;
    }

    public get KeypressInputType(): typeof KeypressInputType {
      return KeypressInputType;
    }

    /*****         watchers       *****/

    @Watch('error')
    private watchForError(): void {
      if (this.error) {
        this.formObserver.setErrors(this.error as string[]);
      }
    }

    /*****         methods        *****/

    public async login(): Promise<void> {
      this.fixEmailInput();
      this.formObserver.reset();
      if (await this.formObserver.validate()) {
        return await this.authLogin(this.form);
      }
      return this.scrollToFirstError(this.formObserver);
    }

    /*****         helpers        *****/

    public fixEmailInput(): void {
      this.form.email = this.removeWhiteSpaces(this.form.email ?? '').toLowerCase();
    }

    @Wait(TimeConfig.WAIT_LONGER)
    private async checkForInvitation(): Promise<void> {
      const invitation = useLocalStorage().getInvitationHash();

      if (invitation) {
        if (invitation.is_new) {
          await NotificationModule.SET_BOTTOM_SHEET({
            message: this.trans('public.invitation.title.guest', 'Jūs esat uzaicināts sadarboties Numbero platformā'),
            authMessage: this.trans(
              'public.invitation.auth_to_continue',
              'Pieslēdzieties vai reģistrējiet jaunu kontu, lai apskatītu ielūgumu',
            ),
            show: true,
          });

          invitation.is_new = false;
          useLocalStorage().setInvitationHash(invitation);
        }
      }
    }

    /*****      vue lifecycle     *****/

    async beforeMount() {
      const email = useLocalStorage().getInvitationHash()?.email ?? '';

      this.form = cloneDeep({ ...DEFAULT_LOGIN_FORM, email });
      await this.checkForInvitation();
    }
  }
</script>
