import YoutubeTutorial from '@/enums/config/YoutubeTutorial';
import { IYoutubeTutorials } from '@/interfaces/config/IYoutube';

const TUTORIALS_RU: IYoutubeTutorials = {
  [YoutubeTutorial.PROFILE]: {
    videoID: 's0LmuZDzPms',
    isActive: true,
  },
  [YoutubeTutorial.COMPANY]: {
    videoID: 'ibX1CtpAOx8',
    isActive: true,
  },
  [YoutubeTutorial.DOCUMENT]: {
    videoID: 'PLrZaYCHpfo',
    isActive: true,
  },
  [YoutubeTutorial.RECURRING]: {
    videoID: 'dRPE450PweI',
    isActive: true,
  },
  [YoutubeTutorial.REPORTS]: {
    videoID: 'RjOKMk-bGSk',
    isActive: true,
  },
  [YoutubeTutorial.WELCOME]: {
    videoID: 'VlhvQFWDqVg',
    isActive: true,
  },
};

export default TUTORIALS_RU;
