import { RouteConfig } from 'vue-router';
import AuthLayout from '@/layouts/Auth.vue';

import RegisterRouter from '@/router/auth/Register';
import LoginRouter from '@/router/auth/Login';
import PasswordForgotRouter from '@/router/auth/PasswordForgot';
import PasswordResetRouter from '@/router/auth/PasswordReset';

const AuthIndexRouter: Array<RouteConfig> = [
  {
    path: '/',
    component: AuthLayout,
    meta: {
      guard: ['maintenance', 'guest'],
    },
    children: [...RegisterRouter, ...LoginRouter, ...PasswordForgotRouter, ...PasswordResetRouter],
  },
];

export default AuthIndexRouter;
