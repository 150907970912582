import { render, staticRenderFns } from "./AddCompany.vue?vue&type=template&id=05b20ff6"
import script from "./AddCompany.vue?vue&type=script&lang=ts"
export * from "./AddCompany.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports