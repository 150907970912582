import { Component, Vue } from 'vue-property-decorator';
import PartnerRepository from '@/repository/Partner';
import CompanyModule from '@/store/modules/Company';
import type IPartner from '@/interfaces/partner/IPartner';

@Component
export default class FetchPartnerMixin extends Vue {
  public async FetchPartnerMixin(
    partnerUUID: string,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<IPartner> {
    return (await PartnerRepository.fetch(companyUUID, partnerUUID)).data;
  }
}
