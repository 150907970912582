import { Component, Vue } from 'vue-property-decorator';
import DocumentPermission from '@/enums/config/permission/Document';
import Locale from '@/enums/config/Locale';
import UserRole from '@/enums/config/UserRole';
import type IPendingUser from '@/interfaces/multiuser/IPendingUser';
import TranslationModule from '@/store/modules/Translation';

@Component
export default class TranslatePermissionMixin extends Vue {
  public TranslatePermissionMixin(
    role: UserRole,
    permissions: DocumentPermission[],
    locale: Locale = TranslationModule.getLocale,
  ): string {
    switch (role) {
      case UserRole.OWNER:
        return this.trans('generic.role.owner', 'Īpašnieks', locale);
      case UserRole.ADMIN:
        return this.trans('generic.role.admin', 'Administrators', locale);
      case UserRole.USER:
        if (role === UserRole.USER && permissions.length > 0) {
          const userRolePermission = this.userRolePermission(permissions, locale);
          return `${this.trans('generic.role.user', 'Lietotājs', locale)}: ${userRolePermission}`;
        } else {
          return this.trans('generic.role.user', 'Lietotājs', locale);
        }
      default:
        return '-';
    }
  }

  public DescribeGivenPermissionsMixin(user: IPendingUser, locale: Locale = TranslationModule.getLocale): string {
    switch (this.parsePermissions(user.permissions)) {
      case this.parsePermissions([DocumentPermission.MANAGE_OWN]):
        return this.trans(
          'public.invitation.description.manage_own',
          'Jums ir piešķirta atļauja pārvaldīt paša veidotus uzņēmuma dokumentus. Varēsiet pārvaldīt un apskatīt tikai pašu veidotus dokumentus, nevarēsiet piekļūt citiem uzņēmuma dokumentiem.',
          locale,
        );
      case this.parsePermissions([DocumentPermission.VIEW_ALL, DocumentPermission.MANAGE_OWN]):
        return this.trans(
          'public.invitation.description.contributor',
          'Jums ir piešķirta atļauja pārvaldīt pašu izveidotos un skatīt citus uzņēmuma dokumentus. Skatot citus uzņēmuma dokumentus, varat skatīt, nosūtīt un lejupielādēt, taču jūs nevarat tos rediģēt vai dzēst.',
          locale,
        );
      case this.parsePermissions([DocumentPermission.VIEW_ALL]):
        return this.trans(
          'public.invitation.description.read_only',
          'Jums ir piešķirta tikai skatīšanas piekļuve, lai pārvaldītu uzņēmuma dokumentus. Varat skatīt visus, nosūtīt vai lejupielādēt, taču nevarat izveidot vai rediģēt esošos dokumentus.',
          locale,
        );
      case this.parsePermissions([DocumentPermission.MANAGE_ALL]):
        return this.trans(
          'public.invitation.description.full_access',
          'Jums ir piešķirta pilna piekļuve uzņēmuma dokumentiem. Jūs varēsiet pārvaldīt jebkurus uzņēmuma dokumentus.',
          locale,
        );
      default:
        return this.trans(
          'public.invitation.description.none',
          'Jums ir dota nenoteikta piekļuve uzņēmuma dokumentiem un tā resursiem',
          locale,
        );
    }
  }

  private userRolePermission(permissions: DocumentPermission[], locale: Locale = TranslationModule.getLocale): string {
    switch (this.parsePermissions(permissions)) {
      case this.parsePermissions([DocumentPermission.MANAGE_OWN]):
        return this.trans('workspace.title.permission.manage_own', 'Pārvaldīt savus', locale);
      case this.parsePermissions([DocumentPermission.VIEW_ALL, DocumentPermission.MANAGE_OWN]):
        return this.trans('workspace.title.permission.contributor', 'Pārvaldīt savus & skatīt citus', locale);
      case this.parsePermissions([DocumentPermission.VIEW_ALL]):
        return this.trans('workspace.title.permission.read_only', 'Tikai skatīt', locale);
      case this.parsePermissions([DocumentPermission.MANAGE_ALL]):
        return this.trans('workspace.title.permission.full_access', 'Pilna piekļuve', locale);
      default:
        return '-';
    }
  }

  private parsePermissions(permissions: DocumentPermission[]): string {
    return JSON.stringify(permissions.sort());
  }
}
