<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.60288 1.79876C7.42705 0.850931 8.64332 0.25 10 0.25C11.3566 0.25 12.5728 0.850872 13.397 1.79861C14.6501 1.71106 15.9352 2.14616 16.8946 3.10557C17.854 4.06498 18.2891 5.35009 18.2016 6.60319C19.1492 7.42735 19.75 8.64348 19.75 10C19.75 11.3568 19.149 12.5731 18.2011 13.3973C18.2884 14.6502 17.8533 15.935 16.8941 16.8943C15.9348 17.8535 14.65 18.2886 13.3971 18.2013C12.5729 19.1491 11.3567 19.75 10 19.75C8.6434 19.75 7.4272 19.1491 6.60304 18.2014C5.34992 18.289 4.0648 17.8539 3.10537 16.8945C2.14596 15.935 1.71086 14.6499 1.79841 13.3968C0.850785 12.5726 0.25 11.3565 0.25 10C0.25 8.64342 0.850846 7.42723 1.79855 6.60306C1.7111 5.35005 2.14621 4.06507 3.10554 3.10574C4.06488 2.1464 5.34987 1.71129 6.60288 1.79876ZM13.6103 8.18593C13.8511 7.84887 13.773 7.38046 13.4359 7.1397C13.0989 6.89894 12.6305 6.97701 12.3897 7.31407L9.1543 11.8436L7.53033 10.2197C7.23744 9.92678 6.76256 9.92678 6.46967 10.2197C6.17678 10.5126 6.17678 10.9874 6.46967 11.2803L8.71967 13.5303C8.87556 13.6862 9.09215 13.7656 9.31186 13.7474C9.53157 13.7293 9.73216 13.6153 9.8603 13.4359L13.6103 8.18593Z"
      fill="currentColor"
    />
  </svg>
</template>

<script lang="ts">
  import { Component, Vue } from 'vue-property-decorator';

  @Component
  export default class CheckBadgeIcon extends Vue {
    /*****         computed       *****/
    /*****         watchers       *****/
    /*****         methods        *****/
    /*****         helpers        *****/
    /*****      vue lifecycle     *****/
  }
</script>
