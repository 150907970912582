import { Component, Vue } from 'vue-property-decorator';
import type INotifcation from '@/interfaces/INotification';
import HelperRepository from '@/repository/Helper';

@Component
export default class MarkNotificationsAsReadMixin extends Vue {
  public async MarkNotificationsAsReadMixin(email: string, verified: boolean): Promise<INotifcation[]> {
    try {
      return (await HelperRepository.updateNotifications(email, verified)).data;
    } catch (_) {
      this.$logger('There was a problem updating notifications status...');
      return [];
    }
  }
}
