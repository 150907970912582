import { Component, Vue } from 'vue-property-decorator';
import type IDocumentList from '@/interfaces/document/IDocumentList';
import DocumentRepository from '@/repository/Document';
import CompanyModule from '@/store/modules/Company';

@Component
export default class BulkDeleteDocumentsMixin extends Vue {
  public async BulkDeleteDocumentsMixin(
    documents: IDocumentList[],
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<void> {
    const documentUUIDs: string[] = documents.map((document) => document.uuid);
    await DocumentRepository.bulkDelete(companyUUID, documentUUIDs);
  }
}
