import { Component, Vue } from 'vue-property-decorator';
import DEFAULT_VAT_STATUS from '@/constants/mocks/VatStatus';
import type ICheckVatStatusForm from '@/interfaces/forms/helper/ICheckVatStatusForm';
import type IVatStatus from '@/interfaces/IVatStatus';
import HelperRepository from '@/repository/Helper';

@Component
export default class CheckVatStatusMixin extends Vue {
  public async CheckVatStatusMixin(payload: ICheckVatStatusForm): Promise<IVatStatus> {
    try {
      return (await HelperRepository.checkVatStatus(payload)).data;
    } catch (_) {
      return DEFAULT_VAT_STATUS;
    }
  }
}
