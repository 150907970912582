import { Component, Vue } from 'vue-property-decorator';
import { AxiosResponse } from 'axios';
import type IResetPasswordForm from '@/interfaces/forms/auth/IPasswordResetForm';
import AuthRepository from '@/repository/Auth';

@Component
export default class PasswordResetMixin extends Vue {
  public async PasswordResetMixin(payload: IResetPasswordForm): Promise<boolean> {
    const { data }: AxiosResponse = await AuthRepository.resetPassword(payload);
    return data.success;
  }
}
