7
<template>
  <v-app-bar app :color="colorPalete.bg" :height="`${navHeight}px`" :min-width="360" flat>
    <v-container class="flex items-center justify-between w-full h-full gap-8">
      <v-slide-y-transition leave-absolute hide-on-leave group>
        <SimpleButton
          v-if="showGoBack"
          key="back"
          rounded
          outlined
          color="white"
          font-size="font-weight-medium text-body-1 px-6"
          @click="goBack"
        >
          <div class="flex items-center justify-center gap-2">
            <v-icon small class="rotate-90">$chevronDown</v-icon>
            <span style="margin-top: 1px" v-text="trans('button.back', 'Atpakaļ')" />
          </div>
        </SimpleButton>
        <RouterLink v-else key="home" :to="goToHome()" class="block" @click.native="$vuetify.goTo('#app')">
          <NumberoIcon class="block" :class="colorPalete.text" />
        </RouterLink>
      </v-slide-y-transition>
      <!-- Application main menu -->
      <div class="flex items-center justify-start gap-2">
        <v-menu
          v-for="n in userDesktopNav"
          :key="n.id"
          content-class="btn--menu btn--items shadow-lg"
          transition="slide-y-transition"
          nudge-bottom="3"
          offset-y
          bottom
          eager
          right
        >
          <template #activator="{ on, attrs, value: show }">
            <v-btn
              :to="n.route"
              v-bind="attrs"
              v-on="on"
              :color="menuColor(n.active)"
              depressed
              link
              class="btn--menu"
              :class="{ 'btn--hover': show }"
            >
              <div class="flex items-center justify-center gap-2" :class="colorPalete.text">
                <span class="leading-none text-body-1" v-text="n.title" />
              </div>
            </v-btn>
          </template>
        </v-menu>
      </div>
      <!-- Application submenu -->
      <div
        v-if="showSubmenu && hasCompany"
        class="fixed left-0 z-50 w-full"
        :class="colorPalete.bg"
        :style="`top: ${navHeight - 4}px`"
      >
        <v-container class="flex items-center justify-start gap-4 bg-inherit w-full" style="max-height: 46px">
          <div v-for="n in currentSubRoutes" :key="n.id" class="bg-inherit relative">
            <v-btn
              :to="n.route"
              color="transparent"
              :ripple="false"
              depressed
              link
              class="z-20"
              :class="n.active ? 'btn--none' : 'btn--menu'"
            >
              <div class="flex items-center justify-center gap-2" :class="colorPalete.text">
                <span class="leading-none text-body-1" v-text="n.title" />
              </div>
            </v-btn>
            <v-slide-x-transition hide-on-leave leave-absolute>
              <div v-if="n.active" class="absolute bottom-0 left-0 right-0 w-full bg-inherit mb-n3 rounded-pill">
                <div class="pa-4 w-full" style="height: 14px">
                  <div class="w-full primary" style="height: 2px" />
                </div>
              </div>
            </v-slide-x-transition>
          </div>
        </v-container>
      </div>
      <div class="flex flex-none items-center gap-2">
        <div>
          <Notifications :color-palete="colorPalete" :use-theme="useTheme" :nav-height="navHeight" />
        </div>
        <div v-if="hasCompany">
          <UserNav :color-palete="colorPalete" :use-theme="useTheme" />
        </div>
      </div>
    </v-container>
  </v-app-bar>
</template>

<script lang="ts">
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import useUserNav from '@/services/UserNav';
  import DocumentRoute from '@/enums/config/document/Route';
  import DocumentType from '@/enums/config/document/Type';
  import DocumentRouteName from '@/enums/config/router/Document';
  import RecurringRouteName from '@/enums/config/router/Recurring';
  import DashboardRouteName from '@/enums/config/router/Dashboard';
  import ProfileRouteName from '@/enums/config/router/Profile';
  import type { RawLocation } from 'vue-router';
  import type IUserNav from '@/interfaces/config/IUserNav';
  import type IColorSchema from '@/interfaces/config/IColorSchema';
  import CompanyModule from '@/store/modules/Company';
  import LoadingModule from '@/store/modules/Loading';
  import PageModule from '@/store/modules/Page';
  import NumberoIcon from '@/components/icons/brands/Numbero.vue';
  import Notifications from '@/components/global/nav/app/Notifications.vue';
  import UserNav from '@/components/global/nav/app/UserNav.vue';

  @Component({
    components: {
      NumberoIcon,
      Notifications,
      UserNav,
      SimpleButton: () => import('@/components/global/buttons/Simple.vue'),
    },
  })
  export default class AppDesktopNav extends Vue {
    @Prop() colorPalete!: IColorSchema;
    @Prop() navHeight!: number;
    @Prop() useTheme!: boolean;

    /*****         computed       *****/

    public get hasCompany(): boolean {
      return CompanyModule.hasCompany;
    }

    private get isDocumentRoute(): boolean {
      return this.$route.meta!.parent === DocumentRouteName.INDEX;
    }

    private get isRecurringRoute(): boolean {
      return this.$route.meta!.parent === RecurringRouteName.INDEX && this.$route.name !== RecurringRouteName.LIST;
    }

    public get showGoBack(): boolean {
      return this.isDocumentRoute || this.isRecurringRoute;
    }

    public get isLoading(): boolean {
      return LoadingModule.isLoading;
    }

    public get userDesktopNav(): IUserNav[] {
      return useUserNav().userDesktopNav();
    }

    public get currentSubRoutes(): IUserNav[] {
      const currentRoute = this.userDesktopNav.find((nav: IUserNav) => nav.route.name === this.$route.meta!.parent);

      if (currentRoute?.childrens && currentRoute.childrens.length > 0) {
        return currentRoute.childrens || [];
      }

      return [];
    }

    public get showSubmenu(): boolean {
      const routeName = this.$route.name as DashboardRouteName | RecurringRouteName;
      const isListRoute = [DashboardRouteName.LIST, RecurringRouteName.LIST].includes(routeName);

      return isListRoute && this.currentSubRoutes.length > 0;
    }

    public get DocumentRoute(): typeof DocumentRoute {
      return DocumentRoute;
    }

    public get DocumentType(): typeof DocumentType {
      return DocumentType;
    }

    public get DashboardRouteName(): typeof DashboardRouteName {
      return DashboardRouteName;
    }

    /*****         watchers       *****/
    /*****         methods        *****/

    public async goBack(): Promise<void> {
      if (this.isLoading) {
        return;
      }

      // Remember url params if it is not created form partner route
      const remember = this.$route.name != DocumentRouteName.PARTNER;
      remember ? await PageModule.REMEMBER_URL_PARAMS() : await PageModule.FORGET_URL_PARAMS();

      const document = await PageModule.GET_ROUTE_PARAM_DOCUMENT();
      if (this.isDocumentRoute) {
        this.$router.push({ name: DashboardRouteName.LIST, params: { document } });
      } else if (this.isRecurringRoute) {
        this.$router.push({ name: RecurringRouteName.LIST, params: { document } });
      }
    }

    /*****         helpers        *****/

    public menuColor(isActive: boolean): string {
      return isActive ? (this.useTheme ? '#000' : '#FFF') : this.colorPalete.bg;
    }

    public goToHome(): RawLocation {
      return this.hasCompany
        ? { name: DashboardRouteName.LIST, params: { document: DocumentRoute.ALL } }
        : { name: ProfileRouteName.DATA };
    }

    /*****      vue lifecycle     *****/
  }
</script>
