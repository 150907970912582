enum DateFormat {
  DOCUMENT = 'DD.MM.YYYY',
  DATABASE = 'YYYY-MM-DD',
  LARAVEL = 'YYYY/MM/DD',

  DATETIME = 'YYYY-MM-DDTHH:mm:ss',

  // Formats for guessing date format
  GUESS_DOCUMENT_1 = 'DD.MM.YYYY',
  GUESS_DOCUMENT_2 = 'DD.MM.YY',
  GUESS_DOCUMENT_3 = 'D.MM.YYYY',
  GUESS_DOCUMENT_4 = 'D.MM.YY',
  GUESS_DOCUMENT_5 = 'DD.M.YYYY',
  GUESS_DOCUMENT_6 = 'DD.M.YY',
  GUESS_DOCUMENT_7 = 'D.M.YYYY',
  GUESS_DOCUMENT_8 = 'D.M.YY',

  GUESS_DATABASE_1 = 'YYYY-MM-DD',
  GUESS_DATABASE_2 = 'YYYY-MM-D',
  GUESS_DATABASE_3 = 'YYYY-M-DD',
  GUESS_DATABASE_4 = 'YYYY-M-D',
  GUESS_DATABASE_5 = 'YY-MM-DD',
  GUESS_DATABASE_6 = 'YY-MM-D',
  GUESS_DATABASE_7 = 'YY-M-DD',
  GUESS_DATABASE_8 = 'YY-M-D',

  GUESS_LARAVEL_1 = 'YYYY/MM/DD',
  GUESS_LARAVEL_2 = 'YY/MM/DD',
  GUESS_LARAVEL_3 = 'YYYY/MM/D',
  GUESS_LARAVEL_4 = 'YY/MM/D',
  GUESS_LARAVEL_5 = 'YYYY/M/DD',
  GUESS_LARAVEL_6 = 'YY/M/DD',
  GUESS_LARAVEL_7 = 'YYYY/M/D',
  GUESS_LARAVEL_8 = 'YY/M/D',
}

export default DateFormat;
