enum SortField {
  TOTAL_WITH_VAT = 'total_with_vat',
  RECORD_NUMBER = 'record_number',
  RECIPIENT = 'recipient',
  STATUS = 'status',
  DATE = 'date',
  NAME = 'name',
  EMAIL = 'email',
  PHONE = 'phone_number',
  SKU = 'sku',
  PRICE = 'price',
  VAT_PERCENT = 'vat_percent',
  OWNER = 'owner',
}

export default SortField;
