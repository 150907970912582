import app from '@/main';
import store from '@/store';
import useCopyObject from '@/services/CopyObject';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import DEFAULT_DOCUMENT_SETTINGS from '@/constants/mocks/company/DocumentSettings';
import DEFAULT_EMAIL_SETTINGS from '@/constants/mocks/company/EmailSettings';
import DocumentType from '@/enums/config/document/Type';
import CurrencyISO from '@/enums/config/iso/CurrencyISO';
import CountryISO from '@/enums/config/iso/CountryISO';
import CompanyType from '@/enums/generics/CompanyType';
import RecurringDocumentType from '@/enums/config/document/RecurringType';
import type ICompany from '@/interfaces/company/ICompany';
import type { IDocumentPreferences, IDocumentPreference } from '@/interfaces/company/IDocumentSettings';
import IEmailSettings, { IEmailPreference } from '@/interfaces/company/IEmailSettings';
import UserModule from '@/store/modules/User';
import UtilModule from '@/store/modules/Util';
import CompanyRepository from '@/repository/Company';

@Module({
  store,
  name: 'Company',
  namespaced: true,
  dynamic: true,
})
class Company extends VuexModule {
  private company: ICompany | null = null;

  public get getCompany(): ICompany | null {
    return this.company;
  }

  public get hasCompany(): boolean {
    return !!this.company;
  }

  public get getCompanyUUID(): string {
    return this.company?.uuid ?? '';
  }

  public get getCompanyName(): string {
    return this.company?.name ?? '';
  }

  public get getCompanyType(): CompanyType {
    return !this.company || this.company!.is_legal ? CompanyType.LEGAL : CompanyType.PRIVATE;
  }

  public get getCompanyCurrencyCode(): CurrencyISO {
    return this.company?.currency_code ?? CurrencyISO.EUR;
  }

  public get getCompanyCurrencySymbol(): CurrencyISO | string {
    const currencyCode = this.getCompanyCurrencyCode;
    return UtilModule.getCurrencies.find((c) => c.code === currencyCode)?.symbol ?? currencyCode;
  }

  public get getCompanyCountry(): CountryISO {
    return this.company?.country ?? CountryISO.LV;
  }

  public get isTaxPayer(): boolean {
    return this.company?.vat_tax_payer ?? false;
  }

  public get getSharedLinkRetentionPolicy(): number {
    return this.company?.share_link_retention_days ?? 0;
  }

  public get isActiveSubscription(): boolean {
    return this.company?.has_subscription ?? false;
  }

  public get getDefaultTaxRate(): number {
    const defaultTaxRate = this.company?.use_taxes ? this.company?.default_tax_rate ?? 21 : 21;
    return this.isTaxPayer ? defaultTaxRate : 0;
  }

  public get getDocumentPreferences(): IDocumentPreferences {
    return useCopyObject().copy(this.company?.document_preferences, DEFAULT_DOCUMENT_SETTINGS.document_preferences);
  }

  public get getEmailSettings(): IEmailSettings {
    return useCopyObject().copy(this.company?.email_preferences, DEFAULT_EMAIL_SETTINGS);
  }

  /*******   Set action   *******/

  @Action({ commit: 'UPDATE_COMPANY' })
  public async SET_COMPANY(companyUUID: string = UserModule.getCompanyUUID): Promise<ICompany | null> {
    let company: ICompany | null = null;
    try {
      company = companyUUID ? (await CompanyRepository.fetch(companyUUID)).data : null;
    } catch (e) {
      app.$logger('unable to fetch company...');
    }
    return company;
  }

  @Action
  public async GET_EMAIL_PREFERENCES(type: DocumentType | RecurringDocumentType): Promise<IEmailPreference> {
    const parsedType = [DocumentType.WAYBILL, DocumentType.CREDIT_NOTE].includes(type as DocumentType)
      ? DocumentType.INVOICE
      : type;
    return this.getEmailSettings[parsedType as keyof IEmailSettings];
  }

  @Action
  public async GET_DOCUMENT_PREFERENCES(type: DocumentType | RecurringDocumentType): Promise<IDocumentPreference> {
    return this.getDocumentPreferences[type];
  }

  /*******   Unset action   *******/

  @Action({ commit: 'UPDATE_COMPANY' })
  public async CLEAR_COMPANY(): Promise<null> {
    return null;
  }

  /*******   Update mutation   *******/

  @Mutation
  public async UPDATE_COMPANY(company: ICompany): Promise<ICompany> {
    return (this.company = company);
  }
}

const CompanyModule = getModule(Company);

export default CompanyModule;
