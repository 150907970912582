import app from '@/main';
import { ObjectDirective } from 'vue';
import { DirectiveBinding } from 'vue/types/options';

const NumberDirective: ObjectDirective = {
  inserted(el: HTMLElement, binding: DirectiveBinding): void {
    const inputElement = el.querySelector('input') || el.querySelector('textarea');

    if (inputElement) {
      inputElement.addEventListener('blur', () => {
        let value = inputElement.value;

        if (typeof value === 'string') {
          // Check if modifier is set to positiv
          // Which means that the number can't be negative
          const isPositive = binding.modifiers.positive;

          // If modifier is set to integer or float convert the string to integer or float
          if (binding.modifiers.integer) {
            const match = value.match(/-?\d+/);
            if (match) {
              const num = parseInt(match[0]);
              value = (isPositive ? Math.abs(num) : num).toString();
            } else {
              value = '';
            }
          } else if (binding.modifiers.float) {
            const match = value.replace(',', '.').match(/-?\d+(\.\d*)?\S*/);
            if (match) {
              const parsedNum = parseFloat(match[0]);
              const num = isPositive ? Math.abs(parsedNum) : parsedNum;
              value = binding.modifiers.fixed
                ? app.setDecimals(app.floorDown(num, binding.value || 2)).toString()
                : num.toString();
            } else {
              value = '';
            }
          }

          inputElement.value = value;
          const event = new Event('input', { bubbles: true, cancelable: true });
          inputElement.dispatchEvent(event);
        }
      });
    }
  },
};

export default NumberDirective;
