<template>
  <div class="flex items-center relative w-full h-full gap-2">
    <input v-if="readonly" type="hidden" v-model="formValue" />
    <slot name="prepend-outer" />
    <IndexAutocomplete
      ref="input-field"
      v-model="userInput"
      :field-type="fieldType"
      :input-type="inputType"
      :tippy-dropdown-id="tippyDropdownId"
      :name="name"
      :items="items"
      :map-item="mapItem"
      :group-by="groupBy"
      :group-by-order-by="groupByOrderBy"
      :group-by-custom-order="groupByCustomOrder"
      :autohide-list="autohideList"
      :autocomplete="autocomplete"
      :placeholder="placeholder"
      :filterable="filterable"
      :filter-description="filterDescription"
      :sortable="sortable"
      :clearable="clearable"
      :autofocus="autofocus && !autofocusSearch"
      :readonly="readonly"
      :loading="loading"
      :disabled="disabled"
      :rounded="rounded"
      :resize="resize"
      :show-error-border="showErrorBorder"
      :hide-selected="hideSelected"
      :hide-list="hideList"
      :use-search="useSearch"
      :search-query="searchQuery"
      :dark="dark"
      :color="color"
      :input-class="inputClass"
      :text-color="textColor"
      :font-size="fontSize"
      @input:debounce="inputDebounce"
      @query="query"
      @focus="focus"
      @blur="blur"
      @open="open"
      @close="close"
      @set="setItem"
      @clear="clear"
      @keydown="keydown"
      @arrow="scrollByArrow"
      @resize="resizeCompleted"
      @redifine="redefineUserInput"
    >
      <template #prepend>
        <slot name="prepend" />
      </template>
      <template #append="props">
        <slot name="append" :disabled="props.disabled" :loading="props.loading" :show="props.show" :hide="props.hide">
          <v-scale-transition hide-on-leave leave-absolute group>
            <v-icon v-if="props.loading" key="loading" color="primary" size="24">$spinner</v-icon>
            <v-btn
              v-else-if="!props.hide"
              key="chevron"
              icon
              small
              tabindex="-1"
              :color="props.disabled ? 'silver' : 'primary'"
              :class="{ 'cursor-not-allowed': props.disabled }"
              class="z-10"
            >
              <v-icon x-small class="transition-all" :class="props.show ? 'rotate-180' : 'rotate-0'">
                $chevronDown
              </v-icon>
            </v-btn>
          </v-scale-transition>
        </slot>
      </template>
      <template #list="props">
        <ItemListAutocomplete
          ref="item-list"
          :tippy-dropdown-id="tippyDropdownId"
          :min-width="minWidth"
          :show="props.show"
          :loading="props.loading"
          :typing="props.typing"
          :overflow="overflow"
          :rounded="rounded"
          :postfix="postfix"
          :items="props.items"
          :map-item="mapItem"
          :use-search="props.useSearch"
          :autofocus="props.autofocusSearch"
          :list-height="listHeight"
          :list-item-height="listItemHeight"
          :font-size="listFontSize ?? fontSize"
          :arrow="props.arrow"
          :set-item="props.setItem"
          :set-arrow="props.setArrow"
          :group-by="props.groupBy"
          :scroll-by-arrow="scrollByArrow"
          :highlight-item="highlightItem"
          @query="searchQuery = $event"
        >
          <template #list-item="{ item, text }">
            <slot name="list-item" :item="item" :text="text" />
          </template>
          <template #list-item-search>
            <slot name="list-item-search" />
          </template>
        </ItemListAutocomplete>
      </template>
    </IndexAutocomplete>
    <slot name="append-outer" />
  </div>
</template>

<script lang="ts">
  import Wait from '@/decorators/Wait';
  import { v4 as uuidv4 } from 'uuid';
  import { Component, Emit, Ref, Prop, Vue, Watch, VModel } from 'vue-property-decorator';
  import KeypressInputType from '@/enums/types/KeypressInputType';
  import type ISelectMap from '@/interfaces/config/ISelectMap';
  import type { TippyComponent } from 'vue-tippy';
  import IndexAutocomplete from '@/components/global/inputs/autocomplete/Index.vue';
  import ItemListAutocomplete from '@/components/global/inputs/autocomplete/ItemList.vue';

  @Component({
    components: {
      IndexAutocomplete,
      ItemListAutocomplete,
    },
  })
  export default class BaseAutocomplete extends Vue {
    @VModel({ default: null }) formValue!: string[] | string | number | null;

    @Prop({ default: 'input' }) fieldType!: 'textarea' | 'input';
    @Prop({ default: KeypressInputType.TEXT }) inputType!: KeypressInputType;
    @Prop({ default: '' }) ignoreInput!: string;
    @Prop({ default: '' }) postfix!: string;

    @Prop({ default: 'off' }) autocomplete!: string;
    @Prop({ default: '-' }) placeholder!: string;
    @Prop({ default: '' }) name!: string;

    @Prop({ default: 20 }) autohideList?: number;

    @Prop({ type: Boolean }) showErrorBorder?: boolean;

    @Prop({ type: Boolean }) clearable?: boolean;
    @Prop({ type: Boolean }) filterable?: boolean;
    @Prop({ type: Boolean }) filterDescription?: boolean;
    @Prop({ type: Boolean }) sortable?: boolean;
    @Prop({ type: Boolean }) autofocus?: boolean;
    @Prop({ type: Boolean }) autofocusSearch?: boolean;
    @Prop({ type: Boolean }) readonly?: boolean;
    @Prop({ type: Boolean }) loading?: boolean;
    @Prop({ type: Boolean }) disabled?: boolean;

    @Prop({ type: Boolean }) overflow?: boolean;
    @Prop({ type: Boolean }) rounded?: boolean;
    @Prop({ type: Boolean }) resize?: boolean;
    @Prop({ type: Boolean }) dark?: boolean;

    @Prop({ type: Boolean }) hideSelected?: boolean;
    @Prop({ type: Boolean }) hideList?: boolean;
    @Prop({ type: Boolean }) useSearch?: boolean;

    @Prop() prepareInput?: (value: typeof this.formValue) => typeof value;

    @Prop() color?: string;
    @Prop() textColor?: string;
    @Prop() inputClass?: string;

    @Prop({ default: 350 }) listHeight!: number;
    @Prop({ default: 44 }) listItemHeight!: number;
    @Prop({ default: 'text-body-2' }) fontSize!: string;
    @Prop({ default: 'text-body-2' }) listFontSize?: string;

    @Prop() items!: any[] | undefined;
    @Prop() mapItem!: ISelectMap;
    @Prop() groupBy!: string;
    @Prop() groupByOrderBy?: string;
    @Prop() groupByCustomOrder?: any[];

    @Ref('input-field') inputField!: IndexAutocomplete;
    @Ref('item-list') itemList!: ItemListAutocomplete;

    public redefineInput: boolean = true;
    public undefinedInput: boolean = true;
    public userInput: string = '';

    public tippyDropdownId: string = uuidv4();
    public minWidth: number = 350;

    public searchQuery: string = '';

    /*****         computed       *****/
    /*****         watchers       *****/

    @Watch('formValue')
    public redefineUserInput(): void {
      if (this.redefineInput && !Array.isArray(this.formValue)) {
        const userInput = (this.userInput ?? '').toString();
        const addPostfix = !!this.postfix && userInput.endsWith(this.postfix);
        this.defineUserInput();

        if (addPostfix) {
          this.addPostfix();
        }
      }

      this.redefineInput = true;
    }

    /*****         methods        *****/

    @Emit('focus')
    public focus(): void {
      if (this.hideList && !this.readonly) {
        this.removePostfix();
      }
      if (this.prepareInput) {
        this.formValue = this.prepareInput(this.formValue);
      }
    }

    @Wait()
    @Emit('blur')
    public blur(): void {
      if (this.hideList && !this.readonly) {
        this.addPostfix();
      }
      this.addPostfix();
    }

    @Emit('open')
    public open(): void {
      if (this.undefinedInput) {
        this.setMinWidth();
        this.undefinedInput = false;
      }
      this.removePostfix();
    }

    @Wait()
    @Emit('close')
    public close(): void {
      this.addPostfix();
    }

    @Emit('input:debounce')
    public inputDebounce(userInput: string): string {
      this.userInput = userInput;
      if (this.formValue !== this.userInput) {
        this.redefineInput = true;
      }

      return (this.formValue = this.userInput);
    }

    @Emit('query')
    public query(userInput: string): string {
      return userInput;
    }

    // Set input value by user selected item
    @Emit('set')
    public setItem(item: any): any {
      if (this.formValue !== item[this.mapItem.value].toString()) {
        this.redefineInput = false;
      }

      // If item is boolean, set it as is, otherwise convert to string
      const itemValue = item[this.mapItem.value];
      this.formValue = typeof itemValue === 'boolean' || Array.isArray(itemValue) ? itemValue : itemValue.toString();
      return item;
    }

    @Emit('clear')
    public clear(): void {}

    @Emit('keydown')
    public keydown(keyCode: string): string {
      return keyCode;
    }

    @Emit('resize')
    public resizeCompleted(): void {}

    /*****         helpers        *****/

    // Set dropdown item class by index
    public highlightItem(index: number, arrowIndex: number, item: any): string {
      const formValue = (this.formValue ?? '').toString().toLowerCase();
      const itemValue = (item[this.mapItem.value] ?? '').toString().toLowerCase();
      const itemText = (item[this.mapItem.text] ?? '').toString().toLowerCase();

      if (!this.hideSelected && (formValue === itemValue || formValue === itemText)) {
        return 'selected-item';
      }
      return index === arrowIndex ? 'highlighted-item' : '';
    }

    // Scroll list by arrow index
    public scrollByArrow(arrowIndex: number = 0): void {
      const virtualScroller = this.itemList?.$refs['virtual-scroller'] as Vue;
      if (virtualScroller) {
        const middleItem = Math.floor(this.listHeight / this.listItemHeight / 2);
        virtualScroller.$el.scrollTop = (arrowIndex - middleItem) * this.listItemHeight;
        this.$nextTick(() => {
          virtualScroller.$el.scrollTop = (arrowIndex - middleItem) * this.listItemHeight;
        });
      }
    }

    private addPostfix(): string | number {
      let userInput = (this.userInput ?? '').toString();
      if (!!this.postfix && !!userInput && !userInput.endsWith(this.postfix)) {
        this.userInput = userInput + this.postfix;
      }
      return this.userInput;
    }

    private removePostfix(): string | number {
      let userInput = (this.userInput ?? '').toString();
      if (!!this.postfix && userInput.endsWith(this.postfix)) {
        this.userInput = userInput.slice(0, -this.postfix.length);
      }
      return this.userInput;
    }

    private defineUserInput(): void {
      const formValue = JSON.parse(JSON.stringify(this.formValue));
      const item = this.items?.find((item) =>
        Array.isArray(formValue)
          ? JSON.stringify(item[this.mapItem.value].sort()) === JSON.stringify(formValue.sort())
          : item[this.mapItem.value] === formValue,
      );
      // If item text should be ignored in input, set empty string
      // Otherwise set input as item text or formValue
      const itemText = item?.text === this.ignoreInput ? '' : item?.text;
      this.userInput = (itemText ?? this.formValue ?? '').toString();
    }

    private setMinWidth(): void {
      const rect = this.inputField.$el.getBoundingClientRect();
      this.minWidth = rect.width;

      const tippyComponent = this.itemList.$refs['item-list'] as typeof TippyComponent;
      const autocompleteTheme = tippyComponent.tip.popper.getElementsByClassName('autocomplete-theme')[0];

      if (autocompleteTheme) {
        autocompleteTheme.style.minWidth = `${rect.width}px`;
        autocompleteTheme.classList.add('shadow-lg', this.rounded ? 'rounded-lg' : 'rounded');
      }
    }

    /*****      vue lifecycle     *****/

    beforeMount() {
      this.defineUserInput();
      if (!this.autofocus) {
        this.addPostfix();
      }

      window.addEventListener('resize', this.setMinWidth);
    }

    beforeDestroy() {
      window.removeEventListener('resize', this.setMinWidth);
    }
  }
</script>
