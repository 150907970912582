import _Vue from 'vue';
import chalk from 'chalk';

export default function HelperPlugin(Vue: typeof _Vue): void {
  Vue.prototype.$logger = (...args: Array<any>): void => {
    if (process.env.VUE_APP_ENVIRONMENT != 'production') {
      console.log(chalk.black.bgGreenBright(' LOGGER :: '), ...args);
    }
  };

  Vue.prototype.$checkCapsLock = (e: KeyboardEvent): boolean => {
    const isKeyboardEvent = e instanceof KeyboardEvent;
    return isKeyboardEvent ? e.getModifierState('CapsLock') : false;
  };

  Vue.prototype.$isSafari = (): boolean => {
    const userAgent: string = navigator.userAgent;

    const isOnMacOS = /Safari/.test(userAgent) && !/Chrome/.test(userAgent);
    const isOniOS = /iPhone|iPad|iPod/.test(userAgent) && typeof (window as any).MSStream === 'undefined';

    return isOnMacOS || isOniOS;
  };
}

declare module 'vue/types/vue' {
  interface Vue {
    $logger(...args: any[]): void;
    $checkCapsLock(e: KeyboardEvent): boolean;
    $isSafari(): boolean;
  }
}
