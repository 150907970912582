import TEST_DATA from '@/constants/config/TestData';
import Locale from '@/enums/config/Locale';
import CountryISO from '@/enums/config/iso/CountryISO';
import CurrencyISO from '@/enums/config/iso/CurrencyISO';
import PartnerSource from '@/enums/generics/PartnerSource';
import type IPartnerForm from '@/interfaces/forms/partner/IPartnerForm';

import useEnv from '@/services/Env';

const { isDev } = useEnv();

const DEFAULT_PARTNER_FORM: IPartnerForm = {
  uuid: '',
  name: isDev ? TEST_DATA.partner.name : '',
  country: CountryISO.LV,
  is_legal: true,

  source: PartnerSource.PARTNERS,
  created_at: '',

  registration_number: null,
  vat_registration_number: null,

  address: null,
  email: null,

  phone_prefix: '+371',
  phone_number: null,

  notes: null,
  internal_notes: null,

  preferences: {
    currency_code: CurrencyISO.EUR,
    document_lang: Locale.LV,
    enable_late_fees: false,
    late_fee_percent: null,
    default_due_days: null,
  },
  payment_methods: [],
};

export default DEFAULT_PARTNER_FORM;
