import { Component, Vue } from 'vue-property-decorator';
import Locale from '@/enums/config/Locale';
import CompanyType from '@/enums/generics/CompanyType';
import TranslationModule from '@/store/modules/Translation';

@Component
export default class TranslateCompanyTypeMixin extends Vue {
  public TranslateCompanyNameFieldMixin(type: CompanyType, locale: Locale = TranslationModule.getLocale): string {
    switch (type) {
      case CompanyType.LEGAL:
        return this.trans('workspace.title.company_name', 'Uzņēmuma nosaukums', locale);
      case CompanyType.PRIVATE:
        return this.trans('workspace.title.full_name', 'Vārds Uzvārds', locale);
    }
  }

  public TranslateCompanyTaxNumberFieldMixin(type: CompanyType, locale: Locale = TranslationModule.getLocale): string {
    switch (type) {
      case CompanyType.LEGAL:
        return this.trans('workspace.title.registration_number', 'Reģistrācijas numurs', locale);
      case CompanyType.PRIVATE:
        return this.trans('workspace.title.taxpayer_number', 'Nodokļu maksātāja numurs (Personas kods)', locale);
    }
  }
}
