import { Component, Vue } from 'vue-property-decorator';
import SortField from '@/enums/generics/SortField';
import type IDocumentListResponse from '@/interfaces/document/response/IDocumentListResponse';
import DocumentRepository from '@/repository/Document';
import CompanyModule from '@/store/modules/Company';
import PageModule from '@/store/modules/Page';

@Component
export default class ListDocumentsMixin extends Vue {
  public async ListDocumentsMixin(companyUUID: string = CompanyModule.getCompanyUUID): Promise<IDocumentListResponse> {
    await PageModule.SET_MISSING_URL_PARAMS({ templates: 0, plain: 1 });
    // Check if sort_field is valid
    const sortField = PageModule.getUrlParams.sort_field;
    const acceptableFields = [SortField.RECORD_NUMBER, SortField.RECIPIENT, SortField.DATE, SortField.TOTAL_WITH_VAT];
    if (sortField && !acceptableFields.includes(sortField)) {
      await PageModule.SET_URL_PARAMS({ sort_field: SortField.DATE });
    }

    return (await DocumentRepository.list(companyUUID, PageModule.getUrlParams)).data;
  }
}
