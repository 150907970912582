<template>
  <div
    :name="tippyDropdownId"
    class="autocomplete relative w-full h-full"
    :class="[{ 'rounded-lg': rounded }, color ?? (dark ? 'eerie' : 'white')]"
    v-click-outside="{ handler: close, include: includeElements }"
  >
    <div
      class="relative flex items-center w-full h-full pl-3 pr-2 overflow-hidden"
      :class="[{ 'rounded-lg': rounded }, { 'field-error': showErrorBorder }, fontSize, inputClass]"
      @click="showItems"
    >
      <slot name="prepend" />
      <v-textarea
        v-if="fieldType === 'textarea'"
        ref="input-ref"
        v-model="formValue"
        :placeholder="placeholder"
        :clearable="clearable"
        :autofocus="autofocus"
        :readonly="readonly"
        :disabled="disabled"
        row-height="18"
        hide-details="auto"
        :name="name"
        :id="name"
        auto-grow
        rows="1"
        solo
        flat
        class="flex items-center w-full h-full truncate rounded-none outline-none text-inherit"
        :class="`${textColor ?? (dark ? 'white' : 'eerie')}--text`"
        @input="input"
        @keypress="keypress"
        @keydown="keydown"
        @blur="blur"
        @focus="focus"
        @click:clear="clear"
      />
      <v-text-field
        v-if="fieldType === 'input'"
        ref="input-ref"
        v-model="formValue"
        :autocomplete="autocomplete"
        :placeholder="placeholder"
        :clearable="clearable"
        :autofocus="autofocus"
        :readonly="readonly"
        :disabled="disabled"
        hide-details="auto"
        :name="name"
        :id="name"
        flat
        solo
        class="flex items-center w-full h-full truncate rounded-none outline-none text-inherit"
        :class="`${textColor ?? (dark ? 'white' : 'eerie')}--text`"
        @input="input"
        @keypress="keypress"
        @keydown="keydown"
        @blur="blur"
        @focus="focus"
        @click:clear="clear"
      />
      <slot name="append" :disabled="disabled" :loading="loading" :show="show" :hide="computedHideList" />
      <v-progress-linear v-if="loading" indeterminate height="2" absolute bottom />
    </div>
    <slot
      name="list"
      :show="show"
      :typing="typing"
      :items="listItems"
      :loading="loading"
      :arrow="arrowIndex"
      :useSearch="useSearch"
      :setItem="setItem"
      :setArrow="setArrowIndex"
      :groupBy="groupBy"
    />
  </div>
</template>

<script lang="ts">
  import Wait from '@/decorators/Wait';
  import throttle from 'lodash-es/throttle';
  import { Component, Emit, Prop, Vue, VModel, Watch, Ref } from 'vue-property-decorator';
  import Locale from '@/enums/config/Locale';
  import KeypressInputType from '@/enums/types/KeypressInputType';
  import TimeConfig from '@/enums/config/TimeConfig';
  import type ISelectMap from '@/interfaces/config/ISelectMap';
  import TranslationModule from '@/store/modules/Translation';

  @Component
  export default class IndexAutocomplete extends Vue {
    @VModel({ default: '' }) formValue!: string;

    @Prop() tippyDropdownId!: string;
    @Prop() fieldType!: 'textarea' | 'input';
    @Prop() inputType!: KeypressInputType;

    @Prop({ default: 'off' }) autocomplete!: string;
    @Prop({ default: '-' }) placeholder!: string;
    @Prop({ default: '' }) name!: string;

    @Prop({ default: 20 }) autohideList?: number;

    @Prop({ type: Boolean }) showErrorBorder?: boolean;

    @Prop({ type: Boolean }) filterable?: boolean;
    @Prop({ type: Boolean }) filterDescription?: boolean;
    @Prop({ type: Boolean }) clearable?: boolean;
    @Prop({ type: Boolean }) sortable?: boolean;
    @Prop({ type: Boolean }) autofocus?: boolean;
    @Prop({ type: Boolean }) readonly?: boolean;
    @Prop({ type: Boolean }) disabled?: boolean;
    @Prop({ type: Boolean }) loading?: boolean;

    @Prop({ type: Boolean }) rounded?: boolean;
    @Prop({ type: Boolean }) resize?: boolean;
    @Prop({ type: Boolean }) dark?: boolean;

    @Prop({ type: Boolean }) hideSelected?: boolean;
    @Prop({ type: Boolean }) hideList?: boolean;
    @Prop({ type: Boolean }) useSearch?: boolean;

    @Prop({ default: '' }) searchQuery!: string;

    @Prop() color?: string;
    @Prop() textColor?: string;
    @Prop() inputClass?: string;

    @Prop({ default: 'text-body-2' }) fontSize!: string;

    @Prop() items!: any[] | undefined;
    @Prop() mapItem!: ISelectMap;
    @Prop() groupBy!: string;
    @Prop() groupByOrderBy?: string;
    @Prop() groupByCustomOrder?: any[];

    @Ref('input-ref') inputRef!: HTMLInputElement | Vue | undefined;

    public show: boolean = false;
    public typing: boolean = false;
    public translate: boolean = false;
    public arrowIndex: number = -1;

    private typingTimeout: number | NodeJS.Timeout = 0;

    private prevInputLength: number = (this.formValue || '').length;

    /*****         computed       *****/

    public get locale(): Locale {
      return TranslationModule.getLocale;
    }

    public get computedHideList(): boolean {
      const inputLength = (this.formValue || '').length;
      const calculatefromPrev = this.prevInputLength + 1 > inputLength;
      const autohideList =
        !!this.autohideList && (calculatefromPrev ? inputLength > this.autohideList : inputLength >= this.autohideList);
      this.prevInputLength = inputLength;

      return !!this.hideList || (autohideList && !this.readonly);
    }

    public get listItems(): any[] {
      let items: any[] = new Array();

      const query = this.readonly ? this.searchQuery : this.formValue;

      if (query === '' || query === null) {
        items.push(...(this.items ?? []));
      } else if (this.filterable) {
        const rawQuery = query.toString().toLowerCase();
        items.push(
          ...(this.items?.filter(
            (item) =>
              item[this.mapItem.text]?.toString().toLowerCase().includes(rawQuery) ||
              (this.filterDescription &&
                this.mapItem.description &&
                item[this.mapItem.description]?.toString().toLowerCase().includes(rawQuery)),
          ) ?? []),
        );
      } else if (this.sortable) {
        items.push(...this.mergeSort(this.items ?? [], this.mapItem.text.toString(), query.toString().toLowerCase()));
      } else {
        items.push(...(this.items ?? []));
      }

      if (this.hideSelected) {
        items = items.filter((item) => item[this.mapItem.text].toString() !== this.formValue);
      }

      // Sort items by groupBy key
      if (this.groupBy) {
        items = items.sort((a, b) => {
          return a[this.groupBy].localeCompare(b[this.groupBy]);
        });

        if (this.groupByCustomOrder && this.groupByCustomOrder.length > 0) {
          const orderBy = this.groupByOrderBy || this.groupBy;
          items = items.sort((a, b) => {
            const orderA = this.groupByCustomOrder!.indexOf(a[orderBy]);
            const orderB = this.groupByCustomOrder!.indexOf(b[orderBy]);
            return orderA - orderB;
          });
        }
      }

      this.translationCompleted();

      return items.map((item, index) => {
        return {
          ...item,
          id: index,
        };
      });
    }

    /*****         watchers       *****/

    @Watch('locale')
    private localeChanged(): void {
      this.translationStarted();
    }

    @Watch('resize')
    private sizeChanged(): void {
      if (this.resize && this.inputRef) {
        this.$nextTick(() => {
          (this.inputRef as any).calculateInputHeight();
          this.resizeCompleted();
        });
      }
    }

    /*****         methods        *****/

    public showItems(): void {
      if (this.disabled || this.computedHideList) {
        this.close();
      } else if (this.readonly) {
        this.show ? this.close() : this.open();
      } else if (!this.show) {
        this.open();
      }
    }

    public setArrowIndex(index: number): void {
      this.arrowIndex = index;
    }

    @Emit('focus')
    public focus(): void {}

    @Emit('blur')
    public blur(): void {}

    @Wait()
    @Emit('open')
    private open(): boolean {
      this.translationCompleted();

      if (this.disabled || this.computedHideList) {
        return this.close();
      }

      if (this.inputRef && !this.readonly) {
        (this.inputRef as HTMLInputElement).focus();
      }
      return (this.show = true);
    }

    @Emit('close')
    public close(): boolean {
      this.arrowIndex = -1;
      // Close action should also brul the input field
      if (this.inputRef && !this.computedHideList) {
        (this.inputRef as HTMLInputElement).blur();
      }
      return (this.show = false);
    }

    @Emit('input:debounce')
    public async input(userInput: string): Promise<string> {
      if (this.typing && !this.show) {
        this.open();
      }
      this.userIsTyping();
      if ((this.formValue ?? '') != (userInput ?? '')) {
        await this.updateQuery(userInput);
      }
      return userInput ?? '';
    }

    @Emit('query')
    public query(userInput: string): string {
      return userInput;
    }

    @Emit('clear')
    public async clear(): Promise<void> {
      this.formValue = '';
      await this.updateQuery();
      this.showItems();
    }

    @Emit('set')
    public setItem(item: any): any {
      this.formValue = item[this.mapItem.text].toString();
      this.close();
      return item;
    }

    @Emit('arrow')
    private arrowChanged(): number {
      return this.arrowIndex;
    }

    @Emit('keydown')
    public keydown(e: KeyboardEvent): string {
      if (this.readonly) {
        e.preventDefault();
      }

      const keyCode: string = e.code.toLowerCase();

      switch (keyCode) {
        case 'escape':
        case 'tab':
          this.close();
          break;
        case 'backspace':
          this.open();
          break;
        case 'arrowdown':
          this.arrowdown();
          break;
        case 'arrowup':
          this.arrowup();
          break;
        case 'enter':
        case 'numpadenter':
          this.enter();
          break;
      }

      return keyCode;
    }

    @Emit('keypress')
    public keypress(e: KeyboardEvent): void {
      switch (this.inputType) {
        case KeypressInputType.POSITIVE_INT:
          this.positiveInteger(e);
          return;
        case KeypressInputType.NEGATIVE_INT:
          this.negativeInteger(e);
          return;
        case KeypressInputType.POSITIVE_FLOAT:
          this.positiveFloat(e);
          return;
        case KeypressInputType.NEGATIVE_FLOAT:
          this.negativeFloat(e);
          return;
      }
    }

    @Emit('resize')
    private resizeCompleted(): void {}

    @Emit('redifine')
    private translationCompleted(): void {
      this.translate = false;
    }

    private translationStarted(): void {
      this.translate = true;
    }

    private enter(): void {
      // Textarea field type should be able to press enter to add new line
      // This will only work if there is no list items to select from
      if (this.fieldType != 'textarea' && this.items && this.items.length > 0) {
        if (this.disabled || this.computedHideList) {
          this.close();
          return;
        }

        const item = this.listItems[this.arrowIndex];
        this.show && !this.computedHideList ? (item ? this.setItem(item) : this.close()) : this.open();
      }
    }

    private arrowdown(): void {
      if (this.arrowIndex < this.listItems.length - 1) {
        this.arrowIndex++;
      }
      this.arrowChanged();
    }

    private arrowup(): void {
      if (this.arrowIndex > 0) {
        this.arrowIndex--;
      }

      this.arrowChanged();
    }

    /*****         helpers        *****/

    private updateQuery = throttle(async function (this: IndexAutocomplete, query = ''): Promise<void> {
      if (this.disabled || this.computedHideList) {
        this.close();
      } else {
        this.query(query ?? '');
      }
    }, TimeConfig.DEBOUNCE);

    private userIsTyping(): void {
      this.typing = true;
      clearTimeout(this.typingTimeout);
      this.typingTimeout = setTimeout(() => {
        this.typing = false;
      }, 500);
    }

    // The mergeSort function is a recursive function
    // That sorts an array of items based on a text key and a user input
    private mergeSort(items: any[], textKey: string, userInput: string): any[] {
      if (items.length <= 1) {
        return items;
      }

      const middle = Math.floor(items.length / 2);
      const left = items.slice(0, middle);
      const right = items.slice(middle);

      return this.merge(
        this.mergeSort(left, textKey, userInput),
        this.mergeSort(right, textKey, userInput),
        textKey,
        userInput,
      );
    }

    // The merge function is called by the mergeSort function to merge and sort two arrays
    private merge(left: any[], right: any[], textKey: string, userInput: string): any[] {
      let result = [];
      let leftIndex = 0;
      let rightIndex = 0;

      while (leftIndex < left.length && rightIndex < right.length) {
        const leftText = left[leftIndex][textKey].toString().toLowerCase();
        const rightText = right[rightIndex][textKey].toString().toLowerCase();
        const userInputText = userInput.toString().toLowerCase();

        if (leftText.startsWith(userInputText) && !rightText.startsWith(userInputText)) {
          result.push(left[leftIndex]);
          leftIndex++;
        } else if (rightText.startsWith(userInputText) && !leftText.startsWith(userInputText)) {
          result.push(right[rightIndex]);
          rightIndex++;
        } else if (leftText < rightText) {
          result.push(left[leftIndex]);
          leftIndex++;
        } else {
          result.push(right[rightIndex]);
          rightIndex++;
        }
      }

      return result.concat(left.slice(leftIndex)).concat(right.slice(rightIndex));
    }

    public includeElements(): any[] {
      const dropdownSearchEl = document.getElementById(`search:${this.tippyDropdownId}`);

      return dropdownSearchEl ? [dropdownSearchEl] : [];
    }

    /*****      vue lifecycle     *****/
  }
</script>
