import { Component, Vue } from 'vue-property-decorator';
import DateFormat from '@/enums/config/DateFormat';
import type CurrencyISO from '@/enums/config/iso/CurrencyISO';
import type ICurrencyExchange from '@/interfaces/ICurrencyExchange';
import HelperRepository from '@/repository/Helper';

@Component
export default class FetchCurrencyExchangeMixin extends Vue {
  public async FetchCurrencyExchangeMixin(
    from: CurrencyISO,
    to: CurrencyISO,
    date?: string,
  ): Promise<ICurrencyExchange> {
    try {
      return (await HelperRepository.currencyExchange(from, to, date)).data;
    } catch (_) {
      this.$logger('There was a problem to get currency exchange...');
      this.$logger('Default exchange rate is set to 0');
      return {
        date: date ?? this.parseNowToFormat(DateFormat.DATABASE),
        rate: 0,
        from,
        to,
      };
    }
  }
}
