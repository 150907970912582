import { Component, Vue } from 'vue-property-decorator';
import DocumentType from '@/enums/config/document/Type';
import RecurringDocumentType from '@/enums/config/document/RecurringType';
import TranslationModule from '@/store/modules/Translation';

@Component
export default class TranslateDocumentNumberMixin extends Vue {
  public TranslateDocumentNumberMixin(
    type: DocumentType | RecurringDocumentType,
    isFinal: boolean = false,
    locale: string = TranslationModule.getLocale,
  ): string {
    switch (type) {
      case DocumentType.WAYBILL:
        return this.trans('workspace.title.document_number.waybill', 'Pavadzīmes numurs', locale);
      case DocumentType.CREDIT_NOTE:
        return this.trans('workspace.title.document_number.credit_note', 'Kredītrēķina numurs', locale);
      case DocumentType.ADVANCE_INVOICE:
        return this.trans('workspace.title.document_number.advance_invoice', 'Avansa rēķina numurs', locale);
      case DocumentType.OFFER:
        return this.trans('workspace.title.document_number.offer', 'Piedāvājuma numurs', locale);
      case DocumentType.INVOICE:
      default:
        return isFinal
          ? this.trans('workspace.title.document_number.final_invoice', 'Gala rēķina numurs', locale)
          : this.trans('workspace.title.document_number.invoice', 'Rēķina numurs', locale);
    }
  }
}
