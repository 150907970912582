import { AxiosPromise } from 'axios';
import ApiRepository from '@/repository/config/Api';
import useFormBuilder from '@/services/FormBuilder';
import type IRegisterCompanyForm from '@/interfaces/forms/auth/IRegisterCompanyForm';
import type ICompanySettings from '@/interfaces/company/ICompanyDetails';
import type IDocumentSettings from '@/interfaces/company/IDocumentSettings';
import type IEmailSettings from '@/interfaces/company/IEmailSettings';
import type ICompanyBrandForm from '@/interfaces/forms/ICompanyBrandForm';

const { jsonToFormData } = useFormBuilder();

export default class CompanyRepository {
  // List all companies
  public static list(): AxiosPromise {
    return ApiRepository.get('/companies');
  }

  // Fetch user company
  public static fetch(companyUUID: string): AxiosPromise {
    return ApiRepository.get(`/companies/${companyUUID}`);
  }

  // Create new company
  public static create(payload: IRegisterCompanyForm): AxiosPromise {
    return ApiRepository.post('/companies', payload);
  }

  // Delete company
  public static delete(companyUUID: string): AxiosPromise {
    return ApiRepository.delete(`/companies/${companyUUID}`);
  }

  /*****        Company update operations      *****/

  // Update user company general data
  public static updateCompanySettings(companyUUID: string, payload: ICompanySettings): AxiosPromise {
    return ApiRepository.post(`/companies/${companyUUID}/base-data`, payload);
  }

  // Update user company branding
  public static updateBrandSettings(companyUUID: string, payload: ICompanyBrandForm): AxiosPromise {
    return ApiRepository.post(`/companies/${companyUUID}/logotype`, jsonToFormData(payload));
  }
  // Update user company document settings
  public static updateDocumentSettings(companyUUID: string, payload: IDocumentSettings): AxiosPromise {
    return ApiRepository.post(`/companies/${companyUUID}/settings`, payload);
  }
  // Update user company email settings
  public static updateEmailSettings(companyUUID: string, payload: IEmailSettings): AxiosPromise {
    return ApiRepository.post(`/companies/${companyUUID}/email-preferences`, { email_preferences: payload });
  }
}
