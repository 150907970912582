import { Component, Vue } from 'vue-property-decorator';
import type IUser from '@/interfaces/user/IUser';
import UserRepository from '@/repository/User';

@Component
export default class FetchMixin extends Vue {
  public async FetchMixin(): Promise<IUser | null> {
    let user: IUser | null = null;
    try {
      user = (await UserRepository.fetch()).data;
    } catch (e) {
      this.$logger('unable to fetch user from mixin...');
    }

    return user;
  }
}
