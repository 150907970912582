import { Component, Vue } from 'vue-property-decorator';
import Locale from '@/enums/config/Locale';
import DateRange from '@/enums/config/DateRange';
import TranslationModule from '@/store/modules/Translation';

@Component
export default class TranslateDateRangeMixin extends Vue {
  public TranslateDateRangeMixin(range: DateRange, locale: Locale = TranslationModule.getLocale): string {
    switch (range) {
      case DateRange.ALL_TIME:
        return this.trans('workspace.title.all_time', 'Pa visu laiku', locale);
      case DateRange.THIS_MONTH:
        return this.trans('workspace.title.this_month', 'Šajā mēnesī', locale);
      case DateRange.LAST_MONTH:
        return this.trans('workspace.title.last_month', 'Iepriekšējā mēnesī', locale);
      case DateRange.THIS_YEAR:
        return this.trans('workspace.title.this_year', 'Šogad', locale);
      case DateRange.LAST_YEAR:
        return this.trans('workspace.title.last_year', 'Iepriekšējā gadā', locale);
      case DateRange.FIRST_QUARTER:
        return this.trans('workspace.title.first_quarter', '1. ceturksnis', locale);
      case DateRange.SECOND_QUARTER:
        return this.trans('workspace.title.second_quarter', '2. ceturksnis', locale);
      case DateRange.THIRD_QUARTER:
        return this.trans('workspace.title.third_quarter', '3. ceturksnis', locale);
      case DateRange.FOURTH_QUARTER:
        return this.trans('workspace.title.fourth_quarter', '4. ceturksnis', locale);
      default:
        return 'lang';
    }
  }
}
