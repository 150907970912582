import { Component, Vue } from 'vue-property-decorator';
import type Locale from '@/enums/config/Locale';
import DocumentType from '@/enums/config/document/Type';
import TranslationModule from '@/store/modules/Translation';

@Component
export default class TranslateDocumentDeleteMixin extends Vue {
  public TranslateDocumentDeleteTitleMixin(
    type: DocumentType | null = null,
    locale: Locale = TranslationModule.getLocale,
  ): string {
    switch (type) {
      case DocumentType.INVOICE:
        return this.trans('modal.invoice.delete.title', 'Dzēst rēķinu', locale);
      case DocumentType.WAYBILL:
        return this.trans('modal.waybill.delete.title', 'Dzēst pavadzīmi', locale);
      case DocumentType.ADVANCE_INVOICE:
        return this.trans('modal.advance_invoice.delete.title', 'Dzēst avansa rēķinu', locale);
      case DocumentType.OFFER:
        return this.trans('modal.offer.delete.title', 'Dzēst piedāvājumu', locale);
      case DocumentType.CREDIT_NOTE:
        return this.trans('modal.credit_note.delete.title', 'Dzēst kredītrēķinu', locale);
      default:
        return this.trans('modal.document.delete.plural.title', 'Dzēst dokumentus', locale);
    }
  }

  public TranslateDocumentDeleteContentMixin(
    type: DocumentType | null = null,
    documentNumber: string = '',
    locale: Locale = TranslationModule.getLocale,
  ): string {
    switch (type) {
      case DocumentType.INVOICE:
        return this.trans(
          'modal.invoice.delete.content',
          'Rēķins :documentNumber tiks neatgriezeniski dzēsts - pēc dzēšanas tos nevarēs atjaunot.<br/><br /><b>Ņem vērā, ka pēc rēķina dzēšanas rēķinu numura secībā būs iztrūkums, ko varēs aizpildīt tikai manuāli.</b><br /><br />Vai esat pārliecināts, ka vēlaties dzēst šo rēķinu?',
          { documentNumber },
          locale,
        );
      case DocumentType.WAYBILL:
        return this.trans(
          'modal.waybill.delete.content',
          'Pavadzīme :documentNumber tiks neatgriezeniski dzēsts - pēc dzēšanas tos nevarēs atjaunot.<br/><br /><b>Ņem vērā, ka pēc pavadzīmes dzēšanas pavazīmes numura secībā būs iztrūkums, ko varēs aizpildīt tikai manuāli.</b><br /><br />Vai esat pārliecināts, ka vēlaties dzēst šo pavadzīmi?',
          { documentNumber },
          locale,
        );
      case DocumentType.ADVANCE_INVOICE:
        return this.trans(
          'modal.advance_invoice.delete.content',
          'Avansa rēķins :documentNumber tiks neatgriezeniski dzēsts - pēc dzēšanas tos nevarēs atjaunot.<br/><br /><b>Ņem vērā, ka pēc avansa rēķina dzēšanas avansa rēķinu numura secībā būs iztrūkums, ko varēs aizpildīt tikai manuāli.</b><br /><br />Vai esat pārliecināts, ka vēlaties dzēst šo avansa rēķinu?',
          { documentNumber },
          locale,
        );
      case DocumentType.OFFER:
        return this.trans(
          'modal.offer.delete.content',
          'Piedāvājums :documentNumber tiks neatgriezeniski dzēsts - pēc dzēšanas tos nevarēs atjaunot.<br/><br /><b>Ņem vērā, ka pēc piedāvājuma dzēšanas piedāvājuma numura secībā būs iztrūkums, ko varēs aizpildīt tikai manuāli.</b><br /><br />Vai esat pārliecināts, ka vēlaties dzēst šo piedāvājumu?',
          { documentNumber },
          locale,
        );
      case DocumentType.CREDIT_NOTE:
        return this.trans(
          'modal.credit_note.delete.content',
          'Kredītrēķins :documentNumber tiks neatgriezeniski dzēsts - pēc dzēšanas tos nevarēs atjaunot.<br/><br /><b>Ņem vērā, ka pēc kredītrēķina dzēšanas kredītrēķinu numura secībā būs iztrūkums, ko varēs aizpildīt tikai manuāli.</b><br /><br />Vai esat pārliecināts, ka vēlaties dzēst šo kredītrēķinu?',
          { documentNumber },
          locale,
        );
      default:
        return this.trans(
          'modal.document.delete.plural.content',
          'Atzīmētie dokumenti tiks neatgriezeniski dzēsti - pēc dzēšanas tos nevarēs atjaunot.<br/><br /><b>Ņem vērā, ka pēc dokumentu dzēšanas dokumentu numura secībā būs iztrūkums, ko varēs aizpildīt tikai manuāli.</b><br /><br />Vai esat pārliecināts, ka vēlaties dzēst šos dokumentus?',
          locale,
        );
    }
  }
}
