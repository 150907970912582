enum TimeConfig {
  INTERVAL = 1000,
  DEBOUNCE = 800,
  TIMEOUT = 1000,

  MIN_RETRIES = 5,
  MAX_RETRIES = 300,

  WAIT = 10,
  WAIT_LONGER = 200,

  EXPIRY = 1,
}

export default TimeConfig;
