import { Component, Vue } from 'vue-property-decorator';
import Locale from '@/enums/config/Locale';
import TranslationModule from '@/store/modules/Translation';

@Component
export default class TranslateLanguageMixin extends Vue {
  public TranslateLanguageMixin(lang: Locale, locale: Locale = TranslationModule.getLocale): string {
    switch (lang) {
      case Locale.LV:
        return this.trans('document.language.type.lv', 'Latviešu', locale);
      case Locale.EN:
        return this.trans('document.language.type.en', 'Angļu', locale);
      case Locale.RU:
        return this.trans('document.language.type.ru', 'Krievu', locale);
      default:
        return lang;
    }
  }
}
