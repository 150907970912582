/**
 * Usage:
 * {{ trans('dashboard.welcome_text', 'Welcome back!') }} returns:
 * - Welcome back!
 *
 * {{ trans('dashboard.welcome_back', 'Welcome back, :user!', {user: 'Captain'}) }} returns:
 * - Welcome back, Captain!
 *
 * Force locale override:
 * {{ trans('dashboard.welcome_back', 'Welcome back!', 'ru') }} returns:
 * - "Welcome back!" russian translation
 */

import _Vue from 'vue';
import type Locale from '@/enums/config/Locale';
import TranslationModule from '@/store/modules/Translation';

export default function TranslationPlugin(Vue: typeof _Vue): void {
  Vue.prototype.trans = (key: string, ...args: Array<any>) => {
    if (TranslationModule.getLocale) {
      let locale = TranslationModule.getLocale;

      if (!!args.length && typeof args[1] === 'string') {
        locale = args[1] as Locale;
      }

      if (!!args.length && typeof args[2] === 'string') {
        locale = args[2] as Locale;
      }

      let value = TranslationModule.getTranslations.length
        ? TranslationModule.getKeyTranslation(key.trim(), locale)
        : '';

      if (!value && args.length > 0) {
        value = args[0];
      }

      if (args.length && typeof args[1] === 'object') {
        const replacements = args[1];

        Object.keys(replacements).forEach((iteration) => {
          const replacementValue = replacements[iteration];
          const expression = new RegExp(`:${iteration}`, 'g');

          value = value.replace(expression, replacementValue);
        });
      }

      return value;
    }
    return '';
  };
}

declare module 'vue/types/vue' {
  interface Vue {
    trans(key: string, translation: string, ...args: Array<any>): string;
  }
}
