import type ISelect from '@/interfaces/config/ISelect';

const TIMEZONES: ISelect[] = [
  {
    text: 'Pacific/Midway',
    value: '-11:00',
  },
  {
    text: 'Pacific/Niue',
    value: '-11:00',
  },
  {
    text: 'Pacific/Pago_Pago',
    value: '-11:00',
  },
  {
    text: 'America/Adak',
    value: '-10:00',
  },
  {
    text: 'Pacific/Honolulu',
    value: '-10:00',
  },
  {
    text: 'Pacific/Rarotonga',
    value: '-10:00',
  },
  {
    text: 'Pacific/Tahiti',
    value: '-10:00',
  },
  {
    text: 'Pacific/Marquesas',
    value: '-09:30',
  },
  {
    text: 'America/Anchorage',
    value: '-09:00',
  },
  {
    text: 'America/Juneau',
    value: '-09:00',
  },
  {
    text: 'America/Metlakatla',
    value: '-09:00',
  },
  {
    text: 'America/Nome',
    value: '-09:00',
  },
  {
    text: 'America/Sitka',
    value: '-09:00',
  },
  {
    text: 'America/Yakutat',
    value: '-09:00',
  },
  {
    text: 'Pacific/Gambier',
    value: '-09:00',
  },
  {
    text: 'America/Los_Angeles',
    value: '-08:00',
  },
  {
    text: 'America/Tijuana',
    value: '-08:00',
  },
  {
    text: 'America/Vancouver',
    value: '-08:00',
  },
  {
    text: 'Pacific/Pitcairn',
    value: '-08:00',
  },
  {
    text: 'America/Boise',
    value: '-07:00',
  },
  {
    text: 'America/Cambridge_Bay',
    value: '-07:00',
  },
  {
    text: 'America/Chihuahua',
    value: '-07:00',
  },
  {
    text: 'America/Creston',
    value: '-07:00',
  },
  {
    text: 'America/Dawson',
    value: '-07:00',
  },
  {
    text: 'America/Dawson_Creek',
    value: '-07:00',
  },
  {
    text: 'America/Denver',
    value: '-07:00',
  },
  {
    text: 'America/Edmonton',
    value: '-07:00',
  },
  {
    text: 'America/Fort_Nelson',
    value: '-07:00',
  },
  {
    text: 'America/Hermosillo',
    value: '-07:00',
  },
  {
    text: 'America/Inuvik',
    value: '-07:00',
  },
  {
    text: 'America/Mazatlan',
    value: '-07:00',
  },
  {
    text: 'America/Ojinaga',
    value: '-07:00',
  },
  {
    text: 'America/Phoenix',
    value: '-07:00',
  },
  {
    text: 'America/Whitehorse',
    value: '-07:00',
  },
  {
    text: 'America/Yellowknife',
    value: '-07:00',
  },
  {
    text: 'America/Bahia_Banderas',
    value: '-06:00',
  },
  {
    text: 'America/Belize',
    value: '-06:00',
  },
  {
    text: 'America/Chicago',
    value: '-06:00',
  },
  {
    text: 'America/Costa_Rica',
    value: '-06:00',
  },
  {
    text: 'America/El_Salvador',
    value: '-06:00',
  },
  {
    text: 'America/Guatemala',
    value: '-06:00',
  },
  {
    text: 'America/Indiana/Knox',
    value: '-06:00',
  },
  {
    text: 'America/Indiana/Tell_City',
    value: '-06:00',
  },
  {
    text: 'America/Managua',
    value: '-06:00',
  },
  {
    text: 'America/Matamoros',
    value: '-06:00',
  },
  {
    text: 'America/Menominee',
    value: '-06:00',
  },
  {
    text: 'America/Merida',
    value: '-06:00',
  },
  {
    text: 'America/Mexico_City',
    value: '-06:00',
  },
  {
    text: 'America/Monterrey',
    value: '-06:00',
  },
  {
    text: 'America/North_Dakota/Beulah',
    value: '-06:00',
  },
  {
    text: 'America/North_Dakota/Center',
    value: '-06:00',
  },
  {
    text: 'America/North_Dakota/New_Salem',
    value: '-06:00',
  },
  {
    text: 'America/Rainy_River',
    value: '-06:00',
  },
  {
    text: 'America/Rankin_Inlet',
    value: '-06:00',
  },
  {
    text: 'America/Regina',
    value: '-06:00',
  },
  {
    text: 'America/Resolute',
    value: '-06:00',
  },
  {
    text: 'America/Swift_Current',
    value: '-06:00',
  },
  {
    text: 'America/Tegucigalpa',
    value: '-06:00',
  },
  {
    text: 'America/Winnipeg',
    value: '-06:00',
  },
  {
    text: 'Pacific/Galapagos',
    value: '-06:00',
  },
  {
    text: 'America/Atikokan',
    value: '-05:00',
  },
  {
    text: 'America/Bogota',
    value: '-05:00',
  },
  {
    text: 'America/Cancun',
    value: '-05:00',
  },
  {
    text: 'America/Cayman',
    value: '-05:00',
  },
  {
    text: 'America/Detroit',
    value: '-05:00',
  },
  {
    text: 'America/Eirunepe',
    value: '-05:00',
  },
  {
    text: 'America/Grand_Turk',
    value: '-05:00',
  },
  {
    text: 'America/Guayaquil',
    value: '-05:00',
  },
  {
    text: 'America/Havana',
    value: '-05:00',
  },
  {
    text: 'America/Indiana/Indianapolis',
    value: '-05:00',
  },
  {
    text: 'America/Indiana/Marengo',
    value: '-05:00',
  },
  {
    text: 'America/Indiana/Petersburg',
    value: '-05:00',
  },
  {
    text: 'America/Indiana/Vevay',
    value: '-05:00',
  },
  {
    text: 'America/Indiana/Vincennes',
    value: '-05:00',
  },
  {
    text: 'America/Indiana/Winamac',
    value: '-05:00',
  },
  {
    text: 'America/Iqaluit',
    value: '-05:00',
  },
  {
    text: 'America/Jamaica',
    value: '-05:00',
  },
  {
    text: 'America/Kentucky/Louisville',
    value: '-05:00',
  },
  {
    text: 'America/Kentucky/Monticello',
    value: '-05:00',
  },
  {
    text: 'America/Lima',
    value: '-05:00',
  },
  {
    text: 'America/Nassau',
    value: '-05:00',
  },
  {
    text: 'America/New_York',
    value: '-05:00',
  },
  {
    text: 'America/Nipigon',
    value: '-05:00',
  },
  {
    text: 'America/Panama',
    value: '-05:00',
  },
  {
    text: 'America/Pangnirtung',
    value: '-05:00',
  },
  {
    text: 'America/Port-au-Prince',
    value: '-05:00',
  },
  {
    text: 'America/Rio_Branco',
    value: '-05:00',
  },
  {
    text: 'America/Thunder_Bay',
    value: '-05:00',
  },
  {
    text: 'America/Toronto',
    value: '-05:00',
  },
  {
    text: 'Pacific/Easter',
    value: '-05:00',
  },
  {
    text: 'America/Anguilla',
    value: '-04:00',
  },
  {
    text: 'America/Antigua',
    value: '-04:00',
  },
  {
    text: 'America/Aruba',
    value: '-04:00',
  },
  {
    text: 'America/Barbados',
    value: '-04:00',
  },
  {
    text: 'America/Blanc-Sablon',
    value: '-04:00',
  },
  {
    text: 'America/Boa_Vista',
    value: '-04:00',
  },
  {
    text: 'America/Campo_Grande',
    value: '-04:00',
  },
  {
    text: 'America/Caracas',
    value: '-04:00',
  },
  {
    text: 'America/Cuiaba',
    value: '-04:00',
  },
  {
    text: 'America/Curacao',
    value: '-04:00',
  },
  {
    text: 'America/Dominica',
    value: '-04:00',
  },
  {
    text: 'America/Glace_Bay',
    value: '-04:00',
  },
  {
    text: 'America/Goose_Bay',
    value: '-04:00',
  },
  {
    text: 'America/Grenada',
    value: '-04:00',
  },
  {
    text: 'America/Guadeloupe',
    value: '-04:00',
  },
  {
    text: 'America/Guyana',
    value: '-04:00',
  },
  {
    text: 'America/Halifax',
    value: '-04:00',
  },
  {
    text: 'America/Kralendijk',
    value: '-04:00',
  },
  {
    text: 'America/La_Paz',
    value: '-04:00',
  },
  {
    text: 'America/Lower_Princes',
    value: '-04:00',
  },
  {
    text: 'America/Manaus',
    value: '-04:00',
  },
  {
    text: 'America/Marigot',
    value: '-04:00',
  },
  {
    text: 'America/Martinique',
    value: '-04:00',
  },
  {
    text: 'America/Moncton',
    value: '-04:00',
  },
  {
    text: 'America/Montserrat',
    value: '-04:00',
  },
  {
    text: 'America/Port_of_Spain',
    value: '-04:00',
  },
  {
    text: 'America/Porto_Velho',
    value: '-04:00',
  },
  {
    text: 'America/Puerto_Rico',
    value: '-04:00',
  },
  {
    text: 'America/Santo_Domingo',
    value: '-04:00',
  },
  {
    text: 'America/St_Barthelemy',
    value: '-04:00',
  },
  {
    text: 'America/St_Kitts',
    value: '-04:00',
  },
  {
    text: 'America/St_Lucia',
    value: '-04:00',
  },
  {
    text: 'America/St_Thomas',
    value: '-04:00',
  },
  {
    text: 'America/St_Vincent',
    value: '-04:00',
  },
  {
    text: 'America/Thule',
    value: '-04:00',
  },
  {
    text: 'America/Tortola',
    value: '-04:00',
  },
  {
    text: 'Atlantic/Bermuda',
    value: '-04:00',
  },
  {
    text: 'America/St_Johns',
    value: '-03:30',
  },
  {
    text: 'America/Araguaina',
    value: '-03:00',
  },
  {
    text: 'America/Argentina/Buenos_Aires',
    value: '-03:00',
  },
  {
    text: 'America/Argentina/Catamarca',
    value: '-03:00',
  },
  {
    text: 'America/Argentina/Cordoba',
    value: '-03:00',
  },
  {
    text: 'America/Argentina/Jujuy',
    value: '-03:00',
  },
  {
    text: 'America/Argentina/La_Rioja',
    value: '-03:00',
  },
  {
    text: 'America/Argentina/Mendoza',
    value: '-03:00',
  },
  {
    text: 'America/Argentina/Rio_Gallegos',
    value: '-03:00',
  },
  {
    text: 'America/Argentina/Salta',
    value: '-03:00',
  },
  {
    text: 'America/Argentina/San_Juan',
    value: '-03:00',
  },
  {
    text: 'America/Argentina/San_Luis',
    value: '-03:00',
  },
  {
    text: 'America/Argentina/Tucuman',
    value: '-03:00',
  },
  {
    text: 'America/Argentina/Ushuaia',
    value: '-03:00',
  },
  {
    text: 'America/Asuncion',
    value: '-03:00',
  },
  {
    text: 'America/Bahia',
    value: '-03:00',
  },
  {
    text: 'America/Belem',
    value: '-03:00',
  },
  {
    text: 'America/Cayenne',
    value: '-03:00',
  },
  {
    text: 'America/Fortaleza',
    value: '-03:00',
  },
  {
    text: 'America/Maceio',
    value: '-03:00',
  },
  {
    text: 'America/Miquelon',
    value: '-03:00',
  },
  {
    text: 'America/Montevideo',
    value: '-03:00',
  },
  {
    text: 'America/Nuuk',
    value: '-03:00',
  },
  {
    text: 'America/Paramaribo',
    value: '-03:00',
  },
  {
    text: 'America/Punta_Arenas',
    value: '-03:00',
  },
  {
    text: 'America/Recife',
    value: '-03:00',
  },
  {
    text: 'America/Santarem',
    value: '-03:00',
  },
  {
    text: 'America/Santiago',
    value: '-03:00',
  },
  {
    text: 'America/Sao_Paulo',
    value: '-03:00',
  },
  {
    text: 'Antarctica/Palmer',
    value: '-03:00',
  },
  {
    text: 'Antarctica/Rothera',
    value: '-03:00',
  },
  {
    text: 'Atlantic/Stanley',
    value: '-03:00',
  },
  {
    text: 'America/Noronha',
    value: '-02:00',
  },
  {
    text: 'Atlantic/South_Georgia',
    value: '-02:00',
  },
  {
    text: 'America/Scoresbysund',
    value: '-01:00',
  },
  {
    text: 'Atlantic/Azores',
    value: '-01:00',
  },
  {
    text: 'Atlantic/Cape_Verde',
    value: '-01:00',
  },
  {
    text: 'Africa/Abidjan',
    value: '+00:00',
  },
  {
    text: 'Africa/Accra',
    value: '+00:00',
  },
  {
    text: 'Africa/Bamako',
    value: '+00:00',
  },
  {
    text: 'Africa/Banjul',
    value: '+00:00',
  },
  {
    text: 'Africa/Bissau',
    value: '+00:00',
  },
  {
    text: 'Africa/Conakry',
    value: '+00:00',
  },
  {
    text: 'Africa/Dakar',
    value: '+00:00',
  },
  {
    text: 'Africa/Freetown',
    value: '+00:00',
  },
  {
    text: 'Africa/Lome',
    value: '+00:00',
  },
  {
    text: 'Africa/Monrovia',
    value: '+00:00',
  },
  {
    text: 'Africa/Nouakchott',
    value: '+00:00',
  },
  {
    text: 'Africa/Ouagadougou',
    value: '+00:00',
  },
  {
    text: 'Africa/Sao_Tome',
    value: '+00:00',
  },
  {
    text: 'America/Danmarkshavn',
    value: '+00:00',
  },
  {
    text: 'Antarctica/Troll',
    value: '+00:00',
  },
  {
    text: 'Atlantic/Canary',
    value: '+00:00',
  },
  {
    text: 'Atlantic/Faroe',
    value: '+00:00',
  },
  {
    text: 'Atlantic/Madeira',
    value: '+00:00',
  },
  {
    text: 'Atlantic/Reykjavik',
    value: '+00:00',
  },
  {
    text: 'Atlantic/St_Helena',
    value: '+00:00',
  },
  {
    text: 'Europe/Dublin',
    value: '+00:00',
  },
  {
    text: 'Europe/Guernsey',
    value: '+00:00',
  },
  {
    text: 'Europe/Isle_of_Man',
    value: '+00:00',
  },
  {
    text: 'Europe/Jersey',
    value: '+00:00',
  },
  {
    text: 'Europe/Lisbon',
    value: '+00:00',
  },
  {
    text: 'Europe/London',
    value: '+00:00',
  },
  {
    text: 'UTC',
    value: '+00:00',
  },
  {
    text: 'Africa/Algiers',
    value: '+01:00',
  },
  {
    text: 'Africa/Bangui',
    value: '+01:00',
  },
  {
    text: 'Africa/Brazzaville',
    value: '+01:00',
  },
  {
    text: 'Africa/Casablanca',
    value: '+01:00',
  },
  {
    text: 'Africa/Ceuta',
    value: '+01:00',
  },
  {
    text: 'Africa/Douala',
    value: '+01:00',
  },
  {
    text: 'Africa/El_Aaiun',
    value: '+01:00',
  },
  {
    text: 'Africa/Kinshasa',
    value: '+01:00',
  },
  {
    text: 'Africa/Lagos',
    value: '+01:00',
  },
  {
    text: 'Africa/Libreville',
    value: '+01:00',
  },
  {
    text: 'Africa/Luanda',
    value: '+01:00',
  },
  {
    text: 'Africa/Malabo',
    value: '+01:00',
  },
  {
    text: 'Africa/Ndjamena',
    value: '+01:00',
  },
  {
    text: 'Africa/Niamey',
    value: '+01:00',
  },
  {
    text: 'Africa/Porto-Novo',
    value: '+01:00',
  },
  {
    text: 'Africa/Tunis',
    value: '+01:00',
  },
  {
    text: 'Arctic/Longyearbyen',
    value: '+01:00',
  },
  {
    text: 'Europe/Amsterdam',
    value: '+01:00',
  },
  {
    text: 'Europe/Andorra',
    value: '+01:00',
  },
  {
    text: 'Europe/Belgrade',
    value: '+01:00',
  },
  {
    text: 'Europe/Berlin',
    value: '+01:00',
  },
  {
    text: 'Europe/Bratislava',
    value: '+01:00',
  },
  {
    text: 'Europe/Brussels',
    value: '+01:00',
  },
  {
    text: 'Europe/Budapest',
    value: '+01:00',
  },
  {
    text: 'Europe/Busingen',
    value: '+01:00',
  },
  {
    text: 'Europe/Copenhagen',
    value: '+01:00',
  },
  {
    text: 'Europe/Gibraltar',
    value: '+01:00',
  },
  {
    text: 'Europe/Ljubljana',
    value: '+01:00',
  },
  {
    text: 'Europe/Luxembourg',
    value: '+01:00',
  },
  {
    text: 'Europe/Madrid',
    value: '+01:00',
  },
  {
    text: 'Europe/Malta',
    value: '+01:00',
  },
  {
    text: 'Europe/Monaco',
    value: '+01:00',
  },
  {
    text: 'Europe/Oslo',
    value: '+01:00',
  },
  {
    text: 'Europe/Paris',
    value: '+01:00',
  },
  {
    text: 'Europe/Podgorica',
    value: '+01:00',
  },
  {
    text: 'Europe/Prague',
    value: '+01:00',
  },
  {
    text: 'Europe/Rome',
    value: '+01:00',
  },
  {
    text: 'Europe/San_Marino',
    value: '+01:00',
  },
  {
    text: 'Europe/Sarajevo',
    value: '+01:00',
  },
  {
    text: 'Europe/Skopje',
    value: '+01:00',
  },
  {
    text: 'Europe/Stockholm',
    value: '+01:00',
  },
  {
    text: 'Europe/Tirane',
    value: '+01:00',
  },
  {
    text: 'Europe/Vaduz',
    value: '+01:00',
  },
  {
    text: 'Europe/Vatican',
    value: '+01:00',
  },
  {
    text: 'Europe/Vienna',
    value: '+01:00',
  },
  {
    text: 'Europe/Warsaw',
    value: '+01:00',
  },
  {
    text: 'Europe/Zagreb',
    value: '+01:00',
  },
  {
    text: 'Europe/Zurich',
    value: '+01:00',
  },
  {
    text: 'Africa/Blantyre',
    value: '+02:00',
  },
  {
    text: 'Africa/Bujumbura',
    value: '+02:00',
  },
  {
    text: 'Africa/Cairo',
    value: '+02:00',
  },
  {
    text: 'Africa/Gaborone',
    value: '+02:00',
  },
  {
    text: 'Africa/Harare',
    value: '+02:00',
  },
  {
    text: 'Africa/Johannesburg',
    value: '+02:00',
  },
  {
    text: 'Africa/Khartoum',
    value: '+02:00',
  },
  {
    text: 'Africa/Kigali',
    value: '+02:00',
  },
  {
    text: 'Africa/Lubumbashi',
    value: '+02:00',
  },
  {
    text: 'Africa/Lusaka',
    value: '+02:00',
  },
  {
    text: 'Africa/Maputo',
    value: '+02:00',
  },
  {
    text: 'Africa/Maseru',
    value: '+02:00',
  },
  {
    text: 'Africa/Mbabane',
    value: '+02:00',
  },
  {
    text: 'Africa/Tripoli',
    value: '+02:00',
  },
  {
    text: 'Africa/Windhoek',
    value: '+02:00',
  },
  {
    text: 'Asia/Amman',
    value: '+02:00',
  },
  {
    text: 'Asia/Beirut',
    value: '+02:00',
  },
  {
    text: 'Asia/Damascus',
    value: '+02:00',
  },
  {
    text: 'Asia/Famagusta',
    value: '+02:00',
  },
  {
    text: 'Asia/Gaza',
    value: '+02:00',
  },
  {
    text: 'Asia/Hebron',
    value: '+02:00',
  },
  {
    text: 'Asia/Jerusalem',
    value: '+02:00',
  },
  {
    text: 'Asia/Nicosia',
    value: '+02:00',
  },
  {
    text: 'Europe/Athens',
    value: '+02:00',
  },
  {
    text: 'Europe/Bucharest',
    value: '+02:00',
  },
  {
    text: 'Europe/Chisinau',
    value: '+02:00',
  },
  {
    text: 'Europe/Helsinki',
    value: '+02:00',
  },
  {
    text: 'Europe/Kaliningrad',
    value: '+02:00',
  },
  {
    text: 'Europe/Kiev',
    value: '+02:00',
  },
  {
    text: 'Europe/Mariehamn',
    value: '+02:00',
  },
  {
    text: 'Europe/Riga',
    value: '+02:00',
  },
  {
    text: 'Europe/Sofia',
    value: '+02:00',
  },
  {
    text: 'Europe/Tallinn',
    value: '+02:00',
  },
  {
    text: 'Europe/Uzhgorod',
    value: '+02:00',
  },
  {
    text: 'Europe/Vilnius',
    value: '+02:00',
  },
  {
    text: 'Europe/Zaporozhye',
    value: '+02:00',
  },
  {
    text: 'Africa/Addis_Ababa',
    value: '+03:00',
  },
  {
    text: 'Africa/Asmara',
    value: '+03:00',
  },
  {
    text: 'Africa/Dar_es_Salaam',
    value: '+03:00',
  },
  {
    text: 'Africa/Djibouti',
    value: '+03:00',
  },
  {
    text: 'Africa/Juba',
    value: '+03:00',
  },
  {
    text: 'Africa/Kampala',
    value: '+03:00',
  },
  {
    text: 'Africa/Mogadishu',
    value: '+03:00',
  },
  {
    text: 'Africa/Nairobi',
    value: '+03:00',
  },
  {
    text: 'Antarctica/Syowa',
    value: '+03:00',
  },
  {
    text: 'Asia/Aden',
    value: '+03:00',
  },
  {
    text: 'Asia/Baghdad',
    value: '+03:00',
  },
  {
    text: 'Asia/Bahrain',
    value: '+03:00',
  },
  {
    text: 'Asia/Kuwait',
    value: '+03:00',
  },
  {
    text: 'Asia/Qatar',
    value: '+03:00',
  },
  {
    text: 'Asia/Riyadh',
    value: '+03:00',
  },
  {
    text: 'Europe/Istanbul',
    value: '+03:00',
  },
  {
    text: 'Europe/Kirov',
    value: '+03:00',
  },
  {
    text: 'Europe/Minsk',
    value: '+03:00',
  },
  {
    text: 'Europe/Moscow',
    value: '+03:00',
  },
  {
    text: 'Europe/Simferopol',
    value: '+03:00',
  },
  {
    text: 'Indian/Antananarivo',
    value: '+03:00',
  },
  {
    text: 'Indian/Comoro',
    value: '+03:00',
  },
  {
    text: 'Indian/Mayotte',
    value: '+03:00',
  },
  {
    text: 'Asia/Tehran',
    value: '+03:30',
  },
  {
    text: 'Asia/Baku',
    value: '+04:00',
  },
  {
    text: 'Asia/Dubai',
    value: '+04:00',
  },
  {
    text: 'Asia/Muscat',
    value: '+04:00',
  },
  {
    text: 'Asia/Tbilisi',
    value: '+04:00',
  },
  {
    text: 'Asia/Yerevan',
    value: '+04:00',
  },
  {
    text: 'Europe/Astrakhan',
    value: '+04:00',
  },
  {
    text: 'Europe/Samara',
    value: '+04:00',
  },
  {
    text: 'Europe/Saratov',
    value: '+04:00',
  },
  {
    text: 'Europe/Ulyanovsk',
    value: '+04:00',
  },
  {
    text: 'Europe/Volgograd',
    value: '+04:00',
  },
  {
    text: 'Indian/Mahe',
    value: '+04:00',
  },
  {
    text: 'Indian/Mauritius',
    value: '+04:00',
  },
  {
    text: 'Indian/Reunion',
    value: '+04:00',
  },
  {
    text: 'Asia/Kabul',
    value: '+04:30',
  },
  {
    text: 'Antarctica/Mawson',
    value: '+05:00',
  },
  {
    text: 'Asia/Aqtau',
    value: '+05:00',
  },
  {
    text: 'Asia/Aqtobe',
    value: '+05:00',
  },
  {
    text: 'Asia/Ashgabat',
    value: '+05:00',
  },
  {
    text: 'Asia/Atyrau',
    value: '+05:00',
  },
  {
    text: 'Asia/Dushanbe',
    value: '+05:00',
  },
  {
    text: 'Asia/Karachi',
    value: '+05:00',
  },
  {
    text: 'Asia/Oral',
    value: '+05:00',
  },
  {
    text: 'Asia/Qyzylorda',
    value: '+05:00',
  },
  {
    text: 'Asia/Samarkand',
    value: '+05:00',
  },
  {
    text: 'Asia/Tashkent',
    value: '+05:00',
  },
  {
    text: 'Asia/Yekaterinburg',
    value: '+05:00',
  },
  {
    text: 'Indian/Kerguelen',
    value: '+05:00',
  },
  {
    text: 'Indian/Maldives',
    value: '+05:00',
  },
  {
    text: 'Asia/Colombo',
    value: '+05:30',
  },
  {
    text: 'Asia/Kolkata',
    value: '+05:30',
  },
  {
    text: 'Asia/Kathmandu',
    value: '+05:45',
  },
  {
    text: 'Antarctica/Vostok',
    value: '+06:00',
  },
  {
    text: 'Asia/Almaty',
    value: '+06:00',
  },
  {
    text: 'Asia/Bishkek',
    value: '+06:00',
  },
  {
    text: 'Asia/Dhaka',
    value: '+06:00',
  },
  {
    text: 'Asia/Omsk',
    value: '+06:00',
  },
  {
    text: 'Asia/Qostanay',
    value: '+06:00',
  },
  {
    text: 'Asia/Thimphu',
    value: '+06:00',
  },
  {
    text: 'Asia/Urumqi',
    value: '+06:00',
  },
  {
    text: 'Indian/Chagos',
    value: '+06:00',
  },
  {
    text: 'Asia/Yangon',
    value: '+06:30',
  },
  {
    text: 'Indian/Cocos',
    value: '+06:30',
  },
  {
    text: 'Antarctica/Davis',
    value: '+07:00',
  },
  {
    text: 'Asia/Bangkok',
    value: '+07:00',
  },
  {
    text: 'Asia/Barnaul',
    value: '+07:00',
  },
  {
    text: 'Asia/Ho_Chi_Minh',
    value: '+07:00',
  },
  {
    text: 'Asia/Hovd',
    value: '+07:00',
  },
  {
    text: 'Asia/Jakarta',
    value: '+07:00',
  },
  {
    text: 'Asia/Krasnoyarsk',
    value: '+07:00',
  },
  {
    text: 'Asia/Novokuznetsk',
    value: '+07:00',
  },
  {
    text: 'Asia/Novosibirsk',
    value: '+07:00',
  },
  {
    text: 'Asia/Phnom_Penh',
    value: '+07:00',
  },
  {
    text: 'Asia/Pontianak',
    value: '+07:00',
  },
  {
    text: 'Asia/Tomsk',
    value: '+07:00',
  },
  {
    text: 'Asia/Vientiane',
    value: '+07:00',
  },
  {
    text: 'Indian/Christmas',
    value: '+07:00',
  },
  {
    text: 'Asia/Brunei',
    value: '+08:00',
  },
  {
    text: 'Asia/Choibalsan',
    value: '+08:00',
  },
  {
    text: 'Asia/Hong_Kong',
    value: '+08:00',
  },
  {
    text: 'Asia/Irkutsk',
    value: '+08:00',
  },
  {
    text: 'Asia/Kuala_Lumpur',
    value: '+08:00',
  },
  {
    text: 'Asia/Kuching',
    value: '+08:00',
  },
  {
    text: 'Asia/Macau',
    value: '+08:00',
  },
  {
    text: 'Asia/Makassar',
    value: '+08:00',
  },
  {
    text: 'Asia/Manila',
    value: '+08:00',
  },
  {
    text: 'Asia/Shanghai',
    value: '+08:00',
  },
  {
    text: 'Asia/Singapore',
    value: '+08:00',
  },
  {
    text: 'Asia/Taipei',
    value: '+08:00',
  },
  {
    text: 'Asia/Ulaanbaatar',
    value: '+08:00',
  },
  {
    text: 'Australia/Perth',
    value: '+08:00',
  },
  {
    text: 'Australia/Eucla',
    value: '+08:45',
  },
  {
    text: 'Asia/Chita',
    value: '+09:00',
  },
  {
    text: 'Asia/Dili',
    value: '+09:00',
  },
  {
    text: 'Asia/Jayapura',
    value: '+09:00',
  },
  {
    text: 'Asia/Khandyga',
    value: '+09:00',
  },
  {
    text: 'Asia/Pyongyang',
    value: '+09:00',
  },
  {
    text: 'Asia/Seoul',
    value: '+09:00',
  },
  {
    text: 'Asia/Tokyo',
    value: '+09:00',
  },
  {
    text: 'Asia/Yakutsk',
    value: '+09:00',
  },
  {
    text: 'Pacific/Palau',
    value: '+09:00',
  },
  {
    text: 'Australia/Darwin',
    value: '+09:30',
  },
  {
    text: 'Antarctica/DumontDUrville',
    value: '+10:00',
  },
  {
    text: 'Asia/Ust-Nera',
    value: '+10:00',
  },
  {
    text: 'Asia/Vladivostok',
    value: '+10:00',
  },
  {
    text: 'Australia/Brisbane',
    value: '+10:00',
  },
  {
    text: 'Australia/Lindeman',
    value: '+10:00',
  },
  {
    text: 'Pacific/Chuuk',
    value: '+10:00',
  },
  {
    text: 'Pacific/Guam',
    value: '+10:00',
  },
  {
    text: 'Pacific/Port_Moresby',
    value: '+10:00',
  },
  {
    text: 'Pacific/Saipan',
    value: '+10:00',
  },
  {
    text: 'Australia/Adelaide',
    value: '+10:30',
  },
  {
    text: 'Australia/Broken_Hill',
    value: '+10:30',
  },
  {
    text: 'Antarctica/Casey',
    value: '+11:00',
  },
  {
    text: 'Antarctica/Macquarie',
    value: '+11:00',
  },
  {
    text: 'Asia/Magadan',
    value: '+11:00',
  },
  {
    text: 'Asia/Sakhalin',
    value: '+11:00',
  },
  {
    text: 'Asia/Srednekolymsk',
    value: '+11:00',
  },
  {
    text: 'Australia/Currie',
    value: '+11:00',
  },
  {
    text: 'Australia/Hobart',
    value: '+11:00',
  },
  {
    text: 'Australia/Lord_Howe',
    value: '+11:00',
  },
  {
    text: 'Australia/Melbourne',
    value: '+11:00',
  },
  {
    text: 'Australia/Sydney',
    value: '+11:00',
  },
  {
    text: 'Pacific/Bougainville',
    value: '+11:00',
  },
  {
    text: 'Pacific/Efate',
    value: '+11:00',
  },
  {
    text: 'Pacific/Guadalcanal',
    value: '+11:00',
  },
  {
    text: 'Pacific/Kosrae',
    value: '+11:00',
  },
  {
    text: 'Pacific/Noumea',
    value: '+11:00',
  },
  {
    text: 'Pacific/Pohnpei',
    value: '+11:00',
  },
  {
    text: 'Asia/Anadyr',
    value: '+12:00',
  },
  {
    text: 'Asia/Kamchatka',
    value: '+12:00',
  },
  {
    text: 'Pacific/Fiji',
    value: '+12:00',
  },
  {
    text: 'Pacific/Funafuti',
    value: '+12:00',
  },
  {
    text: 'Pacific/Kwajalein',
    value: '+12:00',
  },
  {
    text: 'Pacific/Majuro',
    value: '+12:00',
  },
  {
    text: 'Pacific/Nauru',
    value: '+12:00',
  },
  {
    text: 'Pacific/Norfolk',
    value: '+12:00',
  },
  {
    text: 'Pacific/Tarawa',
    value: '+12:00',
  },
  {
    text: 'Pacific/Wake',
    value: '+12:00',
  },
  {
    text: 'Pacific/Wallis',
    value: '+12:00',
  },
  {
    text: 'Antarctica/McMurdo',
    value: '+13:00',
  },
  {
    text: 'Pacific/Auckland',
    value: '+13:00',
  },
  {
    text: 'Pacific/Enderbury',
    value: '+13:00',
  },
  {
    text: 'Pacific/Fakaofo',
    value: '+13:00',
  },
  {
    text: 'Pacific/Tongatapu',
    value: '+13:00',
  },
  {
    text: 'Pacific/Chatham',
    value: '+13:45',
  },
  {
    text: 'Pacific/Apia',
    value: '+14:00',
  },
  {
    text: 'Pacific/Kiritimati',
    value: '+14:00',
  },
];

export default TIMEZONES;
