import store from '@/store';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import type IPartner from '@/interfaces/partner/IPartner';

@Module({
  store,
  name: 'Partner',
  namespaced: true,
  dynamic: true,
})
class Partner extends VuexModule {
  private partnerList: IPartner[] = [];

  public get getPartnerList(): IPartner[] {
    return this.partnerList;
  }

  /*******   Set action   *******/

  @Action({ commit: 'UPDATE_PARTNER_LIST' })
  public async SET_PARTNER_LIST(partnerList: IPartner[]): Promise<IPartner[]> {
    return partnerList;
  }

  /*******   Unset action   *******/

  @Action({ commit: 'UPDATE_PARTNER_LIST' })
  public async CLEAR_PAGINATION(): Promise<IPartner[]> {
    return [];
  }

  @Action
  public async CLEAR_STATE(): Promise<void> {
    this.CLEAR_PAGINATION();
  }

  /*******   Update mutation   *******/

  @Mutation
  public async UPDATE_PARTNER_LIST(partnerList: IPartner[]): Promise<IPartner[]> {
    return (this.partnerList = partnerList);
  }
}

const PartnerModule = getModule(Partner);

export default PartnerModule;
