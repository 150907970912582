import { Component, Vue } from 'vue-property-decorator';
import Locale from '@/enums/config/Locale';
import TranslationModule from '@/store/modules/Translation';

@Component
export default class TranslateGenericDayCountMixin extends Vue {
  public TranslateGenericDayCountMixin(num: number | null, locale: Locale = TranslationModule.getLocale): string {
    if (num === null) {
      return '';
    }
    // Get correct RU translations for days
    const isTeens = this.isBetweenNumbers(num, 11, 19);
    const lastDigit = num % 10;

    if (lastDigit === 1 && !isTeens) {
      return `${num} ${this.trans('dates.day', 'Diena', locale)}`;
    } else if (this.isBetweenNumbers(lastDigit, 2, 5) && !isTeens) {
      return `${num} ${this.trans('dates.days.other', 'Dienas', locale)}`;
    } else {
      return `${num} ${this.trans('dates.days', 'Dienas', locale)}`;
    }
  }
}
