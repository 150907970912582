import _Vue from 'vue';

export default function PrototypePlugin(Vue: typeof _Vue): void {
  Vue.prototype.prepareNumber = (value: string | number | null): string | number => {
    const parsedValue = parseFloat((value ?? '').toString());
    return isNaN(parsedValue) || parsedValue === 0 ? '' : parsedValue;
  };
}

declare module 'vue/types/vue' {
  interface Vue {
    prepareNumber(toast: string | number): string | number;
  }
}
