import app from '@/main';
import store from '@/store';
import isEqual from 'lodash-es/isEqual';
import merge from 'lodash-es/merge';
import sortBy from 'lodash-es/sortBy';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import LocalStorage from '@/enums/config/LocalStorage';
import UserRole from '@/enums/config/UserRole';
import DocumentPermission from '@/enums/config/permission/Document';
import type Locale from '@/enums/config/Locale';
import type IUser from '@/interfaces/user/IUser';
import type IUserCompany from '@/interfaces/user/IUserCompany';
import CompanyModule from '@/store/modules/Company';
import AuthRepository from '@/repository/Auth';
import UserRepository from '@/repository/User';

@Module({
  store,
  name: 'User',
  namespaced: true,
  dynamic: true,
})
class User extends VuexModule {
  private user: IUser | null = null;

  public get isLoggedIn(): boolean {
    return !!this.user;
  }

  public get hasCompanies(): boolean {
    const companies = this.user?.companies ?? [];
    return companies.length > 0;
  }

  public get getUserUUID(): string {
    return this.user?.uuid ?? '';
  }

  public get isEmailVerified(): boolean {
    return this.user?.has_verified_email ?? false;
  }

  public get isEmailPending(): boolean {
    return !!this.user?.pending_email;
  }

  public get getUser(): IUser | null {
    return this.user;
  }

  public get getUserEmail(): string {
    return this.user?.email ?? '';
  }

  public get getUserPendingEmail(): string {
    return this.user?.pending_email ?? '';
  }

  public get getUserLocale(): Locale | null {
    return this.user?.locale ?? null;
  }

  public get getFullName(): string {
    return `${this.user?.first_name} ${this.user?.last_name}`;
  }

  public get getCompanies(): IUserCompany[] {
    const companies = this.user?.companies ?? [];
    return sortBy(companies, ['role', UserRole.OWNER]);
  }

  public get getCompanyUUID(): string {
    return this.user?.selected_company_uuid ?? '';
  }

  public get rememberCompany(): boolean {
    return this.user?.remember_company ?? false;
  }

  public get isAdmin(): boolean {
    const selectedCompany = this.user?.companies?.find((company: IUserCompany) => company.selected);
    return [UserRole.OWNER, UserRole.ADMIN].includes(selectedCompany?.role ?? UserRole.USER);
  }

  public get isReadOnly(): boolean {
    const selectedCompany = this.user?.companies?.find((company: IUserCompany) => company.selected);
    const role = selectedCompany?.role ?? UserRole.USER;
    const permissions = selectedCompany?.permissions ?? [];

    return role === UserRole.USER && isEqual([DocumentPermission.VIEW_ALL], sortBy(permissions));
  }

  /*******   Set action   *******/

  @Action({ commit: 'UPDATE_USER' })
  public async SET_USER(): Promise<IUser | null> {
    let user: IUser | null = null;
    try {
      user = (await UserRepository.fetch()).data;

      // Check if user selected company is marked as selected in companies list
      const hasSelectedCompany = user?.companies?.some(
        (company: IUserCompany) => company.uuid === user?.selected_company_uuid && company.selected,
      );
      // Swicth to first company if selected company is not marked as selected
      if (!hasSelectedCompany) {
        const firstCompanyUUID = user?.companies?.[0]?.uuid ?? '';
        if (firstCompanyUUID) {
          await UserRepository.switchCompany(firstCompanyUUID, user?.remember_company ?? false);
          // Fetch user data again
          user = (await UserRepository.fetch()).data;
        } else {
          await CompanyModule.CLEAR_COMPANY();
        }
      }
    } catch (e) {
      app.$logger('unable to fetch user...');
    }
    return user;
  }

  @Action({ commit: 'UPDATE_USER' })
  public async SET_USER_FIELD(user: Partial<IUser>): Promise<IUser | null> {
    if (this.user) {
      return merge(this.user, user);
    }

    return this.user;
  }

  // Logout user from application
  @Action({ commit: 'CLEAR_USER' })
  public async USER_LOGOUT(): Promise<null> {
    try {
      await AuthRepository.logout();
      await AuthRepository.csrfCookie();
      await CompanyModule.CLEAR_COMPANY();
    } catch (e) {
      app.$logger('unable to completely log out user...');
    }
    return null;
  }

  /*******   Update mutation   *******/

  // Remove user from sate
  @Mutation
  public async CLEAR_USER(): Promise<null> {
    localStorage.removeItem(LocalStorage.SWITCH_COMPANY);
    localStorage.removeItem(LocalStorage.INVITATION_HASH);
    return (this.user = null);
  }

  // Update user sate
  @Mutation
  public async UPDATE_USER(user: IUser | null): Promise<IUser | null> {
    return (this.user = user);
  }
}

const UserModule = getModule(User);

export default UserModule;
