import { Component, Mixins } from 'vue-property-decorator';
import CatchError from '@/decorators/CatchError';
import Loading from '@/decorators/Loading';
import AppState from '@/enums/generics/AppState';
import type IPartner from '@/interfaces/partner/IPartner';
import type IPartnerForm from '@/interfaces/forms/partner/IPartnerForm';
import type IPartnerLookupParams from '@/interfaces/forms/partner/IPartnerLookupParams';
import type IPartnerMixin from '@/interfaces/mixins/IPartnerMixin';
import CompanyModule from '@/store/modules/Company';
import PartnerModule from '@/store/modules/Partner';
import PageModule from '@/store/modules/Page';
import CreatePartnerMixin from '@/mixins/partner/Create';
import DeletePartnerMixin from '@/mixins/partner/Delete';
import FetchPartnerMixin from '@/mixins/partner/Fetch';
import ListPartnersMixin from '@/mixins/partner/List';
import ListMultiSourcePartnerMixin from '@/mixins/partner/ListMultiSource';
import UpdatePartnerMixin from '@/mixins/partner/Update';

@Component
export default class ParterMixin extends Mixins<IPartnerMixin>(
  CreatePartnerMixin,
  DeletePartnerMixin,
  FetchPartnerMixin,
  ListPartnersMixin,
  ListMultiSourcePartnerMixin,
  UpdatePartnerMixin,
) {
  // Fetch partners - use ListPartnersMixin
  @Loading()
  @CatchError()
  public async listPartners(companyUUID: string = CompanyModule.getCompanyUUID): Promise<void> {
    const { data, meta } = await this.ListPartnersMixin(companyUUID);

    await PageModule.SET_PAGINATION(meta);
    await PartnerModule.SET_PARTNER_LIST(data);
  }

  // List combined data from partner and business registry database - use ListMultiSourcePartnerMixin
  public async listMultiSourcePartner(
    params: IPartnerLookupParams,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<Partial<IPartner>[]> {
    return await this.ListMultiSourcePartnerMixin(params, companyUUID);
  }

  // Fetch single partner - use FetchPartnerMixin
  @Loading()
  @CatchError()
  public async fetchPartner(
    partnerUUID: string,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<IPartner> {
    return await this.FetchPartnerMixin(partnerUUID, companyUUID);
  }

  // Create new partner - use CreatePartnerMixin
  @Loading()
  @CatchError()
  public async createPartner(payload: IPartnerForm, companyUUID: string = CompanyModule.getCompanyUUID): Promise<void> {
    await this.CreatePartnerMixin(payload, companyUUID);
    await this.listPartners(companyUUID);

    await this.$notifier({
      message: this.trans('notify.success.record_created', 'Ieraksts veiksmīgi izveidots'),
      state: AppState.SUCCESS,
    });
  }

  // Update existing partner - use UpdatePartnerMixin
  @Loading()
  @CatchError()
  public async updatePartner(
    payload: IPartnerForm,
    partnerUUID: string,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<void> {
    await this.UpdatePartnerMixin(payload, partnerUUID, companyUUID);
    await this.listPartners(companyUUID);

    await this.$notifier({
      message: this.trans('notify.success.record_updated', 'Ieraksts veiksmīgi izlabots'),
      state: AppState.SUCCESS,
    });
  }

  // Delete existing partner - use DeletePartnerMixin
  @Loading()
  @CatchError()
  public async deletePartner(partnerUUID: string, companyUUID: string = CompanyModule.getCompanyUUID): Promise<void> {
    await this.DeletePartnerMixin(partnerUUID, companyUUID);
    await this.listPartners(companyUUID);

    await this.$notifier({
      message: this.trans('notify.success.record_deleted', 'Ieraksts veiksmīgi izdzēsts'),
      state: AppState.SUCCESS,
    });
  }
}
