enum CountryISO {
  AA = 'aa',
  AB = 'ab',
  AC = 'ac',
  AD = 'ad',
  AE = 'ae',
  AF = 'af',
  AG = 'ag',
  AH = 'ah',
  AI = 'ai',
  AJ = 'aj',
  AK = 'ak',
  AL = 'al',
  AM = 'am',
  AN = 'an',
  AO = 'ao',
  AP = 'ap',
  AQ = 'aq',
  AR = 'ar',
  AS = 'as',
  AT = 'at',
  AU = 'au',
  AV = 'av',
  AW = 'aw',
  AX = 'ax',
  AY = 'ay',
  AZ = 'az',
  BA = 'ba',
  BB = 'bb',
  BC = 'bc',
  BD = 'bd',
  BE = 'be',
  BF = 'bf',
  BG = 'bg',
  BH = 'bh',
  BI = 'bi',
  BJ = 'bj',
  BK = 'bk',
  BL = 'bl',
  BM = 'bm',
  BN = 'bn',
  BO = 'bo',
  BP = 'bp',
  BQ = 'bq',
  BR = 'br',
  BS = 'bs',
  BT = 'bt',
  BU = 'bu',
  BV = 'bv',
  BW = 'bw',
  BX = 'bx',
  BY = 'by',
  BZ = 'bz',
  CA = 'ca',
  CB = 'cb',
  CC = 'cc',
  CD = 'cd',
  CE = 'ce',
  CF = 'cf',
  CG = 'cg',
  CH = 'ch',
  CI = 'ci',
  CJ = 'cj',
  CK = 'ck',
  CL = 'cl',
  CM = 'cm',
  CN = 'cn',
  CO = 'co',
  CP = 'cp',
  CQ = 'cq',
  CR = 'cr',
  CS = 'cs',
  CT = 'ct',
  CU = 'cu',
  CV = 'cv',
  CW = 'cw',
  CX = 'cx',
  CY = 'cy',
  CZ = 'cz',
  DA = 'da',
  DB = 'db',
  DC = 'dc',
  DD = 'dd',
  DE = 'de',
  DF = 'df',
  DG = 'dg',
  DH = 'dh',
  DI = 'di',
  DJ = 'dj',
  DK = 'dk',
  DL = 'dl',
  DM = 'dm',
  DN = 'dn',
  DO = 'do',
  DP = 'dp',
  DQ = 'dq',
  DR = 'dr',
  DS = 'ds',
  DT = 'dt',
  DU = 'du',
  DV = 'dv',
  DW = 'dw',
  DX = 'dx',
  DY = 'dy',
  DZ = 'dz',
  EA = 'ea',
  EB = 'eb',
  EC = 'ec',
  ED = 'ed',
  EE = 'ee',
  EF = 'ef',
  EG = 'eg',
  EH = 'eh',
  EI = 'ei',
  EJ = 'ej',
  EK = 'ek',
  EL = 'el',
  EM = 'em',
  EN = 'en',
  EO = 'eo',
  EP = 'ep',
  EQ = 'eq',
  ER = 'er',
  ES = 'es',
  ET = 'et',
  EU = 'eu',
  EV = 'ev',
  EW = 'ew',
  EX = 'ex',
  EY = 'ey',
  EZ = 'ez',
  FA = 'fa',
  FB = 'fb',
  FC = 'fc',
  FD = 'fd',
  FE = 'fe',
  FF = 'ff',
  FG = 'fg',
  FH = 'fh',
  FI = 'fi',
  FJ = 'fj',
  FK = 'fk',
  FL = 'fl',
  FM = 'fm',
  FN = 'fn',
  FO = 'fo',
  FP = 'fp',
  FQ = 'fq',
  FR = 'fr',
  FS = 'fs',
  FT = 'ft',
  FU = 'fu',
  FV = 'fv',
  FW = 'fw',
  FX = 'fx',
  FY = 'fy',
  FZ = 'fz',
  GA = 'ga',
  GB = 'gb',
  GC = 'gc',
  GD = 'gd',
  GE = 'ge',
  GF = 'gf',
  GG = 'gg',
  GH = 'gh',
  GI = 'gi',
  GJ = 'gj',
  GK = 'gk',
  GL = 'gl',
  GM = 'gm',
  GN = 'gn',
  GO = 'go',
  GP = 'gp',
  GQ = 'gq',
  GR = 'gr',
  GS = 'gs',
  GT = 'gt',
  GU = 'gu',
  GV = 'gv',
  GW = 'gw',
  GX = 'gx',
  GY = 'gy',
  GZ = 'gz',
  HA = 'ha',
  HB = 'hb',
  HC = 'hc',
  HD = 'hd',
  HE = 'he',
  HF = 'hf',
  HG = 'hg',
  HH = 'hh',
  HI = 'hi',
  HJ = 'hj',
  HK = 'hk',
  HL = 'hl',
  HM = 'hm',
  HN = 'hn',
  HO = 'ho',
  HP = 'hp',
  HQ = 'hq',
  HR = 'hr',
  HS = 'hs',
  HT = 'ht',
  HU = 'hu',
  HV = 'hv',
  HW = 'hw',
  HX = 'hx',
  HY = 'hy',
  HZ = 'hz',
  IA = 'ia',
  IB = 'ib',
  IC = 'ic',
  ID = 'id',
  IE = 'ie',
  IF = 'if',
  IG = 'ig',
  IH = 'ih',
  II = 'ii',
  IJ = 'ij',
  IK = 'ik',
  IL = 'il',
  IM = 'im',
  IN = 'in',
  IO = 'io',
  IP = 'ip',
  IQ = 'iq',
  IR = 'ir',
  IS = 'is',
  IT = 'it',
  IU = 'iu',
  IV = 'iv',
  IW = 'iw',
  IX = 'ix',
  IY = 'iy',
  IZ = 'iz',
  JA = 'ja',
  JB = 'jb',
  JC = 'jc',
  JD = 'jd',
  JE = 'je',
  JF = 'jf',
  JG = 'jg',
  JH = 'jh',
  JI = 'ji',
  JJ = 'jj',
  JK = 'jk',
  JL = 'jl',
  JM = 'jm',
  JN = 'jn',
  JO = 'jo',
  JP = 'jp',
  JQ = 'jq',
  JR = 'jr',
  JS = 'js',
  JT = 'jt',
  JU = 'ju',
  JV = 'jv',
  JW = 'jw',
  JX = 'jx',
  JY = 'jy',
  JZ = 'jz',
  KA = 'ka',
  KB = 'kb',
  KC = 'kc',
  KD = 'kd',
  KE = 'ke',
  KF = 'kf',
  KG = 'kg',
  KH = 'kh',
  KI = 'ki',
  KJ = 'kj',
  KK = 'kk',
  KL = 'kl',
  KM = 'km',
  KN = 'kn',
  KO = 'ko',
  KP = 'kp',
  KQ = 'kq',
  KR = 'kr',
  KS = 'ks',
  KT = 'kt',
  KU = 'ku',
  KV = 'kv',
  KW = 'kw',
  KX = 'kx',
  KY = 'ky',
  KZ = 'kz',
  LA = 'la',
  LB = 'lb',
  LC = 'lc',
  LD = 'ld',
  LE = 'le',
  LF = 'lf',
  LG = 'lg',
  LH = 'lh',
  LI = 'li',
  LJ = 'lj',
  LK = 'lk',
  LL = 'll',
  LM = 'lm',
  LN = 'ln',
  LO = 'lo',
  LP = 'lp',
  LQ = 'lq',
  LR = 'lr',
  LS = 'ls',
  LT = 'lt',
  LU = 'lu',
  LV = 'lv',
  LW = 'lw',
  LX = 'lx',
  LY = 'ly',
  LZ = 'lz',
  MA = 'ma',
  MB = 'mb',
  MC = 'mc',
  MD = 'md',
  ME = 'me',
  MF = 'mf',
  MG = 'mg',
  MH = 'mh',
  MI = 'mi',
  MJ = 'mj',
  MK = 'mk',
  ML = 'ml',
  MM = 'mm',
  MN = 'mn',
  MO = 'mo',
  MP = 'mp',
  MQ = 'mq',
  MR = 'mr',
  MS = 'ms',
  MT = 'mt',
  MU = 'mu',
  MV = 'mv',
  MW = 'mw',
  MX = 'mx',
  MY = 'my',
  MZ = 'mz',
  NA = 'na',
  NB = 'nb',
  NC = 'nc',
  ND = 'nd',
  NE = 'ne',
  NF = 'nf',
  NG = 'ng',
  NH = 'nh',
  NI = 'ni',
  NJ = 'nj',
  NK = 'nk',
  NL = 'nl',
  NM = 'nm',
  NN = 'nn',
  NO = 'no',
  NP = 'np',
  NQ = 'nq',
  NR = 'nr',
  NS = 'ns',
  NT = 'nt',
  NU = 'nu',
  NV = 'nv',
  NW = 'nw',
  NX = 'nx',
  NY = 'ny',
  NZ = 'nz',
  OA = 'oa',
  OB = 'ob',
  OC = 'oc',
  OD = 'od',
  OE = 'oe',
  OF = 'of',
  OG = 'og',
  OH = 'oh',
  OI = 'oi',
  OJ = 'oj',
  OK = 'ok',
  OL = 'ol',
  OM = 'om',
  ON = 'on',
  OO = 'oo',
  OP = 'op',
  OQ = 'oq',
  OR = 'or',
  OS = 'os',
  OT = 'ot',
  OU = 'ou',
  OV = 'ov',
  OW = 'ow',
  OX = 'ox',
  OY = 'oy',
  OZ = 'oz',
  PA = 'pa',
  PB = 'pb',
  PC = 'pc',
  PD = 'pd',
  PE = 'pe',
  PF = 'pf',
  PG = 'pg',
  PH = 'ph',
  PI = 'pi',
  PJ = 'pj',
  PK = 'pk',
  PL = 'pl',
  PM = 'pm',
  PN = 'pn',
  PO = 'po',
  PP = 'pp',
  PQ = 'pq',
  PR = 'pr',
  PS = 'ps',
  PT = 'pt',
  PU = 'pu',
  PV = 'pv',
  PW = 'pw',
  PX = 'px',
  PY = 'py',
  PZ = 'pz',
  QA = 'qa',
  QB = 'qb',
  QC = 'qc',
  QD = 'qd',
  QE = 'qe',
  QF = 'qf',
  QG = 'qg',
  QH = 'qh',
  QI = 'qi',
  QJ = 'qj',
  QK = 'qk',
  QL = 'ql',
  QM = 'qm',
  QN = 'qn',
  QO = 'qo',
  QP = 'qp',
  QQ = 'qq',
  QR = 'qr',
  QS = 'qs',
  QT = 'qt',
  QU = 'qu',
  QV = 'qv',
  QW = 'qw',
  QX = 'qx',
  QY = 'qy',
  QZ = 'qz',
  RA = 'ra',
  RB = 'rb',
  RC = 'rc',
  RD = 'rd',
  RE = 're',
  RF = 'rf',
  RG = 'rg',
  RH = 'rh',
  RI = 'ri',
  RJ = 'rj',
  RK = 'rk',
  RL = 'rl',
  RM = 'rm',
  RN = 'rn',
  RO = 'ro',
  RP = 'rp',
  RQ = 'rq',
  RR = 'rr',
  RS = 'rs',
  RT = 'rt',
  RU = 'ru',
  RV = 'rv',
  RW = 'rw',
  RX = 'rx',
  RY = 'ry',
  RZ = 'rz',
  SA = 'sa',
  SB = 'sb',
  SC = 'sc',
  SD = 'sd',
  SE = 'se',
  SF = 'sf',
  SG = 'sg',
  SH = 'sh',
  SI = 'si',
  SJ = 'sj',
  SK = 'sk',
  SL = 'sl',
  SM = 'sm',
  SN = 'sn',
  SO = 'so',
  SP = 'sp',
  SQ = 'sq',
  SR = 'sr',
  SS = 'ss',
  ST = 'st',
  SU = 'su',
  SV = 'sv',
  SW = 'sw',
  SX = 'sx',
  SY = 'sy',
  SZ = 'sz',
  TA = 'ta',
  TB = 'tb',
  TC = 'tc',
  TD = 'td',
  TE = 'te',
  TF = 'tf',
  TG = 'tg',
  TH = 'th',
  TI = 'ti',
  TJ = 'tj',
  TK = 'tk',
  TL = 'tl',
  TM = 'tm',
  TN = 'tn',
  TO = 'to',
  TP = 'tp',
  TQ = 'tq',
  TR = 'tr',
  TS = 'ts',
  TT = 'tt',
  TU = 'tu',
  TV = 'tv',
  TW = 'tw',
  TX = 'tx',
  TY = 'ty',
  TZ = 'tz',
  UA = 'ua',
  UB = 'ub',
  UC = 'uc',
  UD = 'ud',
  UE = 'ue',
  UF = 'uf',
  UG = 'ug',
  UH = 'uh',
  UI = 'ui',
  UJ = 'uj',
  UK = 'uk',
  UL = 'ul',
  UM = 'um',
  UN = 'un',
  UO = 'uo',
  UP = 'up',
  UQ = 'uq',
  UR = 'ur',
  US = 'us',
  UT = 'ut',
  UU = 'uu',
  UV = 'uv',
  UW = 'uw',
  UX = 'ux',
  UY = 'uy',
  UZ = 'uz',
  VA = 'va',
  VB = 'vb',
  VC = 'vc',
  VD = 'vd',
  VE = 've',
  VF = 'vf',
  VG = 'vg',
  VH = 'vh',
  VI = 'vi',
  VJ = 'vj',
  VK = 'vk',
  VL = 'vl',
  VM = 'vm',
  VN = 'vn',
  VO = 'vo',
  VP = 'vp',
  VQ = 'vq',
  VR = 'vr',
  VS = 'vs',
  VT = 'vt',
  VU = 'vu',
  VV = 'vv',
  VW = 'vw',
  VX = 'vx',
  VY = 'vy',
  VZ = 'vz',
  WA = 'wa',
  WB = 'wb',
  WC = 'wc',
  WD = 'wd',
  WE = 'we',
  WF = 'wf',
  WG = 'wg',
  WH = 'wh',
  WI = 'wi',
  WJ = 'wj',
  WK = 'wk',
  WL = 'wl',
  WM = 'wm',
  WN = 'wn',
  WO = 'wo',
  WP = 'wp',
  WQ = 'wq',
  WR = 'wr',
  WS = 'ws',
  WT = 'wt',
  WU = 'wu',
  WV = 'wv',
  WW = 'ww',
  WX = 'wx',
  WY = 'wy',
  WZ = 'wz',
  XA = 'xa',
  XB = 'xb',
  XC = 'xc',
  XD = 'xd',
  XE = 'xe',
  XF = 'xf',
  XG = 'xg',
  XH = 'xh',
  XI = 'xi',
  XJ = 'xj',
  XK = 'xk',
  XL = 'xl',
  XM = 'xm',
  XN = 'xn',
  XO = 'xo',
  XP = 'xp',
  XQ = 'xq',
  XR = 'xr',
  XS = 'xs',
  XT = 'xt',
  XU = 'xu',
  XV = 'xv',
  XW = 'xw',
  XX = 'xx',
  XY = 'xy',
  XZ = 'xz',
  YA = 'ya',
  YB = 'yb',
  YC = 'yc',
  YD = 'yd',
  YE = 'ye',
  YF = 'yf',
  YG = 'yg',
  YH = 'yh',
  YI = 'yi',
  YJ = 'yj',
  YK = 'yk',
  YL = 'yl',
  YM = 'ym',
  YN = 'yn',
  YO = 'yo',
  YP = 'yp',
  YQ = 'yq',
  YR = 'yr',
  YS = 'ys',
  YT = 'yt',
  YU = 'yu',
  YV = 'yv',
  YW = 'yw',
  YX = 'yx',
  YY = 'yy',
  YZ = 'yz',
  ZA = 'za',
  ZB = 'zb',
  ZC = 'zc',
  ZD = 'zd',
  ZE = 'ze',
  ZF = 'zf',
  ZG = 'zg',
  ZH = 'zh',
  ZI = 'zi',
  ZJ = 'zj',
  ZK = 'zk',
  ZL = 'zl',
  ZM = 'zm',
  ZN = 'zn',
  ZO = 'zo',
  ZP = 'zp',
  ZQ = 'zq',
  ZR = 'zr',
  ZS = 'zs',
  ZT = 'zt',
  ZU = 'zu',
  ZV = 'zv',
  ZW = 'zw',
  ZX = 'zx',
  ZY = 'zy',
  ZZ = 'zz',
}

export default CountryISO;
