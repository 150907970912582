import store from '@/store';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import type IBank from '@/interfaces/bank/IBank';

@Module({
  store,
  name: 'Bank',
  namespaced: true,
  dynamic: true,
})
class Bank extends VuexModule {
  private banks: IBank[] = [];

  public get getBanks(): IBank[] {
    return this.banks;
  }

  public get hasNoBanks(): boolean {
    return this.banks.length === 0;
  }

  /*******   Set action   *******/

  @Action({ commit: 'UPDATE_BANKS' })
  public async SET_BANKS(banks: IBank[]): Promise<IBank[]> {
    return banks;
  }

  /*******   Unset action   *******/

  @Action({ commit: 'UPDATE_BANKS' })
  public async CLEAR_BANKS(): Promise<IBank[]> {
    return [];
  }

  /*******   Update mutation   *******/

  @Mutation
  public async UPDATE_BANKS(banks: IBank[]): Promise<IBank[]> {
    return (this.banks = banks);
  }
}

const BankModule = getModule(Bank);

export default BankModule;
