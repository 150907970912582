enum DocumentRoute {
  INVOICE = 'invoice',
  WAYBILL = 'waybill',
  ADVANCE_INVOICE = 'advance_invoice',
  OFFER = 'offer',
  FINANCIAL = 'financial',
  ALL = 'all',
}

export default DocumentRoute;
