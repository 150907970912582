<template>
  <v-hover v-slot="{ hover }" :disabled="disabled">
    <div
      @dragover="setActive"
      @dragleave="setInactive"
      @drop="dropFiles"
      class="relative h-full w-full border-2 border-dashed transition-all bg-white"
      :class="[
        showErrorBorder ? 'field-error' : (isActive || hover) && !disabled ? 'border-granite' : 'border-silver',
        { 'rounded-lg': rounded },
      ]"
    >
      <slot :active="isActive" :hover="hover" />
    </div>
  </v-hover>
</template>

<script lang="ts">
  import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

  @Component
  export default class BaseDragNDrop extends Vue {
    @Prop({ default: '' }) accept!: string;
    @Prop({ type: Boolean }) multiple!: boolean;
    @Prop({ type: Boolean }) disabled!: boolean;
    @Prop({ type: Boolean }) rounded!: boolean;
    @Prop({ type: Boolean }) showErrorBorder?: boolean;

    private active = false;
    private inActiveTimeout?: NodeJS.Timeout;

    private events = ['dragover', 'dragleave', 'drop'];

    /*****         computed       *****/

    public get isActive(): boolean {
      return this.active && !this.disabled;
    }
    /*****         watchers       *****/
    /*****         methods        *****/

    public setActive(): void {
      this.active = true;
      clearTimeout(this.inActiveTimeout);
    }

    public setInactive(): void {
      this.inActiveTimeout = setTimeout(() => {
        this.active = false;
      }, 0);
    }

    // This code sometimes doesn't work in chromium browsers
    // https://vikky.dev/dev-tools-drop-event-bug
    public async dropFiles(e: DragEvent): Promise<void> {
      this.setInactive();
      e.preventDefault();

      if (!this.disabled) {
        const files: DataTransfer = new DataTransfer();

        if (e.dataTransfer!.items) {
          Array.from(e.dataTransfer!.items).forEach((item, i) => {
            if (item.kind === 'file') {
              const file = item.getAsFile();
              if (file && this.isAcceptable(file) && this.isMultiple(i)) {
                files.items.add(file);
              }
            }
          });
        } else {
          Array.from(e.dataTransfer!.files).forEach((file, i) => {
            if (file && this.isAcceptable(file) && this.isMultiple(i)) {
              files.items.add(file);
            }
          });
        }

        this.drop(files.files);
      }
    }

    @Emit('drop')
    private drop(files: FileList): FileList {
      return files;
    }

    /*****         helpers        *****/

    private isMultiple(idx: number): boolean {
      return (this.multiple && idx > 0) || idx === 0;
    }

    private isAcceptable(file: File): boolean {
      if (this.accept) {
        const fileExtension = file.name.split('.').pop();
        const accept = this.accept.split(',');
        return accept.some((type) => type.replace('.', '') === fileExtension || file.type.includes(type));
      }
      return !!file.type;
    }

    private preventDefaults(e: Event): void {
      e.preventDefault();
    }

    // This helps to reduce drop event failure in chromium browsers
    // https://stackoverflow.com/questions/21339924/drop-event-not-firing-in-chrome/36207613#36207613
    private preventDefaultForDragOver(e: DragEvent): void {
      e.preventDefault();
      e.dataTransfer!.dropEffect = 'copy';
    }

    /*****      vue lifecycle     *****/

    mounted() {
      this.events.forEach((eventName) => {
        if (eventName === 'dragover') {
          document.body.addEventListener(eventName, this.preventDefaultForDragOver);
        } else {
          document.body.addEventListener(eventName, this.preventDefaults);
        }
      });
    }

    beforeDestroy() {
      this.events.forEach((eventName) => {
        if (eventName === 'dragover') {
          document.body.removeEventListener(eventName, this.preventDefaultForDragOver);
        } else {
          document.body.removeEventListener(eventName, this.preventDefaults);
        }
      });
    }
  }
</script>
