import { RouteConfig } from 'vue-router';
import AuthRouteName from '@/enums/config/router/Auth';
import PasswordForgot from '@/pages/auth/PasswordForgot.vue';

const PasswordForgotRouter: Array<RouteConfig> = [
  {
    path: 'forgot-password',
    name: AuthRouteName.PASSWORD_FORGOT,
    component: PasswordForgot,
    meta: {
      title: 'Forgot password',
    },
  },
];

export default PasswordForgotRouter;
