<template>
  <LabeledBaseAutocomplete
    v-model="formValue"
    :items="countries"
    :map-item="{ text: 'text', value: 'value' }"
    :label="trans('workspace.title.country', 'Valsts')"
    :placeholder="trans('workspace.title.country', 'Valsts')"
    :autocomplete="autocomplete"
    :show-label="showLabel"
    :show-error="showError"
    :show-border-error="showErrorBorder"
    :prepare-input="prepareInput"
    :input-class="inputClass"
    :clearable="clearable"
    :overflow="overflow"
    :disabled="disabled"
    :loading="loading"
    :debounce-validation="debounceValidation"
    :rules="rules"
    :name="name"
    :dark="dark"
    filterable
    use-search
    readonly
    rounded
    class="w-full"
    @focus="focus"
    @blur="blur"
    @open="open"
    @close="close"
    @input:debounce="inputDebounce"
    @query="query"
    @set="set"
    @clear="clear"
    @keydown="keydown"
    @resize="resizeCompleted"
    @error="error"
  >
    <template #prepend>
      <img
        v-if="showFlag"
        :src="selectedCountry.image"
        :alt="selectedCountry.text.toString()"
        loading="lazy"
        height="28"
        width="28"
        class="object-cover rounded-circle cultured mr-2"
        @error="setPlaceholderImage"
      />
    </template>
    <template #list-item="{ item, text }">
      <div class="flex items-center justify-start gap-2">
        <img
          :src="item.image"
          :alt="item.text"
          loading="lazy"
          height="28"
          width="28"
          class="object-cover rounded-circle cultured"
          @error="setPlaceholderImage"
        />
        <div v-text="text" class="text-body-2 truncate leading-none" />
      </div>
    </template>
  </LabeledBaseAutocomplete>
</template>

<script lang="ts">
  import { Component, Emit, Prop, VModel, Vue } from 'vue-property-decorator';
  import DEFAULT_SELECT from '@/constants/mocks/Select';
  import type ISelect from '@/interfaces/config/ISelect';
  import UtilModule from '@/store/modules/Util';
  import LabeledBaseAutocomplete from '@/components/global/inputs/autocomplete/LabeledBase.vue';

  @Component({
    components: {
      LabeledBaseAutocomplete,
    },
  })
  export default class SelectCountry extends Vue {
    @VModel({ default: null }) formValue!: string | number | null;

    @Prop({ default: 'transaction-currency' }) autocomplete?: boolean;
    @Prop({ default: true }) showLabel?: boolean;
    @Prop({ default: true }) showError?: boolean;
    @Prop({ default: true }) showErrorBorder?: boolean;
    @Prop({ type: Boolean }) showFlag?: boolean;
    @Prop({ default: '' }) name?: string;

    @Prop({ default: 0 }) debounceValidation?: number;
    @Prop({ default: '' }) rules?: string;

    @Prop({ type: Boolean }) clearable?: boolean;
    @Prop({ type: Boolean }) loading?: boolean;
    @Prop({ type: Boolean }) disabled?: boolean;

    @Prop({ type: Boolean }) hideSelected?: boolean;
    @Prop({ type: Boolean }) overflow?: boolean;
    @Prop({ type: Boolean }) dark?: boolean;

    @Prop() prepareInput?: (value: typeof this.formValue) => typeof value;

    @Prop() inputClass?: string;

    /*****         computed       *****/

    public get countries(): ISelect[] {
      return UtilModule.selectCountries;
    }

    public get selectedCountry(): ISelect {
      return this.countries.find((i) => i.value === this.formValue) ?? DEFAULT_SELECT;
    }

    /*****         watchers       *****/
    /*****         methods        *****/

    @Emit('focus')
    public focus(): void {}

    @Emit('blur')
    public blur(): void {}

    @Emit('open')
    public open(): void {}

    @Emit('close')
    public close(): void {}

    @Emit('input:debounce')
    public inputDebounce(userInput: string): string {
      return userInput;
    }

    @Emit('query')
    public query(query: string): string {
      return query;
    }

    @Emit('set')
    public set(item: any): any {
      return item;
    }

    @Emit('clear')
    public clear(): void {}

    @Emit('keydown')
    public keydown(keyCode: string): string {
      return keyCode;
    }

    @Emit('resize')
    public resizeCompleted(): void {}

    @Emit('error')
    public error(error: string): string {
      return error;
    }

    /*****         helpers        *****/

    public setPlaceholderImage(event: Event) {
      (event.target as HTMLImageElement).src = DEFAULT_SELECT.image ?? '';
      (event.target as HTMLImageElement).alt = DEFAULT_SELECT.text.toString();
    }

    /*****      vue lifecycle     *****/
  }
</script>
