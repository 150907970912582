enum ItemsCell {
  NAME = 'name',
  DESCRIPTION = 'description',
  MEASUREMENT = 'measurement',
  QUANTITY = 'quantity',
  PRICE = 'price',
  PRICE_WITH_VAT = 'price_with_vat',
  DISCOUNT = 'discount',
  VAT_PERCENT = 'vat_percent',
  // Serves for one cell in the table as total
  TOTAL_WITH_VAT = 'total_with_vat',
  TOTAL_WITHOUT_VAT = 'total_without_vat',
}

export default ItemsCell;
