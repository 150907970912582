import { render, staticRenderFns } from "./ShareDocumentDownload.vue?vue&type=template&id=b6c040e0"
import script from "./ShareDocumentDownload.vue?vue&type=script&lang=ts"
export * from "./ShareDocumentDownload.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports