import { ObjectDirective } from 'vue';
import { DirectiveBinding } from 'vue/types/options';

const WatchDirective: ObjectDirective = {
  inserted(el: HTMLElement, binding: DirectiveBinding): void {
    const inputElement = el.querySelector('input') || el.querySelector('textarea');

    if (inputElement) {
      inputElement.addEventListener('focus', () => {
        if (binding.modifiers.limit) {
          inputElement.setAttribute('maxlength', binding.value);
        }
      });
    }
  },
};

export default WatchDirective;
