<template>
  <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.6667 25V22.3333C22.6667 20.9188 22.1048 19.5623 21.1046 18.5621C20.1044 17.5619 18.7479 17 17.3334 17H6.66671C5.25222 17 3.89567 17.5619 2.89547 18.5621C1.89528 19.5623 1.33337 20.9188 1.33337 22.3333V25"
      stroke-linejoin="round"
      stroke-linecap="round"
      stroke-width="1.5"
      stroke="currentColor"
    />
    <path
      d="M12 11.6667C14.9456 11.6667 17.3334 9.27885 17.3334 6.33333C17.3334 3.38781 14.9456 1 12 1C9.05452 1 6.66671 3.38781 6.66671 6.33333C6.66671 9.27885 9.05452 11.6667 12 11.6667Z"
      stroke-linejoin="round"
      stroke-linecap="round"
      stroke-width="1.5"
      stroke="currentColor"
    />
  </svg>
</template>

<script lang="ts">
  import { Component, Vue } from 'vue-property-decorator';

  @Component
  export default class UserIcon extends Vue {
    /*****         computed       *****/
    /*****         watchers       *****/
    /*****         methods        *****/
    /*****         helpers        *****/
    /*****      vue lifecycle     *****/
  }
</script>
