import _Vue from 'vue';
import Trigger from '@/enums/generics/Trigger';
import type ITriggerConfig from '@/interfaces/config/ITriggerConfig';
import type IDocumentPermissions from '@/interfaces/document/IDocumentPermissions';
import UserModule from '@/store/modules/User';

function permission(permissions: IDocumentPermissions): {
  create: boolean;
  edit: boolean;
  remove: boolean;
  admin: boolean;
} {
  const isAdmin = UserModule.isAdmin;
  const isReadOnly = UserModule.isReadOnly;

  // Permission levels
  const create = isAdmin || (!permissions.edit && !isReadOnly);
  const edit = isAdmin || (permissions.edit && !isReadOnly);
  const remove = isAdmin || (permissions.delete && !isReadOnly);
  const admin = isAdmin;

  return { create, edit, remove, admin };
}

export default function PermissionPlugin(Vue: typeof _Vue): void {
  Vue.prototype.isAllowedTo = (
    permissions: IDocumentPermissions,
  ): { create: boolean; edit: boolean; remove: boolean } => {
    return permission(permissions);
  };

  Vue.prototype.allow = (triggers: ITriggerConfig[], permissions: IDocumentPermissions): ITriggerConfig[] => {
    const allow = permission(permissions);

    // Allowed triggers per permission level
    const view = [
      Trigger.VIEW,
      Trigger.SORT,
      Trigger.FILTER,
      Trigger.PRINT,
      Trigger.DOWNLOAD,
      Trigger.EINVOICE,
      Trigger.SHARE,
      Trigger.SEND,
    ];
    const edit = allow.edit
      ? [Trigger.CREATE, Trigger.EDIT, Trigger.SAVE, Trigger.SET, Trigger.COPY, Trigger.PAYMENTS, Trigger.CONVERT]
      : [];
    const viewCreate = allow.create ? [Trigger.COPY, Trigger.CREATE] : [];
    const remove = allow.remove ? [Trigger.DELETE] : [];
    const admin = allow.admin ? [Trigger.RECURRING] : [];

    // Concatenate all allowed triggers
    const allowTriggers: (Trigger | string)[] = [...view, ...edit, ...viewCreate, ...remove, ...admin].filter(
      (trigger) => (allow.edit ? trigger != Trigger.VIEW : true),
    );

    // Filter triggers
    return triggers.filter((trigger: ITriggerConfig) => allowTriggers.includes(trigger.id.split('::')[0]));
  };
}

declare module 'vue/types/vue' {
  interface Vue {
    isAllowedTo(permissions: IDocumentPermissions): { create: boolean; edit: boolean; remove: boolean };
    allow(triggers: ITriggerConfig[], permissions: IDocumentPermissions): ITriggerConfig[];
  }
}
