import store from '@/store';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({
  store,
  name: 'Error',
  namespaced: true,
  dynamic: true,
})
class Error extends VuexModule {
  private error: any = null;
  private interceptorError: any = null;

  public get getError(): any {
    return this.error ?? null;
  }

  public get getInterceptorError(): any {
    return this.interceptorError ?? null;
  }

  /*******   Set action   *******/

  @Action({ commit: 'UPDATE_ERROR' })
  public async SET_ERROR(error: unknown): Promise<unknown> {
    return error;
  }

  @Action({ commit: 'UPDATE_INTERCEPTOR_ERROR' })
  public async SET_INTERCEPTOR_ERROR(interceptorError: unknown): Promise<unknown> {
    return interceptorError;
  }

  /*******   Unset action   *******/

  @Action({ commit: 'UPDATE_ERROR' })
  public async CLEAR_ERROR(): Promise<null> {
    return null;
  }

  @Action({ commit: 'UPDATE_INTERCEPTOR_ERROR' })
  public async CLEAR_INTERCEPTOR_ERROR(): Promise<null> {
    return null;
  }

  /*******   Update mutation   *******/

  @Mutation
  public async UPDATE_ERROR(error: unknown): Promise<unknown> {
    return (this.error = error);
  }

  @Mutation
  public async UPDATE_INTERCEPTOR_ERROR(interceptorError: unknown): Promise<unknown> {
    return (this.interceptorError = interceptorError);
  }
}

const ErrorModule = getModule(Error);

export default ErrorModule;
