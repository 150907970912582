enum VatCode {
  AE = 'AE', // VAT Reverse Charge
  Z = 'Z', // Zero rated goods
  S = 'S', // Standard Rated
  G = 'G', // Free export item, VAT not charged
  E = 'E', // Exempt from Tax (taxes are not applicable)

  // Rarely used
  O = 'O', // Service Outside the Scope of VAT
  K = 'K', // VAT exempt for EEA intra-community supply of goods and services

  // Not commonly used
  L = 'L', // Canary Islands general indirect tax
  M = 'M', // Tax for production, services and importation in Ceuta and Melilla
  B = 'B', // Transferred (VAT), In Italy
}

export default VatCode;
