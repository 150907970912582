import { Component, Vue } from 'vue-property-decorator';
import Locale from '@/enums/config/Locale';
import SignatureType from '@/enums/generics/SignatureType';
import TranslationModule from '@/store/modules/Translation';

@Component
export default class TranslateSigantureTypeMixin extends Vue {
  public TranslateSigantureTypeMixin(type: SignatureType, locale: Locale = TranslationModule.getLocale): string {
    switch (type) {
      case SignatureType.DIGITALLY_SIGNED:
        return this.trans('document.signature.digitally_signed', 'Derīgs bez paraksta', locale);
      case SignatureType.BOTH_SIGNATURES:
        return this.trans('document.signature.both_signatures', 'Abi paraksti', locale);
      case SignatureType.ESIGNATURE:
        return this.trans('document.signature.esignature_signed', 'Parakstīts ar e-parakstu', locale);
      case SignatureType.SENDER_SIGNATURE:
        return this.trans('document.signature.sender_signature', 'Tikai dokumenta izrakstītāja', locale);
      default:
        return type;
    }
  }
}
