import { Component, Vue } from 'vue-property-decorator';
import type Locale from '@/enums/config/Locale';
import TemplateKey from '@/enums/config/email/TemplateKey';
import TranslationModule from '@/store/modules/Translation';

@Component
export default class TranslateEmailTemplateKeyMixin extends Vue {
  public TranslateEmailTemplateKeyMixin(key: TemplateKey, locale: Locale = TranslationModule.getLocale): string {
    switch (key) {
      case TemplateKey.NUMBER:
        return this.trans('generic.number', 'Numurs', locale);
      case TemplateKey.TOTAL_AMOUNT:
        return this.trans('generic.whole_amount', 'Visa summa', locale);
      case TemplateKey.ISSUED_AT:
        return this.trans('generic.issued_at', 'Izrakstīts', locale);
      case TemplateKey.DUE_AT:
        return this.trans('generic.due_at', 'Apmaksāt līdz', locale);
      case TemplateKey.CLIENT_NAME:
        return this.trans('generic.client_name', 'Klients', locale);
      case TemplateKey.LATE_FEE_RATE:
        return this.trans('generic.late_fee_rate', 'Kavējuma procentu likme', locale);
      default:
        return '-';
    }
  }
}
