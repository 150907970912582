<template>
  <div class="block w-full">
    <slot name="button" :open="open">
      <SubmitButton rounded type="button" :loading="isLoading" class="px-6" @mousedown="open">
        <div class="flex items-center justfiy-between gap-2">
          <span v-text="trans('button.add.company', 'Pievienot kompāniju')" />
          <span class="hidden sm-block">
            <v-icon small>$plus</v-icon>
          </span>
        </div>
      </SubmitButton>
    </slot>
    <BaseModal v-model="showModal" :loading="isLoading" use-header use-form use-esc @cancel="close">
      <template #title>
        <p class="leading-none my-0" v-text="title" />
      </template>
      <template #content>
        <v-form :disabled="isLoading" @submit.prevent class="w-full">
          <validation-observer ref="form-observer" class="flex flex-col items-center w-full gap-y-4">
            <!-- Company type toggle -->
            <ToggleButton
              v-model="form.is_legal"
              :disabled="isLoading"
              :option-false="trans('generic.type.individual', 'Fiziska persona')"
              :option-true="trans('generic.type.legal', 'Juridiska persona')"
            />
            <!-- Country field -->
            <SelectCountry
              v-model="form.country"
              autocomplete="country"
              :disabled="isLoading"
              rules="required|iso:country|min:2|max:2"
              name="country"
              hide-selected
              overflow
              class="text-body-2 w-full"
              @blur="composeVatNumber"
            />
            <!-- Currency field -->
            <SelectCurrency
              v-model="form.currency_code"
              autocomplete="transaction-currency"
              :disabled="isLoading"
              rules="required|iso:currency|min:3|max:3"
              name="currency_code"
              hide-selected
              overflow
              class="text-body-2 w-full"
            />
            <!-- Company name field -->
            <SelectCompanyFromRegistry
              v-model="form.name"
              v-trim
              :disabled="isLoading"
              :search-country="form.country"
              :search-by="RegistrySearchField.NAME"
              :placeholder="translateCompanyNameField(companyType)"
              :label="translateCompanyNameField(companyType)"
              rules="required|max:191"
              :hide-list="companyType === CompanyType.PRIVATE"
              name="name"
              overflow
              class="text-body-2 w-full"
              @set="setCompany"
            />
            <!-- Registration number field -->
            <SelectCompanyFromRegistry
              v-model="form.registration_number"
              v-trim.all.uppercase
              :disabled="isLoading"
              :search-country="form.country"
              :search-by="RegistrySearchField.REGISTRATION_NUMBER"
              :placeholder="translateCompanyTaxNumberField(companyType)"
              :label="translateCompanyTaxNumberField(companyType)"
              :rules="registrationNumberRules"
              :hide-list="companyType === CompanyType.PRIVATE"
              name="registration_number"
              overflow
              class="text-body-2 w-full"
              @keypress.prevent.space
              @set="setCompany"
              @blur="composeVatNumber"
            />
            <v-slide-y-transition hide-on-leave leave-absolute>
              <!-- VAT registration number field -->
              <VatNumberField
                v-if="form.registration_number"
                v-model="form.vat_registration_number"
                v-trim.all.uppercase
                :disabled="isLoading"
                :check-vat-number="vatNumber"
                :placeholder="trans('workspace.title.vat_registration_number', 'PVN reģistrācijas numurs')"
                :label="trans('workspace.title.vat_registration_number', 'PVN reģistrācijas numurs')"
                :rules="registrationNumberRules"
                name="vat_registration_number"
                rounded
                class="text-body-2 w-full"
                @keypress.prevent.space
                @checked="clearVatNumber"
                @blur="fixInputs"
              />
            </v-slide-y-transition>
            <!-- Address field -->
            <TextField
              v-model="form.address"
              v-trim.capitalize
              :disabled="isLoading"
              :placeholder="trans('signup.form.address', 'Adrese')"
              :label="trans('signup.form.address', 'Adrese')"
              rules="max:191"
              name="address"
              rounded
              class="text-body-2 w-full"
            />
            <!-- Submit button -->
            <v-col cols="10" class="py-4">
              <SubmitButton block rounded :loading="isLoading" :text="trans('button.save', 'Saglabāt')" @submit="add" />
            </v-col>
          </validation-observer>
        </v-form>
      </template>
    </BaseModal>
  </div>
</template>

<script lang="ts">
  import Wait from '@/decorators/Wait';
  import defer from 'lodash-es/defer';
  import cloneDeep from 'lodash-es/cloneDeep';
  import { Component, Emit, Mixins, Ref, Prop, Watch } from 'vue-property-decorator';
  import DEFAULT_REGISTER_COMPANY_FORM from '@/constants/mocks/forms/RegisterCompany';
  import RegistrySearchField from '@/enums/config/RegistrySearchField';
  import CountryISO from '@/enums/config/iso/CountryISO';
  import TimeConfig from '@/enums/config/TimeConfig';
  import CompanyType from '@/enums/generics/CompanyType';
  import KeypressInputType from '@/enums/types/KeypressInputType';
  import type { ProviderInstance } from 'vee-validate/dist/types/types';
  import type IRegisterCompanyForm from '@/interfaces/forms/auth/IRegisterCompanyForm';
  import type IBusinessRegistry from '@/interfaces/IBusinessRegistry';
  import ErrorModule from '@/store/modules/Error';
  import LoadingModule from '@/store/modules/Loading';
  import CompanyMixin from '@/mixins/Company';
  import TranslateMixin from '@/mixins/Translate';
  import BaseModal from '@/components/global/modals/Base.vue';
  import TextField from '@/components/global/inputs/TextField.vue';
  import ToggleButton from '@/components/global/buttons/Toggle.vue';
  import SelectCountry from '@/components/global/inputs/SelectCountry.vue';
  import CheckboxField from '@/components/global/inputs/CheckboxField.vue';
  import SelectCurrency from '@/components/global/inputs/SelectCurrency.vue';
  import VatNumberField from '@/components/global/inputs/VatNumberField.vue';
  import SelectCompanyFromRegistry from '@/components/global/inputs/SelectCompanyFromRegistry.vue';
  import SubmitButton from '@/components/global/buttons/Submit.vue';

  @Component({
    components: {
      BaseModal,
      TextField,
      ToggleButton,
      SelectCountry,
      CheckboxField,
      SelectCurrency,
      VatNumberField,
      SelectCompanyFromRegistry,
      SubmitButton,
    },
  })
  export default class AddCompanyModal extends Mixins(CompanyMixin, TranslateMixin) {
    @Prop({ type: Boolean }) useDelay!: boolean;

    @Ref('form-observer') formObserver!: ProviderInstance;

    public form: IRegisterCompanyForm = cloneDeep(DEFAULT_REGISTER_COMPANY_FORM);
    public vatNumber: string = '';

    public showModal: boolean = false;

    /*****         computed       *****/

    public get error(): unknown {
      return ErrorModule.getError;
    }

    public get isLoading(): boolean {
      return LoadingModule.isLoading;
    }

    public get title(): string {
      return this.trans('modal.company.add.title', 'Pievienot kompāniju');
    }

    public get registrationNumberRules(): string {
      const isLatvia = this.form.country === CountryISO.LV;
      return isLatvia ? 'registration_number' : 'min:6|max:50';
    }

    public get companyType(): CompanyType {
      return this.form.is_legal ? CompanyType.LEGAL : CompanyType.PRIVATE;
    }

    public get RegistrySearchField(): typeof RegistrySearchField {
      return RegistrySearchField;
    }

    public get TimeConfig(): typeof TimeConfig {
      return TimeConfig;
    }

    public get CompanyType(): typeof CompanyType {
      return CompanyType;
    }

    public get KeypressInputType(): typeof KeypressInputType {
      return KeypressInputType;
    }

    /*****         watchers       *****/

    @Watch('error')
    private watchForError(): void {
      if (this.error && this.formObserver) {
        this.formObserver.setErrors(this.error as string[]);
      }
    }

    @Watch('showModal')
    private watchForShow(): void {
      this.form = cloneDeep(DEFAULT_REGISTER_COMPANY_FORM);

      this.resetErrors();
    }

    /*****         methods        *****/

    @Emit('create')
    public create(): void {}

    @Emit('open')
    public open(): void {
      this.useDelay ? this.openWithDelay() : this.openNow();
    }

    @Emit('close')
    public close(): void {
      this.showModal = false;
    }

    public async add(): Promise<void> {
      this.fixInputs();
      this.formObserver.reset();
      if (await this.formObserver.validate()) {
        await this.createCompany(this.form);

        // If no errors, close modal
        if (!this.error) {
          this.create();
          return this.close();
        }
      }
      return this.scrollToFirstError(this.formObserver);
    }

    /*****         helpers        *****/

    private openNow(): void {
      this.showModal = true;
    }

    private openWithDelay(): void {
      defer(() => {
        this.openNow();
      });
    }

    @Wait()
    public composeVatNumber(): void {
      this.vatNumber = `${this.form.country}${this.form.registration_number}`;
    }

    public clearVatNumber(): void {
      this.vatNumber = '';
    }

    public setCompany(company: IBusinessRegistry): void {
      this.form.name = company.name.trim();
      this.form.registration_number = this.removeWhiteSpaces(company.registration_number).toUpperCase();
      this.form.vat_registration_number = '';
      this.form.address = company.address.trim();

      // Set VAT number to check with VAT info exchange service
      this.composeVatNumber();
    }

    public fixInputs(): void {
      this.form.registration_number = this.removeWhiteSpaces(this.form.registration_number ?? '').toUpperCase();
      this.form.vat_registration_number = this.removeWhiteSpaces(this.form.vat_registration_number ?? '').toUpperCase();
    }

    private resetErrors(): void {
      this.$nextTick(() => {
        if (this.formObserver) {
          this.formObserver.reset();
        }
      });
    }

    /*****      vue lifecycle     *****/
  }
</script>
