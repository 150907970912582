import { Component, Vue } from 'vue-property-decorator';
import type Locale from '@/enums/config/Locale';
import DocumentType from '@/enums/config/document/Type';
import type ICompanyEmailSettings from '@/interfaces/translate/ICompanyEmailSettings';
import TranslationModule from '@/store/modules/Translation';

@Component
export default class TranslateCompanyEmailSettingsMixin extends Vue {
  public TranslateCompanyEmailSettingsMixin(
    type: DocumentType,
    locale: Locale = TranslationModule.getLocale,
  ): ICompanyEmailSettings {
    switch (type) {
      case DocumentType.ADVANCE_INVOICE:
        return {
          title: this.trans('workspace.title.email_advanced_invoice', 'Avansa rēķina epasts', locale),
          subtitle: this.trans(
            'workspace.description.email_advance_invoice',
            'Šājā sadaļā, Jūs varat izveidot noklusējuma sagatavi avansa rēķina e-pastiem.',
            locale,
          ),
        };
      case DocumentType.OFFER:
        return {
          title: this.trans('workspace.title.email_offer', 'Piedāvājuma e-pasts', locale),
          subtitle: this.trans(
            'workspace.description.email_offer',
            'Šājā sadaļā, Jūs varat izveidot noklusējuma sagatavi piedāvājuma e-pastiem.',
            locale,
          ),
        };
      case DocumentType.INVOICE:
      default:
        return {
          title: this.trans('workspace.title.email_invoice', 'Rēķina epasts', locale),
          subtitle: this.trans(
            'workspace.description.email_invoice',
            'Šājā sadaļā, Jūs varat izveidot noklusējuma sagatavi rēķina e-pastiem.',
            locale,
          ),
        };
    }
  }
}
