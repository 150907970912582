import { Component, Vue } from 'vue-property-decorator';
import Locale from '@/enums/config/Locale';
import VatRate from '@/enums/config/VatRate';
import TranslationModule from '@/store/modules/Translation';

@Component
export default class TranslateVatRateGroupMixin extends Vue {
  public TranslateVatRateGroupMixin(vatRate: VatRate, locale: Locale = TranslationModule.getLocale): string {
    switch (vatRate) {
      case VatRate.REVERSE_CHARGE:
        return this.trans('generic.vat_rate.reverse', 'PVN apgrieztā maksāšana', locale);
      case VatRate.ZERO:
        return this.trans('generic.vat_rate.zero', 'PVN likme 0%', locale);
      case VatRate.EXEMPT:
        return this.trans('generic.vat_rate.exempt', 'Nodokļa atbrīvojumi', locale);
      case VatRate.NONE:
        return this.trans('generic.vat_rate.none', 'PVN netiek piemērots', locale);
      default:
        return '-';
    }
  }
}
