import Bugsnag from '@bugsnag/js';
import LogRocket from 'logrocket';
import Router, { Route, RouteRecord } from 'vue-router';
import usePrototype from '@/services/Prototype';
import DocumentRoute from '@/enums/config/document/Route';
import RecurringDocumentRoute from '@/enums/config/document/RecurringRoute';
import AuthRouteName from '@/enums/config/router/Auth';
import DashboardRouteName from '@/enums/config/router/Dashboard';
import DocumentRouteName from '@/enums/config/router/Document';
import RecurringRouteName from '@/enums/config/router/Recurring';
import SettingsRouteName from '@/enums/config/router/Settings';
import ProfileRouteName from '@/enums/config/router/Profile';
import PublicRouteName from '@/enums/config/router/Public';
import AppModule from '@/store/modules/App';
import CompanyModule from '@/store/modules/Company';
import UserModule from '@/store/modules/User';

const { hasOwnProperty } = usePrototype();

const routerGuards = {
  // Guest guard - allow only guests to access specific routes
  guest: async (): Promise<void> => {
    const isLoggedIn: boolean = UserModule.isLoggedIn;
    if (isLoggedIn) {
      throw { name: DashboardRouteName.LIST, params: { document: DocumentRoute.ALL } };
    }

    if (!isLoggedIn) {
      // Set no user details for bugsnag and logrokect integrations
      if (process.env.VUE_APP_LOGROCKET) {
        LogRocket.identify('0', { name: 'No user', email: 'dev@numbero.app' });
      }
      if (process.env.VUE_APP_BUGSNAG) {
        Bugsnag.setUser('0', 'dev@numbero.app', 'No user');
      }
    }
  },

  // Auth guard - allow only logged in user to access specific routes
  auth: async (): Promise<void> => {
    const isLoggedIn: boolean = UserModule.isLoggedIn;

    if (!isLoggedIn) {
      throw { name: AuthRouteName.LOGIN };
    }

    // Set currenct existing bugsnag user
    const userFullName: string = UserModule.getFullName;
    const userEmail: string = UserModule.getUserEmail;
    const companyUUID: string = UserModule.getCompanyUUID;

    if (isLoggedIn) {
      // Set logged user details for bugsnag and logrokect integrations
      if (process.env.VUE_APP_LOGROCKET) {
        LogRocket.identify(companyUUID || 'no-user-id', { name: userFullName, email: userEmail });
      }
      if (process.env.VUE_APP_BUGSNAG) {
        Bugsnag.setUser(companyUUID, userEmail, userFullName);
      }
    }
  },

  hasCompany: async (route: Route): Promise<void> => {
    const hasCompany: boolean = CompanyModule.hasCompany;
    if (!hasCompany && !Object.values(ProfileRouteName).includes(route.name as ProfileRouteName)) {
      throw { name: ProfileRouteName.INDEX };
    }
  },

  // Admin guard - allow only user with admin like priviliges to access routes
  admin: async (): Promise<void> => {
    const isNotAdmin: boolean = !UserModule.isAdmin;

    if (isNotAdmin) {
      throw { name: DashboardRouteName.LIST, params: { document: DocumentRoute.ALL } };
    }
  },

  // Subscription guard - allow only user with active subscription to access specific routes
  // subscription: async (): Promise<void> => {
  //   const env: string = process.env.VUE_APP_ENVIRONMENT ?? 'production';
  //   const isActiveSubscription = CompanyModule.isActiveSubscription;
  //   if (!isActiveSubscription && env === 'staging') {
  //     throw { name: SettingsRouteName.SUBSCRIPTION };
  //   }
  // },

  // Onboarding guard - allow only users with verified email and passed registration
  // onboarding: async () => {
  //   const isPendingEmail = !!UserModule.getUserPendingEmail;

  //   if (isPendingEmail) {
  //     throw { name: PublicRouteName.ONBOARDING };
  //   } else {
  //     throw { name: DashboardRouteName.LIST, params: { document: DocumentRoute.ALL } };
  //   }
  // },

  // Redirect guards - redirect certain pages to defaults
  redirect: async (route: Route) => {
    if (route.name === ProfileRouteName.INDEX) {
      throw { name: ProfileRouteName.DATA };
    }
    if (route.name === SettingsRouteName.INDEX) {
      throw { name: SettingsRouteName.COMPNAY };
    }
    if (route.name === DashboardRouteName.INDEX) {
      throw { name: DashboardRouteName.LIST, params: { document: DocumentRoute.ALL } };
    }
    if (route.name === DocumentRouteName.INDEX) {
      throw { name: DocumentRouteName.CREATE, params: { document: DocumentRoute.INVOICE } };
    }
    if (route.name === RecurringRouteName.INDEX) {
      throw {
        name: RecurringRouteName.LIST,
        params: { document: RecurringDocumentRoute.INVOICE },
      };
    }
  },

  // Maintenance guard - redirect if app is in maintenance mode
  maintenance: async (route: Route): Promise<void> => {
    const isMaintenance: boolean = AppModule.getIsMaintenance;
    if (isMaintenance && route.name !== PublicRouteName.MAINTENANCE) {
      throw { name: PublicRouteName.MAINTENANCE };
    }
  },

  // Not found guard - redirect if requested page not found
  notFound: async (route: Route): Promise<void> => {
    const isLoggedIn: boolean = UserModule.isLoggedIn;

    const hasRoute: boolean = !!route.matched.length;
    const isRoot: boolean = !!route.path && route.path === '/';

    if (!hasRoute || isRoot) {
      throw isLoggedIn
        ? { name: DashboardRouteName.LIST, params: { document: DocumentRoute.ALL } }
        : { name: AuthRouteName.LOGIN };
    }
  },

  // Set document title
  setTitle(route: Route): void {
    let title = 'Numbero :: ';

    route.matched.forEach((matchedRoute: RouteRecord) => {
      if (hasOwnProperty(matchedRoute.meta, 'title')) {
        title += matchedRoute.meta.title + ' - ';
      }
    });

    document.title = title.slice(0, -3);
  },
};

export default {
  init: async (route: Route, next: Function, router: Router) => {
    routerGuards.setTitle(route);

    // Apply guards - store route guards for later verification
    const guards: string[] = [];
    route.matched.forEach((matchedRoute: RouteRecord) => {
      if (hasOwnProperty(matchedRoute.meta, 'guard')) {
        matchedRoute.meta.guard.forEach((guard: string) => {
          guards.push(guard);
        });
      }
    });

    try {
      // Verify if page exists
      await (routerGuards as any).notFound(route);
      // Verify requested route guards
      for (const guard of guards) {
        await (routerGuards as any)[guard](route);
      }

      await next();
    } catch (e) {
      // Special cases for redirecting to specific routes
      // This solution is used to avoid multiple redirects
      switch (route.name) {
        case DashboardRouteName.INDEX:
        case DocumentRouteName.INDEX:
        case RecurringRouteName.INDEX:
          router.push(e as any);
          break;
        default:
          await next(e);
      }
    }
  },
};
