<template>
  <div class="flex flex-col h-full">
    <AuthNav />
    <v-scroll-y-transition hide-on-leave leave-absolute>
      <RouterView :key="$route.meta?.parent ?? $route.fullPath" class="grow" />
    </v-scroll-y-transition>
    <Footer />
  </div>
</template>

<script lang="ts">
  import { Component, Vue } from 'vue-property-decorator';
  import AuthNav from '@/components/global/nav/AuthNav.vue';
  import Footer from '@/components/global/Footer.vue';

  @Component({
    components: {
      AuthNav,
      Footer,
    },
  })
  export default class AuthLayout extends Vue {
    /*****         computed       *****/
    /*****         watchers       *****/
    /*****         methods        *****/
    /*****         helpers        *****/
    /*****      vue lifecycle     *****/
  }
</script>
