import { Component, Vue } from 'vue-property-decorator';
import type { AxiosError, AxiosResponse } from 'axios';
import HttpStatus from '@/enums/generics/HttpStatus';
import AppState from '@/enums/generics/AppState';
import TimeConfig from '@/enums/config/TimeConfig';
import AuthRepository from '@/repository/Auth';
import AppModule from '@/store/modules/App';

@Component
export default class CsrfRetryMixin extends Vue {
  public async CsrfRetryMixin(): Promise<boolean> {
    let tryToRetry: number = TimeConfig.MIN_RETRIES;

    while (tryToRetry) {
      try {
        const statusCode = (await AuthRepository.csrfCookie()).status;

        if (statusCode) {
          await AppModule.CLEAR_IS_MAINTENANCE();
          return true;
        }
      } catch (e) {
        const networkError: AxiosResponse | null = (e as AxiosError).response ?? null;
        if (networkError && networkError.status === HttpStatus.ServiceUnavailable) {
          await AppModule.SET_IS_MAINTENANCE();
          return false;
        }

        if (tryToRetry === 1) {
          await this.$notifier({
            message: this.trans(
              'notify.error.no_internet_connection',
              'Neizdevās ielādēt datus. Lūdzu, pārbaudiet interneta savienojumu un atkārtoti ielādējiet lapu.',
            ),
            state: AppState.ERROR,
          });
        }
      }
      tryToRetry--;
      await new Promise((resolve) => setTimeout(resolve, TimeConfig.INTERVAL));
    }
    return false;
  }
}
