<template>
  <svg width="29" height="34" viewBox="0 0 29 34" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M27.913,24.586 C26.763,24.586 25.831,23.630 25.831,22.452 L25.831,12.860 C25.831,11.681 26.763,10.726 27.913,10.726 C29.063,10.726 29.995,11.681 29.995,12.860 L29.995,22.452 C29.995,23.630 29.063,24.586 27.913,24.586 ZM24.768,28.334 C24.768,28.996 24.244,29.533 23.598,29.533 L21.098,29.533 L21.098,34.865 C21.098,36.044 20.165,36.999 19.015,36.999 C17.865,36.999 16.933,36.044 16.933,34.865 L16.933,29.533 L13.060,29.533 L13.060,34.865 C13.060,36.044 12.128,36.999 10.978,36.999 C9.828,36.999 8.896,36.044 8.896,34.865 L8.896,29.533 L6.395,29.533 C5.749,29.533 5.225,28.996 5.225,28.334 L5.225,19.669 L5.207,19.669 L5.207,11.409 L6.395,11.409 L23.598,11.409 L24.787,11.409 L24.787,19.669 L24.768,19.669 L24.768,28.334 ZM10.256,2.677 L8.600,0.282 C8.565,0.231 8.615,0.134 8.712,0.064 C8.807,-0.005 8.914,-0.022 8.948,0.028 L10.659,2.503 C11.831,2.041 13.257,1.758 14.997,1.763 C16.727,1.769 18.146,2.060 19.315,2.530 L21.045,0.028 C21.079,-0.022 21.186,-0.005 21.281,0.064 C21.378,0.134 21.428,0.231 21.393,0.282 L19.717,2.706 C24.508,4.910 24.706,10.227 24.706,10.227 L5.288,10.227 C5.288,10.227 5.430,4.862 10.256,2.677 ZM19.258,7.089 C19.903,7.089 20.427,6.553 20.427,5.891 C20.427,5.230 19.903,4.693 19.258,4.693 C18.613,4.693 18.089,5.230 18.089,5.891 C18.089,6.553 18.613,7.089 19.258,7.089 ZM10.735,7.089 C11.381,7.089 11.904,6.553 11.904,5.891 C11.904,5.230 11.381,4.693 10.735,4.693 C10.090,4.693 9.566,5.230 9.566,5.891 C9.566,6.553 10.090,7.089 10.735,7.089 ZM2.080,24.586 C0.930,24.586 -0.002,23.630 -0.002,22.452 L-0.002,12.860 C-0.002,11.681 0.930,10.726 2.080,10.726 C3.231,10.726 4.163,11.681 4.163,12.860 L4.163,22.452 C4.163,23.630 3.231,24.586 2.080,24.586 Z"
      fill-rule="evenodd"
      fill="currentColor"
    />
  </svg>
</template>

<script lang="ts">
  import { Component, Vue } from 'vue-property-decorator';

  @Component
  export default class GooglePlayIcon extends Vue {
    /*****         computed       *****/
    /*****         watchers       *****/
    /*****         methods        *****/
    /*****         helpers        *****/
    /*****      vue lifecycle     *****/
  }
</script>
