<template>
  <svg width="1440" height="164" viewBox="0 0 1440 164" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_5350_7323)">
      <path
        d="M659.056 36.2832H609.572C600.787 36.2832 593.665 43.4048 593.665 52.1897C593.665 60.9746 600.787 68.0962 609.572 68.0962H659.056C667.841 68.0962 674.963 60.9746 674.963 52.1897C674.963 43.4048 667.841 36.2832 659.056 36.2832Z"
        fill="white"
        stroke="#1F8A62"
        stroke-width="5.63395"
        stroke-miterlimit="10"
      />
      <path
        d="M659.056 97.582H609.572C600.787 97.582 593.665 104.704 593.665 113.489C593.665 122.273 600.787 129.395 609.572 129.395H659.056C667.841 129.395 674.963 122.273 674.963 113.489C674.963 104.704 667.841 97.582 659.056 97.582Z"
        fill="white"
        stroke="#1F8A62"
        stroke-width="5.63395"
        stroke-miterlimit="10"
      />
      <path
        d="M425.398 67.6074H-44.0605V97.2983H425.398V67.6074Z"
        fill="white"
        stroke="#1F8A62"
        stroke-width="5.63395"
        stroke-miterlimit="10"
      />
      <path
        d="M466.956 52.584H408.024C399.115 52.584 391.893 59.8065 391.893 68.7159V97.7495C391.893 106.659 399.115 113.881 408.024 113.881H466.956C475.865 113.881 483.087 106.659 483.087 97.7495V68.7159C483.087 59.8065 475.865 52.584 466.956 52.584Z"
        fill="#26AB7B"
        stroke="#1F8A62"
        stroke-width="7.51193"
        stroke-miterlimit="10"
      />
      <path
        d="M570.603 16.9023H497.737C475.884 16.9023 458.168 34.618 458.168 56.4714V110.257C458.168 132.11 475.884 149.826 497.737 149.826H570.603C592.456 149.826 610.172 132.11 610.172 110.257V56.4714C610.172 34.618 592.456 16.9023 570.603 16.9023Z"
        fill="#26AB7B"
        stroke="#1F8A62"
        stroke-width="7.51193"
        stroke-miterlimit="10"
      />
      <path
        d="M617.966 1.87891H584.031V163.78H617.966V1.87891Z"
        fill="#26AB7B"
        stroke="#1F8A62"
        stroke-width="7.51193"
        stroke-miterlimit="10"
      />
    </g>
    <g clip-path="url(#clip1_5350_7323)">
      <path
        d="M989.602 67.6074H1459.06V97.2983H989.602V67.6074Z"
        fill="white"
        stroke="#1F8A62"
        stroke-width="5.63395"
        stroke-miterlimit="10"
      />
      <path
        d="M948.043 52.584H1006.97C1015.88 52.584 1023.11 59.8065 1023.11 68.7159V97.7495C1023.11 106.659 1015.88 113.881 1006.97 113.881H948.043C939.134 113.881 931.912 106.659 931.912 97.7495V68.7159C931.912 59.8065 939.134 52.584 948.043 52.584Z"
        fill="#26AB7B"
        stroke="#1F8A62"
        stroke-width="7.51193"
        stroke-miterlimit="10"
      />
      <path
        d="M844.397 16.9023H917.263C939.116 16.9023 956.832 34.618 956.832 56.4714V110.257C956.832 132.11 939.116 149.826 917.263 149.826H844.397C822.544 149.826 804.828 132.11 804.828 110.257V56.4714C804.828 34.618 822.544 16.9023 844.397 16.9023Z"
        fill="#26AB7B"
        stroke="#1F8A62"
        stroke-width="7.51193"
        stroke-miterlimit="10"
      />
      <path
        d="M797.035 1.87891H830.97V163.78H797.035V1.87891Z"
        fill="#26AB7B"
        stroke="#1F8A62"
        stroke-width="7.51193"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_5350_7323">
        <rect width="723.023" height="163.385" fill="white" transform="translate(-45)" />
      </clipPath>
      <clipPath id="clip1_5350_7323">
        <rect width="723.023" height="163.385" fill="white" transform="matrix(-1 0 0 1 1460 0)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script lang="ts">
  import { Component, Prop, Vue } from 'vue-property-decorator';

  @Component
  export default class WireMaintenanceIcon extends Vue {
    @Prop() primary?: string;
    @Prop() white?: string;

    public get primaryColor(): string {
      return this.primary || this.$vuetify.theme.themes.light.primaryColor!.toString();
    }

    public get whiteColor(): string {
      return this.white || this.$vuetify.theme.themes.light.whiteColor!.toString();
    }

    public get darkerPrimaryColor(): string {
      return '#1F8A62';
    }

    /*****         computed       *****/
    /*****         watchers       *****/
    /*****         methods        *****/
    /*****         helpers        *****/
    /*****      vue lifecycle     *****/
  }
</script>
