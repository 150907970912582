<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.8425 5.03C7.55486 5.06316 7.33778 5.3067 7.33778 5.59625C7.33778 5.8858 7.55486 6.12934 7.8425 6.1625C7.99363 6.1646 8.13908 6.10497 8.24524 5.99739C8.3514 5.88981 8.4091 5.74359 8.405 5.5925C8.40096 5.28353 8.15147 5.03404 7.8425 5.03Z"
      fill="currentColor"
    />
    <path
      d="M7.8425 7.19C7.6927 7.18796 7.54844 7.24657 7.4425 7.3525C7.33657 7.45844 7.27796 7.6027 7.28 7.7525V10.0925C7.28 10.4032 7.53184 10.655 7.8425 10.655C8.15316 10.655 8.405 10.4032 8.405 10.0925V7.7675C8.40905 7.61573 8.35158 7.46878 8.24565 7.36002C8.13972 7.25126 7.99432 7.18995 7.8425 7.19Z"
      fill="currentColor"
    />
    <path
      d="M7.8425 0.5C3.78906 0.504134 0.504134 3.78906 0.5 7.8425C0.5 11.8976 3.78735 15.185 7.8425 15.185C11.8976 15.185 15.185 11.8976 15.185 7.8425C15.1809 3.78906 11.8959 0.504134 7.8425 0.5ZM7.8425 14.06C4.40867 14.06 1.625 11.2763 1.625 7.8425C1.625 4.40867 4.40867 1.625 7.8425 1.625C11.2763 1.625 14.06 4.40867 14.06 7.8425C14.0559 11.2746 11.2746 14.0559 7.8425 14.06Z"
      clip-rule="evenodd"
      fill-rule="evenodd"
      fill="currentColor"
    />
  </svg>
</template>

<script lang="ts">
  import { Component, Vue } from 'vue-property-decorator';

  @Component
  export default class InfoIcon extends Vue {
    /*****         computed       *****/
    /*****         watchers       *****/
    /*****         methods        *****/
    /*****         helpers        *****/
    /*****      vue lifecycle     *****/
  }
</script>
