import { Component, Vue } from 'vue-property-decorator';
import type ICompany from '@/interfaces/company/ICompany';
import CompanyRepository from '@/repository/Company';
import CompanyModule from '@/store/modules/Company';

@Component
export default class FetchCompanyMixin extends Vue {
  public async FetchCompanyMixin(companyUUID: string = CompanyModule.getCompanyUUID): Promise<ICompany> {
    return (await CompanyRepository.fetch(companyUUID)).data;
  }
}
