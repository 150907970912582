import { Component, Vue } from 'vue-property-decorator';
import Locale from '@/enums/config/Locale';
import DealType from '@/enums/generics/DealType';
import TranslationModule from '@/store/modules/Translation';

@Component
export default class TranslateDealTypeMixin extends Vue {
  public TranslateDealTypeMixin(type: DealType, locale: Locale = TranslationModule.getLocale): string {
    switch (type) {
      case DealType.SALE_OF_GOODS:
        return this.trans('generic.deal_type.sale_of_goods', 'Preču pardošana', locale);
      case DealType.GOODS:
        return this.trans('generic.deal_type.goods', 'Preču piegāde', locale);
      case DealType.SERVICE:
        return this.trans('generic.deal_type.service', 'Pakalpojums', locale);
      case DealType.OTHER:
      default:
        return this.trans('generic.deal_type.other', 'Cits', locale);
    }
  }
}
