import cloneDeep from 'lodash-es/cloneDeep';
import { Component, Vue } from 'vue-property-decorator';
import DEFAULT_COMPANY from '@/constants/mocks/company/Company';
import DEFAULT_DOCUMENT_FORM from '@/constants/mocks/forms/Document';
import DateFormat from '@/enums/config/DateFormat';
import type DocumentType from '@/enums/config/document/Type';
import type IBank from '@/interfaces/bank/IBank';
import type ICompany from '@/interfaces/company/ICompany';
import type { IDocumentPreference } from '@/interfaces/company/IDocumentSettings';
import type IDocument from '@/interfaces/document/IDocument';
import type IStock from '@/interfaces/stock/IStock';
import BankModule from '@/store/modules/Bank';
import CompanyModule from '@/store/modules/Company';
import UserModule from '@/store/modules/User';
import UtilModule from '@/store/modules/Util';

@Component
export default class InitBlankDocumentMixin extends Vue {
  public async InitBlankDocumentMixin(type: DocumentType): Promise<IDocument> {
    const document: IDocument = cloneDeep(DEFAULT_DOCUMENT_FORM);

    const company: ICompany = CompanyModule.getCompany || DEFAULT_COMPANY;
    const documentPreferences: IDocumentPreference = await CompanyModule.GET_DOCUMENT_PREFERENCES(type);
    const paymentMethods: IBank[] = BankModule.getBanks;

    const lateFee = company.use_late_fees ? company.default_late_fee_rate : null;
    const dueDays = documentPreferences.default_due_days;

    document.lang = company.default_document_locale || DEFAULT_COMPANY.default_document_locale;
    document.type = type;
    document.currency_code =
      company.default_document_currency_code || company.currency_code || DEFAULT_COMPANY.default_document_currency_code;

    document.tax_by_group = company.tax_by_group;

    document.number_format = documentPreferences.number_format;

    document.date = this.parseNowToFormat(DateFormat.LARAVEL);
    document.due_date = dueDays ? this.addPeriodToFormat(document.date, DateFormat.LARAVEL, dueDays, 'days') : '';
    document.late_fee_percent = lateFee;

    document.logo = company.logo;
    document.color = company.default_document_accent_color;

    document.sender = {
      ...DEFAULT_DOCUMENT_FORM.sender,
      name: company.name,
      registration_number: company.registration_number,
      vat_registration_number: company.vat_registration_number,

      payment_methods: cloneDeep(paymentMethods),

      email: company.email,
      address: company.address,
      country: company.country,
      website: company.website,

      phone_prefix: company.phone_prefix || DEFAULT_COMPANY.phone_prefix,
      phone_number: company.phone_number,

      notes: documentPreferences.default_supplier_notes,
    };

    // Set new recipient preferences based on company settings
    // If company has no preferences, use default company settings - issue when with default_document_locale is null
    const phonePrefix = UtilModule.selectPhonePrefixes.find((i) => i.code === company.country);
    document.recipient = {
      ...DEFAULT_DOCUMENT_FORM.recipient,
      country: company.country,
      phone_prefix: phonePrefix ? phonePrefix.value.toString() : company.phone_prefix,
      preferences: {
        document_lang: company.default_document_locale || DEFAULT_COMPANY.default_document_locale,
        currency_code: company.currency_code || DEFAULT_COMPANY.currency_code,
        enable_late_fees: company.use_late_fees,
        late_fee_percent: lateFee,
        default_due_days: dueDays,
      },
    };

    document.single_vat = CompanyModule.isTaxPayer
      ? company.use_taxes
        ? company.default_tax_rate
        : DEFAULT_COMPANY.default_tax_rate
      : 0;

    document.items.forEach((e: IStock) => (e.vat_percent = document.single_vat ?? 0));
    document.notes = documentPreferences.default_notes;

    if (!UserModule.isReadOnly) {
      document.permissions.edit = true;
      document.permissions.delete = true;
    }

    document.logs = [];

    return cloneDeep(document);
  }
}
