const PASSWORD_STRENGTH: RegExp = new RegExp(
  '^' + // Start at the beginning of the string.
    '(?=.*[a-z|A-Z])' + // Ensure at least one lower or uppercase letter exists.
    '(?=.*[0-9|!@#$%^&*(),.?":{}|<>[\\]/+-`~_=;*])' + // Ensure at least one digit or special character exists.
    '.{8,}' + // Ensure the total length is 8 or more.
    '$', // Match the end of the string.
);

export const SPECIAL_CHARS: RegExp = new RegExp(/^(?=.*[!@#$%^&*(),.?'":{}|<>[\]\\/+\-`~_=;*]).*$/);
export const UPPERCASE: RegExp = new RegExp(/^(?=.*[A-Z]).*$/);
export const LOWERCASE: RegExp = new RegExp(/^(?=.*[a-z]).*$/);
export const NUMBER: RegExp = new RegExp(/^(?=.*[0-9]).*$/);
export const MIN_LENGTH: RegExp = new RegExp(/^.{8,}$/);

export default PASSWORD_STRENGTH;
