enum YoutubeTutorial {
  PROFILE = 'profile',
  COMPANY = 'company',
  DOCUMENT = 'document',
  RECURRING = 'recurring',
  REPORTS = 'reports',
  WELCOME = 'welcome',
}

export default YoutubeTutorial;
