import Axios, { AxiosPromise } from 'axios';
import ApiRepository from '@/repository/config/Api';
import MicroserviceRepository from '@/repository/config/Microservice';
import type CurrencyISO from '@/enums/config/iso/CurrencyISO';
import type ICheckVatStatusForm from '@/interfaces/forms/helper/ICheckVatStatusForm';
import type IRegistrySearchForm from '@/interfaces/forms/helper/IRegistrySearchForm';

export default class HelperRepository {
  // Check company vat payer/vat registration number status
  public static checkVatStatus(payload: ICheckVatStatusForm): AxiosPromise {
    return ApiRepository.post('/helpers/check-vat-payer-status', payload);
  }

  // List business registry database
  public static listBusinessRegistry(payload: IRegistrySearchForm): AxiosPromise {
    return ApiRepository.get('/app-data/company-registry/search', { params: payload });
  }

  // Fetch currecny exchnage rate (currrently supports only ECB - Europe Central Bank exchange rates)
  public static currencyExchange(from: CurrencyISO, to: CurrencyISO, date?: string): AxiosPromise {
    return MicroserviceRepository.get(`currency/exchange/${from}/to/${to}`, { params: { date } });
  }

  // Find IBAN details from bank account number
  public static fetchIban(IBANnumber: string): AxiosPromise {
    return ApiRepository.post('/banks/find-by-iban', { account_number: IBANnumber });
  }

  // Fetch user notifications (firebase rtdb)
  public static fetchNotifications(email: string, verified: boolean): AxiosPromise {
    return Axios.get('https://europe-central2-numbero-project.cloudfunctions.net/numbero-notifications', {
      params: { email, verified },
    });
  }

  // Update user notifications with has read status (firebase rtdb)
  public static updateNotifications(email: string, verified: boolean): AxiosPromise {
    return Axios.get('https://europe-central2-numbero-project.cloudfunctions.net/numbero-notifications/update', {
      params: { email, verified },
    });
  }
}
