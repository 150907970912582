const usePrototype = () => {
  const hasOwnProperty = (obj: unknown, prop: string): boolean => {
    return Object.prototype.hasOwnProperty.call(obj, prop);
  };

  return {
    hasOwnProperty,
  };
};

export default usePrototype;
