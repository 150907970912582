import { Component, Vue } from 'vue-property-decorator';
import type IPendingUser from '@/interfaces/multiuser/IPendingUser';
import UserRepository from '@/repository/User';

@Component
export default class RejectInvitationMixin extends Vue {
  public async RejectInvitationMixin(invitationUUID: string, hash: string): Promise<IPendingUser> {
    return (await UserRepository.rejectInvitation(invitationUUID, hash)).data;
  }
}
