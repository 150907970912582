import { Component, Vue } from 'vue-property-decorator';
import DocumentRepository from '@/repository/Document';
import CompanyModule from '@/store/modules/Company';

@Component
export default class ShareDocumentMixin extends Vue {
  public async ShareDocumentMixin(
    DocumentUUID: string,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<string> {
    return (await DocumentRepository.share(companyUUID, DocumentUUID)).data.link;
  }
}
