const DOCUMENT_NUMBER: RegExp = new RegExp(
  // (\{[Nn]([YyMmDd])?[2-9]?\}|\{([YyMmDd])2?\})
  '(\\{' +
    '[N]' + // Match the number iterator
    '([YMD])?' + // Match with optional the year, month, or day key
    '[2-9]?' + // Match with optional leading zero length
    '\\}|\\{' +
    '([YMD])' + // Match the year, month, or day key
    '2?' + // Match with optional date length
    '\\})',
  'gi',
);

export const DOCUMENT_NUMBER_YEAR: RegExp = new RegExp(/\{Y2?\}/gi);
export const DOCUMENT_NUMBER_MONTH: RegExp = new RegExp(/\{M2?\}/gi);
export const DOCUMENT_NUMBER_DAY: RegExp = new RegExp(/\{D2?\}/gi);

export const DOCUMENT_NUMBER_SEQUENCE: RegExp = new RegExp(/(\{N[YMD]?[2-9]?\}){1}/gi);

export default DOCUMENT_NUMBER;
