enum ProfileRouteName {
  DATA = 'app.profile.data',
  LOCALE = 'app.profile.locale',
  SECURITY = 'app.profile.security',
  DEACTIVATE = 'app.profile.deactivate',

  INDEX = 'app.profile.index',
}

export default ProfileRouteName;
