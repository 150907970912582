import usePrototype from '@/services/Prototype';

const { hasOwnProperty } = usePrototype();

const useFormBuilder = () => {
  const jsonToFormData = (json: { [key: string]: any }, formData = new FormData(), parentKey = ''): FormData => {
    for (const key in json) {
      if (hasOwnProperty(json, key)) {
        const propName = parentKey ? `${parentKey}[${key}]` : key;
        if (typeof json[key] === 'object' && json[key] !== null && !(json[key] instanceof File)) {
          jsonToFormData(json[key], formData, propName);
        } else {
          if (typeof json[key] === 'boolean') {
            json[key] = json[key] ? '1' : '0';
          }

          formData.append(propName, json[key]);
        }
      }
    }
    return formData;
  };

  return {
    jsonToFormData,
  };
};

export default useFormBuilder;
