import { Component, Vue } from 'vue-property-decorator';
import type IRecurringDocument from '@/interfaces/document/IRecurringDocument';
import DocumentRepository from '@/repository/Document';
import CompanyModule from '@/store/modules/Company';

@Component
export default class FetchRecurringMixin extends Vue {
  public async FetchRecurringMixin(
    documentUUID: string,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<IRecurringDocument> {
    return (await DocumentRepository.fetch(companyUUID, documentUUID)).data;
  }
}
