import cloneDeep from 'lodash-es/cloneDeep';
import usePrototype from '@/services/Prototype';

const useCopyObject = () => {
  const { hasOwnProperty } = usePrototype();

  const copy = (obj: any, template: any) => {
    const final_object: any = cloneDeep(template);
    for (const i in template) {
      if (hasOwnProperty(obj, i)) {
        if (Array.isArray(obj[i])) {
          // Copy array
          final_object[i] = Array.from(obj[i]);
        } else if (typeof obj[i] === 'object' && obj[i]) {
          for (const ii in template[i]) {
            if (Array.isArray(obj[i][ii])) {
              // Copy array
              final_object[i][ii] = Array.from(obj[i][ii]);
            } else if (typeof obj[i][ii] === 'object' && obj[i][ii]) {
              for (const iii in template[i][ii]) {
                if (obj[i][ii][iii] !== undefined) {
                  final_object[i][ii][iii] = obj[i][ii][iii];
                }
              }
            } else if (obj[i][ii] !== undefined) {
              final_object[i][ii] = obj[i][ii];
            }
          }
        } else if (obj[i] !== undefined) {
          final_object[i] = obj[i];
        }
      }
    }
    return final_object;
  };

  return {
    copy,
  };
};

export default useCopyObject;
