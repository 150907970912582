<template>
  <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 2.667c7.333 0 13.333 6 13.333 13.333s-6 13.333-13.333 13.333-13.333-6-13.333-13.333 6-13.333 13.333-13.333zM16 5.333c-2.533 0-4.8 0.8-6.533 2.267l14.933 14.933c1.333-1.867 2.267-4.133 2.267-6.533 0-5.867-4.8-10.667-10.667-10.667zM22.533 24.4l-14.933-14.933c-1.467 1.733-2.267 4-2.267 6.533 0 5.867 4.8 10.667 10.667 10.667 2.533 0 4.8-0.8 6.533-2.267z"
      fill-rule="evenodd"
      fill="currentColor"
    />
  </svg>
</template>

<script lang="ts">
  import { Component, Vue } from 'vue-property-decorator';

  @Component
  export default class CancelIcon extends Vue {
    /*****         computed       *****/
    /*****         watchers       *****/
    /*****         methods        *****/
    /*****         helpers        *****/
    /*****      vue lifecycle     *****/
  }
</script>
