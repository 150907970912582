import { RouteConfig } from 'vue-router';
import AuthRouteName from '@/enums/config/router/Auth';
import RegisterPage from '@/pages/auth/Register.vue';

const RegisterRouter: Array<RouteConfig> = [
  {
    path: 'register',
    name: AuthRouteName.REGISTER,
    component: RegisterPage,
    meta: {
      title: 'Register',
    },
  },
];

export default RegisterRouter;
