import { Component, Vue } from 'vue-property-decorator';
import Locale from '@/enums/config/Locale';
import PaymentType from '@/enums/generics/PaymentType';
import TranslationModule from '@/store/modules/Translation';

@Component
export default class TranslatePaymentTypeMixin extends Vue {
  public TranslatePaymentTypeMixin(type: PaymentType, locale: Locale = TranslationModule.getLocale): string {
    switch (type) {
      case PaymentType.BANK_TRANSFER:
        return this.trans('generic.payment_type.bank_transfer', 'Pārskaitījums', locale);
      case PaymentType.CASH:
        return this.trans('generic.payment_type.cash', 'Skaidra nauda', locale);
      case PaymentType.CREDIT_CARD:
        return this.trans('generic.payment_type.credit_card', 'Kredītkarte', locale);
      case PaymentType.OTHER:
      default:
        return this.trans('generic.payment_type.other', 'Cits', locale);
    }
  }
}
