import { Component, Vue } from 'vue-property-decorator';
import type IBankIban from '@/interfaces/bank/IBankIban';
import HelperRepository from '@/repository/Helper';

@Component
export default class FetchIbanMixin extends Vue {
  public async FetchIbanMixin(IBANnumber: string): Promise<IBankIban | null> {
    try {
      return (await HelperRepository.fetchIban(IBANnumber)).data;
    } catch (_) {
      this.$logger('There was a problem to fetch IBAN details or IBAN not found...');
      return null;
    }
  }
}
