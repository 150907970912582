import { Component, Vue } from 'vue-property-decorator';
import DocumentStatus from '@/enums/config/document/Status';
import type IDocumentList from '@/interfaces/document/IDocumentList';
import DocumentRepository from '@/repository/Document';
import CompanyModule from '@/store/modules/Company';

@Component
export default class BulkSetDocumentStatusMixin extends Vue {
  public async BulkSetDocumentStatusMixin(
    documents: IDocumentList[],
    status: DocumentStatus,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<string[]> {
    const documentUUIDs: string[] = documents.map((document) => document.uuid);
    await DocumentRepository.bulkSetStatus(companyUUID, documentUUIDs, status);
    return documentUUIDs;
  }
}
