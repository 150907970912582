import { Component, Vue } from 'vue-property-decorator';
import type Locale from '@/enums/config/Locale';
import DocumentRoute from '@/enums/config/document/Route';
import RecurringDocumentRoute from '@/enums/config/document/RecurringRoute';
import TranslationModule from '@/store/modules/Translation';

@Component
export default class TranslateDocumentRouteMixin extends Vue {
  public TranslateDocumentRouteMixin(route: DocumentRoute, locale: Locale = TranslationModule.getLocale): string {
    switch (route) {
      case DocumentRoute.WAYBILL:
        return this.trans('menu.documents.waybills', 'Pavadzīmes', locale);
      case DocumentRoute.ADVANCE_INVOICE:
        return this.trans('menu.documents.advance_invoices', 'Avansa rēķini', locale);
      case DocumentRoute.OFFER:
        return this.trans('menu.documents.offers', 'Piedāvājumi', locale);
      case DocumentRoute.FINANCIAL:
        return this.trans('generic.bookkeeping_types.financial_documents', 'Finanšu dokumenti', locale);
      case DocumentRoute.ALL:
        return this.trans('menu.documents.all', 'Visi', locale);
      case DocumentRoute.INVOICE:
      default:
        return this.trans('menu.documents.invoices', 'Rēķini', locale);
    }
  }

  public TranslateRecurringDocumentRouteMixin(
    route: RecurringDocumentRoute,
    locale: Locale = TranslationModule.getLocale,
  ): string {
    switch (route) {
      case RecurringDocumentRoute.WAYBILL:
        return this.trans('menu.recurring.waybills', 'Regulārās pavadzīmes', locale);
      case RecurringDocumentRoute.ADVANCE_INVOICE:
        return this.trans('menu.recurring.advance_invoices', 'Regulārie avansa rēķini', locale);
      case RecurringDocumentRoute.OFFER:
        return this.trans('menu.recurring.offers', 'Regulārie piedāvājumi', locale);
      case RecurringDocumentRoute.INVOICE:
      default:
        return this.trans('menu.recurring.invoices', 'Regulārie rēķini', locale);
    }
  }
}
