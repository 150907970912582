import _Vue from 'vue';
import type { ProviderInstance, VeeObserver } from 'vee-validate/dist/types/types';
import type IObserverError from '@/interfaces/config/IObserverError';

function firstError(formObserver: ProviderInstance): IObserverError {
  return (
    Object.entries((formObserver as unknown as VeeObserver).refs)
      .map(([key, value]) => ({ key, value }))
      .filter((error: IObserverError) => {
        return error.value.errors.length;
      })[0] || { key: '', value: { errors: [] } }
  );
}

export default function ErrorPlugin(Vue: typeof _Vue): void {
  Vue.prototype.covertToError = (formObserver: ProviderInstance): Record<string, string> => {
    const errors: Record<string, string> = {};

    Object.entries(formObserver.errors).forEach(([key, value]) => {
      if (value.length > 0) {
        const isArray = Array.isArray(value);
        errors[key] = isArray ? value[0] : value;
      }
    });
    return errors;
  };

  Vue.prototype.firstErrorKey = (formObserver: ProviderInstance): string => {
    return firstError(formObserver).key;
  };

  Vue.prototype.scrollToFirstError = (
    formObserver: ProviderInstance,
    fieldKey = firstError(formObserver).key,
  ): void => {
    if (fieldKey) {
      (formObserver as unknown as VeeObserver).refs[fieldKey].$el.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    } else {
      // Complicated case, when error is not in the form fields
      // We need to find the block with defined the error keys attribute
      // And look for the first error key in the block before scrolling
      const errorEntry = Object.entries(formObserver.errors).find(([_, value]) => value.length > 0);
      const errorKey = errorEntry ? errorEntry[0] : '';
      // Find all elements with block-keys attribute
      const elements = Array.from(document.querySelectorAll('[block-keys]'));
      // Find the first element with the error key in the block-keys attribute
      const element = elements.find((el) => {
        const blockAttrKeys = el.getAttribute('block-keys')?.split(',') || [];
        return blockAttrKeys.some((value) => {
          return value && errorKey.includes(value);
        });
      });
      // Scroll to the element
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  };
}

declare module 'vue/types/vue' {
  interface Vue {
    covertToError(formObserver: ProviderInstance): Record<string, string>;
    firstErrorKey(formObserver: ProviderInstance): string;
    scrollToFirstError(formObserver: ProviderInstance, fieldKey?: string): void;
  }
}
