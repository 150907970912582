import { Component, Mixins } from 'vue-property-decorator';
import CatchError from '@/decorators/CatchError';
import Loading from '@/decorators/Loading';
import type IChart from '@/interfaces/document/IChart';
import type IChartMixin from '@/interfaces/mixins/IChartMixin';
import CompanyModule from '@/store/modules/Company';
import DocumentModule from '@/store/modules/Document';
// import PageModule from '@/store/modules/Page';
import DocumentChartMixin from '@/mixins/chart/Document';

@Component
export default class ChartMixin extends Mixins<IChartMixin>(DocumentChartMixin) {
  // Fetch document chart data - use DocumentChartMixin
  @Loading()
  @CatchError()
  public async fetchDocumentChart(companyUUID: string = CompanyModule.getCompanyUUID): Promise<void> {
    const chartData: IChart[] = await this.DocumentChartMixin(companyUUID);
    await DocumentModule.SET_DOCUMENT_CHART(chartData);
  }
}
