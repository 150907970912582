import TEST_DATA from '@/constants/config/TestData';
import type IPasswordForgotForm from '@/interfaces/forms/auth/IPasswordForgotForm';
import useEnv from '@/services/Env';

const { isDev } = useEnv();

const DEFAULT_PASSWORD_FORGOT_FORM: IPasswordForgotForm = {
  email: isDev ? TEST_DATA.email : '',
};

export default DEFAULT_PASSWORD_FORGOT_FORM;
