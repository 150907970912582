import { Component, Vue } from 'vue-property-decorator';
import StockRepository from '@/repository/Stock';
import CompanyModule from '@/store/modules/Company';

@Component
export default class DeleteStockMixin extends Vue {
  public async DeleteStockMixin(stockUUID: string, companyUUID: string = CompanyModule.getCompanyUUID): Promise<void> {
    await StockRepository.delete(companyUUID, stockUUID);
  }
}
