import { Component, Vue } from 'vue-property-decorator';
import type IChart from '@/interfaces/document/IChart';
import ChartRepository from '@/repository/Chart';
import CompanyModule from '@/store/modules/Company';
import PageModule from '@/store/modules/Page';

@Component
export default class DocumentChartMixin extends Vue {
  public async DocumentChartMixin(companyUUID: string = CompanyModule.getCompanyUUID): Promise<IChart[]> {
    return (await ChartRepository.documentChart(companyUUID, PageModule.getUrlParams)).data;
  }
}
