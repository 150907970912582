import IEmailSettings, { IEmailPreference } from '@/interfaces/company/IEmailSettings';

export const DEFAULT_EMAIL_PREFERENCE: IEmailPreference = {
  email_subject: '',
  email_message: '',
  email_bcc: [],
};

const DEFAULT_EMAIL_SETTINGS: IEmailSettings = {
  invoice: {
    ...DEFAULT_EMAIL_PREFERENCE,
  },
  advance_invoice: {
    ...DEFAULT_EMAIL_PREFERENCE,
  },
  offer: {
    ...DEFAULT_EMAIL_PREFERENCE,
  },
};

export default DEFAULT_EMAIL_SETTINGS;
