<template>
  <svg width="667" height="667" viewBox="0 0 667 667" fill="none">
    <path
      d="M199.601 1.46706C187.067 4.40039 174.534 15.3337 169.867 27.3337C167.467 33.8671 167.334 40.2671 167.334 249.334C167.334 454.134 167.467 465.067 169.734 471.6C172.934 480.934 182.134 490.934 191.734 495.6L199.334 499.334H634L640.267 496.4C649.067 492.4 658 483.6 662.267 474.8L666 467.334L666.401 254.667C666.667 97.7337 666.401 40.1337 665.334 34.6671C662.401 21.0671 653.067 9.46706 640.134 3.46706L634 0.667057L419.334 0.400391C296.534 0.400391 202.534 0.800391 199.601 1.46706ZM625.334 458.667H208.001V41.3337H625.334V458.667Z"
      fill="currentColor"
    />
    <path
      d="M32.9339 168.134C20.6672 171.067 9.86719 179.867 4.13385 192L0.667188 199.334L-0.132812 290.8L40.6672 290L41.0672 248.934L41.3339 208H145.334V166.667L91.7339 166.8C62.1339 166.934 35.7339 167.467 32.9339 168.134Z"
      fill="currentColor"
    />
    <path d="M0.00052084 500.134L40.6672 499.334V334L0.00052084 333.2V500.134Z" fill="currentColor" />
    <path
      d="M0.267187 588.267C0.667187 632.934 0.800521 634.134 3.86719 640.934C7.60052 649.2 17.6005 659.067 26.4005 663.067C32.4005 665.867 34.6672 666 78.4005 666.4L124.134 666.8L123.334 626L82.4005 625.6L41.3339 625.334V542.667H-0.132812L0.267187 588.267Z"
      fill="currentColor"
    />
    <path
      d="M458.667 625.334H376.001V666.8L421.734 666.4C465.067 666 467.601 665.867 473.467 663.2C481.334 659.6 489.867 651.867 494.001 644.8C499.067 636 500.001 626.267 500.001 583.067V542.667H458.667V625.334Z"
      fill="currentColor"
    />
    <path
      d="M167.601 626.267C167.067 626.667 166.667 636 166.667 646.934V666.667H333.334V647.6C333.334 634.534 332.801 628 331.734 626.934C330.534 625.734 310.401 625.334 249.334 625.334C204.801 625.334 168.001 625.734 167.601 626.267Z"
      fill="currentColor"
    />
  </svg>
</template>

<script lang="ts">
  import { Component, Vue } from 'vue-property-decorator';

  @Component
  export default class DragAndDropIcon extends Vue {
    /*****         computed       *****/
    /*****         watchers       *****/
    /*****         methods        *****/
    /*****         helpers        *****/
    /*****      vue lifecycle     *****/
  }
</script>
