<template>
  <div class="block space-y-2">
    <TextField
      v-model="formValue"
      :autocomplete="autocomplete"
      :placeholder="placeholder"
      :clearable="clearable"
      :autofocus="autofocus"
      :show-label="showLabel"
      :show-error="showError"
      :show-error-border="showErrorBorder"
      :text-color="textColor"
      :color="color"
      :label="label"
      :dark="dark"
      :name="name"
      :hint="hint"
      :rules="rules"
      :debounce-validation="debounceValidation"
      :readonly="readonly"
      :disabled="disabled"
      :loading="isLoading"
      :rounded="rounded"
      :reverse="reverse"
      :input-class="inputClass"
      @blur="blur"
      @focus="focus"
      @clear="clear"
      @input:debounce="inputDebounce"
      @error="error"
      @change="change"
      @keydown="keydown"
      @keypress="keypress"
    >
      <template #label="{ label }">
        <div class="flex items-center jsutfiy-start gap-2">
          <slot name="label" :label="label">
            <span v-text="label" />
          </slot>
          <Tooltip arrow :max-width="220" :placement="Placement.RIGHT" interactive>
            <template #tooltip>
              <span
                v-html="
                  sanitizeHTML(
                    trans(
                      'tooltip.vat_check_by_api',
                      'PVN maksātāja statusa pārbaudi veic <a target=\'_blank\' href=\'https://ec.europa.eu/taxation_customs/vies/\'>PVN informācijas apmaiņas sistēma</a>',
                    ),
                  )
                "
              />
            </template>
          </Tooltip>
        </div>
      </template>
      <template #append>
        <v-scale-transition hide-on-leave leave-absolute>
          <Tooltip
            arrow
            :max-width="160"
            :placement="Placement.TOP"
            :text="
              isVerified
                ? trans('tooltip.company_is_vat_payer', 'Uzņēmums ir PVN maksātājs')
                : trans('tooltip.cannot_verify_vat_status', 'Nevaram noteikt PVN maksātāja statusu')
            "
          >
            <template #default>
              <v-scale-transition group hide-on-leave leave-absolute class="cursor-pointer">
                <v-icon v-if="isVerified" key="verified" :color="disabled ? 'silver' : 'primary'" small>
                  $checkBadge
                </v-icon>
                <v-icon v-else-if="formValue" key="notfound" :color="disabled ? 'silver' : 'warning'" small>
                  $warning
                </v-icon>
              </v-scale-transition>
            </template>
          </Tooltip>
        </v-scale-transition>
      </template>
    </TextField>
    <ActionButton
      :show="canBeChecked(formValue)"
      :loading="isLoading"
      primary
      :text="trans('button.check', 'Pārbaudīt')"
      @click="check(formValue ?? '')"
      class="flex items-center justify-end"
    />
  </div>
</template>

<script lang="ts">
  import { Component, Emit, Mixins, Prop, VModel, Watch } from 'vue-property-decorator';
  import Placement from '@/enums/generics/Placement';
  import type ICheckVatStatusForm from '@/interfaces/forms/helper/ICheckVatStatusForm';
  import HelperMixin from '@/mixins/Helper';
  import Tooltip from '@/components/global/Tooltip.vue';
  import TextField from '@/components/global/inputs/TextField.vue';
  import ActionButton from '@/components/global/buttons/Action.vue';

  @Component({
    components: {
      Tooltip,
      TextField,
      ActionButton,
    },
  })
  export default class VatNumberField extends Mixins(HelperMixin) {
    @VModel() formValue!: string | null;

    @Prop({ default: '' }) checkVatNumber!: string;

    @Prop({ default: 'off' }) autocomplete?: string;
    @Prop({ default: '' }) placeholder?: string;
    @Prop({ default: '' }) name?: string;
    @Prop({ default: '' }) hint?: string;
    @Prop({ default: '' }) label?: string;
    @Prop({ default: true }) showLabel?: boolean;
    @Prop({ default: true }) showError?: boolean;
    @Prop({ default: true }) showErrorBorder?: boolean;

    @Prop({ default: 0 }) debounceValidation?: number;
    @Prop({ default: '' }) rules?: string;

    @Prop({ type: Boolean }) clearable?: boolean;
    @Prop({ type: Boolean }) autofocus?: boolean;
    @Prop({ type: Boolean }) readonly?: boolean;
    @Prop({ type: Boolean }) disabled?: boolean;
    @Prop({ type: Boolean }) loading?: boolean;
    @Prop({ type: Boolean }) reverse?: boolean;

    @Prop({ type: Boolean }) rounded?: boolean;
    @Prop({ type: Boolean }) dark?: boolean;

    @Prop() color?: string;
    @Prop() textColor?: string;

    @Prop({ default: '' }) inputClass?: string;

    public isLoading = false;
    public isVerified = false;

    public prevCheckedVatNumber = '';

    /*****         computed       *****/

    public get Placement(): typeof Placement {
      return Placement;
    }

    public get hasChecked(): boolean {
      return !!this.formValue && this.prevCheckedVatNumber === this.formValue;
    }

    /*****         watchers       *****/

    @Watch('checkVatNumber')
    private async watchForParentVatNumber(): Promise<void> {
      await this.check(this.checkVatNumber);
    }

    /*****         methods        *****/

    @Emit('checked')
    public async check(vatNumber: string): Promise<void> {
      this.isLoading = true;

      if (this.canBeChecked(vatNumber)) {
        const payload: ICheckVatStatusForm = {
          registration_number: vatNumber.substring(2),
          country: vatNumber.substring(0, 2).toUpperCase(),
        };

        this.isVerified = (await this.checkVatStatus(payload)).is_vat_payer;
        if (this.isVerified) {
          this.formValue = `${payload.country}${payload.registration_number}`;
          this.prevCheckedVatNumber = `${payload.country}${payload.registration_number}`;
        } else {
          this.prevCheckedVatNumber = this.formValue ?? '';
        }
      }

      this.isLoading = false;
    }

    @Emit('input:debounce')
    public inputDebounce(userInput: string): string {
      return userInput;
    }

    @Emit('change')
    public change(): void {}

    @Emit('keypress')
    public keypress(e: KeyboardEvent): KeyboardEvent {
      return e;
    }

    @Emit('keydown')
    public keydown(e: KeyboardEvent): KeyboardEvent {
      return e;
    }

    @Emit('blur')
    public blur(e: FocusEvent): FocusEvent {
      return e;
    }

    @Emit('focus')
    public focus(e: FocusEvent): FocusEvent {
      return e;
    }

    @Emit('clear')
    public clear(): void {}

    @Emit('error')
    public error(error: string): string {
      return error;
    }

    /*****         helpers        *****/

    public canBeChecked(vatNumber: string | null): boolean {
      return !!vatNumber && vatNumber.length > 5 && !(this.hasChecked && this.isVerified);
    }

    /*****      vue lifecycle     *****/

    async beforeMount() {
      await this.check(this.formValue ?? '');
    }
  }
</script>
