import { Component, Vue } from 'vue-property-decorator';
import type IPartner from '@/interfaces/partner/IPartner';
import type IPartnerForm from '@/interfaces/forms/partner/IPartnerForm';
import PartnerRepository from '@/repository/Partner';
import CompanyModule from '@/store/modules/Company';

@Component
export default class CreatePartnerMixin extends Vue {
  public async CreatePartnerMixin(
    payload: IPartnerForm,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<IPartner> {
    return (await PartnerRepository.create(companyUUID, payload)).data;
  }
}
