import cloneDeep from 'lodash-es/cloneDeep';
import { Component, Vue } from 'vue-property-decorator';
import type { IDocumentPreference } from '@/interfaces/company/IDocumentSettings';
import type IRecurringDocument from '@/interfaces/document/IRecurringDocument';
import CompanyModule from '@/store/modules/Company';
import RecurringModule from '@/store/modules/Recurring';

@Component
export default class InitRecurringTypeMixin extends Vue {
  public async InitRecurringTypeMixin(): Promise<IRecurringDocument> {
    const document: IRecurringDocument = cloneDeep(RecurringModule.getRecurring);

    const documentPreferences: IDocumentPreference = await CompanyModule.GET_DOCUMENT_PREFERENCES(document.type);

    document.number_format = documentPreferences.number_format;
    // Notes should be copied only if the document is not a copy
    if (!RecurringModule.isCopyRecurring) {
      document.notes = documentPreferences.default_notes;
    }

    return cloneDeep(document);
  }
}
