import _Vue from 'vue';
import DOMPurify from 'dompurify';

export default function StringPlugin(Vue: typeof _Vue): void {
  Vue.prototype.removeWhiteSpaces = (str: string): string => {
    return str.replace(/\s/g, '');
  };

  Vue.prototype.sanitizeHTML = (html: string): string => {
    return DOMPurify.sanitize(html);
  };

  Vue.prototype.snakeToCamel = (str: string): string => {
    return str.replace(/(_\w)/g, (match) => {
      return match[1].toUpperCase();
    });
  };
}

declare module 'vue/types/vue' {
  interface Vue {
    removeWhiteSpaces(str: string): string;
    sanitizeHTML(html: string): string;
    snakeToCamel(str: string): string;
  }
}
