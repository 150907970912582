import cloneDeep from 'lodash-es/cloneDeep';
import { Component, Vue } from 'vue-property-decorator';
import { AxiosResponse } from 'axios';
import type IRegisterProfileForm from '@/interfaces/forms/auth/IRegisterProfileForm';
import AuthRepository from '@/repository/Auth';

@Component
export default class RegisterMixin extends Vue {
  public async RegisterMixin(payload: IRegisterProfileForm): Promise<{ message: string }> {
    const response: AxiosResponse = await AuthRepository.register(payload);

    return cloneDeep(response.data);
  }
}
