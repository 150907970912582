<template>
  <v-menu
    content-class="btn--menu btn--items shadow-lg"
    transition="slide-y-transition"
    nudge-bottom="3"
    offset-y
    bottom
    eager
    left
  >
    <template #activator="{ on, attrs, value: show }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        :color="colorPalete.bg"
        height="45"
        depressed
        class="btn--menu pa-2"
        :class="{ 'btn--hover': show }"
      >
        <div class="flex items-center justify-center gap-2" :class="colorPalete.text">
          <span style="max-width: 170px" class="leading-none text-body-1 truncate mt-1" v-text="company.name" />
          <v-icon x-small :class="show ? 'rotate-180' : 'rotate-0'">$chevronDown</v-icon>
          <v-avatar :color="colorPalete.color" size="36">
            <div
              class="flex items-center justify-center w-full h-full"
              :style="composeCompanyInitialsCss(company.name)"
            >
              <v-img v-if="brand.logo" :src="brand.logo" />
              <span v-else class="text-body-1 font-weight-medium" v-text="composeCompanyInitials(company.name)" />
            </div>
          </v-avatar>
        </div>
      </v-btn>
    </template>

    <v-list :color="useTheme ? '#000' : '#FFF'" elevation="2" max-width="400px" class="text-body-2 pa-2">
      <!-- Switch Company -->
      <SwitchCompanyModal :key="isLoadingLocale" use-delay>
        <template #button="{ open }">
          <v-list-item class="button-hover" :class="colorPalete.text" style="min-height: 0" @click="open()">
            <v-list-item-icon class="mx-0">
              <v-icon :color="colorPalete.color" small>$repeat</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="text-left mt-1">
              <v-list-item-title>
                <span
                  class="leading-none text-body-2"
                  v-text="trans('workspace.title.change_company', 'Mainīt kompāniju')"
                />
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </SwitchCompanyModal>
      <!-- Company settings -->
      <v-list-item
        v-if="isAdmin"
        :to="{ name: SettingsRouteName.COMPNAY }"
        class="hidden lg-flex"
        :class="colorPalete.text"
        style="min-height: 0"
      >
        <v-list-item-icon class="mx-0">
          <v-icon :color="colorPalete.color" small>$briefcase</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="text-left mt-1">
          <v-list-item-title>
            <span
              class="leading-none text-body-2"
              v-text="trans('workspace.title.company_settings', 'Uzņēmuma iestatījumi')"
            />
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- User profile -->
      <v-list-item
        :to="{ name: ProfileRouteName.DATA }"
        class="hidden lg-flex"
        :class="colorPalete.text"
        style="min-height: 0"
      >
        <v-list-item-icon class="mx-0">
          <v-icon :color="colorPalete.color" small>$cog</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="text-left mt-1">
          <v-list-item-title>
            <span
              class="leading-none text-body-2"
              v-text="trans('workspace.title.user_settings', 'Lietotāja iestatījumi')"
            />
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- Add new company -->
      <AddCompanyModal use-delay>
        <template #button="{ open }">
          <v-list-item class="button-hover" :class="colorPalete.text" style="min-height: 0" @click="open()">
            <v-list-item-icon class="mx-0">
              <v-icon :color="colorPalete.color" small>$plus</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="text-left mt-1">
              <v-list-item-title>
                <span class="leading-none text-body-2" v-text="trans('button.add.company', 'Pievienot kompāniju')" />
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </AddCompanyModal>
      <!-- Logout -->
      <v-list-item :class="colorPalete.text" style="min-height: 0" @click="logout">
        <v-list-item-icon class="mx-0">
          <v-icon :color="colorPalete.color" small>$logout</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="text-left mt-1">
          <v-list-item-title>
            <span class="leading-none text-body-2" v-text="trans('menu.logout', 'Iziet')" />
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <!-- Support -->
    <v-list color="primary" elevation="2" max-width="400px" class="text-body-2 pa-0 button-hover">
      <SupportModal use-delay>
        <template #button="{ open }">
          <v-list-item color="primary" :class="colorPalete.text" style="min-height: 0" @click="open()">
            <v-list-item-icon class="ml-2 mr-0">
              <v-icon :color="colorPalete.color" small>$headphones</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="text-left mt-1">
              <v-list-item-title>
                <span class="leading-none text-body-2" v-text="trans('workspace.support.title', 'Atbalsts')" />
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </SupportModal>
    </v-list>
  </v-menu>
</template>

<script lang="ts">
  import { Component, Mixins, Prop } from 'vue-property-decorator';
  import useCopyObject from '@/services/CopyObject';
  import DEFAULT_COMPANY_BRAND from '@/constants/mocks/company/CompanyBrand';
  import DEFAULT_COMPANY_DETAILS from '@/constants/mocks/company/CompanyDetails';
  import SettingsRouteName from '@/enums/config/router/Settings';
  import ProfileRouteName from '@/enums/config/router/Profile';
  import type IUser from '@/interfaces/user/IUser';
  import type IColorSchema from '@/interfaces/config/IColorSchema';
  import type ICompanyBrand from '@/interfaces/company/ICompanyBrand';
  import type ICompanyDetails from '@/interfaces/company/ICompanyDetails';
  import AuthMixin from '@/mixins/Auth';
  import CompanyModule from '@/store/modules/Company';
  import LoadingModule from '@/store/modules/Loading';
  import UserModule from '@/store/modules/User';
  import SwitchCompanyModal from '@/components/global/modals/SwitchCompany.vue';
  import AddCompanyModal from '@/components/global/modals/AddCompany.vue';
  import SupportModal from '@/components/global/modals/Support.vue';

  @Component({
    components: {
      SwitchCompanyModal,
      AddCompanyModal,
      SupportModal,
    },
  })
  export default class UserNav extends Mixins(AuthMixin) {
    @Prop() colorPalete!: IColorSchema;
    @Prop() useTheme!: boolean;

    /*****         computed       *****/

    public get user(): IUser | null {
      return UserModule.getUser;
    }

    public get isAdmin(): boolean {
      return UserModule.isAdmin;
    }

    public get isLoadingLocale(): boolean {
      return LoadingModule.isLoadingLocale;
    }

    public get company(): ICompanyDetails {
      return useCopyObject().copy(CompanyModule.getCompany, DEFAULT_COMPANY_DETAILS);
    }

    public get brand(): ICompanyBrand {
      return useCopyObject().copy(CompanyModule.getCompany, DEFAULT_COMPANY_BRAND);
    }

    public get SettingsRouteName(): typeof SettingsRouteName {
      return SettingsRouteName;
    }

    public get ProfileRouteName(): typeof ProfileRouteName {
      return ProfileRouteName;
    }

    /*****         watchers       *****/
    /*****         methods        *****/

    public async logout(): Promise<void> {
      await this.authLogout();
    }

    /*****         helpers        *****/
    /*****      vue lifecycle     *****/
  }
</script>
