import cloneDeep from 'lodash-es/cloneDeep';
import { Component, Mixins } from 'vue-property-decorator';
import CatchError from '@/decorators/CatchError';
import Loading from '@/decorators/Loading';
import AppState from '@/enums/generics/AppState';
import type ISupportForm from '@/interfaces/forms/ISupportForm';
import type ISupportMixin from '@/interfaces/mixins/ISupportMixin';

import RequestSupportMixin from '@/mixins/support/RequestSupport';

@Component
export default class SupportMixin extends Mixins<ISupportMixin>(RequestSupportMixin) {
  // Send bug report or make suggestion to support - use RequestSupportMixin
  @Loading()
  @CatchError()
  public async requestSupport(payload: ISupportForm): Promise<boolean> {
    await this.RequestSupportMixin(payload);

    await this.$notifier({
      message: this.trans('notify.success.email_sent', 'E-pasts veiksmīgi nosūtīts'),
      state: AppState.SUCCESS,
    });

    return cloneDeep(true);
  }
}
