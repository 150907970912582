import { Component, Vue } from 'vue-property-decorator';
import type IStockListResponse from '@/interfaces/stock/response/IStockListResponse';
import type IStock from '@/interfaces/stock/IStock';
import StockRepository from '@/repository/Stock';
import CompanyModule from '@/store/modules/Company';

@Component
export default class ListStocSearchkMixin extends Vue {
  public async ListStocSearchkMixin(
    search: string,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<IStock[]> {
    const response: IStockListResponse = (await StockRepository.list(companyUUID, { search })).data;
    return response.data;
  }
}
