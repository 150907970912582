import { RouteConfig } from 'vue-router';
import RecurringRouteName from '@/enums/config/router/Recurring';

const RecurringRouter: Array<RouteConfig> = [
  {
    path: 'recurring',
    component: () => import(/* webpackChunkName: "appView" */ '@/views/Recurring.vue'),
    meta: {
      guard: ['redirect', 'admin'],
    },
    children: [
      {
        path: '/',
        name: RecurringRouteName.INDEX,
        meta: {
          title: 'Recurring',
        },
      },
      {
        path: ':document',
        name: RecurringRouteName.LIST,
        component: () => import(/* webpackChunkName: "appView" */ '@/pages/app/recurring/Index.vue'),
        meta: {
          parent: RecurringRouteName.INDEX,
          title: 'Recurring List',
        },
      },
      {
        path: ':document/create',
        name: RecurringRouteName.CREATE,
        component: () => import(/* webpackChunkName: "appView" */ '@/pages/app/recurring/View.vue'),
        meta: {
          parent: RecurringRouteName.INDEX,
          title: 'Create recurring document',
        },
      },
      {
        path: ':document/copy/:uuid',
        name: RecurringRouteName.COPY,
        component: () => import(/* webpackChunkName: "appView" */ '@/pages/app/recurring/View.vue'),
        meta: {
          parent: RecurringRouteName.INDEX,
          title: 'Copy recurring document',
        },
        props: true,
      },
      {
        path: ':document/edit/:uuid',
        name: RecurringRouteName.EDIT,
        component: () => import(/* webpackChunkName: "appView" */ '@/pages/app/recurring/View.vue'),
        meta: {
          parent: RecurringRouteName.INDEX,
          title: 'Edit recurring document',
        },
        props: true,
      },
      {
        path: ':document/convert/:uuid',
        name: RecurringRouteName.CONVERT,
        component: () => import(/* webpackChunkName: "appView" */ '@/pages/app/recurring/View.vue'),
        meta: {
          parent: RecurringRouteName.INDEX,
          title: 'Document conversion to recurring',
        },
        props: true,
      },
    ],
  },
];

export default RecurringRouter;
