import _Vue from 'vue';
import CHAR_COLOR_MAP from '@/constants/config/CharColorMap';

function retrieveInitials(value: string): string {
  const cleanedValue = value
    .replace(/[^0-9a-zā-žа-яё\s]/gi, '')
    .replace(/SIA/g, '')
    .replace(/Sabiedrība ar ierobežotu atbildību/g, '')
    .trim();

  const names = cleanedValue.split(' ');

  const initials =
    names.length === 1
      ? cleanedValue.charAt(0) + cleanedValue.charAt(cleanedValue.length - 1)
      : names
          .slice(0, 2)
          .map((name) => name.charAt(0))
          .join('');

  return initials.toUpperCase();
}

function retrieveInitialsCss(color: string, opacity: number): string {
  const [r, g, b] = color
    .slice(1)
    .match(/.{2}/g)
    ?.map((hex) => parseInt(hex, 16)) ?? [0, 0, 0];
  const rgba = `rgba(${r}, ${g}, ${b}, ${opacity / 100})`;
  return `background: ${rgba} !important; color: ${color} !important;`;
}

export default function InitialsPlugin(Vue: typeof _Vue): void {
  // Compose company initials from company name
  Vue.prototype.composeCompanyInitials = retrieveInitials;

  // Compose company intials css from company name
  Vue.prototype.composeCompanyInitialsCss = (name: string): string => {
    const initial: number = retrieveInitials(name).charCodeAt(0);
    const color = CHAR_COLOR_MAP[initial] || '#36B3B3';
    return retrieveInitialsCss(color, 10);
  };

  // Compose clean company name
  Vue.prototype.composeCompanyCleanName = (value: string) => {
    const match = value.match(/"([^"]+)"/);
    return match ? match[1] : value;
  };
}

declare module 'vue/types/vue' {
  interface Vue {
    composeCompanyInitials(value: string): string;
    composeCompanyInitialsCss(name: string): string;
    composeCompanyCleanName(value: string): string;
  }
}
