import _Vue from 'vue';
import CurrencyISO from '@/enums/config/iso/CurrencyISO';
import Integer from '@/enums/config/Integer';

export default function NumberPlugin(Vue: typeof _Vue): void {
  // Regex validation that validates given argument for non-numeric characters
  Vue.prototype.stripFloat = (input: string | number | null, isPositive = Integer.POSITIVE): number | null => {
    if (input === null) {
      return null;
    }
    const str = input.toString().replace(',', '.');
    const match = str.match(/-?\d+(\.\d*)?\S*/);
    if (match) {
      const num = parseFloat(match[0]);
      return isPositive ? Math.abs(num) : num;
    }

    return null;
  };

  // Regex validation that validates given argument for non-numeric characters
  Vue.prototype.stripInteger = (input: string | number | null, isPositive = Integer.POSITIVE): number | null => {
    if (input === null) {
      return null;
    }
    const str = input.toString();
    const match = str.match(/-?\d+/);
    if (match) {
      const num = parseInt(match[0]);
      return isPositive ? Math.abs(num) : num;
    }

    return null;
  };

  // Returns always number - in case it is not number return zero
  Vue.prototype.toNumber = (value: number | string | null): number => {
    if (value) {
      return Number(value ? value.toString().replace(',', '.') : 0);
    }
    return 0;
  };

  // Return number or null
  Vue.prototype.toNumberOrNull = (value: number | string | null): number | null => {
    if (value) {
      return Number(value ? value.toString().replace(',', '.') : 0);
    }
    return null;
  };

  // Check if number is between two numbers
  Vue.prototype.isBetweenNumbers = (num: number, min: number, max: number): boolean => {
    return num >= min && num <= max;
  };

  // Format total amolunt to human readablt number with currency
  Vue.prototype.formatNumberWithCurrency = (amount: number, currency = CurrencyISO.EUR): string => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    const formattedAmount = formatter.format(amount);
    const currencySymbol = formatter.formatToParts(0)[0].value;

    return formattedAmount.replace(currencySymbol, '').trim().replaceAll(',', ' ') + ' ' + currencySymbol;
  };

  // Allows only positive integer values
  Vue.prototype.positiveInteger = (e: KeyboardEvent): void => {
    if (e instanceof KeyboardEvent) {
      const code: number = e.key.charCodeAt(0) || 0;
      const isNumber: boolean = code >= 48 && code <= 57;
      const isCopyOrPaste: boolean = e.metaKey && (code === 99 || code === 118);

      if (!isNumber && !isCopyOrPaste) {
        e.preventDefault();
      }
    }
  };

  // Allows only negative integer values
  Vue.prototype.negativeInteger = (e: KeyboardEvent): void => {
    if (e instanceof KeyboardEvent) {
      const code: number = e.key.charCodeAt(0) || 0;
      const isNegative: boolean = e.target instanceof HTMLInputElement && e.target.value.includes('-');
      const isNumber: boolean = code >= 48 && code <= 57;
      const isMinus: boolean = code === 45;
      const isCopyOrPaste: boolean = e.metaKey && (code === 99 || code === 118);

      if (!isNumber && !(isMinus && !isNegative) && !isCopyOrPaste) {
        e.preventDefault();
      } else if (isMinus && isNegative) {
        e.preventDefault();
      }
    }
  };

  // Allows only positive float values
  Vue.prototype.positiveFloat = (e: KeyboardEvent): void => {
    if (e instanceof KeyboardEvent) {
      const code: number = e.key.charCodeAt(0) || 0;
      const isNumber: boolean = code >= 48 && code <= 57;
      const isDecimal: boolean = code === 46 || code === 44; // Check for comma as well
      const isCopyOrPaste: boolean = e.metaKey && (code === 99 || code === 118);

      if (!isNumber && !isDecimal && !isCopyOrPaste) {
        e.preventDefault();
      } else if (
        isDecimal &&
        e.target instanceof HTMLInputElement &&
        (e.target.value.includes('.') || e.target.value.includes(','))
      ) {
        e.preventDefault();
      }
    }
  };

  // Allows only negative float values
  Vue.prototype.negativeFloat = (e: KeyboardEvent): void => {
    if (e instanceof KeyboardEvent) {
      const code: number = e.key.charCodeAt(0) || 0;
      const isNegative: boolean = e.target instanceof HTMLInputElement && e.target.value.includes('-');
      const isNumber: boolean = code >= 48 && code <= 57;
      const isMinus: boolean = code === 45;
      const isDecimal: boolean = code === 46 || code === 44; // Check for comma as well
      const isCopyOrPaste: boolean = e.metaKey && (code === 99 || code === 118);

      if (!isNumber && !(isMinus && !isNegative) && !isDecimal && !isCopyOrPaste) {
        e.preventDefault();
      } else if (isMinus && isNegative) {
        e.preventDefault();
      } else if (
        isDecimal &&
        e.target instanceof HTMLInputElement &&
        (e.target.value.includes('.') || e.target.value.includes(','))
      ) {
        e.preventDefault();
      }
    }
  };

  // Allow to enter only characters that could be used in phone number
  Vue.prototype.phoneNumber = (e: KeyboardEvent): void => {
    if (e instanceof KeyboardEvent) {
      const code: number = e.key.charCodeAt(0) || 0;
      const isNumber: boolean = code >= 48 && code <= 57;
      const isBracket: boolean = code === 40 || code === 41;
      const isDash: boolean = code === 45;
      const isSpace: boolean = code === 32;
      const isCopyOrPaste: boolean = e.metaKey && (code === 99 || code === 118);

      if (!isNumber && !isBracket && !isDash && !isSpace && !isCopyOrPaste) {
        e.preventDefault();
      }
    }
  };

  // Floor down number
  Vue.prototype.floorDown = (value: number | null, precision: number = 2): number => {
    if (value) {
      const isNegative: boolean = value < 0;
      const inputValue: number = isNegative ? value * -1 : value;

      const factor = Math.pow(10, precision);
      const n = precision < 0 ? inputValue : 0.01 / factor + inputValue;
      const result = Math.trunc((n + Number.EPSILON) * factor) / factor;

      return isNegative ? result * -1 : result;
    }
    return 0;
  };

  // Round up number
  Vue.prototype.roundUp = (value: number | null, precision: number = 2): number => {
    if (value) {
      const isNegative: boolean = value < 0;
      const inputValue: number = isNegative ? value * -1 : value;

      const factor = Math.pow(10, precision);
      const n = precision < 0 ? inputValue : 0.01 / factor + inputValue;
      const result = Math.round((n + Number.EPSILON) * factor) / factor;

      return isNegative ? result * -1 : result;
    }
    return 0;
  };

  // Count how many decimal places number have
  Vue.prototype.countDecimals = (input: number): number => {
    const str = input.toString();
    const match = str.match(/\.(\d+)/);
    if (match) {
      return match[1].length;
    }
    return 0;
  };

  Vue.prototype.setDecimals = (input: number, min = 2): string => {
    return Vue.prototype.countDecimals(input) < 2 ? input.toFixed(min) : (input || 0).toString();
  };
}

declare module 'vue/types/vue' {
  interface Vue {
    stripFloat(value: string | number | null, isPositive?: Integer): number | null;
    stripInteger(value: string | number | null, isPositive?: Integer): number | null;

    toNumber(value: string | number | null): number;
    toNumberOrNull(value: string | number | null): number | null;
    isBetweenNumbers(num: number, min: number, max: number): boolean;
    formatNumberWithCurrency(amount: number, currency?: string): string;

    positiveInteger(evt: KeyboardEvent): void;
    negativeInteger(evt: KeyboardEvent): void;
    positiveFloat(evt: KeyboardEvent): void;
    negativeFloat(evt: KeyboardEvent): void;
    phoneNumber(evt: KeyboardEvent): number | string;

    floorDown(value: number | null, decimal?: number): number;
    roundUp(value: number | null, pow?: number): number;
    countDecimals(value: number): number;
    setDecimals(value: number, min?: number): number;
  }
}
