import { Component, Vue } from 'vue-property-decorator';
import type IRegisterCompanyForm from '@/interfaces/forms/auth/IRegisterCompanyForm';
import type ICompany from '@/interfaces/company/ICompany';
import CompanyRepository from '@/repository/Company';

@Component
export default class CreateCompanyMixin extends Vue {
  public async CreateCompanyMixin(payload: IRegisterCompanyForm): Promise<ICompany> {
    return (await CompanyRepository.create(payload)).data;
  }
}
