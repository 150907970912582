import { RouteConfig } from 'vue-router';
import PublicRouteName from '@/enums/config/router/Public';
import VerifyEmailPage from '@/pages/public/VerifyEmail.vue';

const VerifyEmailRouter: Array<RouteConfig> = [
  {
    path: 'email/verify',
    name: PublicRouteName.EMAIL_VERIFY,
    component: VerifyEmailPage,
    meta: {
      title: 'Email Verification',
    },
  },
];

export default VerifyEmailRouter;
