import app from '@/main';
import { RouteRecordPublic } from 'vue-router';
import DocumentRoute from '@/enums/config/document/Route';
import RecurringDocumentRoute from '@/enums/config/document/RecurringRoute';
import DashboardRouteName from '@/enums/config/router/Dashboard';
import RecurringRouteName from '@/enums/config/router/Recurring';
import SettingsRouteName from '@/enums/config/router/Settings';
import StockRouteName from '@/enums/config/router/Stock';
import PartnerRouteName from '@/enums/config/router/Partner';
import ProfileRouteName from '@/enums/config/router/Profile';
import type IUserNav from '@/interfaces/config/IUserNav';
import CompanyModule from '@/store/modules/Company';
import UserModule from '@/store/modules/User';

const useUserNav = () => {
  const appRoutes = (filterBy: string): string[] => {
    return app.$router
      .getRoutes()
      .filter((r: RouteRecordPublic) => r.name && r.name.includes(filterBy))
      .map((r: RouteRecordPublic) => r.name || 'noname');
  };

  const profileNav = (): IUserNav[] => {
    const routeName = app.$route.name || '';

    return [
      {
        id: 'data',
        active: appRoutes(ProfileRouteName.DATA).includes(routeName),
        title: app.trans('workspace.title.profile_data', 'Pamatinformācija'),
        route: { name: ProfileRouteName.DATA },
      },
      {
        id: 'locale',
        active: appRoutes(ProfileRouteName.LOCALE).includes(routeName),
        title: app.trans('workspace.title.system_language', 'Sistēmas valoda'),
        route: { name: ProfileRouteName.LOCALE },
      },
      {
        id: 'security',
        active: appRoutes(ProfileRouteName.SECURITY).includes(routeName),
        title: app.trans('workspace.title.password_change', 'Paroles maiņa'),
        route: { name: ProfileRouteName.SECURITY },
      },
      {
        id: 'deactivate',
        active: appRoutes(ProfileRouteName.DEACTIVATE).includes(routeName),
        title: app.trans('workspace.title.delete_account', 'Konta dzēšana'),
        route: { name: ProfileRouteName.DEACTIVATE },
      },
    ];
  };

  const settingsNav = (): IUserNav[] => {
    const routeName = app.$route.name || '';

    return [
      {
        id: 'company',
        active: appRoutes(SettingsRouteName.COMPNAY).includes(routeName),
        title: app.trans('workspace.title.company_settings', 'Uzņēmuma iestatījumi'),
        route: { name: SettingsRouteName.COMPNAY },
      },
      {
        id: 'brand',
        active: appRoutes(SettingsRouteName.BRAND).includes(routeName),
        title: app.trans('workspace.title.company_logo', 'Uzņēmuma logotips'),
        route: { name: SettingsRouteName.BRAND },
      },
      {
        id: 'document',
        active: appRoutes(SettingsRouteName.DOCUMENT).includes(routeName),
        title: app.trans('workspace.title.document_settings', 'Dokumenta iestatījumi'),
        route: { name: SettingsRouteName.DOCUMENT },
      },
      {
        id: 'email',
        active: appRoutes(SettingsRouteName.EMAIL).includes(routeName),
        title: app.trans('workspace.title.email', 'Epasts'),
        route: { name: SettingsRouteName.EMAIL },
      },
      {
        id: 'payments',
        active: appRoutes(SettingsRouteName.PAYMENTS).includes(routeName),
        title: app.trans('workspace.title.payment_method.plural', 'Maksājuma veidi'),
        route: { name: SettingsRouteName.PAYMENTS },
      },
      {
        id: 'users',
        active: appRoutes(SettingsRouteName.USERS).includes(routeName),
        title: app.trans('workspace.title.users', 'Lietotāji'),
        route: { name: SettingsRouteName.USERS },
      },
      // {
      //   id: 'subscription',
      //   active: appRoutes(SettingsRouteName.SUBSCRIPTION).includes(routeName),
      //   title: app.trans('workspace.title.subscription', 'Abonements'),
      //   route: { name: SettingsRouteName.SUBSCRIPTION },
      // },
    ];
  };

  const userDesktopNav = (): IUserNav[] => {
    const routeName = app.$route.name || '';
    const documentType = app.$route.params.document ?? '';

    const userNav: IUserNav[] = [
      {
        id: 'documents',
        active: appRoutes('app.dashboard').includes(routeName) || appRoutes('app.document').includes(routeName),
        title: app.trans('menu.documents', 'Dokumenti'),
        route: { name: DashboardRouteName.INDEX },
        childrens: [
          {
            id: DocumentRoute.ALL,
            active: documentType === DocumentRoute.ALL,
            title: app.trans('menu.documents.all', 'Visi'),
            route: { name: DashboardRouteName.LIST, params: { document: DocumentRoute.ALL } },
          },
          {
            id: DocumentRoute.INVOICE,
            active: documentType === DocumentRoute.INVOICE,
            title: app.trans('menu.documents.invoices', 'Rēķini'),
            route: { name: DashboardRouteName.LIST, params: { document: DocumentRoute.INVOICE } },
          },
          {
            id: DocumentRoute.WAYBILL,
            active: documentType === DocumentRoute.WAYBILL,
            title: app.trans('menu.documents.waybills', 'Pavadzīmes'),
            route: { name: DashboardRouteName.LIST, params: { document: DocumentRoute.WAYBILL } },
          },
          {
            id: DocumentRoute.ADVANCE_INVOICE,
            active: documentType === DocumentRoute.ADVANCE_INVOICE,
            title: app.trans('menu.documents.advance_invoices', 'Avansa rēķini'),
            route: { name: DashboardRouteName.LIST, params: { document: DocumentRoute.ADVANCE_INVOICE } },
          },
          {
            id: DocumentRoute.OFFER,
            active: documentType === DocumentRoute.OFFER,
            title: app.trans('menu.documents.offers', 'Piedāvājumi'),
            route: { name: DashboardRouteName.LIST, params: { document: DocumentRoute.OFFER } },
          },
          {
            id: DocumentRoute.FINANCIAL,
            active: documentType === DocumentRoute.FINANCIAL,
            title: app.trans('menu.documents.financial', 'Finanšu'),
            route: { name: DashboardRouteName.LIST, params: { document: DocumentRoute.FINANCIAL } },
          },
        ],
      },
      {
        id: 'partner',
        active: appRoutes('app.partner').includes(routeName),
        title: app.trans('menu.partners', 'Partneri'),
        route: { name: PartnerRouteName.INDEX },
      },
      {
        id: 'stock',
        active: appRoutes('app.stock').includes(routeName),
        title: app.trans('menu.items', 'Preces un pakalpojumi'),
        route: { name: StockRouteName.INDEX },
      },
      {
        id: 'recurring',
        active: appRoutes('app.recurring').includes(routeName),
        title: app.trans('menu.recurring', 'Regulārie dokumenti'),
        route: { name: RecurringRouteName.INDEX },
        childrens: [
          {
            id: RecurringDocumentRoute.INVOICE,
            active: documentType === RecurringDocumentRoute.INVOICE,
            title: app.trans('menu.documents.invoices', 'Rēķini'),
            route: { name: RecurringRouteName.LIST, params: { document: RecurringDocumentRoute.INVOICE } },
          },
          {
            id: RecurringDocumentRoute.WAYBILL,
            active: documentType === RecurringDocumentRoute.WAYBILL,
            title: app.trans('menu.documents.waybills', 'Pavadzīmes'),
            route: { name: RecurringRouteName.LIST, params: { document: RecurringDocumentRoute.WAYBILL } },
          },
          {
            id: RecurringDocumentRoute.ADVANCE_INVOICE,
            active: documentType === RecurringDocumentRoute.ADVANCE_INVOICE,
            title: app.trans('menu.documents.advance_invoices', 'Avansa rēķini'),
            route: { name: RecurringRouteName.LIST, params: { document: RecurringDocumentRoute.ADVANCE_INVOICE } },
          },
          {
            id: RecurringDocumentRoute.OFFER,
            active: documentType === RecurringDocumentRoute.OFFER,
            title: app.trans('menu.documents.offers', 'Piedāvājumi'),
            route: { name: RecurringRouteName.LIST, params: { document: RecurringDocumentRoute.OFFER } },
          },
        ],
      },
    ];

    return CompanyModule.hasCompany
      ? UserModule.isAdmin
        ? userNav
        : userNav.filter((nav) => nav.id !== 'recurring')
      : [];
  };

  const userMobileNav = (): IUserNav[] => {
    const routeName = app.$route.name || '';

    if (CompanyModule.hasCompany) {
      if (UserModule.isAdmin) {
        return userDesktopNav().concat([
          {
            id: 'settings',
            active: appRoutes('app.settings').includes(routeName),
            title: app.trans('workspace.title.company_settings', 'Uzņēmuma iestatījumi'),
            route: { name: SettingsRouteName.INDEX },
            childrens: settingsNav(),
          },
          {
            id: 'profile',
            active: appRoutes('app.profile').includes(routeName),
            title: app.trans('workspace.title.user_settings', 'Lietotāja iestatījumi'),
            route: { name: ProfileRouteName.INDEX },
            childrens: profileNav(),
          },
        ]);
      } else {
        return userDesktopNav().concat({
          id: 'profile',
          active: appRoutes('app.profile').includes(routeName),
          title: app.trans('workspace.title.user_settings', 'Lietotāja iestatījumi'),
          route: { name: ProfileRouteName.INDEX },
          childrens: profileNav(),
        });
      }
    } else {
      return [
        {
          id: 'profile',
          active: appRoutes('app.profile').includes(routeName),
          title: app.trans('workspace.title.user_settings', 'Lietotāja iestatījumi'),
          route: { name: ProfileRouteName.INDEX },
          childrens: profileNav(),
        },
      ];
    }
  };

  return {
    profileNav,
    settingsNav,
    userDesktopNav,
    userMobileNav,
  };
};

export default useUserNav;
