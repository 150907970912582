import { Component, Vue } from 'vue-property-decorator';
import DocumentType from '@/enums/config/document/Type';
import RecurringDocumentType from '@/enums/config/document/RecurringType';
import TranslationModule from '@/store/modules/Translation';

@Component
export default class TranslateDocumentDateMixin extends Vue {
  public TranslateDocumentDateMixin(
    type: DocumentType | RecurringDocumentType,
    locale: string = TranslationModule.getLocale,
  ): string {
    switch (type) {
      case DocumentType.WAYBILL:
        return this.trans('workspace.title.date.waybill', 'Pavadzīmes datums', locale);
      case DocumentType.CREDIT_NOTE:
        return this.trans('workspace.title.document_number.credit_note', 'Kredītrēķina numurs', locale);
      case DocumentType.ADVANCE_INVOICE:
        return this.trans('workspace.title.date.advance_invoice', 'Datums', locale);
      case DocumentType.OFFER:
        return this.trans('workspace.title.date.offer', 'Datums', locale);
      case DocumentType.INVOICE:
      default:
        return this.trans('workspace.title.date.invoice', 'Rēķina datums', locale);
    }
  }

  public TranslateDocumentDueDateMixin(
    type: DocumentType | RecurringDocumentType,
    locale: string = TranslationModule.getLocale,
  ): string {
    switch (type) {
      case DocumentType.WAYBILL:
        return this.trans('workspace.title.due_date.waybill', 'Apmaksas datums', locale);
      case DocumentType.CREDIT_NOTE:
        return this.trans('workspace.title.due_date.credit_note', 'Apmaksas datums', locale);
      case DocumentType.ADVANCE_INVOICE:
        return this.trans('workspace.title.due_date.advance_invoice', 'Apmaksas datums', locale);
      case DocumentType.OFFER:
        return this.trans('workspace.title.due_date.offer', 'Piedāvājums spekā līdz', locale);
      case DocumentType.INVOICE:
      default:
        return this.trans('workspace.title.due_date.invoice', 'Apmaksas datums', locale);
    }
  }
}
