import { RouteConfig } from 'vue-router';
import PublicRouteName from '@/enums/config/router/Public';
import InvitationPage from '@/pages/public/Invitations.vue';

const InvitationsRouter: Array<RouteConfig> = [
  {
    path: 'invitations/:uuid',
    name: PublicRouteName.INVITATION,
    component: InvitationPage,
    meta: {
      title: 'Invitations',
    },
  },
];

export default InvitationsRouter;
