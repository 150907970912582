7
<template>
  <div>
    <AppMobileNav v-if="isMobile" :color-palete="colorPalete" :nav-height="navHeight" :use-theme="useTheme" />
    <AppDesktopNav v-else :color-palete="colorPalete" :nav-height="navHeight" :use-theme="useTheme" />
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
  import useBreakpoints from '@/services/Breakpoints';
  import type IColorSchema from '@/interfaces/config/IColorSchema';
  import AppDesktopNav from '@/components/global/nav/app/brekpoint/Desktop.vue';
  import AppMobileNav from '@/components/global/nav/app/brekpoint/Mobile.vue';

  @Component({
    components: {
      AppDesktopNav,
      AppMobileNav,
    },
  })
  export default class AppNav extends Vue {
    @Prop({ type: Boolean }) darkOnly!: boolean;

    private minNavHeight: number = 56;
    private maxNavHeight: number = 86;

    public navHeight: number = this.maxNavHeight;
    public isScrolling: boolean = false;

    /*****         computed       *****/

    public get colorPalete(): IColorSchema {
      return {
        bg: this.useTheme ? 'black' : 'cultured',
        text: this.useTheme ? 'cultured--text' : 'black--text',
        color: this.useTheme ? 'cultured' : 'black',
      };
    }

    public get useTheme(): boolean {
      return this.isScrolling || this.darkOnly;
    }

    public get isMobile(): boolean {
      return useBreakpoints().isMobile() || useBreakpoints().isTablet();
    }

    /*****         watchers       *****/

    @Watch('$route')
    private routeChanged(): void {
      this.navHeight = this.maxNavHeight;
    }

    /*****         methods        *****/
    /*****         helpers        *****/

    public scrollHandler(): void {
      this.isScrolling = window.scrollY > 0;

      const scrollRange = this.minNavHeight - this.maxNavHeight;

      const maxScrollY = document.body.scrollHeight - window.innerHeight;
      const scrollPercent = Math.min(window.scrollY / maxScrollY, 1);

      this.navHeight = this.maxNavHeight + scrollPercent * scrollRange;
    }

    /*****      vue lifecycle     *****/

    beforeMount() {
      window.addEventListener('scroll', this.scrollHandler);
    }

    beforeDestroy() {
      window.removeEventListener('scroll', this.scrollHandler);
    }
  }
</script>
