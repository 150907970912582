<template>
  <svg width="22" height="11" viewBox="0 0 22 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 6C21.1046 6 22 5.10457 22 4C22 2.89543 21.1046 2 20 2C18.8954 2 18 2.89543 18 4C18 5.10457 18.8954 6 20 6Z"
      fill="currentColor"
    />
    <path
      d="M14 10C15.1046 10 16 9.10457 16 8C16 6.89543 15.1046 6 14 6C12.8954 6 12 6.89543 12 8C12 9.10457 12.8954 10 14 10Z"
      fill="currentColor"
    />
    <path
      d="M8 4C9.10457 4 10 3.10457 10 2C10 0.89543 9.10457 0 8 0C6.89543 0 6 0.89543 6 2C6 3.10457 6.89543 4 8 4Z"
      fill="currentColor"
    />
    <path
      d="M2 11C3.10457 11 4 10.1046 4 9C4 7.89543 3.10457 7 2 7C0.89543 7 0 7.89543 0 9C0 10.1046 0.89543 11 2 11Z"
      fill="currentColor"
    />
    <path
      d="M7.96164 1.00074C8.23998 0.990048 8.51016 1.09593 8.70712 1.29289L14.1274 6.71321L19.4453 3.16795C19.9048 2.8616 20.5257 2.98577 20.8321 3.4453C21.1384 3.90483 21.0142 4.5257 20.5547 4.83205L14.5547 8.83205C14.1581 9.09647 13.63 9.04417 13.2929 8.70711L8.0565 3.4707L2.75927 9.65079C2.39985 10.0701 1.76855 10.1187 1.34923 9.75926C0.929901 9.39984 0.881339 8.76854 1.24076 8.34921L7.24076 1.34921C7.42204 1.13772 7.6833 1.01143 7.96164 1.00074Z"
      clip-rule="evenodd"
      fill-rule="evenodd"
      fill="currentColor"
    />
  </svg>
</template>

<script lang="ts">
  import { Component, Vue } from 'vue-property-decorator';

  @Component
  export default class LineChartIcon extends Vue {
    /*****         computed       *****/
    /*****         watchers       *****/
    /*****         methods        *****/
    /*****         helpers        *****/
    /*****      vue lifecycle     *****/
  }
</script>
