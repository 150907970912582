const WEBSITE_URL: RegExp = new RegExp(
  '^' + // Start at the beginning of the string.
    '(https?:\\/\\/(www\\.)?)?' + // Optionally match the protocol (http or https) and www.
    '([-a-zA-Z0-9]{1,63}\\.)+' + // Match the domain name.
    '[a-zA-Z]{2,63}' + // Match the top-level domain.
    '\\b' + // Word boundary.
    '([-a-zA-Z0-9()@:%_+.~#?&/=]*)' + // Match the rest of the URL.
    '$', // Match the end of the string.
);

export default WEBSITE_URL;
