import { Component, Vue } from 'vue-property-decorator';
import AuthRepository from '@/repository/Auth';

@Component
export default class LogoutMixin extends Vue {
  public async LogoutMixin(): Promise<void> {
    try {
      await AuthRepository.logout();
    } catch (e) {
      this.$logger('unable to logout user from mixin...');
    }
  }
}
