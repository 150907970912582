import { Component, Vue } from 'vue-property-decorator';
import type IVerificationForm from '@/interfaces/forms/auth/IVerificationForm';
import UserRepository from '@/repository/User';

@Component
export default class VerifyEmailMixin extends Vue {
  public async VerifyEmailMixin(payload: IVerificationForm): Promise<boolean> {
    try {
      return (await UserRepository.verifyEmail(payload)).status === 204;
    } catch (_) {
      this.$logger('there was a verifying new user email...');
      return false;
    }
  }
}
