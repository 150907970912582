const useEnv = () => {
  const isLocal = (): boolean => {
    return !['production', 'staging', 'local'].includes(process.env.VUE_APP_ENVIRONMENT || 'production');
  };

  const isDev = isLocal();

  return {
    isLocal,
    isDev,
  };
};

export default useEnv;
