<template>
  <div class="flex flex-col h-full">
    <AppNav v-if="isLoggedIn" />
    <AuthNav v-else />
    <v-container class="flex items-center justify-center grow text-center">
      <v-row class="items-center justify-center w-full text-body-1">
        <v-col cols="12" tag="header" class="flex flex-col items-center gap-4">
          <h2 class="text-h4 font-weight-bold text-center leading-none" v-text="title" />
          <a
            v-if="!documentLink"
            download
            :href="documentLink"
            class="link text-body-1 text-center leading-none granite--text"
            v-text="downloadText"
          />
        </v-col>
        <v-col cols="12" class="flex items-center justify-center gap-2">
          <div style="max-width: 600px" class="w-full">
            <YoutubeVideo :tutorial="YoutubeTutorial.WELCOME" :autoplay="!isLoggedIn" class="rounded-lg" />
          </div>
        </v-col>
        <v-col cols="12" class="flex flex-col items-center justify-center gap-2">
          <p
            class="text-body-1 text-center leading-none granite--text"
            v-text="trans('workspace.download_numbero_today.title', 'Lejupielādē Numbero jau šodien!')"
          />
          <div class="flex flex-column flex-lg-row items-center justify-around gap-4">
            <DownloadAppButton
              link="https://apps.apple.com/us/app/numbero/id1611171622"
              icon="$appStore"
              store="AppStore"
            />
            <DownloadAppButton
              link="https://play.google.com/store/apps/details?id=app.numbero"
              icon="$playStore"
              store="GooglePlay"
            />
          </div>
        </v-col>
      </v-row>
    </v-container>
    <Footer />
    <div v-if="documentLink" class="d-none">
      <iframe :src="documentLink" />
    </div>
  </div>
</template>

<script lang="ts">
  import Axios from 'axios';
  import { Component, Mixins } from 'vue-property-decorator';
  import YoutubeTutorial from '@/enums/config/YoutubeTutorial';
  import { apiBaseURL } from '@/repository/config/Api';
  import AppState from '@/enums/generics/AppState';
  import UserModule from '@/store/modules/User';
  import HelperMixin from '@/mixins/Helper';
  import AuthNav from '@/components/global/nav/AuthNav.vue';
  import AppNav from '@/components/global/nav/AppNav.vue';
  import Footer from '@/components/global/Footer.vue';
  import YoutubeVideo from '@/components/global/YoutubeVideo.vue';
  import DownloadAppButton from '@/components/global/buttons/DownloadApp.vue';

  @Component({
    components: {
      AuthNav,
      AppNav,
      Footer,
      YoutubeVideo,
      DownloadAppButton,
    },
  })
  export default class ShareDocumentDownloadPage extends Mixins(HelperMixin) {
    public documentLink: string = '';

    /*****         computed       *****/

    public get YoutubeTutorial(): typeof YoutubeTutorial {
      return YoutubeTutorial;
    }

    public get title(): string {
      return this.trans('workspace.share.seo.title', 'Draudzīgākā rēķinu izrakstīšanas platforma');
    }

    public get downloadText(): string {
      return this.trans(
        'workspace.share.click_here_to_download.title',
        'Ja lejupielāde netika sākta automātiski, Noklikšķiniet šeit, lai lejupielādētu koplietoto dokumentu',
      );
    }

    public get isLoggedIn(): boolean {
      return UserModule.isLoggedIn;
    }

    /*****         watchers       *****/
    /*****         methods        *****/
    /*****         helpers        *****/
    /*****      vue lifecycle     *****/

    async created() {
      if (this.$route.query) {
        const uuid: string = (this.$route.params.uuid || '').toString().trim();
        const expires: string = (this.$route.query.expires || '').toString().trim();
        const signature: string = (this.$route.query.signature || '').toString().trim();

        try {
          const documentLink = `${apiBaseURL}/bookkeeping-records/download/${uuid}?expires=${expires}&signature=${signature}`;

          await Axios.get(documentLink);
          this.documentLink = documentLink;
        } catch (_) {
          this.documentLink = '';

          await this.$notifier({
            message: this.trans(
              'notify.error.dowload_share_link_broken',
              'Saitei, kuru mēģināt lejupielādēt, ir beidzies derīguma termiņš vai tā nav derīga',
            ),
            state: AppState.ERROR,
          });
        }
      }
    }
  }
</script>
