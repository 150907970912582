7
<template>
  <v-app-bar app :color="colorPalete.bg" :height="`${navHeight}px`" :min-width="360" flat>
    <v-container class="flex items-center justify-between w-full h-full gap-4">
      <a :href="homePage" class="block">
        <NumberoIcon class="flex items-center" :class="`${colorPalete.text}--text`" />
      </a>
      <v-spacer />
      <v-spacer />
      <div class="flex flex-none items-center gap-4">
        <YoutubeModal v-if="!isMobile" :tutorial="YoutubeTutorial.PROFILE" />
        <LanguageSwitcher :color="colorPalete.color" overflow />
      </div>
    </v-container>
  </v-app-bar>
</template>

<script lang="ts">
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
  import useBreakpoints from '@/services/Breakpoints';
  import Locale from '@/enums/config/Locale';
  import type IColorSchema from '@/interfaces/config/IColorSchema';
  import YoutubeTutorial from '@/enums/config/YoutubeTutorial';
  import TranslationModule from '@/store/modules/Translation';
  import NumberoIcon from '@/components/icons/brands/Numbero.vue';
  import LanguageSwitcher from '@/components/global/LanguageSwitcher.vue';

  @Component({
    components: {
      NumberoIcon,
      LanguageSwitcher,
      YoutubeModal: () => import('@/components/global/modals/Youtube.vue'),
    },
  })
  export default class AuthNav extends Vue {
    @Prop({ type: Boolean }) darkOnly!: boolean;

    private minNavHeight: number = 56;
    private maxNavHeight: number = 86;

    public navHeight: number = this.maxNavHeight;
    public isScrolling: boolean = false;

    /*****         computed       *****/

    public get YoutubeTutorial(): typeof YoutubeTutorial {
      return YoutubeTutorial;
    }

    public get localeLink(): string {
      const locale = TranslationModule.getLocale;
      return locale === Locale.LV ? '' : `${locale}`;
    }

    public get homePage(): string {
      const link = process.env.VUE_APP_HOMEPAGE ?? 'https://numbero.app';
      return `${link}/${this.localeLink}`;
    }

    public get colorPalete(): IColorSchema {
      return {
        bg: this.useTheme ? 'black' : 'cultured',
        text: this.useTheme ? 'cultured' : 'black',
        color: this.useTheme ? 'cultured' : 'black',
      };
    }

    public get useTheme(): boolean {
      return this.isScrolling || this.darkOnly;
    }

    public get isMobile(): boolean {
      return useBreakpoints().isMobile() || useBreakpoints().isTablet();
    }

    /*****         watchers       *****/

    @Watch('$route')
    private routeChanged(): void {
      this.navHeight = this.maxNavHeight;
    }

    /*****         methods        *****/
    /*****         helpers        *****/

    private scrollHandler(): void {
      this.isScrolling = window.scrollY > 0;

      const scrollRange = this.minNavHeight - this.maxNavHeight;

      const maxScrollY = document.body.scrollHeight - window.innerHeight;
      const scrollPercent = Math.min(window.scrollY / maxScrollY, 1);

      this.navHeight = this.maxNavHeight + scrollPercent * scrollRange;
    }

    /*****      vue lifecycle     *****/

    beforeMount() {
      window.addEventListener('scroll', this.scrollHandler);
    }

    beforeDestroy() {
      window.removeEventListener('scroll', this.scrollHandler);
    }
  }
</script>
