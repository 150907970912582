import { AxiosPromise } from 'axios';
import ApiRepository from '@/repository/config/Api';
import useFormBuilder from '@/services/FormBuilder';
import type ISupportForm from '@/interfaces/forms/ISupportForm';

const { jsonToFormData } = useFormBuilder();

export default class SupportRepository {
  // User support form
  public static contact(payload: ISupportForm): AxiosPromise {
    return ApiRepository.post('/contact-form/submit', jsonToFormData(payload));
  }
}
