<template>
  <TippyComponent
    ref="item-list"
    :to="tippyDropdownId"
    :append-to="() => appElement"
    :placement="Placement.BOTTOM"
    :max-width="minWidth"
    animation="shift-away"
    theme="autocomplete"
    trigger="manual"
    distance="2"
    flip-on-update
    interactive
    sticky
    :boundary="overflow ? () => appElement : 'scrollParent'"
    :z-index="overflow ? 204 : 10"
    :hide-on-click="false"
  >
    <div class="autocomplete white w-full overflow-hidden" :class="[rounded ? 'rounded-lg' : 'rounded']">
      <div v-if="useSearch" key="search" :id="`search:${tippyDropdownId}`" class="pa-2 pb-1">
        <TextField
          v-model="searchQuery"
          :autofocus="autofocus && show"
          v-trim
          :name="`search:${tippyDropdownId}`"
          type="text"
          input-class="text-field-silver"
          class="search-text-field text-body-2 rounded w-full"
          @input:debounce="query"
        >
          <template #prepend>
            <div class="px-1">
              <v-icon>$magnifyingGlass</v-icon>
            </div>
          </template>
        </TextField>
      </div>
      <div
        v-if="items.length === 0"
        key="empty"
        class="flex items-center justify-center granite--text text-center py-2"
      >
        <v-chip v-if="typing || loading" key="typing" color="transparent" small>
          <TypingIcon />
        </v-chip>
        <div v-else key="search" class="flex items-center justify-center gap-1">
          <slot name="list-item-search">
            <v-icon>$magnifyingGlass</v-icon>
            <div class="leading-none" v-text="trans('workspace.title.type_to_search', 'Sāciet rakstīt lai meklētu')" />
          </slot>
        </div>
      </div>
      <DynamicScroller
        v-else
        :items="items"
        :min-item-size="100"
        class="block granite--text w-full h-full pa-2"
        :style="`max-height: ${viewportListHeight}px`"
      >
        <template v-slot="{ item, index, active }">
          <div>
            <div
              class="text-left text-body-1 leading-none font-weight-bold px-1 py-2"
              v-if="groupsFirstItemIndexes.includes(index)"
              v-text="item[groupBy]"
            />
            <DynamicScrollerItem
              :item="item"
              :active="active"
              :size-dependencies="[item[mapItem.text], item[mapItem.description]]"
              :data-index="index"
            >
              <div
                @mousedown="setItem(item)"
                @mouseover.prevent="setArrow(index)"
                :class="highlightItem(index, arrow, item)"
                class="flex flex-col items-start justify-center cursor-pointer rounded-none px-2 py-3"
              >
                <slot
                  name="list-item"
                  :font-size="fontSize"
                  :item="item"
                  :text="`${item[mapItem.text]}${item[mapItem.value] !== '' ? postfix : ''}`"
                >
                  <div class="flex items-center justify-start" :class="fontSize">
                    <div
                      v-text="`${item[mapItem.text]}${item[mapItem.value] !== '' ? postfix : ''}`"
                      class="text-left"
                    />
                  </div>
                  <div
                    v-if="mapItem.description && item[mapItem.description]"
                    class="flex items-center justify-start opacity-50"
                  >
                    <div v-text="item[mapItem.description]" class="text-caption text-left" />
                  </div>
                </slot>
              </div>
            </DynamicScrollerItem>
          </div>
        </template>
      </DynamicScroller>
    </div>
  </TippyComponent>
</template>

<script lang="ts">
  import { Component, Emit, Ref, Prop, Vue, Watch } from 'vue-property-decorator';
  import Placement from '@/enums/generics/Placement';
  import type ISelectMap from '@/interfaces/config/ISelectMap';
  import TypingIcon from '@/components/icons/Typing.vue';
  import IndexAutocomplete from '@/components/global/inputs/autocomplete/Index.vue';
  import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller';
  import { TippyComponent } from 'vue-tippy';

  import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

  @Component({
    components: {
      TypingIcon,
      TippyComponent,
      DynamicScroller,
      DynamicScrollerItem,
      TextField: () => import('@/components/global/inputs/TextField.vue'),
    },
  })
  export default class ItemListAutocomplete extends Vue {
    @Prop() textfield!: IndexAutocomplete;
    @Prop() tippyDropdownId!: string;
    @Prop({ default: 350 }) minWidth!: number;

    @Prop({ type: Boolean }) show?: boolean;
    @Prop({ type: Boolean }) overflow?: boolean;

    @Prop({ type: Boolean }) loading?: boolean;
    @Prop({ type: Boolean }) rounded?: boolean;
    @Prop({ type: Boolean }) typing?: boolean;
    @Prop({ type: Boolean }) autofocus?: boolean;
    @Prop({ type: Boolean }) useSearch?: boolean;

    @Prop({ default: -1 }) arrow!: number;
    @Prop({ default: 350 }) listHeight!: number;
    @Prop({ default: 44 }) listItemHeight!: number;

    @Prop({ default: 'text-body-2' }) fontSize!: string;

    @Prop({ default: '' }) postfix!: string;
    @Prop() items!: any[];
    @Prop() mapItem!: ISelectMap;
    @Prop() groupBy!: string;

    @Prop() setItem!: (item: any) => any;
    @Prop() setArrow!: (index: number) => void;
    @Prop() highlightItem!: (index: number, arrow: number, item: any) => string;

    @Ref('virtual-scroller') virtualScroller!: Vue;
    @Ref('item-list') itemList!: typeof TippyComponent;

    public searchQuery: string = '';

    /*****         computed       *****/

    public get groupsFirstItemIndexes(): any[] {
      if (!this.groupBy) {
        return [];
      }

      const seenValues = new Set();
      const firstIndexes: number[] = [];

      this.items.forEach((item, index) => {
        const value = item[this.groupBy];
        if (!seenValues.has(value)) {
          seenValues.add(value);
          firstIndexes.push(index);
        }
      });

      return firstIndexes;
    }

    public get Placement(): typeof Placement {
      return Placement;
    }

    public get appElement(): HTMLElement {
      return document.getElementById('app') as HTMLElement;
    }

    public get viewportListHeight(): number {
      const minListHeight = (this.listHeight + this.listItemHeight) * 2;
      const viewportListHeight = window.innerHeight / 2 - this.listItemHeight;
      return window.innerHeight > minListHeight ? this.listHeight : viewportListHeight;
    }

    /*****         watchers       *****/

    @Watch('show')
    public watchShowChange(): void {
      this.show ? this.showTippy() : this.hideTippy();
    }

    /*****         methods        *****/

    @Emit('query')
    public query(query: string): string {
      return query;
    }

    private showTippy(): void {
      this.searchQuery = '';
      this.query(this.searchQuery);
      this.itemList.tip.show();
      this.itemList.tip.popperInstance.update();
    }

    private hideTippy(): void {
      this.itemList.tip.hide();
    }

    /*****         helpers        *****/
    /*****      vue lifecycle     *****/
  }
</script>
