import TEST_DATA from '@/constants/config/TestData';
import type IPasswordResetForm from '@/interfaces/forms/auth/IPasswordResetForm';
import useEnv from '@/services/Env';

const { isDev } = useEnv();

const DEFAULT_PASSWORD_RESET_FORM: IPasswordResetForm = {
  email: '',
  password: isDev ? TEST_DATA.password : '',
  token: '',
};

export default DEFAULT_PASSWORD_RESET_FORM;
