<template>
  <div v-if="isMounted" style="min-height: 360px; overflow: hidden">
    <youtube
      ref="youtube"
      style="width: 100% !important; margin-bottom: -7px; transform: scale(1.02)"
      :video-id="videoId"
      :player-vars="config"
    />
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
  import YOUTUBE_TUTORIALS from '@/constants/config/YoutubeTutorials';
  import Locale from '@/enums/config/Locale';
  import YoutubeTutorial from '@/enums/config/YoutubeTutorial';
  import TranslationModule from '@/store/modules/Translation';
  import VueYoutube from 'vue-youtube';

  Vue.use(VueYoutube);

  @Component
  export default class YoutubeVideo extends Vue {
    @Prop() tutorial!: YoutubeTutorial;
    @Prop({ type: Boolean }) autoplay!: boolean;
    @Prop({ type: Boolean }) show!: boolean;

    @Ref('youtube') youtube!: any;

    // Youtube player parameters
    // https://developers.google.com/youtube/player_parameters
    public config = { autoplay: 0 };
    public isMounted: boolean = false;

    /*****         computed       *****/

    public get locale(): Locale {
      return TranslationModule.getLocale;
    }

    public get videoId(): string {
      const tutorials = YOUTUBE_TUTORIALS[this.locale];
      const tutorial = tutorials ? tutorials[this.tutorial] : null;
      return tutorial && tutorial.isActive ? tutorial.videoID : '';
    }

    /*****         watchers       *****/

    @Watch('show', { immediate: true })
    public playVideo(show: boolean): void {
      if (this.youtube) {
        show ? this.youtube.player.playVideo() : this.youtube.player.stopVideo();
      }
    }

    /*****         methods        *****/
    /*****         helpers        *****/
    /*****      vue lifecycle     *****/

    beforeMount() {
      this.config.autoplay = Number(this.autoplay);
      this.isMounted = true;
    }
  }
</script>
