import _Vue from 'vue';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import DateFormat from '@/enums/config/DateFormat';
import DateRange from '@/enums/config/DateRange';
import TranslationModule from '@/store/modules/Translation';
import type { ManipulateType } from 'dayjs';
import type { IRecurringConfig } from '@/interfaces/document/IRecurringDocument';

import lv from 'dayjs/locale/lv';
import en from 'dayjs/locale/en';
import ru from 'dayjs/locale/ru';
import Locale from '@/enums/config/Locale';

function getLocale(): Locale {
  return TranslationModule.getLocale;
}

function setDateLocale(locale = getLocale()): void {
  switch (locale) {
    case Locale.LV:
      dayjs.locale(lv);
      break;
    case Locale.EN:
      dayjs.locale(en);
      break;
    case Locale.RU:
      dayjs.locale(ru);
      break;
    default:
      dayjs.locale(lv);
      break;
  }
}

export default function DatePlugin(Vue: typeof _Vue): void {
  Vue.prototype.parseNowToFormat = (format: DateFormat, locale = getLocale()): string => {
    setDateLocale(locale);
    return dayjs().format(format);
  };

  Vue.prototype.parseDateToFormat = (date: Date, format: DateFormat, locale = getLocale()): string => {
    setDateLocale(locale);
    return dayjs(date).format(format);
  };

  Vue.prototype.parseFormatToFormat = (
    date: string,
    oldFormat: DateFormat,
    newFormat: DateFormat,
    locale = getLocale(),
  ): string => {
    if (typeof date === 'string' && date.length > 0) {
      setDateLocale(locale);
      dayjs.extend(customParseFormat);

      // Try to parse date with given format
      // If it fails, try to guess the format
      const formattedDate: dayjs.Dayjs = dayjs(date, oldFormat);
      if (formattedDate.isValid()) {
        return formattedDate.format(newFormat);
      } else {
        const guessedDate: dayjs.Dayjs = dayjs(date);
        if (guessedDate.isValid()) {
          return formattedDate.format(newFormat);
        }
      }
    }

    return '';
  };

  Vue.prototype.parseDatetimeFormatToTime = (datetime: string, format: DateFormat, locale = getLocale()): string => {
    if (typeof datetime === 'string' && datetime.length > 0) {
      setDateLocale(locale);

      const formattedTime: string = dayjs(datetime, format).format('HH:mm:ss');
      return formattedTime;
    }

    return '';
  };

  Vue.prototype.parseRangeToFormat = (range: DateRange, format: DateFormat, locale = getLocale()): string[] => {
    dayjs.extend(quarterOfYear);

    if (Object.values(DateRange).includes(range)) {
      setDateLocale(locale);

      switch (range) {
        case DateRange.ALL_TIME:
          return ['', ''];
        case DateRange.THIS_MONTH:
          return [dayjs().startOf('month').format(format), dayjs().endOf('month').format(format)];
        case DateRange.LAST_MONTH:
          return [
            dayjs().subtract(1, 'month').startOf('month').format(format),
            dayjs().subtract(1, 'month').endOf('month').format(format),
          ];
        case DateRange.THIS_YEAR:
          return [dayjs().startOf('year').format(format), dayjs().endOf('year').format(format)];
        case DateRange.LAST_YEAR:
          return [
            dayjs().subtract(1, 'year').startOf('year').format(format),
            dayjs().subtract(1, 'year').endOf('year').format(format),
          ];
        case DateRange.FIRST_QUARTER:
          return [
            dayjs().quarter(1).startOf('quarter').format(format),
            dayjs().quarter(1).endOf('quarter').format(format),
          ];
        case DateRange.SECOND_QUARTER:
          return [
            dayjs().quarter(2).startOf('quarter').format(format),
            dayjs().quarter(2).endOf('quarter').format(format),
          ];
        case DateRange.THIRD_QUARTER:
          return [
            dayjs().quarter(3).startOf('quarter').format(format),
            dayjs().quarter(3).endOf('quarter').format(format),
          ];
        case DateRange.FOURTH_QUARTER:
          return [
            dayjs().quarter(4).startOf('quarter').format(format),
            dayjs().quarter(4).endOf('quarter').format(format),
          ];
      }
    }

    return ['', ''];
  };

  // Date comparison
  Vue.prototype.isBefore = (date: string, compareDate: string, format: DateFormat, locale = getLocale()): boolean => {
    setDateLocale(locale);
    dayjs.extend(customParseFormat);

    const dateFormatted: dayjs.Dayjs = dayjs(date, format, locale);
    const compareDateFormatted: dayjs.Dayjs = dayjs(compareDate, format, locale);

    return dateFormatted.isBefore(compareDateFormatted);
  };

  Vue.prototype.isAfter = (date: string, compareDate: string, format: DateFormat, locale = getLocale()): boolean => {
    setDateLocale(locale);
    dayjs.extend(customParseFormat);

    const dateFormatted: dayjs.Dayjs = dayjs(date, format, locale);
    const compareDateFormatted: dayjs.Dayjs = dayjs(compareDate, format, locale);

    return dateFormatted.isAfter(compareDateFormatted);
  };

  Vue.prototype.isBetween = (
    date: string,
    startDate: string,
    endDate: string,
    format: DateFormat,
    locale = getLocale(),
  ): boolean => {
    setDateLocale(locale);
    dayjs.extend(isBetween);
    dayjs.extend(customParseFormat);

    const dateFormatted: dayjs.Dayjs = dayjs(date, format, locale);
    const startDateFormatted: dayjs.Dayjs = dayjs(startDate, format, locale);
    const endDateFormatted: dayjs.Dayjs = dayjs(endDate, format, locale);

    return dateFormatted.isBetween(startDateFormatted, endDateFormatted);
  };

  // Date manipulation
  Vue.prototype.addPeriodToDate = (date: Date, count: number, period: ManipulateType, locale = getLocale()): Date => {
    setDateLocale(locale);
    return dayjs(date).add(count, period).toDate();
  };

  Vue.prototype.addPeriodToFormat = (
    date: string,
    format: DateFormat,
    count: number,
    period: ManipulateType,
    locale = getLocale(),
  ): string => {
    setDateLocale(locale);
    dayjs.extend(customParseFormat);

    return dayjs(date, format, locale).add(count, period).format(format);
  };

  Vue.prototype.diffDateFormat = (
    dateFrom: string,
    dateTo: string,
    format: DateFormat,
    locale = getLocale(),
  ): number => {
    setDateLocale(locale);
    dayjs.extend(customParseFormat);

    const dateFromFormatted: dayjs.Dayjs = dayjs(dateFrom, format, locale);
    const dateToFormatted: dayjs.Dayjs = dayjs(dateTo, format, locale);

    return dateToFormatted.diff(dateFromFormatted, 'day');
  };

  Vue.prototype.nextRecurringDate = (config: IRecurringConfig, format: DateFormat, locale = getLocale()): string => {
    if (!Object.keys(config).every((key: string) => config[key as keyof IRecurringConfig])) {
      return '';
    }

    setDateLocale(locale);
    dayjs.extend(isSameOrBefore);
    dayjs.extend(customParseFormat);

    let nextDate: dayjs.Dayjs = dayjs(config.start_date, format, locale);
    const endDate: dayjs.Dayjs = dayjs(config.end_date, format, locale);
    const currentDate: dayjs.Dayjs = dayjs();

    while (nextDate.isSameOrBefore(currentDate) && nextDate.isSameOrBefore(endDate)) {
      nextDate = nextDate.add(config.period, config.period_type);
    }

    if (nextDate.isAfter(endDate)) {
      return '';
    }

    return nextDate.format(format);
  };

  Vue.prototype.lastRecurringDate = (config: IRecurringConfig, format: DateFormat, locale = getLocale()): string => {
    if (!Object.keys(config).every((key: string) => config[key as keyof IRecurringConfig])) {
      return '';
    }

    setDateLocale(locale);
    dayjs.extend(isSameOrBefore);
    dayjs.extend(customParseFormat);

    let lastDate: dayjs.Dayjs = dayjs(config.start_date, format, locale);
    const endDate: dayjs.Dayjs = dayjs(config.end_date, format, locale);
    const currentDate: dayjs.Dayjs = dayjs().startOf('day');

    while (lastDate.add(config.period, config.period_type).isSameOrBefore(endDate)) {
      lastDate = lastDate.add(config.period, config.period_type);
    }

    if (lastDate.isAfter(currentDate)) {
      return lastDate.format(format);
    }
    return '';
  };

  Vue.prototype.countIterations = (config: IRecurringConfig, format: DateFormat, locale = getLocale()): number => {
    if (!Object.keys(config).every((key: string) => config[key as keyof IRecurringConfig])) {
      return 0;
    }

    setDateLocale(locale);
    dayjs.extend(isSameOrBefore);
    dayjs.extend(customParseFormat);

    let nextDate: dayjs.Dayjs = dayjs(config.start_date, format, locale);
    const endDate: dayjs.Dayjs = dayjs(config.end_date, format, locale);
    const currentDate: dayjs.Dayjs = dayjs().startOf('day');
    let count = 0;

    while (nextDate.isSameOrBefore(endDate)) {
      if (nextDate.isAfter(currentDate)) {
        count++;
      }
      nextDate = nextDate.add(config.period, config.period_type);
    }

    return count;
  };

  Vue.prototype.guessDateFormat = (date: string): DateFormat | string => {
    dayjs.extend(customParseFormat);

    const dateFormats: DateFormat[] = Object.values(DateFormat);
    for (const format of dateFormats) {
      if (dayjs(date, format, true).isValid()) {
        return format;
      }
    }

    return '';
  };
}

declare module 'vue/types/vue' {
  interface Vue {
    parseNowToFormat(format: DateFormat, locale?: Locale): string;
    parseDateToFormat(date: Date, format: DateFormat, locale?: Locale): string;
    parseFormatToFormat(date: string, oldFormat: DateFormat, newFormat: DateFormat, locale?: Locale): string;
    parseDatetimeFormatToTime(datetime: string, format: DateFormat, locale?: Locale): string;
    parseRangeToFormat(range: DateRange, format: DateFormat, locale?: Locale): string[];

    // Date comparison
    isBefore(date: string, compareDate: string, format: DateFormat, locale?: Locale): boolean;
    isAfter(date: string, compareDate: string, format: DateFormat, locale?: Locale): boolean;
    isBetween(date: string, startDate: string, endDate: string, format: DateFormat, locale?: Locale): boolean;

    // Date manipulation
    addPeriodToDate(date: Date, count: number, period: ManipulateType, locale?: Locale): Date;
    addPeriodToFormat(date: string, format: DateFormat, count: number, period: ManipulateType, locale?: Locale): string;
    diffDateFormat(dateFrom: string, dateTo: string, format: DateFormat, locale?: Locale): number;

    // Recurring dates
    nextRecurringDate(config: IRecurringConfig, format: DateFormat, locale?: Locale): string;
    lastRecurringDate(config: IRecurringConfig, format: DateFormat, locale?: Locale): string;
    countIterations(config: IRecurringConfig, format: DateFormat, locale?: Locale): number;

    // Helper functions
    guessDateFormat(date: string): DateFormat | string;
  }
}
