<template>
  <BaseAutocomplete
    v-model="locale"
    :items="locales"
    :map-item="{ text: 'text', value: 'value' }"
    font-size="title font-weight-medium"
    list-font-size="title font-weight-medium"
    :overflow="overflow"
    :icon-color="color"
    :text-color="color"
    color="transparent"
    hide-selected
    readonly
    style="max-width: 80px; height: 32px"
    @set="switchLocale"
  />
</template>

<script lang="ts">
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import Locale from '@/enums/config/Locale';
  import type ISelect from '@/interfaces/config/ISelect';
  import TranslationModule from '@/store/modules/Translation';
  import BaseAutocomplete from '@/components/global/inputs/autocomplete/Base.vue';

  @Component({
    components: {
      BaseAutocomplete,
    },
  })
  export default class LanguageSwitcher extends Vue {
    @Prop() color?: string;

    @Prop({ type: Boolean }) overflow?: boolean;

    public locale = Locale.LV;

    /*****         computed       *****/

    public get locales(): ISelect[] {
      return TranslationModule.getLocales.map((locale: Locale) => ({
        value: locale,
        text: locale.toLocaleUpperCase(),
      }));
    }

    /*****         watchers       *****/
    /*****         methods        *****/

    // handle user selection and emit this value back to parent
    public async switchLocale(item: ISelect): Promise<void> {
      const locale = item.value as Locale;
      if (locale === TranslationModule.getLocale) {
        return;
      }

      await TranslationModule.SWITCH_LOCALE(locale);
      this.locale = TranslationModule.getLocale;
    }

    /*****         helpers        *****/
    /*****      vue lifecycle     *****/

    beforeMount() {
      this.locale = TranslationModule.getLocale;
    }
  }
</script>
