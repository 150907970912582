<template>
  <v-slide-y-transition leave-absolute hide-on-leave>
    <div v-if="show" :class="{ 'cursor-not-allowed': loading }">
      <v-btn
        plain
        height="16px"
        min-width="0"
        :ripple="false"
        :disabled="loading || disabled"
        class="font-weight-medium pa-0"
        :class="fontSize"
        @click="submit"
        @mousedown.native="mousedown"
      >
        <span :class="{ 'primary--text': primary }" v-text="text" />
      </v-btn>
    </div>
  </v-slide-y-transition>
</template>
<script lang="ts">
  import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

  @Component
  export default class ActionButton extends Vue {
    @Prop({ default: '' }) text!: string;
    @Prop({ default: 'text-body-2' }) fontSize!: string;

    @Prop({ type: Boolean }) loading!: boolean;
    @Prop({ type: Boolean }) disabled!: boolean;
    @Prop({ type: Boolean }) primary!: boolean;
    @Prop({ type: Boolean }) show!: boolean;

    /*****         computed       *****/
    /*****         watchers       *****/
    /*****         methods        *****/

    @Emit('click')
    public submit(): void {}

    @Emit('mousedown')
    public mousedown(): void {}

    /*****         helpers        *****/
    /*****      vue lifecycle     *****/
  }
</script>
