import { Component, Vue } from 'vue-property-decorator';
import Locale from '@/enums/config/Locale';
import BankType from '@/enums/generics/BankType';
import TranslationModule from '@/store/modules/Translation';

@Component
export default class TranslateBankTypeMixin extends Vue {
  public TranslateBankTypeMixin(type: BankType, name = '', locale: Locale = TranslationModule.getLocale): string {
    switch (type) {
      case BankType.BANK_ACCOUNT:
        return this.trans('workspace.title.bank_account', 'Bankas konts', locale);
      case BankType.PAYPAL:
        return this.trans('workspace.title.paypal_account', 'Paypal', locale);
      case BankType.SKRILL:
        return this.trans('workspace.title.skrill_account', 'Skrill', locale);
      case BankType.OTHER:
        return name
          ? `${name} ${this.trans('workspace.title.account', 'Konts', locale).toLowerCase()}`
          : this.trans('workspace.title.other_account', 'Cits konts', locale);
      default:
        return type;
    }
  }

  public TranslateBankTypeDescriptionMixin(type: BankType, locale: Locale = TranslationModule.getLocale): string {
    switch (type) {
      case BankType.BANK_ACCOUNT:
        return this.trans(
          'workspace.description.payment_methods.bank',
          'Pievienojiet Jūsu bankas kontu. Automātiskā konta lauka aizpilde pieejama Latvijas banku kontiem.',
          locale,
        );
      case BankType.PAYPAL:
        return this.trans(
          'workspace.description.payment_methods.paypal',
          'PayPal ļauj veikt maksājumus, izmantojot kredītkartes, bankas kontus, pircēju kredītus vai PayPal konta atlikumu.',
          locale,
        );
      case BankType.SKRILL:
        return this.trans(
          'workspace.description.payment_methods.skrill',
          'Skrill ļauj norēķināties ar kredītkartēm, bankas kontiem vai Skrill konta atlikumu.',
          locale,
        );
      case BankType.OTHER:
      default:
        return this.trans(
          'workspace.description.payment_methods.other',
          'Pievienojiet citu maksājuma konta adresi vai kriptovalūtas adresi.',
          locale,
        );
    }
  }

  public TranslateBankTypeFieldMixin(type: BankType, locale: Locale = TranslationModule.getLocale): string {
    switch (type) {
      case BankType.BANK_ACCOUNT:
        return this.trans('workspace.title.account_number.bank', 'Konta numurs', locale);
      case BankType.PAYPAL:
        return this.trans('workspace.title.account_number.paypal', 'Paypal epasts', locale);
      case BankType.SKRILL:
        return this.trans('workspace.title.account_number.skrill', 'Skrill epasts', locale);
      case BankType.OTHER:
      default:
        return this.trans('workspace.title.account_number.other', 'Konta adrese', locale);
    }
  }
}
