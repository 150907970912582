import store from '@/store';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { Route } from 'vue-router';
import IGNORED_ROUTES from '@/constants/config/RouterHistory';

@Module({
  store,
  name: 'RouterHistory',
  namespaced: true,
  dynamic: true,
})
class RouterHistory extends VuexModule {
  public lastRoute: Route | null = null;

  public get getLastRoute(): any {
    return this.lastRoute ?? null;
  }

  /*******   Set action   *******/

  @Action
  public async REMEMBER_LAST_ROUTE(to: Route, from: Route): Promise<void> {
    if (to && from) {
      const isRootPath: boolean = from.fullPath === '/';
      isRootPath ? await this.SET_LAST_ROUTE(to) : await this.SET_LAST_ROUTE(from);
    } else if (to) {
      await this.SET_LAST_ROUTE(to);
    }
  }

  @Action({ commit: 'UPDATE_LAST_ROUTE' })
  public async SET_LAST_ROUTE(route: Route): Promise<Route | null> {
    const routeName: string = route.name ?? '';
    if (IGNORED_ROUTES.includes(routeName)) {
      return this.lastRoute;
    }
    return route;
  }

  /*******   Unset action   *******/

  @Action({ commit: 'UPDATE_LAST_ROUTE' })
  public CLEAR_LAST_ROUTE(): null {
    return null;
  }

  /*******   Update mutation   *******/

  @Mutation
  public UPDATE_LAST_ROUTE(lastRoute: Route | null): Route | null {
    return (this.lastRoute = lastRoute);
  }
}

const RouterHistoryModule = getModule(RouterHistory);

export default RouterHistoryModule;
