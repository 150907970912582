<template>
  <div class="flex flex-col h-full">
    <AppNav dark-only />
    <v-scroll-y-transition hide-on-leave leave-absolute>
      <RouterView :key="$route.meta?.parent ?? $route.fullPath" class="grow" />
    </v-scroll-y-transition>
    <Footer />
    <InvitationResponseModal />
    <OnboardingNewModal />
  </div>
</template>

<script lang="ts">
  import { Component, Vue } from 'vue-property-decorator';
  import AppNav from '@/components/global/nav/AppNav.vue';
  import Footer from '@/components/global/Footer.vue';

  @Component({
    components: {
      AppNav,
      Footer,
      InvitationResponseModal: () => import('@/components/global/modals/InvitationResponse.vue'),
      OnboardingNewModal: () => import('@/components/global/modals/OnboardingNew.vue'),
    },
  })
  export default class AppLayout extends Vue {
    /*****         computed       *****/
    /*****         watchers       *****/
    /*****         methods        *****/
    /*****         helpers        *****/
    /*****      vue lifecycle     *****/
  }
</script>
