import YoutubeTutorial from '@/enums/config/YoutubeTutorial';
import { IYoutubeTutorials } from '@/interfaces/config/IYoutube';

const TUTORIALS_LV: IYoutubeTutorials = {
  [YoutubeTutorial.PROFILE]: {
    videoID: 'gc4tJWnuMkI',
    isActive: true,
  },
  [YoutubeTutorial.COMPANY]: {
    videoID: 'ct7rH4gADDw',
    isActive: true,
  },
  [YoutubeTutorial.DOCUMENT]: {
    videoID: '8rnpMXOB9tQ',
    isActive: true,
  },
  [YoutubeTutorial.RECURRING]: {
    videoID: 'qnzAWw3gFFg',
    isActive: true,
  },
  [YoutubeTutorial.REPORTS]: {
    videoID: 'oUcoCNAuuu0',
    isActive: true,
  },
  [YoutubeTutorial.WELCOME]: {
    videoID: 'VlhvQFWDqVg',
    isActive: true,
  },
};

export default TUTORIALS_LV;
