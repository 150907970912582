import { Component, Vue } from 'vue-property-decorator';
import AuthRouteName from '@/enums/config/router/Auth';
import DashboardRouteName from '@/enums/config/router/Dashboard';
import DocumentRoute from '@/enums/config/document/Route';
import PublicRouteName from '@/enums/config/router/Public';
import LocalStorage from '@/enums/config/LocalStorage';
import AppState from '@/enums/generics/AppState';
import AppModule from '@/store/modules/App';
import UserModule from '@/store/modules/User';
import CompanyModule from '@/store/modules/Company';
import AuthMixin from '@/mixins/Auth';

@Component
export default class NetworkErrorMixin extends Vue {
  // Not modified status code - 304
  public async notModified(vue: Vue): Promise<void> {
    this.$logger('route', vue.$route.name, 'notModified');

    await this.$notifier({
      message: this.trans('notify.error.not_modified', 'Pieprasītās izmaiņas jau ir piemērotas'),
      state: AppState.INFO,
    });
  }

  // Unauthorized status code - 401
  public async unauthorized(vue: Vue): Promise<void> {
    this.$logger('route', vue.$route.name, 'unauthorized');

    await UserModule.CLEAR_USER();
    await this.$notifier({
      message: this.trans('notify.error.unauthorized', 'Lietotājs nav pieslēdzies'),
      state: AppState.ERROR,
    });
    if (vue.$route.name != AuthRouteName.LOGIN) {
      await vue.$router.replace({ name: AuthRouteName.LOGIN });
    }
    await CompanyModule.CLEAR_COMPANY();
    await new AuthMixin().CsrfMixin();
  }

  // Forbidden status code - 403
  public async forbidden(vue: Vue, message: string): Promise<void> {
    this.$logger('route', vue.$route.name, 'forbidden');

    await this.$notifier({ message, state: AppState.ERROR });
    // FIXME: This could lead to an issue if there is an unfinished job
    // FIXME: To reproduce it - try to send bulk email and then try to trigger bulk download
    await vue.$router.replace({ name: DashboardRouteName.LIST, params: { document: DocumentRoute.ALL } });
  }

  // Forbidden status code - 403 and error code E_ACCESS_DENIED
  public async forbiddenAccessDenied(vue: Vue): Promise<void> {
    this.$logger('route', vue.$route.name, 'forbiddenAccessDenied');

    await this.$notifier({
      message: this.trans(
        'notify.error.access_denied.company.resource',
        'Piekļuve uzņēmuma resursam ir liegta, lūdzu, pārslēdziet uzņēmumu',
      ),
      state: AppState.ERROR,
    });

    localStorage.setItem(LocalStorage.SWITCH_COMPANY, 'show');
    await AppModule.SWITCH_COMPANY();
  }

  // Not found status code - 404
  public async notFound(vue: Vue): Promise<void> {
    this.$logger('route', vue.$route.name, 'notFound');

    await this.$notifier({
      message: this.trans('notify.error.not_found', 'Pieprasītie resursi nav atrasti'),
      state: AppState.WARNING,
    });

    localStorage.setItem(LocalStorage.SWITCH_COMPANY, 'show');
    await AppModule.SWITCH_COMPANY();
  }

  // Validation error status code - 422
  public async validationError(vue: Vue, errors: string): Promise<void> {
    this.$logger('route', vue.$route.name, 'validationError');

    const message: string = errors
      ? this.trans('notify.error.form.invalid', 'Nekorekti aizpildīta forma')
      : this.trans('notify.error.internal', 'Aplikācijas darbības kļuda');

    await this.$notifier({ message, state: AppState.ERROR });
  }

  // CSRF mistmatch status code - 419
  public async csrfMistmatch(vue: Vue): Promise<void> {
    this.$logger('route', vue.$route.name, 'csrfMistmatch');

    if (await UserModule.SET_USER()) {
      const message: string = this.trans('notify.warning.try_again', 'Darbība neizdevās, lūdzu, mēģiniet vēlreiz');
      await this.$notifier({ message, state: AppState.WARNING });
      await new AuthMixin().CsrfMixin();
      return;
    }

    const message: string = this.trans('notify.error.csrf_mismatch', 'CSRF marķiera nesakritība');
    await this.$notifier({ message, state: AppState.ERROR });
    await UserModule.CLEAR_USER();

    if (vue.$route.name != AuthRouteName.LOGIN) {
      await vue.$router.replace({ name: AuthRouteName.LOGIN });
    }

    await CompanyModule.CLEAR_COMPANY();
    await new AuthMixin().CsrfMixin();
  }

  // Too many requests status code - 429
  public async tooManyRequests(vue: Vue, retryAfter: number): Promise<void> {
    this.$logger('route', vue.$route.name, 'tooManyRequests');

    let message: string = this.trans('notify.error.internal', 'Aplikācijas darbības kļuda');
    if (retryAfter) {
      message = this.trans(
        'notify.error.too_many_requests',
        'Pārāk daudz ielogošanās mēģinājumu. Lūdzu, mēģiniet vēlreiz pēc :seconds sekundēm',
        { seconds: retryAfter },
      );
    }
    await this.$notifier({ message, state: AppState.ERROR });
  }

  // Service unavailable status code - 503 (Ongoing maintenance)
  public async serviceUnavailable(vue: Vue): Promise<void> {
    this.$logger('route', vue.$route.name, 'serviceUnavailable');

    await AppModule.SET_IS_MAINTENANCE();

    await this.$notifier({
      message: this.trans('notify.error.maintenance', 'Šobrīd notiek sistēmas uzlabošanas darbi'),
      state: AppState.INFO,
    });

    await vue.$router.replace({ name: PublicRouteName.MAINTENANCE });
  }

  // Default status code
  public async default(vue: Vue, message: string): Promise<void> {
    this.$logger('route', vue.$route.name, 'default');

    await this.$notifier({
      message: message ?? this.trans('notify.error.internal', 'Aplikācijas darbības kļuda'),
      state: AppState.ERROR,
    });
  }
}
