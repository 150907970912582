import TEST_DATA from '@/constants/config/TestData';
import type IRegisterProfileForm from '@/interfaces/forms/auth/IRegisterProfileForm';

import useEnv from '@/services/Env';

const { isDev } = useEnv();

const DEFAULT_REGISTER_WITH_INVITATION_FORM: IRegisterProfileForm = {
  email: isDev ? TEST_DATA.newUser.email : '',
  password: isDev ? TEST_DATA.newUser.password : '',

  first_name: isDev ? TEST_DATA.newUser.first_name : '',
  last_name: isDev ? TEST_DATA.newUser.last_name : '',

  terms: false,
  including_company: false,
  invitation_hash: '',
};

export default DEFAULT_REGISTER_WITH_INVITATION_FORM;
