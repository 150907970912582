<template>
  <v-app class="min-h-screen" style="min-width: 360px">
    <v-main class="cultured" id="parent">
      <RouterView v-if="isReady || isMaintenance" />
      <LoadingOverlay v-else />
    </v-main>
    <SessionIdleModal />
    <BottomSheet />
    <Snackbar />
  </v-app>
</template>

<script lang="ts">
  import { Component, Vue } from 'vue-property-decorator';
  import AppModule from '@/store/modules/App';
  import UserModule from '@/store/modules/User';

  @Component({
    components: {
      Snackbar: () => import('@/components/global/SnackBar.vue'),
      BottomSheet: () => import('@/components/global/BottomSheet.vue'),
      LoadingOverlay: () => import('@/components/global/LoadingOverlay.vue'),
      SessionIdleModal: () => import('@/components/global/modals/SessionIdle.vue'),
    },
  })
  export default class App extends Vue {
    /*****         computed       *****/

    public get isReady(): boolean {
      return AppModule.getIsReady;
    }

    public get isMaintenance(): boolean {
      return AppModule.getIsMaintenance;
    }

    public get isLoggedIn(): boolean {
      return UserModule.isLoggedIn;
    }

    /*****         watchers       *****/
    /*****         methods        *****/
    /*****         helpers        *****/
    /*****      vue lifecycle     *****/
  }
</script>
