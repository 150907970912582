import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import DateFormat from '@/enums/config/DateFormat';
import LocalStorage from '@/enums/config/LocalStorage';
import TimeConfig from '@/enums/config/TimeConfig';
import type IUserInvitationForm from '@/interfaces/forms/IUserInvitationForm';

const useLocalStorage = () => {
  // Get invitation hash from local storage
  const getInvitationHash = (): IUserInvitationForm | null => {
    const invitation = localStorage.getItem(LocalStorage.INVITATION_HASH);

    if (typeof invitation === 'string') {
      return JSON.parse(decodeURIComponent(atob(invitation)));
    }

    return null;
  };

  // Set invitation hash to local storage
  const setInvitationHash = (invitation: IUserInvitationForm): void => {
    dayjs.extend(customParseFormat);
    invitation.expires_at = dayjs().add(TimeConfig.EXPIRY, 'minutes').format(DateFormat.DATETIME);
    localStorage.setItem(LocalStorage.INVITATION_HASH, btoa(encodeURIComponent(JSON.stringify(invitation))));
  };

  // Remove invitation hash from local storage
  const unsetInvitationHash = (): void => {
    localStorage.removeItem(LocalStorage.INVITATION_HASH);
  };

  // Unset invitation hash if expired
  const unsetInvitationHashIfExpired = (): void => {
    const invitation = getInvitationHash();
    if (invitation) {
      const { expires_at } = invitation;
      dayjs.extend(customParseFormat);

      const expiryDate: dayjs.Dayjs = dayjs(expires_at, DateFormat.DATETIME);
      const currentDate: dayjs.Dayjs = dayjs();

      if (expiryDate.isBefore(currentDate)) {
        return unsetInvitationHash();
      }
      return;
    }

    return unsetInvitationHash();
  };

  return {
    getInvitationHash,
    setInvitationHash,
    unsetInvitationHash,
    unsetInvitationHashIfExpired,
  };
};

export default useLocalStorage;
