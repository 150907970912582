import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Vue from 'vue';
import App from '@/App.vue';

import router from '@/router/Index';
import store from '@/store';

// Bugsnag
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
// Logrocket
import LogRocket from 'logrocket';
// Google Tag Manager
import VueGtm from '@gtm-support/vue2-gtm';
// Cookies management
import VueCookies from 'vue-cookies';
// Firebase realtime database for notifications
import { rtdbPlugin } from 'vuefire';

import '@/validation';
import '@/sass/main.scss';

// Plugins
import ArrayPlugin from '@/plugins/Array';
import DatePlugin from '@/plugins/Date';
import DocumentPlugin from '@/plugins/Document';
import ErrorPlugin from '@/plugins/Error';
import HelperPlugin from '@/plugins/Helper';
import InitialsPlugin from '@/plugins/Initials';
import TranslationPlugin from '@/plugins/Translation';
import NotifierPlugin from '@/plugins/Notifier';
import NumberPlugin from '@/plugins/Number';
import PermissionPlugin from '@/plugins/Permission';
import PrototypePlugin from '@/plugins/Prototype';
import StringPlugin from '@/plugins/String';
import vuetify from '@/plugins/Vuetify';

// Directives
import NumberDirective from '@/directives/Number';
import TrimDirective from '@/directives/Trim';
import WatchDirective from '@/directives/Watch';

// Register plugins
Vue.use(ArrayPlugin);
Vue.use(DatePlugin);
Vue.use(DocumentPlugin);
Vue.use(ErrorPlugin);
Vue.use(HelperPlugin);
Vue.use(InitialsPlugin);
Vue.use(NotifierPlugin);
Vue.use(NumberPlugin);
Vue.use(PermissionPlugin);
Vue.use(PrototypePlugin);
Vue.use(StringPlugin);
Vue.use(TranslationPlugin);

// Register 3rd party plugins
Vue.use(rtdbPlugin);
Vue.use(VueCookies);
Vue.use(VueGtm, {
  id: process.env.VUE_APP_GOOGLE_TAG_MANAGER ? process.env.VUE_APP_GOOGLE_TAG_MANAGER : 'GTM-XXXXXX',
  vueRouter: router,
});

// Register directives
Vue.directive('number', NumberDirective);
Vue.directive('trim', TrimDirective);
Vue.directive('watch', WatchDirective);

Vue.config.productionTip = false;

// Initialize LogRocket
if (process.env.VUE_APP_LOGROCKET) {
  LogRocket.init(process.env.VUE_APP_LOGROCKET);
}
// Initialize Bugsnag
if (process.env.VUE_APP_BUGSNAG) {
  Bugsnag.start({
    apiKey: process.env.VUE_APP_BUGSNAG,
    plugins: [new BugsnagPluginVue()],
    // Add logrocket url to bugnsag session url metadata
    onError: (event: any): any => {
      event.addMetadata('logrocket', {
        sessionUrl: process.env.VUE_APP_LOGROCKET ? LogRocket.sessionURL : 'none',
      });
    },
    beforeSend: (event: any): any => {
      event.updateMetadata('logrocket', {
        sessionUrl: process.env.VUE_APP_LOGROCKET ? LogRocket.sessionURL : 'none',
      });
    },
  });
}

const app = new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');

export default app;
