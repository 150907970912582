import { Component, Vue } from 'vue-property-decorator';
import type Locale from '@/enums/config/Locale';
import DocumentType from '@/enums/config/document/Type';
import type ICompanyDocumentSettings from '@/interfaces/translate/ICompanyDocumentSettings';
import TranslationModule from '@/store/modules/Translation';

@Component
export default class TranslateCompanyDocumentSettingsMixin extends Vue {
  public TranslateCompanyDocumentSettingsMixin(
    type: DocumentType,
    locale: Locale = TranslationModule.getLocale,
  ): ICompanyDocumentSettings {
    switch (type) {
      case DocumentType.WAYBILL:
        return {
          title: this.trans('workspace.title.waybill', 'Pavadzīme', locale),
          subtitle: this.trans('workspace.description.settings_waybill', 'Vispārējie pavadzīmes iestatījumi.', locale),
          numberFormat: this.trans('workspace.title.waybill_number_format', 'Pavadzīmes numura formāts', locale),
          numberFormatPreview: this.trans(
            'workspace.title.preview.next_waybill_number',
            'Nākamās pavadīmes numura priekšskatījums',
            locale,
          ),
          defaultPaymentTerm: this.trans(
            'workspace.title.default_payment_term',
            'Noklusējuma maksājuma termiņš',
            locale,
          ),
          defaultNotes: this.trans('workspace.title.default_notes.waybill', 'Pavadzīmes piezīmes', locale),
          defaultSupplierNotes: this.trans(
            'workspace.title.default_supplier_notes',
            'Noklusējuma piegādātāja piezīmes',
            locale,
          ),
          saveStockTitle: this.trans('workspace.title.save_item_as_new', 'Saglabāt jaunus tabulas ierakstus', locale),
          saveStockTooltip: this.trans(
            'workspace.description.save_item_as_new',
            "Ja iespējots, tabulas vienības tiks automātiski saglabātas pie 'Precēm un Pakalpojumiem'.",
            locale,
          ),
        };
      case DocumentType.ADVANCE_INVOICE:
        return {
          title: this.trans('workspace.title.advance_invoices', 'Avansa rēķini', locale),
          subtitle: this.trans(
            'workspace.description.settings_advance_invoices',
            'Vispārējie avansa rēķina iestatījumi.',
            locale,
          ),
          numberFormat: this.trans(
            'workspace.title.advance_invoice_number_format',
            'Avansa rēķina numura formāts',
            locale,
          ),
          numberFormatPreview: this.trans(
            'workspace.title.preview.next_advance_invoice_number',
            'Nākamā avansa rēķina numura priekšskatījums',
            locale,
          ),
          defaultPaymentTerm: this.trans(
            'workspace.title.default_payment_term',
            'Noklusējuma maksājuma termiņš',
            locale,
          ),
          defaultNotes: this.trans('workspace.title.default_notes.advance_invoice', 'Avansa rēķina piezīmes', locale),
          defaultSupplierNotes: this.trans(
            'workspace.title.default_supplier_notes',
            'Noklusējuma piegādātāja piezīmes',
            locale,
          ),
          saveStockTitle: this.trans('workspace.title.save_item_as_new', 'Saglabāt jaunus tabulas ierakstus', locale),
          saveStockTooltip: this.trans(
            'workspace.description.save_item_as_new',
            "Ja iespējots, tabulas vienības tiks automātiski saglabātas pie 'Precēm un Pakalpojumiem'.",
            locale,
          ),
        };
      case DocumentType.OFFER:
        return {
          title: this.trans('workspace.title.offer', 'Piedāvājums', locale),
          subtitle: this.trans('workspace.description.settings_offer', 'Vispārējie piedāvājuma iestatījumi.', locale),
          numberFormat: this.trans('workspace.title.offer_number_format', 'Piedāvājuma numura formāts', locale),
          numberFormatPreview: this.trans(
            'workspace.title.preview.next_offer_number',
            'Nākamā piedāvājuma numura priekšskatījums',
            locale,
          ),
          defaultPaymentTerm: this.trans(
            'workspace.title.default_payment_term',
            'Noklusējuma maksājuma termiņš',
            locale,
          ),
          defaultNotes: this.trans('workspace.title.default_notes.offer', 'Piedāvājuma piezīmes', locale),
          defaultSupplierNotes: this.trans(
            'workspace.title.default_supplier_notes',
            'Noklusējuma piegādātāja piezīmes',
            locale,
          ),
          saveStockTitle: this.trans('workspace.title.save_item_as_new', 'Saglabāt jaunus tabulas ierakstus', locale),
          saveStockTooltip: this.trans(
            'workspace.description.save_item_as_new',
            "Ja iespējots, tabulas vienības tiks automātiski saglabātas pie 'Precēm un Pakalpojumiem'.",
            locale,
          ),
        };
      case DocumentType.CREDIT_NOTE:
        return {
          title: this.trans('workspace.title.credit_note', 'Rēķins', locale),
          subtitle: this.trans(
            'workspace.description.settings_credit_note',
            'Vispārējie kredītrēķina iestatījumi.',
            locale,
          ),
          numberFormat: this.trans('workspace.title.credit_note_number_format', 'Kredītrēķina numura formāts', locale),
          numberFormatPreview: this.trans(
            'workspace.title.preview.next_credit_note_number',
            'Nākamā kredītrēķina numura priekšskatījums',
            locale,
          ),
          defaultPaymentTerm: this.trans(
            'workspace.title.default_payment_term',
            'Noklusējuma maksājuma termiņš',
            locale,
          ),
          defaultNotes: this.trans('workspace.title.default_notes.credit_note', 'Kredītrēķina piezīmes', locale),
          defaultSupplierNotes: this.trans(
            'workspace.title.default_supplier_notes',
            'Noklusējuma piegādātāja piezīmes',
            locale,
          ),
          saveStockTitle: this.trans('workspace.title.save_item_as_new', 'Saglabāt jaunus tabulas ierakstus', locale),
          saveStockTooltip: this.trans(
            'workspace.description.save_item_as_new',
            "Ja iespējots, tabulas vienības tiks automātiski saglabātas pie 'Precēm un Pakalpojumiem'.",
            locale,
          ),
        };
      case DocumentType.INVOICE:
      default:
        return {
          title: this.trans('workspace.title.invoice', 'Rēķins', locale),
          subtitle: this.trans('workspace.description.settings_invoice', 'Vispārējie rēķina iestatījumi.', locale),
          numberFormat: this.trans('workspace.title.invoice_number_format', 'Rēķina numura formāts', locale),
          numberFormatPreview: this.trans(
            'workspace.title.preview.next_invoice_number',
            'Nākamā rēķina numura priekšskatījums',
            locale,
          ),
          defaultPaymentTerm: this.trans(
            'workspace.title.default_payment_term',
            'Noklusējuma maksājuma termiņš',
            locale,
          ),
          defaultNotes: this.trans('workspace.title.default_notes.invoice', 'Rēķina piezīmes', locale),
          defaultSupplierNotes: this.trans(
            'workspace.title.default_supplier_notes',
            'Noklusējuma piegādātāja piezīmes',
            locale,
          ),
          saveStockTitle: this.trans('workspace.title.save_item_as_new', 'Saglabāt jaunus tabulas ierakstus', locale),
          saveStockTooltip: this.trans(
            'workspace.description.save_item_as_new',
            "Ja iespējots, tabulas vienības tiks automātiski saglabātas pie 'Precēm un Pakalpojumiem'.",
            locale,
          ),
        };
    }
  }
}
