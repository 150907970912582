import Vue, { ComponentOptions } from 'vue';
import { createDecorator } from 'vue-class-component';
import TimeConfig from '@/enums/config/TimeConfig';

const Wait = (waitTime = TimeConfig.WAIT) =>
  createDecorator((options: ComponentOptions<Vue>, key: string) => {
    const useMethod = options.methods![key];

    // Wrap the method with the try catch logic
    options.methods![key] = async function wrapperMethod(...args: any) {
      this.$logger('Wait start');

      await new Promise((resolve) => setTimeout(resolve, waitTime));
      const returnValue = await useMethod.apply(this, args);

      this.$logger('Wait end');
      return returnValue;
    };
  });

export default Wait;
