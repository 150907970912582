import { Component, Vue } from 'vue-property-decorator';
import Locale from '@/enums/config/Locale';
import DocumentType from '@/enums/config/document/Type';
import TranslationModule from '@/store/modules/Translation';
@Component
export default class TranslateSendDocumentsMixin extends Vue {
  public TranslateSendDocumentsMixin(
    documentTypes: DocumentType[],
    locale: Locale = TranslationModule.getLocale,
  ): string {
    const usePlural: boolean = documentTypes.length > 1;
    const types: DocumentType[] = [...new Set(documentTypes)];

    const type: DocumentType | null = types.length === 1 ? types[0] : null;

    switch (type) {
      case DocumentType.INVOICE:
        return usePlural
          ? this.trans('modal.email.invoice.plural.title', 'Nosūtīt rēķinus', locale)
          : this.trans('modal.email.invoice.title', 'Nosūtīt rēķinu', locale);
      case DocumentType.WAYBILL:
        return usePlural
          ? this.trans('modal.email.waybill.plural.title', 'Nosūtīt pavadzīmes', locale)
          : this.trans('modal.email.waybill.title', 'Nosūtīt pavadzīmi', locale);
      case DocumentType.CREDIT_NOTE:
        return usePlural
          ? this.trans('modal.email.credit_note.plural.title', 'Nosūtīt kredītrēķinus', locale)
          : this.trans('modal.email.credit_note.title', 'Nosūtīt kredītrēķinu', locale);
      case DocumentType.ADVANCE_INVOICE:
        return usePlural
          ? this.trans('modal.email.advance_invoice.plural.title', 'Nosūtīt avansa rēķinus', locale)
          : this.trans('modal.email.advance_invoice.title', 'Nosūtīt avansa rēķinu', locale);
      case DocumentType.OFFER:
        return usePlural
          ? this.trans('modal.email.offer.plural.title', 'Nosūtīt piedāvājumus', locale)
          : this.trans('modal.email.offer.title', 'Nosūtīt piedāvājumu', locale);
      default:
        return this.trans('modal.email.document.plural.title', 'Nosūtīt dokumentus', locale);
    }
  }
}
