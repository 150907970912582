enum KeypressInputType {
  TEXT = 'text',
  EMAIL = 'email',
  POSITIVE_INT = 'positiveInt',
  NEGATIVE_INT = 'negativeInt',
  POSITIVE_FLOAT = 'positiveFloat',
  NEGATIVE_FLOAT = 'negativeFloat',
}

export default KeypressInputType;
