<template>
  <svg width="21" height="21" viewBox="0 0 21 21" fill="currentColor">
    <path d="M18.002 3V14.17L20.002 16.17V3C20.002 1.9 19.102 1 18.002 1H4.83203L6.83203 3H18.002Z" />
    <path
      d="M1.81062 0.80957L0.390625 2.21957L2.00063 3.82957V16.9996C2.00063 18.0996 2.90063 18.9996 4.00063 18.9996H17.1706L18.7806 20.6096L20.1906 19.1996L1.81062 0.80957ZM4.00063 16.9996V5.82957L11.0706 12.8996L10.2506 13.9996L8.00063 10.9996L5.00063 14.9996H13.1706L15.1706 16.9996H4.00063Z"
    />
  </svg>
</template>

<script lang="ts">
  import { Component, Vue } from 'vue-property-decorator';

  @Component
  export default class NoImageIcon extends Vue {
    /*****         computed       *****/
    /*****         watchers       *****/
    /*****         methods        *****/
    /*****         helpers        *****/
    /*****      vue lifecycle     *****/
  }
</script>
