<template>
  <v-btn-toggle
    v-model="formValue"
    mandatory
    borderless
    :background-color="backgroundColor"
    :active-class="activeClass"
    class="rounded-lg shadow-md ma-0"
    @change="change"
  >
    <v-btn
      :value="falseValue"
      :ripple="false"
      :disabled="disabled"
      :loading="loading"
      :min-height="0"
      height="auto"
      color="transparent"
      class="font-weight-medium text-subtitle-2"
      :class="btnClass || 'px-5 py-4'"
    >
      <slot name="option-false">
        <span v-text="optionFalse" />
      </slot>
    </v-btn>

    <v-btn
      :value="trueValue"
      :ripple="false"
      :disabled="disabled"
      :loading="loading"
      :min-height="0"
      height="auto"
      color="transparent"
      class="font-weight-medium text-subtitle-2"
      :class="btnClass || 'px-5 py-4'"
    >
      <slot name="option-true">
        <span v-text="optionTrue" />
      </slot>
    </v-btn>
  </v-btn-toggle>
</template>
<script lang="ts">
  import { Component, Emit, Prop, VModel, Vue } from 'vue-property-decorator';

  @Component
  export default class ToggleButton extends Vue {
    @VModel() formValue!: string | number | boolean;

    @Prop({ default: '' }) optionFalse!: string;
    @Prop({ default: '' }) optionTrue!: string;

    @Prop({ default: 'primary' }) primaryColor!: string;
    @Prop({ default: 'white' }) secondaryColor!: string;
    @Prop() btnClass?: string;

    @Prop({ type: Boolean }) disabled?: boolean;
    @Prop({ type: Boolean }) loading?: boolean;

    private isBool = false;
    private isNumeric = false;

    /*****         computed       *****/

    public get trueValue(): string | number | boolean {
      if (this.isBool) {
        return true;
      } else if (this.isNumeric) {
        return 1;
      } else {
        return this.optionTrue || '1';
      }
    }

    public get falseValue(): string | number | boolean {
      if (this.isBool) {
        return false;
      } else if (this.isNumeric) {
        return 0;
      } else {
        return this.optionFalse || '0';
      }
    }

    public get activeClass(): string {
      return `${this.primaryColor} ${this.secondaryColor}--text`;
    }

    public get backgroundColor(): string {
      return this.secondaryColor;
    }

    /*****         watchers       *****/
    /*****         methods        *****/

    @Emit('change')
    public change(formValue: string | number | boolean): string | number | boolean {
      return formValue;
    }

    /*****         helpers        *****/
    /*****      vue lifecycle     *****/

    beforeMount() {
      this.isNumeric = typeof this.formValue === 'number';
      this.isBool = typeof this.formValue === 'boolean';
    }
  }
</script>
