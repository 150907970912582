import { Component, Mixins } from 'vue-property-decorator';
import CatchError from '@/decorators/CatchError';
import Loading from '@/decorators/Loading';
import AppState from '@/enums/generics/AppState';
import type IStock from '@/interfaces/stock/IStock';
import type IStockForm from '@/interfaces/forms/stock/IStockForm';
import type IStockMixin from '@/interfaces/mixins/IStockMixin';
import CompanyModule from '@/store/modules/Company';
import StockModule from '@/store/modules/Stock';
import PageModule from '@/store/modules/Page';
import CreateStockMixin from '@/mixins/stock/Create';
import DeleteStockMixin from '@/mixins/stock/Delete';
import ListStocksMixin from '@/mixins/stock/List';
import ListStocSearchkMixin from '@/mixins/stock/ListSearch';
import TotalStockMixin from '@/mixins/stock/Total';
import UpdateStockMixin from '@/mixins/stock/Update';

@Component
export default class StockMixin extends Mixins<IStockMixin>(
  CreateStockMixin,
  DeleteStockMixin,
  ListStocksMixin,
  ListStocSearchkMixin,
  TotalStockMixin,
  UpdateStockMixin,
) {
  // Fetch stock - use ListStocksMixin
  @Loading()
  @CatchError()
  public async listStocks(companyUUID: string = CompanyModule.getCompanyUUID): Promise<void> {
    const { data, meta } = await this.ListStocksMixin(companyUUID);

    await PageModule.SET_PAGINATION(meta);
    await StockModule.SET_STOCK_LIST(data);
  }

  // Fetch stock by search - use ListSearchStockMixin
  public async listStockSearch(search: string, companyUUID: string = CompanyModule.getCompanyUUID): Promise<IStock[]> {
    return await this.ListStocSearchkMixin(search, companyUUID);
  }

  // Create new stock - use CreateStockMixin
  @Loading()
  @CatchError()
  public async createStock(payload: IStockForm, companyUUID: string = CompanyModule.getCompanyUUID): Promise<void> {
    await this.CreateStockMixin(payload, companyUUID);
    await this.listStocks(companyUUID);

    await this.$notifier({
      message: this.trans('notify.success.record_created', 'Ieraksts veiksmīgi izveidots'),
      state: AppState.SUCCESS,
    });
  }

  // Update existing stock - use UpdateStockMixin
  @Loading()
  @CatchError()
  public async updateStock(
    payload: IStockForm,
    stockUUID: string,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<void> {
    await this.UpdateStockMixin(payload, stockUUID, companyUUID);
    await this.listStocks(companyUUID);

    await this.$notifier({
      message: this.trans('notify.success.record_updated', 'Ieraksts veiksmīgi izlabots'),
      state: AppState.SUCCESS,
    });
  }

  // Delete existing stock - use DeleteStockMixin
  @Loading()
  @CatchError()
  public async deleteStock(stockUUID: string, companyUUID: string = CompanyModule.getCompanyUUID): Promise<void> {
    await this.DeleteStockMixin(stockUUID, companyUUID);
    await this.listStocks(companyUUID);

    await this.$notifier({
      message: this.trans('notify.success.record_deleted', 'Ieraksts veiksmīgi izdzēsts'),
      state: AppState.SUCCESS,
    });
  }
}
