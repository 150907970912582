const CHAR_COLOR_MAP: Record<number, string> = {
  48: '#5162BE',
  49: '#519B39',
  50: '#C1F429',
  51: '#E1CAE4',
  52: '#81BD25',
  53: '#C18CB6',
  54: '#0C5DE2',
  55: '#963E75',
  56: '#C903D8',
  57: '#3EBF56',
  65: '#5162BE',
  66: '#519B39',
  67: '#C1F429',
  68: '#E1CAE4',
  69: '#81BD25',
  70: '#C18CB6',
  71: '#0C5DE2',
  72: '#963E75',
  73: '#C903D8',
  74: '#3EBF56',
  75: '#31CDDB',
  76: '#581642',
  77: '#2F8B4C',
  78: '#73B491',
  79: '#C1A385',
  80: '#F2832B',
  81: '#A6B535',
  82: '#050360',
  83: '#D74B28',
  84: '#9C3D9E',
  85: '#9DE751',
  86: '#6F30C5',
  87: '#280860',
  88: '#B562E3',
  89: '#95B1A1',
  90: '#494C0C',
  97: '#5162BE',
  98: '#519B39',
  99: '#C1F429',
  100: '#E1CAE4',
  101: '#81BD25',
  102: '#C18CB6',
  103: '#0C5DE2',
  104: '#963E75',
  105: '#C903D8',
  106: '#3EBF56',
  107: '#31CDDB',
  108: '#581642',
  109: '#2F8B4C',
  110: '#73B491',
  111: '#C1A385',
  112: '#F2832B',
  113: '#A6B535',
  114: '#050360',
  115: '#D74B28',
  116: '#9C3D9E',
  117: '#9DE751',
  118: '#6F30C5',
  119: '#280860',
  120: '#B562E3',
  121: '#95B1A1',
  122: '#494C0C',
};

export default CHAR_COLOR_MAP;
