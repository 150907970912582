import { render, staticRenderFns } from "./UserOnboarding.vue?vue&type=template&id=0c976de4"
import script from "./UserOnboarding.vue?vue&type=script&lang=ts"
export * from "./UserOnboarding.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports