<template>
  <v-btn
    :color="color"
    :block="block"
    :rounded="rounded"
    :loading="loading"
    :disabled="disabled"
    :outlined="outlined"
    :height="height"
    :type="type"
    depressed
    :class="fontSize"
    @click="submit"
    @mousedown.native="mousedown"
  >
    <slot>
      <span v-text="text" />
    </slot>
  </v-btn>
</template>
<script lang="ts">
  import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

  @Component
  export default class SubmitButton extends Vue {
    @Prop({ default: 'submit' }) type!: HTMLButtonElement['type'];
    @Prop({ default: 'primary' }) color!: string;
    @Prop({ default: '46' }) height!: string;

    @Prop({ default: '' }) text!: string;
    @Prop({ default: 'font-weight-medium text-body-1' }) fontSize!: string;

    @Prop({ type: Boolean }) block!: boolean;
    @Prop({ type: Boolean }) rounded!: boolean;
    @Prop({ type: Boolean }) loading!: boolean;
    @Prop({ type: Boolean }) disabled!: boolean;

    @Prop({ type: Boolean }) outlined!: boolean;
    @Prop({ default: true }) depressed!: boolean;

    /*****         computed       *****/
    /*****         watchers       *****/
    /*****         methods        *****/

    @Emit('click')
    @Emit('submit')
    public submit(): void {}

    @Emit('mousedown')
    public mousedown(): void {}

    /*****         helpers        *****/
    /*****      vue lifecycle     *****/
  }
</script>
