<template>
  <v-container class="flex items-center justify-center h-full text-center">
    <v-row class="items-center justify-center grow-0 w-full">
      <v-col cols="12" tag="header" class="flex flex-col items-center gap-2 pa-4">
        <h2 class="text-h4 font-weight-bold text-center leading-none" v-text="title" />
      </v-col>
      <v-progress-linear
        v-model="progressBar"
        background-color="secondary"
        color="primary"
        height="25"
        class="rounded-lg cultured--text"
      >
        <template v-slot="{ value }">
          <strong v-text="`${Math.ceil(value)}%`" />
        </template>
      </v-progress-linear>
    </v-row>
  </v-container>
</template>

<script lang="ts">
  import { Component, Vue } from 'vue-property-decorator';
  import useLocalStorage from '@/services/LocalStorage';
  import DocumentRoute from '@/enums/config/document/Route';
  import AuthRouteName from '@/enums/config/router/Auth';
  import ProfileRouteName from '@/enums/config/router/Profile';
  import DashboardRouteName from '@/enums/config/router/Dashboard';
  import AppState from '@/enums/generics/AppState';
  import CompanyModule from '@/store/modules/Company';
  import UserModule from '@/store/modules/User';

  @Component
  export default class InvitationPage extends Vue {
    public progressBar: number = 0;

    /*****         computed       *****/

    public get title(): string {
      return this.trans('public.invitation.title', 'Lūdzu, uzgaidiet, kamēr mēs pārbaudam Jūsu ielūguma saiti');
    }

    private get isLoggedIn(): boolean {
      return UserModule.isLoggedIn;
    }

    /*****         watchers       *****/
    /*****         methods        *****/

    private beginInvitationCheck(): void {
      const interval = setInterval(async () => {
        if (this.progressBar >= 100) {
          await this.completeInvitationCheck();
          clearInterval(interval);
        }

        this.updateProgressBar();
      }, 600);
    }

    /*****         helpers        *****/

    private updateProgressBar(): void {
      if (this.progressBar > 90 && this.progressBar < 100) {
        this.progressBar += 5;
      } else if (this.progressBar > 40 && this.progressBar < 90) {
        this.progressBar += 25;
      } else if (this.progressBar < 40) {
        this.progressBar += 35;
      }
    }

    // Complete invitation check
    private async completeInvitationCheck(): Promise<void> {
      const uuid = this.$route.params.uuid;
      const hash = this.$route.query.hash;
      const email = (this.$route.query.email || '').toString();
      const is_new = true;

      // Check if invitation uuid and hash is present
      // If so, set it to local storage
      // But if invitation uuid and hash is not present
      // Remove invitation hash from local storage
      if (uuid && typeof hash === 'string') {
        useLocalStorage().setInvitationHash({ uuid, hash, email, is_new });
      } else {
        useLocalStorage().unsetInvitationHash();
        await this.$notifier({
          message: this.trans('notify.error.invitation.not_found', 'Ielūgums nav atrasts'),
          state: AppState.ERROR,
        });
      }

      // Redirect to login page if user is logged in
      if (this.isLoggedIn) {
        if (CompanyModule.hasCompany) {
          await this.$router.replace({
            name: DashboardRouteName.LIST,
            params: { document: DocumentRoute.ALL },
          });
        } else {
          await this.$router.replace({ name: ProfileRouteName.DATA });
        }
      } else {
        await this.$router.replace({ name: AuthRouteName.LOGIN });
      }
    }

    /*****      vue lifecycle     *****/

    mounted() {
      this.beginInvitationCheck();
    }
  }
</script>
