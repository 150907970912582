import CountryISO from '@/enums/config/iso/CountryISO';
import CurrencyISO from '@/enums/config/iso/CurrencyISO';
import type ICompanyDetails from '@/interfaces/company/ICompanyDetails';

const DEFAULT_COMPANY_DETAILS: ICompanyDetails = {
  name: '',
  is_legal: true,
  registration_number: '',
  vat_tax_payer: false,
  vat_registration_number: '',
  currency_code: CurrencyISO.EUR,
  address: '',
  phone_prefix: '+371',
  phone_number: '',
  country: CountryISO.LV,
  email: '',
  website: '',
};

export default DEFAULT_COMPANY_DETAILS;
