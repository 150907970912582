import { Component, Vue } from 'vue-property-decorator';
import type ICompany from '@/interfaces/company/ICompany';
import type IEmailSettings from '@/interfaces/company/IEmailSettings';
import CompanyRepository from '@/repository/Company';
import CompanyModule from '@/store/modules/Company';

@Component
export default class UpdateEmailSettingsMixin extends Vue {
  public async UpdateEmailSettingsMixin(
    payload: IEmailSettings,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<ICompany> {
    return (await CompanyRepository.updateEmailSettings(companyUUID, payload)).data;
  }
}
