<template>
  <div class="block w-full">
    <slot name="button" :open="open" />
    <BaseModal v-model="showModal" :loading="isLoading" use-header use-form use-esc @cancel="close">
      <template #title>
        <p class="leading-none my-0" v-text="title" />
        <p class="leading-none mt-2 text-body-2" v-text="subtitle" />
      </template>
      <template #content>
        <v-form :disabled="isLoading" @submit.prevent class="w-full">
          <validation-observer ref="form-observer" class="flex flex-col items-center w-full gap-y-4">
            <!-- Message field -->
            <TextAreaField
              v-model="form.message"
              v-trim
              :placeholder="trans('workspace.title.email_message', 'Ziņa')"
              :label="trans('workspace.support.your_message', 'Jūsu ziņa')"
              :disabled="isLoading"
              autocomplete="message"
              counter="5000"
              rules="required|max:5000"
              name="message"
              rows="4"
              rounded
              class="text-body-2 w-full"
            />
            <!-- Drag n drop file field -->
            <DragNDropField
              v-model="form.attachments"
              :label="trans('workspace.title.attachment', 'Pielikums')"
              :hint="
                trans(
                  'workspace.support.email.attachment.guide',
                  'Iespējams pievienot līdz pat :fileCount attēliem un video failiem. Viena faila izmērs nedrīkst pārsniegt :fileSize',
                  { fileCount: 4, fileSize: '4MB' },
                )
              "
              :disabled="isLoading || form.attachments.length >= 4"
              :image-formats="imageFormats"
              :video-formats="videoFormats"
              :rules="attachmentRules"
              name="attachments"
              :upload-limit="4"
              multiple
              rounded
              class="text-body-2 w-full"
            />
            <!-- Send message to support button -->
            <v-col cols="10" class="py-4">
              <SubmitButton
                block
                rounded
                :loading="isLoading"
                :text="trans('button.send', 'Nosūtīt')"
                @mousedown="support"
              />
            </v-col>
          </validation-observer>
        </v-form>
      </template>
    </BaseModal>
  </div>
</template>

<script lang="ts">
  import defer from 'lodash-es/defer';
  import cloneDeep from 'lodash-es/cloneDeep';
  import { Component, Emit, Mixins, Ref, Prop, Watch } from 'vue-property-decorator';
  import type { ProviderInstance } from 'vee-validate/dist/types/types';
  import DEFAULT_SUPPORT_FORM from '@/constants/mocks/forms/Support';
  import MimeType from '@/enums/config/MimeType';
  import type ISupportForm from '@/interfaces/forms/ISupportForm';
  import ErrorModule from '@/store/modules/Error';
  import LoadingModule from '@/store/modules/Loading';
  import SupportMixin from '@/mixins/Support';
  import BaseModal from '@/components/global/modals/Base.vue';
  import TextAreaField from '@/components/global/inputs/TextAreaField.vue';
  import DragNDropField from '@/components/global/inputs/dragndrop/DragNDrop.vue';
  import SubmitButton from '@/components/global/buttons/Submit.vue';

  @Component({
    components: {
      BaseModal,
      TextAreaField,
      DragNDropField,
      SubmitButton,
    },
  })
  export default class SupportModal extends Mixins(SupportMixin) {
    @Prop({ type: Boolean }) useDelay!: boolean;

    @Ref('form-observer') formObserver!: ProviderInstance;

    public form: ISupportForm = DEFAULT_SUPPORT_FORM;
    public showModal: boolean = false;

    /*****         computed       *****/

    public get error(): unknown {
      return ErrorModule.getError;
    }

    public get isLoading(): boolean {
      return LoadingModule.isLoading;
    }

    public get title(): string {
      return this.trans('workspace.support.email.title', 'Ir jautājums? Sazinieties ar mums.');
    }

    public get subtitle(): string {
      return this.trans(
        'workspace.support.email.subtitle',
        'Mūsu atbalsta komanda ar prieku atbildēs uz Jūsu jautājumiem.',
      );
    }

    public get imageFormats(): MimeType[] {
      return [MimeType.JPEG, MimeType.PNG, MimeType.BPM, MimeType.WEBP];
    }

    public get videoFormats(): MimeType[] {
      return [MimeType.MP4, MimeType.MPEG, MimeType.WEBM, MimeType.AVI];
    }

    public get attachmentRules(): string {
      return `mimetypes:${this.imageFormats.join(',')},${this.videoFormats.join(',')}|filesize:4096`;
    }

    /*****         watchers       *****/

    @Watch('error')
    private watchForError(): void {
      if (this.error && this.formObserver) {
        this.lookForAttachmentError();
        this.formObserver.setErrors(this.error as string[]);
      }
    }

    @Watch('showModal')
    private watchForShow(): void {
      this.resetErrors();
    }

    /*****         methods        *****/

    @Emit('request')
    public request(): void {}

    @Emit('open')
    public open(): void {
      this.useDelay ? this.openWithDelay() : this.openNow();
    }

    @Emit('close')
    public close(): void {
      this.showModal = false;
    }

    public async support(): Promise<void> {
      this.formObserver.reset();
      if (await this.formObserver.validate()) {
        await this.requestSupport(this.form);
        // If no errors, close modal
        if (!this.error) {
          this.request();
          return this.close();
        }
      }
      return this.scrollToFirstError(this.formObserver);
    }

    /*****         helpers        *****/

    private openNow(): void {
      this.showModal = true;
    }

    private openWithDelay(): void {
      defer(() => {
        this.openNow();
      });
    }

    public resetErrors(): void {
      this.form = cloneDeep(DEFAULT_SUPPORT_FORM);

      if (this.formObserver) {
        this.formObserver.reset();
        this.$nextTick(() => {
          this.formObserver.reset();
        });
      }
    }

    private lookForAttachmentError(): void {
      const error: Record<string, string[]>[] | undefined = Object.entries(this.error ?? {}).find(([key]) =>
        ['attachments'].some((e: string) => key.includes(e)),
      );

      if (error) {
        const errorKey: string = error[0].toString();
        const errorValue: string = error[1][0].toString();

        const errorMessage: string = errorValue.replace(
          errorKey,
          this.trans('workspace.title.attachment', 'Pielikums'),
        );

        this.formObserver.setErrors({ attachments: [errorMessage] } as unknown as string[]);
      }
    }

    /*****      vue lifecycle     *****/
  }
</script>
