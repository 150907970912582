import type { IRecurringMailer } from '@/interfaces/document/IRecurringDocument';

const DEFAULT_RECURRING_DOCUMENT_MAILER_FORM: IRecurringMailer = {
  email: '',
  bcc: [],
  subject: '',
  message: '',
};

export default DEFAULT_RECURRING_DOCUMENT_MAILER_FORM;
