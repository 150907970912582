import { Component, Vue } from 'vue-property-decorator';
import type Locale from '@/enums/config/Locale';
import DocumentType from '@/enums/config/document/Type';
import RecurringDocumentType from '@/enums/config/document/RecurringType';
import TranslationModule from '@/store/modules/Translation';

@Component
export default class TranslateDocumentNumberAndDateMixin extends Vue {
  public TranslateDocumentNumberAndDateMixin(
    type: DocumentType | RecurringDocumentType,
    locale: Locale = TranslationModule.getLocale,
  ): string {
    switch (type) {
      case DocumentType.WAYBILL:
        return this.trans('workspace.title.document_number_and_date.waybill', 'Pavadzīmes numurs un datums', locale);
      case DocumentType.ADVANCE_INVOICE:
        return this.trans(
          'workspace.title.document_number_and_date.advance_invoice',
          'Avansa rēķina numurs un datums',
          locale,
        );
      case DocumentType.OFFER:
        return this.trans('workspace.title.document_number_and_date.offer', 'Piedāvājuma numurs un datums', locale);
      case DocumentType.CREDIT_NOTE:
        return this.trans(
          'workspace.title.document_number_and_date.credit_note',
          'Kredītrēķina numurs un datums',
          locale,
        );
      case DocumentType.INVOICE:
      default:
        return this.trans('workspace.title.document_number_and_date.invoice', 'Rēķina numurs un datums', locale);
    }
  }
}
