import VatCode from '@/enums/config/VatCode';
import VatExemptionCode from '@/enums/config/VatExemptionCode';
import type IUserReferenceForm from '@/interfaces/forms/reference/IUserReferenceForm';

const DEFAULT_USER_REFERENCE_FORM: IUserReferenceForm = {
  uuid: '',
  title: '',
  vat_code: VatCode.Z,
  vat_exemption_code: VatExemptionCode.NONE,
  is_default: false,
};

export default DEFAULT_USER_REFERENCE_FORM;
