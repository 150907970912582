enum DocumentRouteName {
  CREATE = 'app.document.create',
  COPY = 'app.document.copy',
  EDIT = 'app.document.edit',
  CONVERT = 'app.document.convert',
  PARTNER = 'app.document.partner',

  INDEX = 'app.document.index',

  VIEW = 'app.document.view',
}

export default DocumentRouteName;
