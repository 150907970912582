import { Component, Vue } from 'vue-property-decorator';
import Locale from '@/enums/config/Locale';
import UserRole from '@/enums/config/UserRole';
import TranslationModule from '@/store/modules/Translation';

@Component
export default class TranslateUserRoleMixin extends Vue {
  public TranslateUserRoleMixin(role: UserRole, locale: Locale = TranslationModule.getLocale): string {
    switch (role) {
      case UserRole.OWNER:
        return this.trans('generic.role.owner', 'Īpašnieks', locale);
      case UserRole.ADMIN:
        return this.trans('generic.role.admin', 'Administrators', locale);
      case UserRole.USER:
        return this.trans('generic.role.user', 'Lietotājs', locale);
      default:
        return '-';
    }
  }
}
