import { Component, Vue } from 'vue-property-decorator';
import CompanyRepository from '@/repository/Company';
import CompanyModule from '@/store/modules/Company';

@Component
export default class DeleteCompanyMixin extends Vue {
  public async DeleteCompanyMixin(companyUUID: string = CompanyModule.getCompanyUUID): Promise<boolean> {
    return (await CompanyRepository.delete(companyUUID)).data.success;
  }
}
