<template>
  <v-container class="flex items-center justify-center h-full text-center">
    <v-row class="items-center justify-center grow-0 w-full">
      <v-col cols="12" tag="header" class="flex flex-col items-center gap-4 pa-4">
        <h2 class="text-h4 font-weight-bold text-center leading-none" v-text="title" />
        <p class="text-body-1 text-center leading-none granite--text" v-text="subtitle" />
      </v-col>
      <v-progress-linear
        v-model="progressBar"
        background-color="secondary"
        color="primary"
        height="25"
        class="rounded-lg cultured--text"
      >
        <template v-slot="{ value }">
          <strong v-text="`${Math.ceil(value)}%`" />
        </template>
      </v-progress-linear>
    </v-row>
  </v-container>
</template>

<script lang="ts">
  import { Component, Mixins } from 'vue-property-decorator';
  import AppState from '@/enums/generics/AppState';
  import AuthRouteName from '@/enums/config/router/Auth';
  import ProfileRouteName from '@/enums/config/router/Profile';
  import type IUser from '@/interfaces/user/IUser';
  import UserModule from '@/store/modules/User';
  import ProfileMixin from '@/mixins/Profile';

  @Component
  export default class VerifyEmailPage extends Mixins(ProfileMixin) {
    public progressBar: number = 0;
    private isVerified: boolean = false;
    private verificationRetries: number = 0;

    /*****         computed       *****/

    // Retrieve user state from store
    public get user(): IUser | null {
      return UserModule.getUser;
    }

    // User logged in state from store
    public get isLoggedIn(): boolean {
      return UserModule.isLoggedIn;
    }

    public get title(): string {
      return this.trans('verification.user_email.heading', 'Mirkli uzgaidiet līdz mēs verificēsim Jūsu e-pastu');
    }

    public get subtitle(): string {
      if (this.progressBar >= 25) {
        if (this.isVerified) {
          switch (true) {
            case this.progressBar >= 100:
              return this.trans('email_confirmation.progress.title.email_verified', 'E-pasts ir verificēts');
            case this.progressBar >= 60 && this.progressBar <= 80:
              return this.trans('email_confirmation.progress.title.check_profile', 'Profila pārbaude');
          }
        }
        return this.trans('email_confirmation.progress.title.email_verification', 'E-pasta verifikācija');
      }
      return this.trans('onboarding.progress.loading', 'Notiek ielāde');
    }

    /*****         watchers       *****/
    /*****         methods        *****/

    public beginVerification(): void {
      const interval = setInterval(async () => {
        if (this.shouldStopVerification()) {
          await this.completeVerification();
          clearInterval(interval);
        }

        this.updateProgressBar();
        this.verificationRetries++;
      }, 600);
    }

    public async completeVerification(): Promise<void> {
      const successMessage = this.trans('notify.success.email_verified', 'E-pasts ir verificēts');
      const failureMessage = this.trans('notify.error.email_verification', 'Neizdevās verificēt e-pastu');

      const message = this.isVerified ? successMessage : failureMessage;
      const state = this.isVerified ? AppState.SUCCESS : AppState.ERROR;

      await this.$notifier({ message, state });

      if (this.isLoggedIn) {
        await this.$router.push({ name: ProfileRouteName.DATA });
      } else {
        await UserModule.USER_LOGOUT();
        await this.$router.push({ name: AuthRouteName.LOGIN });
      }
    }

    /*****         helpers        *****/

    private shouldStopVerification(): boolean {
      return this.progressBar >= 100 && this.verificationRetries > 6;
    }

    private updateProgressBar(): void {
      if (this.progressBar > 90 && this.progressBar < 100) {
        this.progressBar += 5;
      } else if (this.progressBar > 40 && this.progressBar < 90) {
        this.progressBar += 25;
      } else if (this.progressBar < 40) {
        this.progressBar += 15;
      }
    }

    /*****      vue lifecycle     *****/

    async beforeMount() {
      this.beginVerification();

      if (this.$route.query) {
        this.isVerified = await this.profileVerifyEmail({
          hash: (this.$route.query.hash || '').toString().trim(),
          uid: (this.$route.query.uid || '').toString().trim(),
          signature: (this.$route.query.signature || '').toString().trim(),
        });
      }
    }
  }
</script>
