import { Component, Vue } from 'vue-property-decorator';
import type Locale from '@/enums/config/Locale';
import SortDir from '@/enums/generics/SortDir';
import SortField from '@/enums/generics/SortField';
import TranslationModule from '@/store/modules/Translation';

@Component
export default class TranslateSortFieldMixin extends Vue {
  public TranslateSortFieldMixin(
    sortField: SortField,
    sortDir: SortDir,
    locale: Locale = TranslationModule.getLocale,
  ): string {
    switch (sortField) {
      case SortField.DATE:
        switch (sortDir) {
          case SortDir.ASC:
            return this.trans('generic.sort.oldest', 'Vecākais', locale);
          default:
            return this.trans('generic.sort.latest', 'Jaunākais', locale);
        }
      case SortField.PRICE:
      case SortField.VAT_PERCENT:
      case SortField.TOTAL_WITH_VAT:
        switch (sortDir) {
          case SortDir.ASC:
            return this.trans('generic.sort.asc', 'Pieaugoši', locale);
          default:
            return this.trans('generic.sort.desc', 'Dilstoši', locale);
        }
      default:
        switch (sortDir) {
          case SortDir.ASC:
            return this.trans('generic.sort.a_to_z', 'No A-Z', locale);
          default:
            return this.trans('generic.sort.z_to_a', 'No Z-A', locale);
        }
    }
  }
}
