import Locale from '@/enums/config/Locale';
import type IYoutube from '@/interfaces/config/IYoutube';
import TUTORIALS_LV from '@/constants/config/tutorials/Tutorials_LV';
import TUTORIALS_EN from '@/constants/config/tutorials/Tutorials_EN';
import TUTORIALS_RU from '@/constants/config/tutorials/Tutorials_RU';

const YOUTUBE_TUTORIALS: IYoutube = {
  [Locale.LV]: TUTORIALS_LV,
  [Locale.EN]: TUTORIALS_EN,
  [Locale.RU]: TUTORIALS_RU,
};

export default YOUTUBE_TUTORIALS;
