enum Unit {
  EACH = 'each',
  MINUTE = 'minute',
  HOUR = 'hour',
  DAY = 'day',
  NIGHT = 'night',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
  G = 'g',
  HG = 'hg',
  KG = 'kg',
  LB = 'lb',
  TON = 'ton',
  MILE = 'mile',
  M = 'm',
  KM = 'km',
  M2 = 'm2',
  HA = 'ha',
  AC = 'ac',
  M3 = 'm3',
  L = 'l',
  ML = 'ml',
  WORD = 'word',
  PAGE = 'page',
  PACKAGE = 'package',
  KIT = 'kit',
  KWH = 'kwh',
  MWH = 'mwh',
  GWH = 'gwh',
}

export default Unit;
