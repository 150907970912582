import axios, { InternalAxiosRequestConfig } from 'axios';
import ErrorModule from '@/store/modules/Error';
import TranslationModule from '@/store/modules/Translation';

export const microserviceBaseURL = process.env.VUE_APP_MS_URL;

export const axiosConfig = {
  baseURL: microserviceBaseURL,
  withCredentials: true,
};

const MicroserviceRepository = axios.create(axiosConfig);

// Setup interceptors
MicroserviceRepository.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    await TranslationModule.SET_LOCALE();
    config.headers['App-Locale'] = TranslationModule.getLocale;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

MicroserviceRepository.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    await ErrorModule.SET_INTERCEPTOR_ERROR(error);
    return Promise.reject(error);
  },
);

export default MicroserviceRepository;
