import type ISelect from '@/interfaces/config/ISelect';

const DEFAULT_SELECT: ISelect = {
  text: '',
  value: '',
  description: '',
  symbol: '',
  image: require('@/assets/svg/flag/default.svg'),
};

export default DEFAULT_SELECT;
