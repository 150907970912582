<template>
  <v-container fluid class="flex items-center justify-center h-full text-center px-0 overflow-hidden">
    <v-row class="relative items-center justify-center grow-0 z-10">
      <v-col cols="12" class="flex flex-col items-center justify-center text-center gap-4 mt-12">
        <h2
          class="text-h4 font-weight-bold text-center leading-none"
          :style="`max-width: ${maxWidth}%`"
          v-text="title"
        />
        <p class="text-body-1 text-center leading-none granite--text" v-text="description" />
      </v-col>
      <v-col cols="12" class="flex flex-col items-center justify-center gap-2">
        <WireMaintenanceIcon class="w-full h-full" />
      </v-col>
      <v-col cols="12" class="flex flex-col items-center justify-center gap-2 ma-16">
        <NumberoIcon />
      </v-col>
      <div class="absolute inset-0 w-full flex items-center justify-center -z-1">
        <div ref="radial-gradient" class="blog w-full h-full rounded-pill pulse" />
      </div>
    </v-row>
  </v-container>
</template>

<script lang="ts">
  import { Component, Mixins, Ref } from 'vue-property-decorator';
  import type { AxiosError, AxiosResponse } from 'axios';
  import useBreakpoints from '@/services/Breakpoints';
  import DashboardRouteName from '@/enums/config/router/Dashboard';
  import DocumentRoute from '@/enums/config/document/Route';
  import HttpStatus from '@/enums/generics/HttpStatus';
  import AppModule from '@/store/modules/App';
  import RouterHistoryModule from '@/store/modules/RouterHistory';
  import UserRepository from '@/repository/User';
  import ProfileMixin from '@/mixins/Profile';
  import NumberoIcon from '@/components/icons/brands/Numbero.vue';
  import WireMaintenanceIcon from '@/components/icons/static/WireMaintenance.vue';

  @Component({
    components: {
      NumberoIcon,
      WireMaintenanceIcon,
    },
  })
  export default class MaintenancePage extends Mixins(ProfileMixin) {
    @Ref('radial-gradient') radialGradient!: HTMLElement;

    /*****         computed       *****/

    public get title(): string {
      return this.trans('workspace.title.maintenance_underway', 'Notiek uzlabošanas darbi');
    }

    public get description(): string {
      return this.trans('workspace.description.maintenance_underway', 'Būsim atpakaļ pēc īsa brīža!');
    }

    public get maxWidth(): number {
      if (useBreakpoints().isDesktop()) {
        return 23;
      }

      if (useBreakpoints().isLaptop()) {
        return 45;
      }

      if (useBreakpoints().isTablet()) {
        return 50;
      }

      return 100;
    }

    /*****         watchers       *****/
    /*****         methods        *****/

    private setWidthEqualToHeight() {
      if (this.radialGradient) {
        const height = this.radialGradient.offsetHeight;
        this.radialGradient.style.width = `${height}px`;
        this.radialGradient.style.background = 'radial-gradient(circle, #e6f9f2 60%, transparent 80%)';
      }
    }

    /*****         helpers        *****/
    /*****      vue lifecycle     *****/

    /*****         lifecycle hooks       *****/

    mounted() {
      this.setWidthEqualToHeight();
      window.addEventListener('resize', this.setWidthEqualToHeight);
    }

    beforeDestroy() {
      window.removeEventListener('resize', this.setWidthEqualToHeight);
    }

    async beforeMount() {
      try {
        await UserRepository.fetch();
        await AppModule.CLEAR_IS_MAINTENANCE();
      } catch (e) {
        const networkError: AxiosResponse | null = (e as AxiosError).response ?? null;
        if (networkError && networkError.status === HttpStatus.ServiceUnavailable) {
          await AppModule.SET_IS_MAINTENANCE();
        } else {
          await AppModule.CLEAR_IS_MAINTENANCE();
        }
      }

      if (!AppModule.getIsMaintenance) {
        await this.$router.push(
          RouterHistoryModule.getLastRoute ?? {
            name: DashboardRouteName.LIST,
            params: { document: DocumentRoute.ALL },
          },
        );
      }
    }
  }
</script>
