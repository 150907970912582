<template>
  <validation-provider
    :rules="rules"
    :debounce="debounceValidation"
    v-slot="{ errors }"
    :name="label"
    :vid="name"
    tag="div"
    class="relative flex flex-col items-start gap-y-2 text-left"
  >
    <label
      v-if="showLabel && label"
      :for="name"
      class="float-left text-left text-inherit leading-none"
      :class="dark ? 'white--text' : 'black--text'"
    >
      <slot name="label" :label="label">
        <span v-text="label" />
      </slot>
    </label>
    <v-textarea
      v-model="formValue"
      :placeholder="placeholder"
      :row-height="rowHeight"
      :clearable="clearable"
      :autofocus="autofocus"
      :readonly="readonly"
      :disabled="disabled"
      :loading="isLoading"
      hide-details="auto"
      :counter="counter"
      :rows="rows"
      :name="name"
      :id="name"
      auto-grow
      solo
      flat
      :color="textColor ?? (dark ? 'white' : 'eerie')"
      :background-color="color ?? (dark ? 'eerie' : 'white')"
      class="overflow-hidden text-inherit w-full pa-0"
      :class="[{ 'rounded-lg': rounded }, { 'text-field-error': !!error(errors) && showErrorBorder }, inputClass]"
      @blur="blur"
      @focus="focus"
      @input="input"
      @change="change"
      @keydown="keydown"
      @keypress="keypress"
      @click:clear="clear"
    >
      <template #append>
        <slot name="append" />
      </template>
      <template #counter="{ props }">
        <div class="absolute" :class="{ 'error--text': counter && props.value > counter }" style="top: 2px; right: 4px">
          {{ props.value }}/{{ counter }}
        </div>
      </template>
    </v-textarea>
    <v-scroll-y-transition>
      <div v-show="error(errors) && showError" class="text-caption error--text mt-n1" v-text="error(errors)" />
    </v-scroll-y-transition>
    <v-scroll-y-transition>
      <div v-show="!(error(errors) && showError) && hint" class="text-caption black--text mt-n1" v-text="hint" />
    </v-scroll-y-transition>
    <div v-if="disabled" class="absolute inset-0 cursor-not-allowed" />
  </validation-provider>
</template>

<script lang="ts">
  import throttle from 'lodash-es/throttle';
  import { Component, Emit, Prop, VModel, Vue } from 'vue-property-decorator';
  import KeypressInputType from '@/enums/types/KeypressInputType';
  import TimeConfig from '@/enums/config/TimeConfig';

  @Component
  export default class TextAreaField extends Vue {
    @VModel() formValue!: string | number | null;

    @Prop({ default: KeypressInputType.TEXT }) inputType!: KeypressInputType;

    @Prop({ default: 'primary' }) loadingColor!: string;
    @Prop({ default: '' }) placeholder?: string;
    @Prop({ default: '' }) name?: string;
    @Prop({ default: '' }) hint?: string;
    @Prop({ default: '' }) label?: string;
    @Prop({ default: true }) showLabel?: boolean;
    @Prop({ default: true }) showError?: boolean;
    @Prop({ default: true }) showErrorBorder?: boolean;

    @Prop({ default: 0 }) debounceValidation?: number;
    @Prop({ default: '' }) rules?: string;

    @Prop({ type: Boolean }) clearable?: boolean;
    @Prop({ type: Boolean }) autofocus?: boolean;
    @Prop({ type: Boolean }) readonly?: boolean;
    @Prop({ type: Boolean }) disabled?: boolean;
    @Prop({ type: Boolean }) loading?: boolean;

    @Prop({ type: Boolean }) rounded?: boolean;
    @Prop({ type: Boolean }) dark?: boolean;

    @Prop() color?: string;
    @Prop() textColor?: string;
    @Prop() inputClass?: string;

    @Prop({ default: 24 }) rowHeight?: number;
    @Prop({ default: 1 }) rows?: number;
    @Prop() counter?: number;

    /*****         computed       *****/

    public get isLoading(): boolean | string {
      return this.loading ? this.loadingColor : false;
    }

    /*****         watchers       *****/
    /*****         methods        *****/

    @Emit('input:debounce')
    public async input(userInput: string): Promise<string> {
      if ((this.formValue ?? '') != (userInput ?? '')) {
        await this.updateQuery(userInput);
      }
      return userInput ?? '';
    }

    @Emit('query')
    public query(userInput: string): string {
      return userInput;
    }

    @Emit('change')
    public change(): void {}

    @Emit('keypress')
    public keypress(e: KeyboardEvent): void {
      switch (this.inputType) {
        case KeypressInputType.POSITIVE_INT:
          this.positiveInteger(e);
          return;
        case KeypressInputType.NEGATIVE_INT:
          this.negativeInteger(e);
          return;
        case KeypressInputType.POSITIVE_FLOAT:
          this.positiveFloat(e);
          return;
        case KeypressInputType.NEGATIVE_FLOAT:
          this.negativeFloat(e);
          return;
      }
    }

    @Emit('keydown')
    public keydown(e: KeyboardEvent): KeyboardEvent {
      return e;
    }

    @Emit('blur')
    public blur(e: FocusEvent): FocusEvent {
      return e;
    }

    @Emit('focus')
    public focus(e: FocusEvent): FocusEvent {
      return e;
    }

    @Emit('clear')
    public async clear(): Promise<void> {
      this.formValue = '';
      await this.updateQuery();
    }

    @Emit('error')
    public error(errors: any): string {
      if (errors.length) {
        return errors[0];
      }
      return '';
    }

    /*****         helpers        *****/

    private updateQuery = throttle(async function (this: TextAreaField, query = ''): Promise<void> {
      this.query(query ?? '');
    }, TimeConfig.DEBOUNCE);

    /*****      vue lifecycle     *****/
  }
</script>
