import { RouteConfig } from 'vue-router';
import DashboardRouteName from '@/enums/config/router/Dashboard';

const DashboardRouter: Array<RouteConfig> = [
  {
    path: 'dashboard',
    component: () => import(/* webpackChunkName: "appView" */ '@/views/Dashboard.vue'),
    meta: {
      guard: ['redirect'],
    },
    children: [
      {
        path: '/',
        name: DashboardRouteName.INDEX,
        meta: {
          title: 'Dashboard',
        },
      },
      {
        path: ':document',
        name: DashboardRouteName.LIST,
        component: () => import(/* webpackChunkName: "appView" */ '@/pages/app/dashboard/Index.vue'),
        meta: {
          parent: DashboardRouteName.INDEX,
          title: 'Dashboard',
        },
      },
    ],
  },
];

export default DashboardRouter;
