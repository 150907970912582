<template>
  <div class="flex items-center justify-start gap-2">
    <!-- Add new company -->
    <AddCompanyModal v-if="!hasCompany" use-delay />
    <!-- Notifications -->
    <Tooltip
      :text="trans('workspace.title.notifications', 'Paziņojumi')"
      :placement="Placement.BOTTOM"
      :offset="tooltipOffset"
      arrow
    >
      <v-menu
        content-class="btn--menu btn--items shadow-lg"
        transition="slide-y-transition"
        :close-on-content-click="false"
        nudge-bottom="-3"
        nudge-left="5"
        offset-y
        bottom
        eager
        left
        @input="showMenu"
      >
        <template #activator="{ on, attrs, value: show }">
          <v-badge :value="hasUnreadNotifications" color="primary" offset-y="17" offset-x="19">
            <template #badge>
              <span v-text="unreadNotificationCount" />
            </template>
            <v-btn
              v-bind="attrs"
              v-on="on"
              height="38"
              width="38"
              depressed
              icon
              class="btn--menu pa-0"
              :class="{ 'btn--hover': show }"
            >
              <v-scale-transition group leave-absolute>
                <v-icon v-if="hasUnreadNotifications" key="unread" :color="colorPalete.color" class="ring">
                  $bellAlert
                </v-icon>
                <v-icon v-else key="read" :color="colorPalete.color">$bell</v-icon>
              </v-scale-transition>
            </v-btn>
          </v-badge>
        </template>

        <v-list
          v-if="notifications.length > 0"
          :color="useTheme ? '#000' : '#FFF'"
          elevation="2"
          max-width="400px"
          class="cursor-default py-2 px-0"
        >
          <v-list-item v-for="n in notifications" :key="n.uuid" :color="useTheme ? '#000' : '#FFF'">
            <v-card
              :color="useTheme ? '#000' : '#FFF'"
              elevation="0"
              :class="useTheme ? 'cultured--text' : 'black--text'"
              class="pa-2 space-y-2"
            >
              <v-card-title class="flex items-center gap-2 transition-all pa-0">
                <v-list-item-icon class="ma-0">
                  <v-icon :color="iconColor(n.type)" small>{{ icon(n.type) }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="pa-0">
                  <v-list-item-title>
                    <span
                      class="text-body-1 font-weight-bold leading-none"
                      v-text="trans(n.title_key, n.title_default)"
                    />
                  </v-list-item-title>
                </v-list-item-content>
                <v-scale-transition>
                  <v-list-item-action v-if="!n.has_read" class="ma-0" style="min-width: 0">
                    <div style="width: 8px; height: 8px; border-radius: 50%" class="primary" />
                  </v-list-item-action>
                </v-scale-transition>
              </v-card-title>
              <v-card-text class="pa-0" :class="useTheme ? 'cultured--text' : 'black--text'" style="opacity: 0.8">
                <span
                  class="text-body-2 font-weight-light leading-none"
                  v-text="trans(n.content_key, n.content_default)"
                />
              </v-card-text>
            </v-card>
          </v-list-item>
        </v-list>
      </v-menu>
    </Tooltip>
    <!-- Support -->
    <Tooltip
      v-if="!hasCompany"
      :text="trans('workspace.support.title', 'Atbalsts')"
      :placement="Placement.BOTTOM"
      :offset="tooltipOffset"
      arrow
    >
      <SupportModal use-delay>
        <template #button="{ open }">
          <v-btn height="38" width="38" depressed icon class="btn--menu pa-0" @click="open()">
            <v-icon :color="colorPalete.color">$headphones</v-icon>
          </v-btn>
        </template>
      </SupportModal>
    </Tooltip>
    <!-- Logout -->
    <Tooltip
      v-if="!hasCompany"
      :text="trans('menu.logout', 'Iziet')"
      :placement="Placement.BOTTOM"
      :offset="tooltipOffset"
      arrow
    >
      <v-btn height="38" width="38" depressed icon class="btn--menu pa-0" @click="logout">
        <v-icon :color="colorPalete.color">$logout</v-icon>
      </v-btn>
    </Tooltip>
  </div>
</template>

<script lang="ts">
  import { Component, Mixins, Prop } from 'vue-property-decorator';
  import NotificationType from '@/enums/config/NotificationType';
  import Placement from '@/enums/generics/Placement';
  import type IUser from '@/interfaces/user/IUser';
  import type IColorSchema from '@/interfaces/config/IColorSchema';
  import type INotifcation from '@/interfaces/INotification';
  import AuthMixin from '@/mixins/Auth';
  import HelperMixin from '@/mixins/Helper';
  import CompanyModule from '@/store/modules/Company';
  import UserModule from '@/store/modules/User';
  import NotificationModule from '@/store/modules/Notification';

  @Component({
    components: {
      AddCompanyModal: () => import('@/components/global/modals/AddCompany.vue'),
      SupportModal: () => import('@/components/global/modals/Support.vue'),
      Tooltip: () => import('@/components/global/Tooltip.vue'),
    },
  })
  export default class Notifications extends Mixins(AuthMixin, HelperMixin) {
    @Prop() colorPalete!: IColorSchema;
    @Prop() useTheme!: boolean;
    @Prop() navHeight!: number;

    /*****         computed       *****/

    public get isLoggedIn(): boolean {
      return UserModule.isLoggedIn;
    }

    public get user(): IUser | null {
      return UserModule.getUser;
    }

    public get hasCompany(): boolean {
      return CompanyModule.hasCompany;
    }

    public get notifications(): INotifcation[] {
      return NotificationModule.getNotifications;
    }

    public get unreadNotificationCount(): number {
      return NotificationModule.getUnreadNotificationCount;
    }

    public get hasUnreadNotifications(): boolean {
      return NotificationModule.hasUnreadNotifications;
    }

    public get tooltipOffset(): string {
      return `0, ${this.navHeight / 3.6}`;
    }

    public get Placement(): typeof Placement {
      return Placement;
    }

    /*****         watchers       *****/
    /*****         methods        *****/

    public async logout(): Promise<void> {
      await this.authLogout();
    }

    /*****         helpers        *****/

    public async showMenu(show: boolean): Promise<void> {
      if (!show && this.hasUnreadNotifications && this.isLoggedIn && this.user) {
        await this.markNotificationsAsRead(this.user);
      }
    }

    public icon(type: NotificationType): string {
      switch (type) {
        case NotificationType.DEFAULT:
          return '$bookmarkSolid';
        case NotificationType.VERIFY_EMAIL:
          return '$envelopeSolid';
        case NotificationType.ALERT:
          return '$warning';
        default:
          return '$bookmarkSolid';
      }
    }

    public iconColor(type: NotificationType): string {
      switch (type) {
        case NotificationType.DEFAULT:
          return 'primary';
        case NotificationType.VERIFY_EMAIL:
          return 'warning';
        case NotificationType.ALERT:
          return 'error';
        default:
          return 'primary';
      }
    }

    /*****      vue lifecycle     *****/

    beforeMount() {
      setTimeout(async () => {
        if (this.isLoggedIn && this.user && this.notifications.length === 0) {
          await this.listNotifications(this.user);
        }
      }, 2000);
    }
  }
</script>
