import { Component, Vue } from 'vue-property-decorator';
import DocumentRepository from '@/repository/Document';
import CompanyModule from '@/store/modules/Company';

@Component
export default class NextDocumentNumberSequenceMixin extends Vue {
  public async NextDocumentNumberSequenceMixin(
    numberFormat: string,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<number> {
    return (await DocumentRepository.recordNumber(companyUUID, numberFormat)).data.number_iterator;
  }
}
