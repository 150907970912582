import Locale from '@/enums/config/Locale';
import type ITestData from '@/interfaces/config/ITestData';

const TEST_DATA: ITestData = {
  email: 'test@numbero.app',
  password: 'password',

  newUser: {
    email: 'new@numbero.app',
    password: 'password',
    first_name: 'New',
    last_name: 'User',
    locale: Locale.EN,
    company: {
      name: 'NUMBERO APP, SIA',
      registration_number: '40003520643',
      vat_number: 'LV40003520643',
    },
  },

  partner: {
    name: 'Cool Partner',
  },

  stock: {
    sku: 'SKU-123',
    name: 'Product Name',
    description: 'Product Description',
  },

  settings: {
    logo: 'https://cdn.midjourney.com/1a2fbc9c-bd6f-4730-86d9-39d244138a2f/0_2.webp',
    color: '#3581B8',
  },

  document: {
    exchnage_rate: 1.5,
    exchange_date: '',
  },
};

export default TEST_DATA;
