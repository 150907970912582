import { RouteConfig } from 'vue-router';
import AuthRouteName from '@/enums/config/router/Auth';
import PasswordResetPage from '@/pages/auth/PasswordReset.vue';

const PasswordResetRouter: Array<RouteConfig> = [
  {
    path: 'reset-password/:token',
    name: AuthRouteName.PASSWORD_RESET,
    component: PasswordResetPage,
    meta: {
      title: 'Set new password',
    },
  },
];

export default PasswordResetRouter;
