import type IPagination from '@/interfaces/IPagination';

const DEFAULT_PAGINATION: IPagination = {
  current_page: 1,
  last_page: 1,
  per_page: 25,

  total: 1,
  from: 1,
  to: 1,

  path: '',
};

export default DEFAULT_PAGINATION;
