import Vue from 'vue';
import axios, { InternalAxiosRequestConfig } from 'axios';
import ErrorModule from '@/store/modules/Error';
import TranslationModule from '@/store/modules/Translation';

export const apiBaseURL = process.env.VUE_APP_API_URL;

export const axiosConfig = {
  baseURL: apiBaseURL,
  withCredentials: true,
  // These are the default values
  // And work only if the request is same origin
  xsrfCookieName: 'XSRF-TOKEN',
  xsrfHeaderName: 'X-XSRF-TOKEN',
};

const ApiRepository = axios.create(axiosConfig);

// Setup interceptors
ApiRepository.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    await TranslationModule.SET_LOCALE();
    config.headers['App-Locale'] = TranslationModule.getLocale;

    // This needs to be set because Axios set the XSRF-TOKEN only if isSameOrigin
    const xsrfToken = Vue.$cookies.get('XSRF-TOKEN');
    if (xsrfToken) {
      config.headers['X-XSRF-TOKEN'] = xsrfToken;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

ApiRepository.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    await ErrorModule.SET_INTERCEPTOR_ERROR(error);
    return Promise.reject(error);
  },
);

export default ApiRepository;
