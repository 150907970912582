import { Component, Vue } from 'vue-property-decorator';
import type IUser from '@/interfaces/user/IUser';
import UserRepository from '@/repository/User';

@Component
export default class LeaveUserCompanyMixin extends Vue {
  public async LeaveUserCompanyMixin(companyUUID: string): Promise<IUser> {
    return (await UserRepository.leaveCompany(companyUUID)).data;
  }
}
