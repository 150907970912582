import { RouteConfig } from 'vue-router';
import AppLayout from '@/layouts/App.vue';

import ProfileRouter from '@/router/app/Profile';
import SettingsRouter from '@/router/app/Settings';
import DashboardRouter from '@/router/app/Dashboard';
import DocumentRouter from '@/router/app/Document';
import RecurringRouter from '@/router/app/Recurring';
import PartnerRouter from '@/router/app/Partner';
import StockRouter from '@/router/app/Stock';

const UserIndexRouter: Array<RouteConfig> = [
  {
    path: '/',
    component: AppLayout,
    meta: {
      guard: ['maintenance', 'auth', 'hasCompany'],
    },
    children: [
      ...ProfileRouter,
      ...SettingsRouter,
      ...DashboardRouter,
      ...DocumentRouter,
      ...RecurringRouter,
      ...PartnerRouter,
      ...StockRouter,
    ],
  },
];

export default UserIndexRouter;
