import { Component, Vue } from 'vue-property-decorator';
import SortField from '@/enums/generics/SortField';
import type IStockListResponse from '@/interfaces/stock/response/IStockListResponse';
import StockRepository from '@/repository/Stock';
import CompanyModule from '@/store/modules/Company';
import PageModule from '@/store/modules/Page';

@Component
export default class ListStocksMixin extends Vue {
  public async ListStocksMixin(companyUUID: string = CompanyModule.getCompanyUUID): Promise<IStockListResponse> {
    // Check if sort_field is valid
    const sortField = PageModule.getUrlParams.sort_field;
    const acceptableFields = [SortField.SKU, SortField.NAME, SortField.PRICE, SortField.VAT_PERCENT];
    if (sortField && !acceptableFields.includes(sortField)) {
      await PageModule.SET_URL_PARAMS({ sort_field: SortField.NAME });
    }

    return (await StockRepository.list(companyUUID, PageModule.getUrlParams)).data;
  }
}
