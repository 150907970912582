import { Component, Vue } from 'vue-property-decorator';
import SortField from '@/enums/generics/SortField';
import type IPartnerListResponse from '@/interfaces/partner/response/IPartnerListResponse';
import PartnerRepository from '@/repository/Partner';
import CompanyModule from '@/store/modules/Company';
import PageModule from '@/store/modules/Page';

@Component
export default class ListPartnersMixin extends Vue {
  public async ListPartnersMixin(companyUUID: string = CompanyModule.getCompanyUUID): Promise<IPartnerListResponse> {
    // Check if sort_field is valid
    const sortField = PageModule.getUrlParams.sort_field;
    const acceptableFields = [SortField.NAME, SortField.EMAIL, SortField.PHONE];
    if (sortField && !acceptableFields.includes(sortField)) {
      await PageModule.SET_URL_PARAMS({ sort_field: SortField.NAME });
    }

    return (await PartnerRepository.list(companyUUID, PageModule.getUrlParams)).data;
  }
}
