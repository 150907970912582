import { Component, Vue } from 'vue-property-decorator';
import type Locale from '@/enums/config/Locale';
import type DocumentType from '@/enums/config/document/Type';
import RecurringDocumentType from '@/enums/config/document/RecurringType';
import TranslationModule from '@/store/modules/Translation';

@Component
export default class TranslateRecurringTypeMixin extends Vue {
  public TranslateRecurringTypeMixin(
    type: RecurringDocumentType | DocumentType,
    locale: Locale = TranslationModule.getLocale,
  ): string {
    switch (type) {
      case RecurringDocumentType.WAYBILL:
        return this.trans('generic.recurring_type.waybill', 'Regulārā pavadzīme', locale);
      case RecurringDocumentType.ADVANCE_INVOICE:
        return this.trans('generic.recurring_type.advance_invoice', 'Regulārais avansa rēķins', locale);
      case RecurringDocumentType.OFFER:
        return this.trans('generic.recurring_type.offer', 'Regulārais piedāvājums', locale);
      case RecurringDocumentType.INVOICE:
      default:
        return this.trans('generic.recurring_type.invoice', 'Regulārais rēķins', locale);
    }
  }
}
