<template>
  <div class="block w-full">
    <slot name="button" :open="open" />
    <BaseModal v-model="showModal" :loading="isLoading" use-header use-form use-esc eager @cancel="close">
      <template #title>
        <p class="leading-none my-0" v-text="title" />
      </template>
      <template #content>
        <div class="block space-y-4">
          <div class="grow w-full" v-for="company in userCompanies" :key="company.uuid">
            <v-hover v-slot="{ hover }">
              <v-card
                :ripple="false"
                elevation="0"
                rounded="lg"
                :disabled="isLoading"
                class="block w-full border-1 transition-all"
                :class="
                  company.selected
                    ? 'border-primary'
                    : hover
                    ? 'border-silver bg-silver-30 scale-103'
                    : 'border-cultured'
                "
                @click="switchCompany(company)"
              >
                <v-list-item>
                  <v-list-item-avatar
                    :color="company.selected ? 'granite' : 'silver'"
                    size="40"
                    :style="composeCompanyInitialsCss(company.name)"
                  >
                    <v-img v-if="company.logo" :src="company.logo" />
                    <span v-else class="text-body-1 font-weight-medium" v-text="composeCompanyInitials(company.name)" />
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>
                      <span class="leading-none text-body-2 font-weight-medium" v-text="company.name" />
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <span
                        class="text-caption granite--text"
                        v-text="translatePermission(company.role, [...company.permissions])"
                      />
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <template v-if="company.role === UserRole.USER">
                    <v-btn
                      icon
                      tabindex="-1"
                      color="granite"
                      class="z-10"
                      :class="{ 'cursor-not-allowed': false }"
                      @click.stop="openDeleteModal(company)"
                    >
                      <v-icon color="error" size="22"> $trash </v-icon>
                    </v-btn>
                  </template>
                </v-list-item>
              </v-card>
            </v-hover>
          </div>

          <CheckboxField
            v-model="rememberCompany"
            :disabled="isLoading"
            :label="trans('workspace.title.company.show_default', 'Rādīt šo kompāniju pēc noklusējuma')"
            name="remember"
            class="pt-2"
          >
            <template #label>
              <div
                v-text="trans('workspace.title.company.show_default', 'Rādīt šo kompāniju pēc noklusējuma')"
                class="black--text text-subtitle-2 font-weight-thin"
                @click.stop
              />
            </template>
          </CheckboxField>
        </div>
      </template>
    </BaseModal>
    <DeleteModal
      v-model="showDeleteModal"
      :title="trans('modal.company.leave.title', 'Pamest uzņēmumu')"
      :content="
        trans('modal.company.leave.question', 'Vai tiešām vēlaties pamest uzņēmumu :company?', {
          company: form?.name ?? '-',
        })
      "
      @confirm="leaveCompany(form?.uuid ?? '')"
      @cancel="closeDeleteModal"
    />
  </div>
</template>

<script lang="ts">
  import defer from 'lodash-es/defer';
  import { Component, Emit, Mixins, Ref, Prop, Watch } from 'vue-property-decorator';
  import LocalStorage from '@/enums/config/LocalStorage';
  import UserRole from '@/enums/config/UserRole';
  import type { ProviderInstance } from 'vee-validate/dist/types/types';
  import type IUserCompany from '@/interfaces/user/IUserCompany';
  import UserModule from '@/store/modules/User';
  import ProfileMixin from '@/mixins/Profile';
  import TranslateMixin from '@/mixins/Translate';
  import BaseModal from '@/components/global/modals/Base.vue';
  import CheckboxField from '@/components/global/inputs/CheckboxField.vue';

  @Component({
    components: {
      BaseModal,
      CheckboxField,
      DeleteModal: () => import('@/components/global/modals/Delete.vue'),
    },
  })
  export default class SwitchCompanyModal extends Mixins(ProfileMixin, TranslateMixin) {
    @Prop({ type: Boolean }) useDelay!: boolean;

    @Ref('form-observer') formObserver!: ProviderInstance;

    public rememberCompany: boolean = false;

    public isLoading = false;
    public showModal: boolean = false;
    public showDeleteModal: boolean = false;

    public form: IUserCompany | null = null;

    /*****         computed       *****/

    public get userCompanies(): IUserCompany[] {
      return UserModule.getCompanies;
    }

    public get title(): string {
      return this.trans('workspace.change.company.title', 'Izvēlies kompāniju, lai turpinātu');
    }

    public get UserRole(): typeof UserRole {
      return UserRole;
    }

    /*****         watchers       *****/

    @Watch('userCompanies.length', { deep: true, immediate: true })
    private watchForCompaniesCount(newValue: number, oldValue: number): void {
      const forceSwitchCompany = localStorage.getItem(LocalStorage.SWITCH_COMPANY);
      const hasNewCompany = newValue != oldValue;
      // Open modal if user has attached to new company
      // And force switch company is set
      if (hasNewCompany && forceSwitchCompany) {
        this.open();
      }
    }

    /*****         methods        *****/

    @Emit('open')
    public open(): void {
      this.rememberCompany = UserModule.rememberCompany;
      this.useDelay ? this.openWithDelay() : this.openNow();
      localStorage.removeItem(LocalStorage.SWITCH_COMPANY);
    }

    @Emit('close')
    public close(): void {
      this.showModal = false;
    }

    @Emit('switch')
    public async switchCompany(company: IUserCompany): Promise<void> {
      this.isLoading = true;

      if (company.selected) {
        await this.rememberUserCompany(this.rememberCompany);
      } else {
        await this.switchUserCompany(company.uuid, this.rememberCompany);
      }

      this.close();

      this.isLoading = false;
    }

    public async leaveCompany(companyUUID: string): Promise<void> {
      this.isLoading = true;

      await this.leaveUserCompany(companyUUID);
      this.closeDeleteModal();

      if (!this.userCompanies.length) {
        this.close();
      }
      this.isLoading = false;
    }

    public openDeleteModal(company: IUserCompany): void {
      this.form = company;
      this.showDeleteModal = true;
    }

    public closeDeleteModal(): void {
      this.form = null;
      this.showDeleteModal = false;
    }

    /*****         helpers        *****/

    private openNow(): void {
      this.showModal = true;
    }

    private openWithDelay(): void {
      defer(() => {
        this.openNow();
      });
    }

    /*****      vue lifecycle     *****/
  }
</script>
