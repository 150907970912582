import Locale from '@/enums/config/Locale';
import CurrencyISO from '@/enums/config/iso/CurrencyISO';
import type IDocumentSettings from '@/interfaces/company/IDocumentSettings';

const DEFAULT_DOCUMENT_SETTINGS: IDocumentSettings = {
  // General settings
  default_document_locale: Locale.LV,
  timezone: 3,
  default_document_currency_code: CurrencyISO.EUR,
  share_link_retention_days: 30,
  use_ur_database: true,
  // Document settings per type
  document_preferences: {
    invoice: {
      number_format: 'INV-{Y}/{N}',
      default_due_days: 14,
      default_notes: '',
      default_supplier_notes: '',
      autosave_items: false,
    },
    waybill: {
      number_format: 'WYB-{Y}/{N}',
      default_due_days: 14,
      default_notes: '',
      default_supplier_notes: '',
      autosave_items: false,
    },
    offer: {
      number_format: 'OFR-{Y}/{N}',
      default_due_days: 14,
      default_notes: '',
      default_supplier_notes: '',
      autosave_items: false,
    },
    advance_invoice: {
      number_format: 'ADV-{Y}/{N}',
      default_due_days: 14,
      default_notes: '',
      default_supplier_notes: '',
      autosave_items: false,
    },
    credit_note: {
      number_format: 'CRN-{Y}/{N}',
      default_due_days: 14,
      default_notes: '',
      default_supplier_notes: '',
      autosave_items: false,
    },
  },
  // Fines & Taxes
  use_taxes: false,
  use_late_fees: false,
  default_tax_rate: 21,
  default_late_fee_rate: 0.5,
  // Vat calculation method
  tax_by_group: true,
};

export default DEFAULT_DOCUMENT_SETTINGS;
