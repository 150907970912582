import DEFAULT_DOCUMENT_FORM from '@/constants/mocks/forms/Document';
import DEFAULT_RECURRING_DOCUMENT_MAILER_FORM from '@/constants/mocks/forms/RecurringDocumentMailer';
import PeriodType from '@/enums/config/PeriodType';
import type IRecurringDocument from '@/interfaces/document/IRecurringDocument';

const DEFAULT_RECURRING_DOCUMENT_FORM: IRecurringDocument = {
  ...DEFAULT_DOCUMENT_FORM,
  recurring: {
    config: {
      start_date: '',
      end_date: '',
      period_type: PeriodType.DAY,
      period: 1,
    },
    mailer: DEFAULT_RECURRING_DOCUMENT_MAILER_FORM,
  },
  is_template: true,
};

export default DEFAULT_RECURRING_DOCUMENT_FORM;
