import cloneDeep from 'lodash-es/cloneDeep';
import { Component, Vue } from 'vue-property-decorator';
import DEFAULT_COMPANY from '@/constants/mocks/company/Company';
import DEFAULT_RECURRING_DOCUMENT_FORM from '@/constants/mocks/forms/RecurringDocument';
import DEFAULT_STOCK_FORM from '@/constants/mocks/forms/Stock';
import type ICompany from '@/interfaces/company/ICompany';
import type IRecurringDocument from '@/interfaces/document/IRecurringDocument';
import type IStock from '@/interfaces/stock/IStock';
import CompanyModule from '@/store/modules/Company';
import GenericModule from '@/store/modules/Generic';
import StockMixin from '@/mixins/Stock';
import TranslateMixin from '@/mixins/Translate';

@Component
export default class InitEditRecurringMixin extends Vue {
  public async InitEditRecurringMixin(fetchedRecurring: IRecurringDocument): Promise<IRecurringDocument> {
    const unitList = GenericModule.getUnits;
    const document: IRecurringDocument = cloneDeep({ ...DEFAULT_RECURRING_DOCUMENT_FORM, ...fetchedRecurring });

    const company: ICompany = CompanyModule.getCompany || DEFAULT_COMPANY;

    document.logo = company.logo;
    document.color = company.default_document_accent_color;
    document.currency_code =
      document.currency_code ||
      document.recipient.preferences.currency_code ||
      company.currency_code ||
      DEFAULT_COMPANY.default_document_currency_code;

    const singleVat = document.has_multi_vat
      ? CompanyModule.getDefaultTaxRate
      : new StockMixin().composeVatPercent(document.items[0].vat_percent);
    document.single_vat = singleVat;

    // Remove empty items and fix measurement
    const payloadItems: IStock[] = document.items
      .filter((e) => !!e.name || !!e.measurement || !!e.price)
      .map((e) => {
        e.measurement = (unitList.find((f) => e.measurement === f.text)?.value ?? e.measurement ?? '').toString();
        e.vat_percent = document.sender.vat_registration_number
          ? document.has_multi_vat
            ? e.vat_percent
            : singleVat
          : 0;
        return { ...e, ...new StockMixin().composeItem(e) };
      });

    document.items = cloneDeep(payloadItems.length ? payloadItems : [DEFAULT_STOCK_FORM]);

    // This is a workaround to fix the locale of the additional price rows
    // Backend could return the description in document locale
    // But we need to display it in the system locale
    for (const e of document.additional_price_rows) {
      e.description = await new TranslateMixin().translatePaidWithInSystemLocale(e.description, document.lang);
    }

    return cloneDeep(document);
  }
}
