<template>
  <svg width="30" height="26" viewBox="0 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.3334 25V3.66667C20.3334 2.95942 20.0524 2.28115 19.5523 1.78105C19.0522 1.28095 18.3739 1 17.6667 1H12.3334C11.6261 1 10.9478 1.28095 10.4477 1.78105C9.94764 2.28115 9.66669 2.95942 9.66669 3.66667V25M4.33335 6.33333H25.6667C27.1394 6.33333 28.3334 7.52724 28.3334 9V22.3333C28.3334 23.8061 27.1394 25 25.6667 25H4.33335C2.86059 25 1.66669 23.8061 1.66669 22.3333V9C1.66669 7.52724 2.86059 6.33333 4.33335 6.33333Z"
      stroke-linejoin="round"
      stroke-linecap="round"
      stroke="currentColor"
      stroke-width="2"
    />
  </svg>
</template>

<script lang="ts">
  import { Component, Vue } from 'vue-property-decorator';

  @Component
  export default class BriefcaseIcon extends Vue {
    /*****         computed       *****/
    /*****         watchers       *****/
    /*****         methods        *****/
    /*****         helpers        *****/
    /*****      vue lifecycle     *****/
  }
</script>
