import { Component, Mixins } from 'vue-property-decorator';
import CatchError from '@/decorators/CatchError';
import Loading from '@/decorators/Loading';
import LocalStorage from '@/enums/config/LocalStorage';
import DocumentRoute from '@/enums/config/document/Route';
import AuthRouteName from '@/enums/config/router/Auth';
import DashboardRouteName from '@/enums/config/router/Dashboard';
import PublicRouteName from '@/enums/config/router/Public';
import AppState from '@/enums/generics/AppState';
import type ILoginForm from '@/interfaces/forms/auth/ILoginForm';
import type IRegisterProfileForm from '@/interfaces/forms/auth/IRegisterProfileForm';
import type IPasswordForgotForm from '@/interfaces/forms/auth/IPasswordForgotForm';
import type IPasswordResetForm from '@/interfaces/forms/auth/IPasswordResetForm';
import type IUser from '@/interfaces/user/IUser';
import type IAuthMixin from '@/interfaces/mixins/IAuthMixin';
import CsrfMixin from '@/mixins/auth/Csrf';
import CsrfRetryMixin from '@/mixins/auth/CsrfRetry';
import LoginMixin from '@/mixins/auth/Login';
import LogoutMixin from '@/mixins/auth/Logout';
import PasswordForgotMixin from '@/mixins/auth/PasswordForgot';
import PasswordResetMixin from '@/mixins/auth/PasswordReset';
import RegisterMixin from '@/mixins/auth/Register';
import TranslationModule from '@/store/modules/Translation';
import NotificationModule from '@/store/modules/Notification';
import CompanyModule from '@/store/modules/Company';
import ReferenceModule from '@/store/modules/Reference';
import RouterHistoryModule from '@/store/modules/RouterHistory';
import UserModule from '@/store/modules/User';

@Component
export default class AuthMixin extends Mixins<IAuthMixin>(
  CsrfMixin,
  CsrfRetryMixin,
  LoginMixin,
  LogoutMixin,

  PasswordForgotMixin,
  PasswordResetMixin,
  RegisterMixin,
) {
  // Register user and his business - use RegisterMixin
  @Loading()
  @CatchError()
  public async authRegister(payload: IRegisterProfileForm): Promise<void> {
    await this.RegisterMixin(payload);
    // Fire app google ads conversion trigger when new user signups
    this.$gtm.trackEvent({ event: 'register' });
    await this.$router.push({ name: PublicRouteName.ONBOARDING });
  }

  // Login user to application - use LoginMixin
  @Loading()
  @CatchError()
  public async authLogin(payload: ILoginForm): Promise<void> {
    const data: { user: IUser; message: string } = await this.LoginMixin(payload);

    await UserModule.UPDATE_USER(data.user);
    await CompanyModule.SET_COMPANY();

    await TranslationModule.SET_LOCALE();
    await TranslationModule.SWITCH_LOCALE(TranslationModule.getLocale);

    if (UserModule.rememberCompany) {
      localStorage.removeItem(LocalStorage.SWITCH_COMPANY);
    } else {
      localStorage.setItem(LocalStorage.SWITCH_COMPANY, 'show');
    }

    await this.$notifier({ message: data.message, state: AppState.SUCCESS });
    // Check if route has histor and reroute if necessary
    await this.$router.push(
      RouterHistoryModule.getLastRoute ?? {
        name: DashboardRouteName.LIST,
        params: { document: DocumentRoute.ALL },
      },
    );

    if (CompanyModule.getCompany) {
      await ReferenceModule.SET_VAT_CODES();
      await ReferenceModule.SET_VAT_REFERENCES();
      await ReferenceModule.SET_USER_REFERENCES();
    }
  }

  // Request user password reset - use PasswordForgotMixin
  @Loading()
  @CatchError()
  public async authPasswordForgot(payload: IPasswordForgotForm): Promise<void> {
    const success: boolean = await this.PasswordForgotMixin(payload);
    localStorage.setItem(LocalStorage.RESET_PASSWORD_EMAIL, payload.email);
    await NotificationModule.SET_BOTTOM_SHEET({
      message: this.trans('passwords.sent', 'Mēs nosūtījām paroles maiņas linku uz Jūsu e-pastu!'),
      authMessage: '',
      show: success,
    });
  }

  // Set new user password - use PasswordResetMixin
  @Loading()
  @CatchError()
  public async authPasswordReset(payload: IPasswordResetForm): Promise<void> {
    const success: boolean = await this.PasswordResetMixin(payload);

    localStorage.removeItem(LocalStorage.RESET_PASSWORD_EMAIL);
    await NotificationModule.SET_BOTTOM_SHEET({
      message: this.trans('passwords.reset', 'Jūsu parole ir atjaunināta!'),
      authMessage: '',
      show: success,
    });
  }

  // Logout user and remove company data
  // Route to auth page and fetch csrf cookie - use LogoutMixin and CsrfMixin
  @Loading()
  public async authLogout(): Promise<void> {
    await this.LogoutMixin();

    await UserModule.CLEAR_USER();

    if (this.$route.name != AuthRouteName.LOGIN) {
      await this.$router.replace({ name: AuthRouteName.LOGIN });
    }

    await CompanyModule.CLEAR_COMPANY();

    await this.$notifier({
      message: this.trans('notify.success.user_logout', 'Uz redzēšanos un jauku dienu!'),
      state: AppState.SUCCESS,
    });

    await this.CsrfMixin();
  }
}
