<template>
  <v-container class="flex items-center justify-center h-full text-center">
    <v-row class="items-center justify-center grow-0 w-full">
      <v-col cols="12" tag="header" class="flex flex-col items-center gap-4 pa-4">
        <h2 class="text-h4 font-weight-bold text-center leading-none" v-text="title" />
        <p class="text-body-1 text-center leading-none granite--text" v-text="subtitle" />
      </v-col>
      <v-progress-linear
        v-model="progressBar"
        background-color="secondary"
        color="primary"
        height="25"
        class="rounded-lg cultured--text"
      >
        <template v-slot="{ value }">
          <strong v-text="`${Math.ceil(value)}%`" />
        </template>
      </v-progress-linear>
    </v-row>
  </v-container>
</template>

<script lang="ts">
  import { Component, Mixins } from 'vue-property-decorator';
  import Onboarding from '@/enums/config/Onboarding';
  import AppState from '@/enums/generics/AppState';
  import AuthRouteName from '@/enums/config/router/Auth';
  import ProfileRouteName from '@/enums/config/router/Profile';
  import type IUser from '@/interfaces/user/IUser';
  import UserModule from '@/store/modules/User';
  import CompanyModule from '@/store/modules/Company';
  import AuthMixin from '@/mixins/Auth';

  @Component
  export default class UserOnboardingPage extends Mixins(AuthMixin) {
    public progressBar: number = 0;
    private verificationRetries: number = 0;

    /*****         computed       *****/

    // Retrieve user state from store
    public get user(): IUser | null {
      return UserModule.getUser;
    }

    // User logged in state from store
    public get isLoggedIn(): boolean {
      return UserModule.isLoggedIn;
    }

    public get title(): string {
      return this.trans(
        'onboarding.registration.vertify.subheading',
        'Mirkli uzgaidiet līdz mēs izveidosim Jūsu profilu',
      );
    }

    public get subtitle(): string {
      if (this.progressBar >= 25) {
        if (this.isLoggedIn) {
          switch (true) {
            case this.progressBar >= 100:
              return this.trans('onboarding.progress.completed', 'Profils veiksmīgi izveidots');
            case this.progressBar >= 80 && this.progressBar <= 95:
              return this.trans('onboarding.progress.title.create_company', 'Uzņēmuma profila iestatīšana');
            case this.progressBar >= 65 && this.progressBar <= 80:
              return this.trans('onboarding.progress.title.create_profile', 'Jūsu profila izveide');
          }
        }
        return this.trans(
          'onboarding.progress.title.send_verify_email',
          'Nosūtam profila apstiprinājumu uz Jūsu e-pastu',
        );
      }
      return this.trans('onboarding.progress.loading', 'Notiek ielāde');
    }

    /*****         watchers       *****/
    /*****         methods        *****/

    public beginVerification(): void {
      const interval = setInterval(async () => {
        if (this.shouldStopVerification()) {
          await this.completeVerification();
          clearInterval(interval);
        }

        this.updateProgressBar();
        this.verificationRetries++;
      }, 600);
    }

    public async completeVerification(): Promise<void> {
      const successMessage = this.trans('notify.success.account_created', 'Konts ir veiksmīgi izveidots');
      const failureMessage = this.trans('notify.error.email_verification', 'Neizdevās verificēt e-pastu');

      const message = this.isLoggedIn ? successMessage : failureMessage;
      const state = this.isLoggedIn ? AppState.SUCCESS : AppState.ERROR;

      await this.$notifier({ message, state });

      if (this.isLoggedIn) {
        this.$cookies.set('onboarding', Onboarding.NEW_USER);
        await this.$router.push({ name: ProfileRouteName.DATA });
      } else {
        await UserModule.USER_LOGOUT();
        await this.$router.push({ name: AuthRouteName.LOGIN });
      }
    }

    /*****         helpers        *****/

    private shouldStopVerification(): boolean {
      return this.progressBar >= 100 && this.verificationRetries > 6;
    }

    private updateProgressBar(): void {
      if (this.progressBar > 80 && this.progressBar < 100) {
        this.progressBar += 5;
      } else if (this.progressBar > 45 && this.progressBar < 85) {
        this.progressBar += 15;
      } else if (this.progressBar < 50) {
        this.progressBar += 20;
      }
    }

    /*****      vue lifecycle     *****/

    async beforeMount() {
      this.beginVerification();

      await UserModule.SET_USER();
      await CompanyModule.SET_COMPANY();
    }
  }
</script>
