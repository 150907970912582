import { Component, Vue } from 'vue-property-decorator';
import type IUser from '@/interfaces/user/IUser';
import type IProfileUpdateForm from '@/interfaces/forms/IProfileUpdateForm';
import UserRepository from '@/repository/User';

@Component
export default class UpdateMixin extends Vue {
  public async UpdateMixin(payload: IProfileUpdateForm): Promise<{ user: IUser; message: string }> {
    return (await UserRepository.update(payload)).data;
  }
}
