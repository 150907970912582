import useEnv from '@/services/Env';
import { RouteConfig } from 'vue-router';
import PublicRouteName from '@/enums/config/router/Public';
import DebugPage from '@/pages/public/Debug.vue';

const { isDev } = useEnv();

const DebugRouter: Array<RouteConfig> = isDev
  ? [
      {
        path: 'debug',
        name: PublicRouteName.DEBUG,
        component: DebugPage,
        meta: {
          title: 'Debug',
        },
      },
    ]
  : [];

export default DebugRouter;
