import { Component, Vue } from 'vue-property-decorator';
import { AxiosResponse } from 'axios';
import type IPasswordForgotForm from '@/interfaces/forms/auth/IPasswordForgotForm';
import AuthRepository from '@/repository/Auth';

@Component
export default class PasswordForgotMixin extends Vue {
  public async PasswordForgotMixin(payload: IPasswordForgotForm): Promise<boolean> {
    const { data }: AxiosResponse = await AuthRepository.requestPasswordReset(payload);
    return data.success;
  }
}
