import { Component, Vue } from 'vue-property-decorator';
import DocumentActivity from '@/enums/config/document/Activity';
import Locale from '@/enums/config/Locale';
import TranslationModule from '@/store/modules/Translation';

@Component
export default class TranslateDocumentActivityMixin extends Vue {
  public TranslateDocumentActivityMixin(
    activity: DocumentActivity,
    locale: Locale = TranslationModule.getLocale,
  ): string {
    switch (activity) {
      case DocumentActivity.CREATED:
        return this.trans('bookkeeping_history.created', 'Dokuments veiksmīgi izveidots', locale);
      case DocumentActivity.EMAIL_SENT:
        return this.trans('bookkeeping_history.email_sent', 'E-pasts nosūtīts uz', locale);
      case DocumentActivity.DOWNLOADED:
        return this.trans('bookkeeping_history.downloaded', 'Dokuments lejuplādēts', locale);
      case DocumentActivity.EMAIL_OPENED:
        return this.trans('bookkeeping_history.email_opened', 'E-pasts apskatīts', locale);
      case DocumentActivity.STATUS_CHANGE:
        return this.trans('bookkeeping_history.status_change', 'Statusa maiņa uz', locale);
      case DocumentActivity.PAYMENT_CREATED:
        return this.trans('bookkeeping_history.payment_created', 'Pievienota apmaksa', locale);
      case DocumentActivity.UPDATED:
      default:
        return this.trans('bookkeeping_history.updated', 'Dokuments veiksmīgi atjaunots', locale);
    }
  }
}
