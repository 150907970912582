<template>
  <TippyComponent
    :interactive="interactive"
    :placement="placement"
    :max-width="maxWidth"
    arrow-type="round"
    :offset="offset"
    :theme="theme"
    flip-on-update
    :arrow="arrow"
    small
    :trigger="disable ? 'manual' : 'mouseenter focus'"
  >
    <template v-slot:trigger>
      <slot>
        <v-icon color="primary" small>$info</v-icon>
      </slot>
    </template>
    <span class="break-all">
      <slot name="tooltip" :text="text">
        <span v-text="text" />
      </slot>
    </span>
  </TippyComponent>
</template>

<script lang="ts">
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import Placement from '@/enums/generics/Placement';
  import { TippyComponent } from 'vue-tippy';

  @Component({
    components: {
      TippyComponent,
    },
  })
  export default class Tooltip extends Vue {
    @Prop({ default: '' }) text!: string;

    @Prop({ default: Placement.TOP }) placement!: Placement;
    @Prop({ type: Boolean }) interactive!: boolean;
    @Prop({ type: Boolean }) disable!: boolean;
    @Prop({ type: Boolean }) arrow!: boolean;
    @Prop({ type: Boolean }) dark!: boolean;

    @Prop({ default: 0 }) offset!: string | number;
    @Prop() maxWidth?: string | number;

    /*****         computed       *****/

    public get theme(): string {
      return this.dark ? 'tooltip-dark' : 'tooltip-light';
    }

    /*****         watchers       *****/
    /*****         methods        *****/
    /*****         helpers        *****/
    /*****      vue lifecycle     *****/
  }
</script>
