import { Component, Vue } from 'vue-property-decorator';
import UserRepository from '@/repository/User';

@Component
export default class ResendVerificationMixin extends Vue {
  public async ResendVerificationMixin(): Promise<boolean> {
    try {
      return (await UserRepository.resendEmailVerification()).status === 204;
    } catch (_) {
      this.$logger('resend email verification failed...');
      return false;
    }
  }
}
