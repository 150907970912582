import { Component, Mixins } from 'vue-property-decorator';
import CatchError from '@/decorators/CatchError';
import Loading from '@/decorators/Loading';
import RecurringRouteName from '@/enums/config/router/Recurring';
import AppState from '@/enums/generics/AppState';
import type RecurringDocumentType from '@/enums/config/document/RecurringType';
import type IRecurringDocument from '@/interfaces/document/IRecurringDocument';
import type IRecurringMixin from '@/interfaces/mixins/IRecurringMixin';
import CompanyModule from '@/store/modules/Company';
import RecurringModule from '@/store/modules/Recurring';
import PageModule from '@/store/modules/Page';
import CreateRecurringMixin from '@/mixins/recurring/Create';
import DeleteRecurringMixin from '@/mixins/recurring/Delete';
import FetchRecurringMixin from '@/mixins/recurring/Fetch';
import ListRecurringsMixin from '@/mixins/recurring/List';
import UpdateRecurringMixin from '@/mixins/recurring/Update';
import InitBlankRecurringMixin from '@/mixins/recurring/init/Blank';
import InitConvertRecurringMixin from '@/mixins/recurring/init/Convert';
import InitCopyRecurringMixin from '@/mixins/recurring/init/Copy';
import InitEditRecurringMixin from '@/mixins/recurring/init/Edit';
import InitRecurringTypeMixin from '@/mixins/recurring/init/Type';
import DocumentMixin from '@/mixins/Document';

@Component
export default class RecurringMixin extends Mixins<IRecurringMixin>(
  CreateRecurringMixin,
  DeleteRecurringMixin,
  FetchRecurringMixin,
  ListRecurringsMixin,
  UpdateRecurringMixin,
  InitBlankRecurringMixin,
  InitConvertRecurringMixin,
  InitCopyRecurringMixin,
  InitEditRecurringMixin,
  InitRecurringTypeMixin,
) {
  // Fetch recurring documents - use ListRecurringMixin
  @Loading()
  @CatchError()
  public async listRecurrings(companyUUID: string = CompanyModule.getCompanyUUID): Promise<void> {
    const { data, meta } = await this.ListRecurringsMixin(companyUUID);

    await PageModule.SET_PAGINATION(meta);
    await RecurringModule.SET_RECURRING_LIST(data);
  }

  // Fetch single recurring document - use FetchRecurringtMixin
  @Loading()
  @CatchError()
  public async fetchRecurring(
    documentUUID: string,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<IRecurringDocument> {
    return await this.FetchRecurringMixin(documentUUID, companyUUID);
  }

  // Create new recurring document - use CreateRecurringMixin
  @Loading()
  @CatchError()
  public async createRecurring(companyUUID: string = CompanyModule.getCompanyUUID): Promise<void> {
    const createdRecurring = await this.CreateRecurringMixin(companyUUID);

    await this.$notifier({
      message: this.trans('notify.success.record_created', 'Ieraksts veiksmīgi izveidots'),
      state: AppState.SUCCESS,
    });

    // Refetch company data if user has set update sender globally
    if (RecurringModule.forceUpdateCompany) {
      await CompanyModule.SET_COMPANY();
    }

    await this.$router.push({
      name: RecurringRouteName.EDIT,
      params: { document: createdRecurring.type, uuid: createdRecurring.uuid },
    });
  }

  // Update existing recurring document - use UpdateRecurringMixin
  @Loading()
  @CatchError()
  public async updateRecurring(companyUUID: string = CompanyModule.getCompanyUUID): Promise<void> {
    const updatedRecurring = await this.UpdateRecurringMixin(companyUUID);

    await this.$notifier({
      message: this.trans('notify.success.record_updated', 'Ieraksts veiksmīgi izlabots'),
      state: AppState.SUCCESS,
    });

    // Refetch company data if user has set update sender globally
    if (RecurringModule.forceUpdateCompany) {
      await CompanyModule.SET_COMPANY();
    }

    await RecurringModule.SET_RECURRING({ items: updatedRecurring.items });
  }

  // Delete existing recurring document - use DeleteDocumentMixin
  @Loading()
  @CatchError()
  public async deleteRecurring(
    documentUUID: string,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<void> {
    await this.DeleteRecurringMixin(documentUUID, companyUUID);

    await this.$notifier({
      message: this.trans('notify.success.record_deleted', 'Ieraksts veiksmīgi izdzēsts'),
      state: AppState.SUCCESS,
    });
  }

  // Init blank recurring document - use InitBlankRecurringMixin
  @Loading()
  @CatchError()
  public async initBlankRecurring(type: RecurringDocumentType): Promise<void> {
    const recurring = this.InitBlankRecurringMixin(type);
    await RecurringModule.SET_RECURRING(recurring);
    await RecurringModule.SET_RECURRING_TOTALS();
  }

  // Init convert recurring document - use InitConvertRecurringMixin
  @Loading()
  @CatchError()
  public async initConvertRecurring(
    type: RecurringDocumentType,
    documentUUID: string,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<void> {
    const fetchedDocument = await new DocumentMixin().fetchDocument(documentUUID, companyUUID);
    const recurring = await this.InitConvertRecurringMixin(type, fetchedDocument);
    await RecurringModule.SET_RECURRING(recurring);
    await RecurringModule.SET_RECURRING_TOTALS();
  }

  // Init copy recurring document - use InitCopyRecurringMixin
  @Loading()
  @CatchError()
  public async initCopyRecurring(
    documentUUID: string,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<void> {
    const fetchedRecurring = await this.fetchRecurring(documentUUID, companyUUID);
    const recurring = await this.InitCopyRecurringMixin(fetchedRecurring);
    await RecurringModule.SET_RECURRING(recurring);
    await RecurringModule.SET_RECURRING_TOTALS();
  }

  // Init edit recurring document - use InitEditRecurringMixin
  @Loading()
  @CatchError()
  public async initEditRecurring(
    documentUUID: string,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<void> {
    const fetchedRecurring = await this.fetchRecurring(documentUUID, companyUUID);
    const recurring = await this.InitEditRecurringMixin(fetchedRecurring);
    await RecurringModule.SET_RECURRING(recurring);
    await RecurringModule.SET_RECURRING_TOTALS();
  }

  // Init type recurring document - use InitRecurringTypeMixin
  @Loading()
  @CatchError()
  public async initRecurringType(): Promise<void> {
    const recurring = await this.InitRecurringTypeMixin();
    await RecurringModule.SET_RECURRING(recurring);
    await RecurringModule.SET_RECURRING_TOTALS();
  }
}
