<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.16663 12.5H3.33329C2.89127 12.5 2.46734 12.3244 2.15478 12.0118C1.84222 11.6993 1.66663 11.2754 1.66663 10.8333V3.33332C1.66663 2.8913 1.84222 2.46737 2.15478 2.15481C2.46734 1.84225 2.89127 1.66666 3.33329 1.66666H10.8333C11.2753 1.66666 11.6992 1.84225 12.0118 2.15481C12.3244 2.46737 12.5 2.8913 12.5 3.33332V4.16666M9.16663 7.49999H16.6666C17.5871 7.49999 18.3333 8.24618 18.3333 9.16666V16.6667C18.3333 17.5871 17.5871 18.3333 16.6666 18.3333H9.16663C8.24615 18.3333 7.49996 17.5871 7.49996 16.6667V9.16666C7.49996 8.24618 8.24615 7.49999 9.16663 7.49999Z"
      stroke-linejoin="round"
      stroke-linecap="round"
      stroke="currentColor"
      stroke-width="1.5"
    />
  </svg>
</template>

<script lang="ts">
  import { Component, Vue } from 'vue-property-decorator';

  @Component
  export default class CopyIcon extends Vue {
    /*****         computed       *****/
    /*****         watchers       *****/
    /*****         methods        *****/
    /*****         helpers        *****/
    /*****      vue lifecycle     *****/
  }
</script>
