import app from '@/main';
import store from '@/store';
import useCopyObject from '@/services/CopyObject';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import COINLESS_CURRENCY from '@/constants/config/currency/Coinless';
import DEFAULT_RECURRING_RECURRING_FORM from '@/constants/mocks/forms/RecurringDocument';
import DocumentType from '@/enums/config/document/Type';
import CountryISO from '@/enums/config/iso/CountryISO';
import RecurringDocumentType from '@/enums/config/document/RecurringType';
import RecurringRouteName from '@/enums/config/router/Recurring';
import IRecurringDocument, { IRecurringMailer } from '@/interfaces/document/IRecurringDocument';
import type CurrencyISO from '@/enums/config/iso/CurrencyISO';
import type SignatureType from '@/enums/generics/SignatureType';
import type ITotal from '@/interfaces/document/ITotal';
import type IStock from '@/interfaces/stock/IStock';
import CompanyModule from '@/store/modules/Company';
import UtilModule from '@/store/modules/Util';

@Module({
  store,
  name: 'Recurring',
  namespaced: true,
  dynamic: true,
})
class Recurring extends VuexModule {
  private recurring: IRecurringDocument = DEFAULT_RECURRING_RECURRING_FORM;
  private recurringTotals: ITotal[] = [];
  private recurringList: IRecurringDocument[] = [];

  public get getRecurring(): IRecurringDocument {
    return this.recurring;
  }

  public get getRecurringItems(): IStock[] {
    return this.recurring.items;
  }

  public get getSignatureType(): SignatureType {
    return this.recurring.signature.type;
  }

  public get getRecurringType(): RecurringDocumentType {
    return this.recurring.type as RecurringDocumentType;
  }

  public get getRecurringCountry(): CountryISO {
    return this.recurring.sender.country ?? CountryISO.LV;
  }

  public get getRecurringCurrencyCode(): CurrencyISO {
    return this.recurring.currency_code;
  }

  public get getRecurringCurrencySymbol(): CurrencyISO | string {
    const currencyCode = this.getRecurringCurrencyCode;
    return UtilModule.getCurrencies.find((c) => c.code === currencyCode)?.symbol ?? currencyCode;
  }

  public get getRecurringCurrencyRate(): number {
    return this.recurring.currency_rate;
  }

  public get getRecurringColor(): string {
    return this.recurring.color;
  }

  public get getRecurringSingleVat(): number | null {
    return this.recurring.single_vat ?? null;
  }

  public get getTotals(): ITotal[] {
    return this.recurringTotals;
  }

  public get forceUpdateCompany(): boolean {
    return this.recurring.sender.update_globally ?? false;
  }

  public get isMultiCurrency(): boolean {
    return this.getRecurringCurrencyCode != CompanyModule.getCompanyCurrencyCode;
  }

  public get isTaxPayer(): boolean {
    return !!this.recurring.sender.vat_registration_number;
  }

  public get isTaxByGroup(): boolean {
    return this.recurring.tax_by_group;
  }

  public get hasDiscount(): boolean {
    return this.recurring.has_discount;
  }

  public get hasMultiVat(): boolean {
    return this.recurring.has_multi_vat;
  }

  public get hasZeroVat(): boolean {
    return this.recurring.items.some((i) => i.vat_percent === null || Number(i.vat_percent) === 0);
  }

  public get hasAdditionalPriceRows(): boolean {
    return (this.recurring.additional_price_rows!.length || 0) > 0;
  }

  public get hasUnsupportedTotalAmount(): boolean {
    return (this.recurring.total_with_vat ?? 0) >= 1000000000;
  }

  public get isBillable(): boolean {
    return [RecurringDocumentType.INVOICE, RecurringDocumentType.WAYBILL].includes(
      this.recurring.type as RecurringDocumentType,
    );
  }

  public get isAdditionalPriceRows(): boolean {
    return [RecurringDocumentType.INVOICE, RecurringDocumentType.WAYBILL].includes(
      this.recurring.type as RecurringDocumentType,
    );
  }

  public get isNewRecurring(): boolean {
    return app.$route.name !== RecurringRouteName.EDIT;
  }

  public get isCopyRecurring(): boolean {
    return app.$route.name === RecurringRouteName.COPY;
  }

  // Recurring document list and chart getters

  public get getRecurringList(): IRecurringDocument[] {
    return this.recurringList;
  }

  /*******   Set action   *******/

  @Action({ commit: 'UPDATE_RECURRING' })
  public async SET_RECURRING(recurring: Partial<IRecurringDocument>): Promise<IRecurringDocument> {
    const mergedRecurring: IRecurringDocument = useCopyObject().copy(recurring, this.recurring);
    return mergedRecurring;
  }

  @Action({ commit: 'UPDATE_RECURRING_TOTALS' })
  public async SET_RECURRING_TOTALS(items = this.getRecurringItems): Promise<ITotal[]> {
    const recurringTotals: ITotal[] = items.reduce((acc: ITotal[], item: IStock) => {
      const vatRate = item.vat_percent === null ? null : app.toNumber(item.vat_percent);
      const totalWithoutVat = app.toNumber(item.total_without_vat || 0);
      const totalWithVat = app.toNumber(item.total_with_vat || 0);
      const totalVat = totalWithVat - totalWithoutVat;

      let total = acc.find((total) => total.vat_rate === vatRate);

      if (!total) {
        total = {
          vat_rate: vatRate,
          total_without_vat: 0,
          total_with_vat: 0,
          total_vat: 0,
        };
        acc.push(total);
      }

      total.total_vat += totalVat;
      total.total_without_vat += totalWithoutVat;
      total.total_with_vat += totalWithVat;

      return acc;
    }, []);

    // Calculate totals for the document based on vat calculation method
    recurringTotals.forEach((total) => {
      if (this.isTaxByGroup) {
        const vatRate = total.vat_rate;
        const totalWithoutVat = total.total_without_vat;

        total.total_with_vat = app.roundUp(app.floorDown(totalWithoutVat * ((vatRate ?? 0) / 100 + 1), 3));
        total.total_vat = total.total_with_vat - totalWithoutVat;
      }

      total.total_without_vat = app.roundUp(app.floorDown(total.total_without_vat), 4);
      total.total_with_vat = app.roundUp(app.floorDown(total.total_with_vat), 4);
      total.total_vat = app.roundUp(app.floorDown(total.total_vat), 4);
    });

    return recurringTotals;
  }

  @Action({ commit: 'UPDATE_RECURRING_LIST' })
  public async SET_RECURRING_LIST(recurringList: IRecurringDocument[]): Promise<IRecurringDocument[]> {
    return recurringList;
  }

  @Action
  public async GET_DEFAULT_MAILER(): Promise<IRecurringMailer> {
    const type = this.recurring.type ?? DocumentType.INVOICE;
    const emailPreferneces = await CompanyModule.GET_EMAIL_PREFERENCES(type as DocumentType);

    return {
      email: this.recurring.recipient.email ?? '',
      bcc: emailPreferneces.email_bcc ?? [],
      subject: emailPreferneces.email_subject ?? '',
      message: emailPreferneces.email_message ?? '',
    };
  }

  /*******   Unset action   *******/

  @Action({ commit: 'UPDATE_RECURRING' })
  public async CLEAR_RECURRING(): Promise<IRecurringDocument> {
    return DEFAULT_RECURRING_RECURRING_FORM;
  }

  @Action({ commit: 'UPDATE_RECURRING_TOTALS' })
  public async CLEAR_RECURRING_TOTALS(): Promise<ITotal[]> {
    return [];
  }

  @Action({ commit: 'UPDATE_RECURRING_LIST' })
  public async CLEAR_RECURRING_LIST(): Promise<IRecurringDocument[]> {
    return [];
  }

  @Action
  public async CLEAR_STATE(): Promise<void> {
    await this.CLEAR_RECURRING();
    await this.CLEAR_RECURRING_TOTALS();
    await this.CLEAR_RECURRING_LIST();
  }

  /*******   Update mutation   *******/

  @Mutation
  public async UPDATE_RECURRING(recurring: IRecurringDocument): Promise<IRecurringDocument> {
    return (this.recurring = recurring);
  }

  @Mutation
  public async UPDATE_RECURRING_TOTALS(recurringTotals: ITotal[]): Promise<ITotal[]> {
    // Update document totals in document currency
    const isCoinless = COINLESS_CURRENCY.includes(this.recurring.currency_code);

    const isAdditionalPriceRows = [RecurringDocumentType.INVOICE, RecurringDocumentType.WAYBILL].includes(
      this.recurring.type as RecurringDocumentType,
    );
    const totalAdditional = isAdditionalPriceRows
      ? this.recurring.additional_price_rows.reduce((total, row) => total + app.toNumber(row.amount), 0)
      : 0;
    const totalWithoutVat = recurringTotals.reduce((total, item) => total + item.total_without_vat, 0);
    const totalWithVat = recurringTotals.reduce((total, item) => total + item.total_with_vat, 0);

    this.recurring.total_without_vat = app.roundUp(totalWithoutVat, isCoinless ? 0 : 3);
    this.recurring.total_with_vat = app.roundUp(totalWithVat - totalAdditional, isCoinless ? 0 : 3);
    this.recurring.vat_amount = app.roundUp(totalWithVat - totalWithoutVat, isCoinless ? 0 : 3);

    // Update document totals in local currency
    const isLocalCoinless = COINLESS_CURRENCY.includes(CompanyModule.getCompanyCurrencyCode);

    const totalAdditionalLocal = totalAdditional * this.recurring.currency_rate;
    const totalWithoutVatLocal = totalWithoutVat * this.recurring.currency_rate;
    const totalWithVatLocal = totalWithVat * this.recurring.currency_rate;

    this.recurring.total_without_vat_local = app.roundUp(totalWithoutVatLocal, isLocalCoinless ? 0 : 3);
    this.recurring.total_with_vat_local = app.roundUp(
      totalWithVatLocal - totalAdditionalLocal,
      isLocalCoinless ? 0 : 3,
    );
    this.recurring.vat_amount_local = app.roundUp(totalWithVatLocal - totalWithoutVatLocal, isLocalCoinless ? 0 : 3);

    return (this.recurringTotals = recurringTotals);
  }

  @Mutation
  public async UPDATE_RECURRING_LIST(recurringList: IRecurringDocument[]): Promise<IRecurringDocument[]> {
    return (this.recurringList = recurringList);
  }
}

const RecurringModule = getModule(Recurring);

export default RecurringModule;
