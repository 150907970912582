import { RouteConfig } from 'vue-router';
import StockRouteName from '@/enums/config/router/Stock';

const StockRouter: Array<RouteConfig> = [
  {
    path: 'stock',
    component: () => import(/* webpackChunkName: "appView" */ '@/views/Stock.vue'),
    children: [
      {
        path: '/',
        name: StockRouteName.INDEX,
        component: () => import(/* webpackChunkName: "appView" */ '@/pages/app/stock/Index.vue'),
        meta: {
          title: 'Stock',
        },
      },
    ],
  },
];

export default StockRouter;
