import { Component, Vue } from 'vue-property-decorator';
import type IDocument from '@/interfaces/document/IDocument';
import DocumentRepository from '@/repository/Document';
import CompanyModule from '@/store/modules/Company';

@Component
export default class FetchDocumentMixin extends Vue {
  public async FetchDocumentMixin(
    documentUUID: string,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<IDocument> {
    return (await DocumentRepository.fetch(companyUUID, documentUUID)).data;
  }
}
