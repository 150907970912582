<template>
  <v-card color="silver" elevation="2" rounded="lg" height="100" class="w-full z-1">
    <template>
      <div class="absolute inset-0 overflow-hidden rounded-lg z-10 h-full">
        <v-hover v-slot="{ hover }">
          <div class="flex items-center justify-center h-full">
            <img
              :src="imageUrl"
              class="inline-flex justify-center h-full object-cover transition-all"
              :style="`transform: scale(${hover ? 1.2 : 1})`"
            />
            <v-card-title
              class="absolute block pa-1 cultured transition-all z-10 bottom-0 w-full"
              :class="[{ 'opacity-0': hover }, { 'error--text': isInvalid }]"
            >
              <div class="truncate px-1 pt-1 leading-none caption" v-text="file.name" />
              <div
                class="flex items-center justify-between truncate space-x-1 px-1 pt-1 leading-none font-weight-bold text-xs"
              >
                <span class="truncate" v-text="file.type" />
                <span v-text="`${(file.size / 1000 / 1000).toFixed(2)}MB`" />
              </div>
            </v-card-title>
            <v-slide-x-reverse-transition>
              <v-btn
                v-show="hover || isInvalid"
                color="error"
                fab
                x-small
                class="absolute top-0 right-0 z-30 ma-1"
                @click="remove"
              >
                <v-icon color="cultured">$clear</v-icon>
              </v-btn>
            </v-slide-x-reverse-transition>
          </div>
        </v-hover>
      </div>
    </template>
  </v-card>
</template>

<script lang="ts">
  import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
  import type { ProviderInstance } from 'vee-validate/dist/types/types';
  import BaseDragNDrop from '@/components/global/inputs/dragndrop/Base.vue';

  @Component({
    components: {
      BaseDragNDrop,
    },
  })
  export default class DragNDropFilePreview extends Vue {
    @Prop() file!: File;
    @Prop() validator?: ProviderInstance;

    public imageUrl: string = '';
    public isInvalid: boolean = false;

    /*****         computed       *****/

    public get isImage(): boolean {
      return this.file.type.startsWith('image/');
    }

    public get isVideo(): boolean {
      return this.file.type.startsWith('video/');
    }

    /*****         watchers       *****/

    @Watch('file', { deep: true })
    private setImageUrl(): void {
      if (this.isVideo) {
        this.setVideoThumbnail(this.file).then((img) => {
          if (typeof img === 'string') {
            this.imageUrl = img;
          }
        });
      } else if (this.isImage) {
        this.imageUrl = URL.createObjectURL(this.file);
      } else {
        this.imageUrl = '';
      }
    }

    /*****         methods        *****/

    @Emit('remove')
    public remove(): void {}

    /*****         helpers        *****/

    private setVideoThumbnail(file: File) {
      return new Promise((resolve, _) => {
        // create video element
        let video = document.createElement('video');
        video.src = URL.createObjectURL(file);

        // when metadata is loaded get video duration
        video.onloadedmetadata = function () {
          // seek to the start
          video.currentTime = 0;
        };

        // when video data is loaded draw on canvas and create img
        video.onseeked = function () {
          // create canvas
          let canvas = document.createElement('canvas');
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;

          // draw video frame to canvas
          let ctx = canvas.getContext('2d');
          ctx!.drawImage(video, 0, 0, canvas.width, canvas.height);

          // create img from canvas data
          let img = canvas.toDataURL('image/jpeg');
          resolve(img);
        };
      });
    }

    /*****      vue lifecycle     *****/

    async mounted() {
      this.setImageUrl();

      if (this.validator) {
        const { valid } = await this.validator.validate([this.file]);
        this.isInvalid = !valid;
      }
    }
  }
</script>
