import _Vue from 'vue';
import sortBy from 'lodash-es/sortBy';
import isEqual from 'lodash-es/isEqual';

export default function ArrayPlugin(Vue: typeof _Vue): void {
  Vue.prototype.isEqualWhenSorted = (a: any[], b: any[]): boolean => {
    return isEqual(sortBy(a), sortBy(b));
  };

  Vue.prototype.isEqualNormalized = (a: any, b: any): boolean => {
    const normalizedA = Object.fromEntries(
      Object.entries(a).map(([key, value]) => [key, value === null || value === '' ? '' : value]),
    );
    const normalizedB = Object.fromEntries(
      Object.entries(b).map(([key, value]) => [key, value === null || value === '' ? '' : value]),
    );
    return isEqual(normalizedA, normalizedB);
  };

  Vue.prototype.joinByPipe = (arr: any[]): string => {
    return arr.filter(Boolean).join(' | ').trim();
  };
}

declare module 'vue/types/vue' {
  interface Vue {
    isEqualWhenSorted(a: any[], b: any[]): boolean;
    isEqualNormalized(a: any, b: any): boolean;
    joinByPipe(arr: any[]): string;
  }
}
