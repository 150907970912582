import { Component, Vue } from 'vue-property-decorator';
import type ISendDocumentForm from '@/interfaces/forms/email/ISendDocumentForm';
import DocumentRepository from '@/repository/Document';
import CompanyModule from '@/store/modules/Company';

@Component
export default class BulkSendDocumentsMixin extends Vue {
  public async BulkSendDocumentsMixin(
    documents: ISendDocumentForm,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<string[]> {
    const payload = this.BulkPrepareSendDocumentsPayloadMixin(documents);
    await DocumentRepository.bulkSend(companyUUID, payload);
    const documentUUIDs = payload.documents.map((document) => document.uuid).flat() as string[];

    return documentUUIDs;
  }

  public BulkPrepareSendDocumentsPayloadMixin(documents: ISendDocumentForm): ISendDocumentForm {
    return {
      email: documents.email,
      documents: documents.documents.map(({ email, name, uuid }) => {
        return {
          email,
          name,
          uuid: uuid.map((uuid) => (typeof uuid === 'string' ? uuid : uuid.uuid)),
        };
      }),
    };
  }
}
