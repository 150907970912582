import { RouteConfig } from 'vue-router';
import PublicRouteName from '@/enums/config/router/Public';
import ShareDocumentDownloadPage from '@/pages/public/ShareDocumentDownload.vue';

const ShareDocumentRouteName: Array<RouteConfig> = [
  {
    path: 'share/:uuid',
    name: PublicRouteName.SHARE_DOCUMENT,
    component: ShareDocumentDownloadPage,
    meta: {
      title: 'Download Shared Document',
    },
  },
];

export default ShareDocumentRouteName;
