import { Component, Vue } from 'vue-property-decorator';
import DocumentRepository from '@/repository/Document';
import CompanyModule from '@/store/modules/Company';

@Component
export default class DeleteDocumentPaymentMixin extends Vue {
  public async DeleteDocumentPaymentMixin(
    documentUUID: string,
    paymentUUID: string,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<void> {
    await DocumentRepository.deletePayment(companyUUID, documentUUID, paymentUUID);
  }
}
