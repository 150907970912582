<template>
  <svg width="29" height="34" viewBox="0 0 29 34" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24.276,16.753 C24.040,18.205 24.239,19.717 24.837,21.067 C25.599,22.788 27.010,24.251 28.996,25.002 C28.936,25.133 28.880,25.258 28.825,25.381 C28.143,26.883 27.748,27.663 26.804,29.101 C26.678,29.293 26.546,29.491 26.410,29.691 C25.019,31.731 23.153,33.976 20.889,33.997 C18.679,34.017 18.112,32.556 15.115,32.573 C12.117,32.590 11.490,34.021 9.281,34.001 C6.918,33.980 5.083,31.776 3.685,29.693 C3.613,29.584 3.541,29.475 3.470,29.368 C2.620,28.073 1.933,26.729 1.401,25.379 C0.830,23.929 0.439,22.476 0.219,21.066 C-0.018,19.554 -0.056,18.093 0.093,16.754 C0.278,15.089 0.752,13.610 1.496,12.442 C1.508,12.425 1.518,12.409 1.528,12.393 C3.273,9.702 6.025,8.127 8.613,8.127 C11.248,8.127 12.904,9.575 15.083,9.575 C17.196,9.575 18.485,8.125 21.531,8.125 C23.833,8.125 26.273,9.380 28.012,11.552 C27.531,11.815 27.098,12.114 26.709,12.444 C25.334,13.611 24.538,15.147 24.276,16.753 ZM14.125,8.128 C13.766,6.164 14.690,4.142 15.812,2.779 C17.046,1.277 19.161,0.125 20.969,0.000 C21.274,2.053 20.434,4.063 19.327,5.485 C18.141,7.009 16.100,8.190 14.125,8.128 Z"
      fill-rule="evenodd"
      fill="currentColor"
    />
  </svg>
</template>

<script lang="ts">
  import { Component, Vue } from 'vue-property-decorator';

  @Component
  export default class AppleIcon extends Vue {
    /*****         computed       *****/
    /*****         watchers       *****/
    /*****         methods        *****/
    /*****         helpers        *****/
    /*****      vue lifecycle     *****/
  }
</script>
