import type IGenericState from '@/interfaces/states/IGeneric';

const DEFAULT_GENERICS: IGenericState = {
  dateRanges: [],
  discounts: [],
  emailTemplateKeys: [],
  languages: [],
  lateFees: [],
  paymentMethods: [],
  periods: [],
  periodTypes: [],
  perPage: [],
  statusColors: [],
  timezones: [],
  units: [],
  vatPercents: [],
};

export default DEFAULT_GENERICS;
