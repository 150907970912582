<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.6667 1.33325V3.99992M5.33333 1.33325V3.99992M2 6.66659H14M3.33333 2.49992H12.6667C13.403 2.49992 14 3.09687 14 3.83325V13.1666C14 13.903 13.403 14.4999 12.6667 14.4999H3.33333C2.59695 14.4999 2 13.903 2 13.1666V3.83325C2 3.09687 2.59695 2.49992 3.33333 2.49992Z"
      troke-linejoin="round"
      stroke-linecap="round"
      stroke="currentColor"
      stroke-width="1.5"
    />
  </svg>
</template>

<script lang="ts">
  import { Component, Vue } from 'vue-property-decorator';

  @Component
  export default class CalendarIcon extends Vue {
    /*****         computed       *****/
    /*****         watchers       *****/
    /*****         methods        *****/
    /*****         helpers        *****/
    /*****      vue lifecycle     *****/
  }
</script>
