import { RouteConfig } from 'vue-router';
import PublicRouteName from '@/enums/config/router/Public';
import UserOnboardingPage from '@/pages/public/UserOnboarding.vue';

const OnboardingRouter: Array<RouteConfig> = [
  {
    path: 'onboarding',
    name: PublicRouteName.ONBOARDING,
    component: UserOnboardingPage,
    meta: {
      title: 'User Onboarding',
      guard: ['guest'],
    },
  },
];

export default OnboardingRouter;
