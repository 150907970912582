import store from '@/store';
import { getModule, Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import DEFAULT_BOTTOM_SHEET from '@/constants/mocks/BottomSheet';
import DEFAULT_TOAST from '@/constants/mocks/Toast';
import type INotifcation from '@/interfaces/INotification';
import type IBottomSheet from '@/interfaces/IBottomSheet';
import type IToast from '@/interfaces/IToast';

@Module({
  store,
  name: 'Notification',
  namespaced: true,
  dynamic: true,
})
class Notification extends VuexModule {
  private notifications: INotifcation[] = [];
  private bottomSheet: IBottomSheet = DEFAULT_BOTTOM_SHEET;
  private toast: IToast = DEFAULT_TOAST;

  public get getToast(): IToast {
    return this.toast;
  }

  public get getBottomSheet(): IBottomSheet {
    return this.bottomSheet;
  }

  public get getNotifications(): INotifcation[] {
    return this.notifications.sort((a, b) => {
      // Sort by `has_read` first
      if (a.has_read !== b.has_read) {
        return a.has_read ? 1 : -1;
      }

      // If `has_read` is the same, sort by `expiry`
      const aExpiry = a.expiry ? new Date(a.expiry).getTime() : -Infinity;
      const bExpiry = b.expiry ? new Date(b.expiry).getTime() : -Infinity;

      return aExpiry - bExpiry;
    });
  }

  public get getUnreadNotificationCount(): number {
    return this.notifications.filter((notification: INotifcation) => !notification.has_read).length;
  }

  public get hasUnreadNotifications(): boolean {
    return this.getUnreadNotificationCount > 0;
  }

  /*******   Set action   *******/

  @Action({ commit: 'UPDATE_NOTIFICATIONS' })
  public async SET_NOTIFICATIONS(notifications: INotifcation[]): Promise<INotifcation[]> {
    return notifications;
  }

  @Action({ commit: 'UPDATE_BOTTOM_SHEET' })
  public async SET_BOTTOM_SHEET(bottomSheet: IBottomSheet): Promise<IBottomSheet> {
    return bottomSheet;
  }

  @Action({ commit: 'UPDATE_TOAST' })
  public async SET_TOAST(toast: IToast): Promise<IToast> {
    return toast;
  }

  @Action({ commit: 'UPDATE_NOTIFICATIONS' })
  public async MARK_NOTIFICATIONS_AS_READ(notifications = this.notifications): Promise<INotifcation[]> {
    notifications.forEach((notification: INotifcation) => {
      notification.has_read = true;
    });

    return notifications;
  }

  /*******   Unset action   *******/

  @Action({ commit: 'UPDATE_NOTIFICATIONS' })
  public async CLEAR_NOTIFICATIONS(): Promise<INotifcation[]> {
    return [];
  }

  @Action({ commit: 'UPDATE_BOTTOM_SHEET' })
  public async CLEAR_BOTTOM_SHEET(): Promise<IBottomSheet> {
    return DEFAULT_BOTTOM_SHEET;
  }

  @Action({ commit: 'UPDATE_TOAST' })
  public async CLEAR_TOAST(): Promise<IToast> {
    return DEFAULT_TOAST;
  }

  /*******   Update mutation   *******/

  @Mutation
  public async UPDATE_NOTIFICATIONS(notifications: INotifcation[]): Promise<INotifcation[]> {
    return (this.notifications = notifications);
  }

  @Mutation
  public async UPDATE_BOTTOM_SHEET(bottomSheet: IBottomSheet): Promise<IBottomSheet> {
    return (this.bottomSheet = bottomSheet);
  }

  @Mutation
  public UPDATE_TOAST(toast: IToast): IToast {
    return (this.toast = toast);
  }
}

const NotificationModule = getModule(Notification);

export default NotificationModule;
