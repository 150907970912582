import app from '@/main';

const useBreakpoints = () => {
  const isMobile = (): boolean => {
    return app.$vuetify.breakpoint.smAndDown;
  };

  const isTablet = (): boolean => {
    return app.$vuetify.breakpoint.mdAndDown;
  };

  const isLaptop = (): boolean => {
    return app.$vuetify.breakpoint.lgOnly;
  };

  const isDesktop = (): boolean => {
    return app.$vuetify.breakpoint.lgAndUp;
  };

  return {
    isMobile,
    isTablet,
    isLaptop,
    isDesktop,
  };
};

export default useBreakpoints;
