import { Component, Vue } from 'vue-property-decorator';
import type IStockForm from '@/interfaces/forms/stock/IStockForm';
import StockRepository from '@/repository/Stock';
import CompanyModule from '@/store/modules/Company';

@Component
export default class UpdateStockMixin extends Vue {
  public async UpdateStockMixin(
    payload: IStockForm,
    stockUUID: string,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<void> {
    // Add VAT reference UUID to payload
    payload.vat_reference_uuid = payload.vat_reference?.uuid ?? '';
    await StockRepository.update(companyUUID, stockUUID, payload);
  }
}
