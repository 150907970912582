enum DocumentActivity {
  CREATED = 'created',
  UPDATED = 'updated',
  EMAIL_SENT = 'email_sent',
  DOWNLOADED = 'downloaded',
  EMAIL_OPENED = 'email_opened',
  STATUS_CHANGE = 'status_change',
  PAYMENT_CREATED = 'payment_created',
}

export default DocumentActivity;
