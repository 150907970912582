import { Component, Vue } from 'vue-property-decorator';
import type ICompany from '@/interfaces/company/ICompany';
import type IDocumentSettings from '@/interfaces/company/IDocumentSettings';
import CompanyRepository from '@/repository/Company';
import CompanyModule from '@/store/modules/Company';

@Component
export default class UpdateDocumentSettingsMixin extends Vue {
  public async UpdateDocumentSettingsMixin(
    payload: IDocumentSettings,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<ICompany> {
    return (await CompanyRepository.updateDocumentSettings(companyUUID, payload)).data;
  }
}
