import { ObjectDirective } from 'vue';
import { DirectiveBinding } from 'vue/types/options';

const TrimDirective: ObjectDirective = {
  inserted(el: HTMLElement, binding: DirectiveBinding): void {
    const inputElement = el.querySelector('input') || el.querySelector('textarea');

    if (inputElement) {
      inputElement.addEventListener('blur', () => {
        let value = inputElement.value;

        if (typeof value === 'string') {
          value = processValue(value, binding);
          inputElement.value = value;
          const event = new Event('input', { bubbles: true, cancelable: true });
          inputElement.dispatchEvent(event);
        }
      });
    }
  },
  componentUpdated(el: HTMLElement, binding: DirectiveBinding): void {
    const editableElement = el.querySelector('div[contenteditable]');

    if (editableElement) {
      editableElement.addEventListener('blur', () => {
        let value = editableElement.textContent;

        if (typeof value === 'string') {
          value = processValue(value, binding);
          editableElement.textContent = value;
          const event = new Event('input', { bubbles: true, cancelable: true });
          editableElement.dispatchEvent(event);
        }
      });
    }
  },
};

function processValue(value: string, binding: DirectiveBinding): string {
  // Trim all spaces (excluding newlines) if modifier is set to all otherwise trim only the beginning and end of the string
  value = binding.modifiers.all ? value.replace(/[^\S\r\n]+/g, '') : value.replace(/[^\S\r\n]+/g, ' ').trim();

  // If modifier is set to lowercase, uppercase or capitalize
  // Convert the string to lowercase, uppercase or capitalize the first letter of each sentence
  if (binding.modifiers.lowercase) {
    value = value.toLowerCase();
  } else if (binding.modifiers.uppercase) {
    value = value.toUpperCase();
  } else if (binding.modifiers.capitalize) {
    const sentences = value.split(/([.!?])\s/).filter(Boolean);
    for (let i = 0; i < sentences.length; i++) {
      sentences[i] = sentences[i].charAt(0).toUpperCase() + sentences[i].slice(1);
    }
    value = sentences.join(' ');
  } else if (binding.modifiers.capitalizeWords) {
    value = value.replace(/\b\w/g, (char) => char.toUpperCase());
  }

  return value;
}

export default TrimDirective;
