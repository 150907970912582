import Vue from 'vue';
import * as rules from 'vee-validate/dist/rules';
import { ValidationProvider, ValidationObserver, setInteractionMode, extend } from 'vee-validate';
import TranslationModule from '@/store/modules/Translation';
import COUNTRY_ISO from '@/constants/regex/iso/CountryISO';
import CURRENCY_ISO from '@/constants/regex/iso/CurrencyISO';
import LOCALE_ISO from '@/constants/regex/iso/LocaleISO';
import PHONE_PREFIX_ISO from '@/constants/regex/iso/PhonePrefixISO';
import ITERATOR_DAY from '@/constants/regex/iterator/Day';
import ITERATOR_MONTH from '@/constants/regex/iterator/Month';
import ITERATOR_YEAR from '@/constants/regex/iterator/Year';
import ITERATOR_PLAIN from '@/constants/regex/iterator/Plain';
import DECIMAL from '@/constants/regex/Decimal';
import HEX_COLOR from '@/constants/regex/HexColor';
import PASSWORD_STRENGTH from '@/constants/regex/PasswordStrength';
import PHONE_NUMBER from '@/constants/regex/PhoneNumber';
import REGISTRATION_NUMBER from '@/constants/regex/RegistrationNumber';
import WEBSITE_URL from '@/constants/regex/WebsiteUrl';

// import * as regex_const from '@/constants/validation.constant';

// Register validation observer and provider components
Vue.component('validation-provider', ValidationProvider);
Vue.component('validation-observer', ValidationObserver);

// Set interaction mode
setInteractionMode('eager');

// just to prevent linting error
const validationRules: any = rules;

// Custom rule: company registration number
validationRules.registration_number = {
  validate: (value: string): boolean => {
    return REGISTRATION_NUMBER.test(value.trim());
  },
};

// Custom rule: phone number
validationRules.phone_number = {
  validate: (value: string): boolean => {
    return PHONE_NUMBER.test(value);
  },
};

// Custom rule: country, currency and locale iso code where alpha are 2 or 3 characters
validationRules.iso = {
  validate: (value: string, { iso }: Record<string, string>): boolean => {
    if (iso === 'country') {
      return COUNTRY_ISO.test(value.toLowerCase());
    }
    if (iso === 'locale') {
      return LOCALE_ISO.test(value.toLowerCase());
    }
    if (iso === 'currency') {
      return CURRENCY_ISO.test(value.toUpperCase());
    }
    if (iso === 'phone_prefix') {
      return PHONE_PREFIX_ISO.test(value.toUpperCase());
    }

    return false;
  },
  params: ['iso'],
};

validationRules.mimetypes = {
  validate: (value: File[] | File, mimeTypes: string[]): boolean => {
    const files: File[] = Array.isArray(value) ? value : [value];

    // Invalid validation should be set to False
    return !files.some((file: File) => {
      const fileMimeType: string = file.type;
      // Check if file mime type is not in the list of allowed mime types
      return !mimeTypes.includes(fileMimeType);
    });
  },
};

validationRules.filesize = {
  validate: (value: File[] | File, { size }: Record<string, number>): boolean => {
    const files: File[] = Array.isArray(value) ? value : [value];

    return !files.some((file: File) => {
      const fileSize: number = file.size / 1000;
      // Check if file size is greater than the allowed file size
      return fileSize > size;
    });
  },
  params: ['size'],
};

validationRules.dimension = {
  validate: async (value: File[] | File, { width, height }: Record<string, number>): Promise<boolean> => {
    const files: File[] = Array.isArray(value) ? value : [value];

    const results = await Promise.all(
      files.map((file: File) => {
        return new Promise((resolve) => {
          const image: HTMLImageElement = new Image();
          image.src = URL.createObjectURL(file);
          image.onload = () => {
            const imageWidth: number = image.width;
            const imageHeight: number = image.height;

            // Check if image width and height are greater than the allowed width and height
            resolve(imageWidth > width || imageHeight > height);
          };
        });
      }),
    );

    const isValid = !results.some((result) => result);
    return isValid;
  },
  params: ['width', 'height'],
};

validationRules.color = {
  validate: (value: string, { type }: Record<string, string>): boolean => {
    if (type === 'hex') {
      return HEX_COLOR.test(value);
    }
    return false;
  },
  params: ['type'],
};

// Custom rule: password strength
validationRules.password_strength = {
  validate: (value: string): boolean => {
    return PASSWORD_STRENGTH.test(value);
  },
};

// Custom rule: valid website url
validationRules.website = {
  validate: (value: string): boolean => {
    return WEBSITE_URL.test(value);
  },
};

// Custom rule: decimal number
validationRules.decimal = {
  validate: (value: string): boolean => {
    return DECIMAL.test(value);
  },
};

// Custom rule: decimal places limit exceeded
validationRules.decimal_places_limit = {
  validate: (value: string, { count }: any): boolean => {
    const patern = '^-?\\d*(?:\\.?\\d{0,' + count + '})$';

    return new RegExp(patern).test(value);
  },
  params: ['count'],
};

// Custom rule: record number format
validationRules.record_number_format = {
  validate: (value: string): boolean => {
    const hasNumberIterator: boolean = ITERATOR_PLAIN.test(value);

    const iteratorCount: RegExpMatchArray | null = value.match(new RegExp(/{N.*?}/, 'g'));
    const hasSingleIterator: boolean = !!iteratorCount && iteratorCount.length === 1;

    return hasNumberIterator && hasSingleIterator;
  },
};

// Custom rule: total amount allowed
validationRules.total_amount = {
  validate: (value: string, args: string[]): boolean => {
    const pow: number | string = args.length ? args[0] : 9;

    const isPow: boolean = !isNaN(Number(pow));
    const isNumber: boolean = !isNaN(Number(value));

    if (isNumber) return Number(value) < Math.pow(10, isPow ? Number(pow) : 9);
    return false;
  },
};

// Custom rule: prepaid amount allowed
validationRules.prepaid_amount = {
  validate: (value: string, { amount }: any): boolean => {
    const hasTotalAmount: boolean = !isNaN(Number(amount));
    const hasPrepaidAmount: boolean = !isNaN(Number(value));

    if (hasPrepaidAmount && hasTotalAmount) {
      return Number(value) <= Number(amount);
    }
    return false;
  },
  params: ['amount'],
};

// Custom rule: number iterator with year key
validationRules.iterator_y = {
  validate: (value: string): boolean => {
    const numIterator: RegExpMatchArray | null = value.match(new RegExp(/{N.*?}/, 'g'));
    if (numIterator) {
      const yearKey: string = /.*(Y).*/.test(numIterator[0]) ? numIterator[0].replace(/.*(Y).*/g, '$1') : '';
      if (yearKey) {
        const hasYear: boolean = ITERATOR_YEAR.test(value);
        return hasYear;
      }
    }
    return true;
  },
};

// Custom rule: number iterator with month key
validationRules.iterator_m = {
  validate: (value: string): boolean => {
    const numIterator: RegExpMatchArray | null = value.match(new RegExp(/{N.*?}/, 'g'));
    if (numIterator) {
      const monthKey: string = /.*(M).*/.test(numIterator[0]) ? numIterator[0].replace(/.*(M).*/g, '$1') : '';
      if (monthKey) {
        const hasYear: boolean = ITERATOR_YEAR.test(value);
        const hasMonth: boolean = ITERATOR_MONTH.test(value);
        return hasYear && hasMonth;
      }
    }
    return true;
  },
};

// Custom rule: number iterator with day key
validationRules.iterator_d = {
  validate: (value: string): boolean => {
    const numIterator: RegExpMatchArray | null = value.match(new RegExp(/{N.*?}/, 'g'));
    if (numIterator) {
      const dayKey: string = /.*(D).*/.test(numIterator[0]) ? numIterator[0].replace(/.*(D).*/g, '$1') : '';
      if (dayKey) {
        const hasYear: boolean = ITERATOR_YEAR.test(value);
        const hasMonth: boolean = ITERATOR_MONTH.test(value);
        const hasDay: boolean = ITERATOR_DAY.test(value);
        return hasYear && hasMonth && hasDay;
      }
    }
    return true;
  },
};

// Register rules
Object.entries(rules).forEach(([name, schema]) => {
  extend(name, {
    ...schema,
    message(field: string, params: Record<string, any>): string {
      return TranslationModule.getValidationKeyTranslation(field, params);
    },
  });
});
