<template>
  <svg height="21" width="40" class="loader">
    <circle class="dot" cx="10" cy="10" r="3" fill="currentColor" />
    <circle class="dot" cx="20" cy="10" r="3" fill="currentColor" />
    <circle class="dot" cx="30" cy="10" r="3" fill="currentColor" />
  </svg>
</template>

<style scoped lang="scss">
  @keyframes blink {
    50% {
      fill: transparent;
    }
  }
  .dot {
    animation: 1s blink infinite;
    fill: currentColor;
  }
  .dot:nth-child(2) {
    animation-delay: 250ms;
  }
  .dot:nth-child(3) {
    animation-delay: 500ms;
  }

  .loader {
    color: currentColor;
  }
</style>

<script lang="ts">
  import { Component, Vue } from 'vue-property-decorator';

  @Component
  export default class TypingIcon extends Vue {
    /*****         computed       *****/
    /*****         watchers       *****/
    /*****         methods        *****/
    /*****         helpers        *****/
    /*****      vue lifecycle     *****/
  }
</script>
