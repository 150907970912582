import { Component, Vue } from 'vue-property-decorator';
import type IRegistrySearchForm from '@/interfaces/forms/helper/IRegistrySearchForm';
import type IBusinessRegistry from '@/interfaces/IBusinessRegistry';
import HelperRepository from '@/repository/Helper';

@Component
export default class ListBusinessRegistryMixin extends Vue {
  public async ListBusinessRegistryMixin(payload: IRegistrySearchForm): Promise<IBusinessRegistry[]> {
    try {
      return (await HelperRepository.listBusinessRegistry(payload)).data;
    } catch (error) {
      this.$logger('There was a problem searching registry database...');
      return [];
    }
  }
}
