<template>
  <v-footer color="black">
    <v-container class="flex items-center justify-between cultured--text" :class="isMobile ? 'flex-col py-4' : 'py-8'">
      <div
        class="flex text-body-2"
        :class="isMobile ? 'flex-col items-start w-full gap-4 py-4' : 'items-center justify-start gap-16'"
      >
        <NumberoIcon />
        <div class="flex flex-col items-start justify-center shrink gap-2">
          <a :href="`mailto:${numberoEmail}`" target="_blank" v-text="numberoEmail" class="link cultured--text" />
          <a
            :href="`tel:${removeWhiteSpaces(numberoPhoneNumber)}`"
            target="_blank"
            v-text="numberoPhoneNumber"
            class="link cultured--text"
          />
        </div>
        <div class="flex flex-col items-start justify-center shrink gap-2">
          <a
            :href="termsConditionsLink"
            target="_blank"
            v-text="trans('legal.form.terms_of_use', 'Lietošanas noteikumi')"
            class="link cultured--text"
          />
          <a
            :href="privacyPolicyLink"
            target="_blank"
            v-text="trans('legal.privacy_policy', 'Privātuma politika')"
            class="link cultured--text"
          />
        </div>
      </div>
      <div class="text-caption silver--text">
        <span v-html="sanitizeHTML(copyrigthText)" />
      </div>
    </v-container>
  </v-footer>
</template>

<script lang="ts">
  import { Component, Vue } from 'vue-property-decorator';
  import useBreakpoints from '@/services/Breakpoints';
  import TranslationModule from '@/store/modules/Translation';
  import NumberoIcon from '@/components/icons/brands/Numbero.vue';
  import Locale from '@/enums/config/Locale';

  @Component({
    components: {
      NumberoIcon,
    },
  })
  export default class Footer extends Vue {
    /*****         computed       *****/

    public get localeLink(): string {
      const locale = TranslationModule.getLocale;
      return locale === Locale.LV ? '' : `${locale}/`;
    }

    public get currentYear(): number {
      return new Date().getFullYear();
    }

    public get isMobile(): boolean {
      return useBreakpoints().isMobile();
    }

    public get numberoEmail(): string {
      return 'info@numbero.app';
    }

    public get numberoPhoneNumber(): string {
      return '+371 28 25 333 5';
    }

    public get termsConditionsLink(): string {
      return 'https://numbero.app/' + this.localeLink + 'legal/terms-conditions';
    }

    public get privacyPolicyLink(): string {
      return 'https://numbero.app/' + this.localeLink + 'legal/privacy-policy';
    }

    public get copyrigthText(): string {
      return `&copy; Numbero ${this.currentYear}. ${this.trans(
        'workspace.title.all_rights_reserved',
        'Visas Tiesības Aizsargātas',
      )}.`;
    }

    /*****         watchers       *****/
    /*****         methods        *****/
    /*****         helpers        *****/
    /*****      vue lifecycle     *****/
  }
</script>
